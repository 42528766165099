export const channelsData = [
  {
    id: 1,
    name: "Channel 1/ Puja to Guru Dev",
    title: "Homage to the Vedic Tradition of Masters",
    subtitle: "Puja to Guru Dev",
    description:
      "Maharishi honours the eternal tradition of Vedic Masters that has passed down the supreme knowledge of Total Natural Law from teacher to student throughout the ages. On behalf if the entire world family, Maharishi in this programme expresses offerings of gratitude and love to His Divinity Brahmananda Saraswati, Maharishi's master who has inspired the dawn of a new era of enlightenment and peace for all time to come.",
    videoSrc:
      "https://mh01-ch1.delivery.fuugo.com/smil:mh01-ch1.smil/playlist.m3u8",
  },
  {
    id: 2,
    name: "Channel 2/ Rudra Abhishek",
    title: "Rudra Abhishek",
    subtitle: "Vedic Recitation for World Peace",
    description:
      "In modern science, quantum physics has discovered that everything in creation is the expression of the self-interacting dynamics or reverberations of the United Field of Natural Law. Maharishi's Vedic Science shows that this fundamental field of Nature's intelligence can be experienced on the leve of one's own self-referral awareness and that the sounds of Vedic recitation can enliven the nourishing and harmonizing qualities of Natural Law in collective consciousness and throughout the environment.",
    videoSrc:
      "https://mh01-ch2.delivery.fuugo.com/smil:mh01-ch2.smil/playlist.m3u8",
  },
  {
    id: 3,
    name: "Channel 3/ News of Rising Invincibility",
    title: "News of Rising Invincibility",
    subtitle: "Daily Reports from Around the World",
    description:
      "For more than 50 years, Maharishi's Vedic programmes have brought enlightenment and bliss to the world family. Now, share in the exciting news of all activities of the Global Country of World Peace that Maharishi has founded to maintain Heaven on Earth in each generation.",
    videoSrc: "",
    links: [
      {
        linkName: "Play LIVE",
        linkSrc:
          "https://mh01-ch3-low-no-dvr.delivery.fuugo.com/ngrp:mh01-ch3-low-no-dvr-5a4ac5d6_all/playlist.m3u8",
      },
      {
        linkName: "Play (Time Slider)",
        linkSrc:
          "https://mh01-ch3-low.delivery.fuugo.com/ngrp:mh01-Channel_3_Low-e857ff93_all/playlist.m3u8?DVR",
      },
      {
        linkName: "Play HD (Time Slider)",
        linkSrc:
          "https://mh01-ch3-high.delivery.fuugo.com/ngrp:mh01-Channel_3_High-87901a18_all/playlist.m3u8?DVR",
      },
    ],
  },
  {
    id: 4,
    name: "Channel 4/ Coronation of New Rajas",
    title: "Coronation of New Rajas",
    subtitle: "Administrators of Total Knowledge",
    description:
      "Maharishi has trained Vedic administrators, Rajas, to bring his Vedic Science and Technology to each country of the world. This celebration features traditional Vedic recitation that enlivens in the awareness of each Raja the cosmic intelligence of the Unified Field of Natural Law-the Constitution of the Universe-which administers the infinite diversity of the ever-expanding universe in perfect harmony.",
    videoSrc:
      "https://mh01-web-vod.delivery.fuugo.com/2007_10_30_Coronation_Ceremony_of_Rajas_Global_Country_of_World_Peace_CH4/playlist.m3u8?ex=1653519555&ul=75&sf=b5b7cac3f1f4c9eb4b7a01f6aed09dec",
  },
  {
    id: 5,
    name: "Channel 5/ Global Mother Divine",
    title: "Global Mother Divine",
    subtitle: "Knowledge and Enlightenment",
    description:
      "The Global Mother Divine Organization offers every lady the knowledge and practical techniques to gain inner happiness, perfect health, and success in every area of life. Through these simple and effective programmes, the ladies of the world hace the opportunity to create harmony, prosperity and everlasting peace in our family of nations.",
    videoSrc: "",
    links: [
      {
        linkName: "Play",
        linkSrc:
          "https://mh01-ch5.delivery.fuugo.com/smil:mh01-ch5.smil/playlist.m3u8",
      },
      {
        linkName: "Archives",
      },
    ],
  },
  {
    id: 6,
    name: "Channel 6/ Maharishi's Vedic Science",
    title: "Presentations on Maharishi's Vedic Science in Hindi",
    subtitle: "",
    description:
      "This channel features a wide range of inspiring programmes especially form India -Ved Bhumi Bharat- the Land of the Veda. Each day's schedule includes Vedic Recitation Performances, Pujas, Bhajans, and beautiful lectures by His Holiness Maharishi Mahesh Yogui in Hindi as well as the best programmes of Channel Three.",
    videoSrc:
      "https://mh01-ch6.delivery.fuugo.com/smil:mh01-ch6.smil/playlist.m3u8",
  },
  {
    id: 7,
    name: "Archive of Maharishi's Global Family Chat",
  },
];
