import { channelsData } from "./channelsData.js";
import { archivesData } from "./archivesData.js";
import { archivesChannel5 } from "./archivesChannel5";
import Hls from "hls.js";

// * Elements of the video section
const channelTitle = document.getElementById("channel-title");
const channelSubtitle = document.getElementById("channel-subtitle");
const channelDescription = document.getElementById("channel-description");
const videoRef = document.getElementById("video-link");
const video = document.getElementById("video");

// * Elements from the accordion section
const accordion = document.getElementsByClassName("accordion");
const accordionSection = document.getElementById("accordion-section");
const archiveAccordion = document.getElementById("archive-accordion-section");
const archiveList = document.getElementById("archive-list");

// * Icon elements
const videoDownloadIcon = document.getElementById("channel-video-download");
const backIcon = document.getElementById("back-icon");
const playIcon = document.createElement("i");
playIcon.setAttribute("class", "fa-solid fa-play");
playIcon.setAttribute("id", "play-icon");

// * Boolean variables
let isArchiveChannel5Active = false;

// * Initialize the video player (Video.js library) & mute the video at the beginning
const player = videojs("video", { liveui: true, autoplay: true });
player.muted(true);

// * Fill the video description with channel's 3 info
const defaultVideoSrc = channelsData[2].links[0].linkSrc;
changeVideoInformation(
  channelsData[2].title,
  channelsData[2].subtitle,
  channelsData[2].description,
  defaultVideoSrc
);

/*
  Code for the channels Accordion 
*/
// * Create and return an arrow icon
function createArrowIcon() {
  const arrowIcon = document.createElement("i");
  arrowIcon.setAttribute("class", "fa-solid fa-angle-right");

  return arrowIcon;
}

// * Create and return a div element for the panel section
function createPanel() {
  const panelDiv = document.createElement("div");
  panelDiv.setAttribute("class", "panel");

  return panelDiv;
}

// * Create a function to add an element to the 'channels' accordion
function addAccordionElement(channelData) {
  // Create a new element and add the content
  let accordionButton = document.createElement("button");
  const channelNumber = channelData.name.split("/")[0];
  const channelName = channelData.name.split("/")[1];
  let buttonContent;

  // This code takes
  if ((channelNumber != null, channelName != null)) {
    const spanParent = document.createElement("span");
    const span = document.createElement("span");
    const spanContent = document.createTextNode(channelNumber);
    spanParent.style.fontWeight = "lighter";
    buttonContent = document.createTextNode(channelName);
    span.appendChild(spanContent);
    span.classList.add("span-channel-name");

    spanParent.appendChild(span);
    spanParent.appendChild(buttonContent);

    accordionButton.classList.add("span-channel-name");
    accordionButton.appendChild(spanParent);
  } else {
    buttonContent = document.createTextNode(channelData.name);
    accordionButton.appendChild(buttonContent);
  }

  accordionButton.setAttribute("class", "accordion");

  // Add the created element to the DOM
  const accordionSection = document.getElementById("accordion-section");
  accordionSection.insertAdjacentElement("beforeend", accordionButton);

  // If the channels have additional links, add an arrow-icon
  let isArchiveButton = channelData.name == "archive";
  if (hasLinks(channelData) || isArchiveButton) {
    accordionButton.insertAdjacentElement("beforeend", createArrowIcon());

    if (!isArchiveButton) addPanel(channelData, accordionButton);
  }
}

// * Add buttons the Accordion section
channelsData.map((channelData) => {
  addAccordionElement(channelData);
});

// * This function checks if the current channel has additional links
function hasLinks(channelData) {
  const haveLinks = channelData.hasOwnProperty("links");
  return haveLinks ? true : false;
}

// * Add the panel with the additional links, (for channels 3 & 5)
function addPanel(channelData, accordionButton) {
  const panel = createPanel();
  panel.innerHTML = `
    <ul class="list-disc">
      ${channelData.links
        .map(
          (link) =>
            `<li id="${link.linkSrc}" class="list-element-accordion">${link.linkName}</li>`
        )
        .join("")}
    </ul>
  `;

  accordionButton.insertAdjacentElement("afterend", panel);

  const listElementAccordion = document.querySelectorAll(
    ".list-element-accordion"
  );
  listElementAccordion.forEach((element) => {
    element.addEventListener("click", function () {
      if (element.innerHTML == "Archives") {
        isArchiveChannel5Active = true;

        // * Empty the list
        archiveList.innerHTML = "";

        // * Fill the archive accordion for channel 5 with the elements from the json object
        archivesChannel5.map((archive) => {
          let year = addArchiveYearPanel(archive, archive.year);
          addArchiveElements(archive, year);
        });

        accordionSection.classList.add("accordion-inactive");
        archiveAccordion.classList.add("accordion-active");
      } else {
        loadVideo(element.id);
        player.muted(false);
      }
    });
  });
}

// * Change the titles, description, and source of the video
function changeVideoInformation(title, subtitle, description, videoSrc) {
  channelTitle.innerHTML = title;
  channelSubtitle.innerHTML = subtitle;
  channelDescription.innerHTML = description;
  loadVideo(videoSrc);
}

// * Implement the Hls.js library for playing videos
function loadVideo(videoSrc) {
  if (Hls.isSupported()) {
    var hls = new Hls();
    var config = {
      capLevelToPlayerSize: true,
      maxBufferSize: 30,
      maxBufferLength: 30,
    };
    window.self.hls = new Hls(config);
    hls.loadSource(videoSrc);
    hls.attachMedia(video);
    hls.on(Hls.Events.MANIFEST_PARSED, function () {
      player.src(videoSrc);
      player.ready(function () {
        player.play();
      });
    });
  } else if (player.canPlayType("application/vnd.apple.mpegurl")) {
    player.src(videoSrc);
    player.addEventListener("canplay", function () {
      player.play();
    });
  }
}

// * Deactivate the dropdown menu for the channel 3 or channel 5, if one of them is active
function deactivateDropdownChannels() {
  let panelChannel3 = accordion[2].nextElementSibling;
  let panelChannel5 = accordion[4].nextElementSibling;
  if (panelChannel3.style.maxHeight != "") {
    handleClickPanel(accordion[2], null);
    const icon = accordion[2].childNodes[1];
    icon.classList.remove("icon-active");
  } else if (panelChannel5.style.maxHeight != "") {
    handleClickPanel(accordion[4], null);
    const icon = accordion[4].childNodes[1];
    icon.classList.remove("icon-active");
  }
}

// * Add an event listener to each accordion's button
for (let i = 0; i < accordion.length; i++) {
  accordion[i].addEventListener("click", function () {
    if (channelsData[i].name === "Archive of Maharishi's Global Family Chat") {
      deactivateDropdownChannels();

      let title = "Maharishi Channel Archives";
      let subtitle = "Select a day on the right menu";
      changeVideoInformation(title, subtitle, "", "");

      accordionSection.classList.add("accordion-inactive");
      archiveAccordion.classList.add("accordion-active");
    } else {
      let title = channelsData[i].title;
      let subtitle = channelsData[i].subtitle;
      let description = channelsData[i].description;
      let videoSrc = channelsData[i].videoSrc;
      changeVideoInformation(title, subtitle, description, videoSrc);

      updateActiveClass(i);

      // Activate the video sound except for channels' 3 & 5 dropdown
      if (!hasLinks(channelsData[i])) {
        deactivateDropdownChannels();
        player.muted(false);
      }

      // Show the panel for channels 3 & 5
      if (hasLinks(channelsData[i])) {
        let panel;
        if (i == 2) {
          panel = accordion[4].nextElementSibling;
          if (panel.style.maxHeight != "") {
            handleClickPanel(accordion[4], null);
            const icon = accordion[4].childNodes[1];
            icon.classList.remove("icon-active");
          }
        } else {
          panel = accordion[2].nextElementSibling;
          if (panel.style.maxHeight != "") {
            handleClickPanel(accordion[2], null);
            const icon = accordion[2].childNodes[1];
            icon.classList.remove("icon-active");
          }
        }

        accordion[i].style.margin = "0";
        handleClickPanel(accordion[i], null);
      }
    }
  });
}

// * Convert accordion element into an array
const accordionArray = [...accordion];

// * Update the 'active' class of the element
function updateActiveClass(index) {
  // Add the 'active' class to the current element.
  accordionArray[index].classList.add("active");

  if (!hasLinks(channelsData[index])) {
    playIcon.hidden = false;
    accordionArray[index].insertAdjacentElement("beforeend", playIcon);
  } else {
    const children = accordionArray[index].childNodes;
    children[1].classList.toggle("icon-active");
  }

  // Remove it for the rest of the elements.
  accordionArray.filter((accordionElement, i) => {
    if (i != index) {
      accordionElement.classList.remove("active");

      // And hide the 'play' icon
      if (hasLinks(channelsData[index])) {
        playIcon.hidden = true;
      }
    }
  });
}

// * This function shows the panel of the elements that has 'dropdown' option
function handleClickPanel(currentElement, arrowIcon) {
  if (arrowIcon != null) {
    arrowIcon.classList.toggle("icon-active-archive");
  }

  let panel = currentElement.nextElementSibling;
  if (panel.style.maxHeight) {
    panel.style.maxHeight = null;
  } else {
    panel.style.maxHeight = panel.scrollHeight + "px";
  }
}

/*
  Code for the Archive Accordion section
*/
// * Create the 'back' button for the Archive accordion
backIcon.addEventListener("click", () => {
  // Remove CSS class from the elements inside the accordion
  archiveAccordion.classList.remove("accordion-active");
  accordionSection.classList.remove("accordion-inactive");

  if (isArchiveChannel5Active) {
    isArchiveChannel5Active = false;

    // * Empty the list
    archiveList.innerHTML = "";

    // * Fill the archive accordion with the default Archives
    archivesData.map((archive) => {
      let year = addArchiveYearPanel(archive, archive.year);
      addArchiveElements(archive, year);
    });
  }

  // Add elements to the Archive accordion
  const yearsElements = document.querySelectorAll(".add-year-list");
  yearsElements.forEach((element) => {
    element.nextSibling.style.maxHeight = null;
    element.lastChild.classList.remove("icon-active-archive");
  });

  // Add some default information to the 'Video description'
  let title = "Maharishi Channel Archives";
  let subtitle = "Select a day on the right menu";
  changeVideoInformation(title, subtitle, "", "");

  videoDownloadIcon.classList.add("hidden");
});

// * Create and return a list element (<li></li>)
function createListElement(listContent, hasSubList, object, filter) {
  const listLabel = document.createElement("li");
  let text;
  if (filter == "day") {
    const listContentTmp = `Day ${listContent}`;
    text = document.createTextNode(listContentTmp);
  } else {
    text = document.createTextNode(listContent);
  }
  const arrowIcon = createArrowIcon();

  listLabel.appendChild(text);

  if (filter == "day") {
    listLabel.classList.toggle("add-day-list");
  } else {
    listLabel.appendChild(arrowIcon);
  }

  listLabel.addEventListener("click", () => {
    if (filter == "day") {
      listLabel.classList.toggle("add-day-list-active");
      const anotherLabels = document.querySelectorAll(".add-day-list-active");
      anotherLabels.forEach((currentLabel) => {
        if (!(listLabel == currentLabel)) {
          currentLabel.classList.toggle("add-day-list-active");
        }
      });

      object.filter((element) => {
        if (listContent == element.day) {
          let title = `Day ${element.day}`;
          let description = element.description;
          let videoSrc = element.videoSrc;
          changeVideoInformation(title, "", description, videoSrc);

          // Activate the video sound
          player.muted(false);

          videoDownloadIcon.classList.remove("hidden");
          videoRef.setAttribute("href", videoSrc);
        }
      });
    } else {
      listLabel.classList.toggle("add-month-list");

      const monthsElements = document.querySelectorAll(".add-month-list");
      monthsElements.forEach((element) => {
        if (!(listLabel == element)) {
          element.nextSibling.style.maxHeight = null;
          element.lastChild.classList.remove("icon-active-archive");
        }
      });

      handleClickPanel(listLabel, arrowIcon);
    }
  });

  return listLabel;
}

// * Create and return an unordered list (<ul></ul>) for the Accordion section
function createList(elements, object, filter) {
  const list = document.createElement("ul");

  elements.map((element) => {
    list.appendChild(createListElement(element, true, object, filter));
    const divider = document.createElement("hr");

    if (filter == "days") {
      object.filter((elementO) => {
        if (elementO.monthName == element) {
          const daysPanel = createPanel();
          const days = elementO.days.map((day) => day.day);
          const daysList = createList(days, elementO.days, "day");

          daysPanel.appendChild(daysList);

          list.appendChild(daysPanel);
        }
      });
    }

    list.appendChild(divider);
  });

  return list;
}

// * This function adds the month's list to each 'year' panel
function addArchiveElements(archive, year) {
  const monthsPanel = createPanel();
  const months = archive.months.map((month) => month.monthName);
  monthsPanel.style.overflowY = "scroll";
  const monthsList = createList(months, archive.months, "days");

  monthsPanel.appendChild(monthsList);

  year.insertAdjacentElement("afterend", monthsPanel);
}

// * Add the Archive panel with the years list
function addArchiveYearPanel(archive, property) {
  const yearListElement = document.createElement("li");
  const divider = document.createElement("hr");
  const span = document.createElement("span");
  const paragraph = document.createElement("p");
  const arrowIcon = createArrowIcon();

  paragraph.innerHTML = "Year ";
  span.innerHTML = property;
  paragraph.appendChild(span);

  yearListElement.classList.toggle("add-year-list");
  yearListElement.appendChild(paragraph);

  yearListElement.appendChild(arrowIcon);

  yearListElement.addEventListener("click", () => {
    yearListElement.classList.toggle("list-year-active");

    const yearsElements = document.querySelectorAll(".add-year-list");
    yearsElements.forEach((element) => {
      if (!(yearListElement == element)) {
        element.nextSibling.style.maxHeight = null;
        element.lastChild.classList.remove("icon-active-archive");

        const monthsElements = document.querySelectorAll(".add-month-list");
        monthsElements.forEach((element) => {
          element.nextSibling.style.maxHeight = null;
          element.lastChild.classList.remove("icon-active-archive");
        });
      }
    });

    handleClickPanel(yearListElement, arrowIcon);
  });

  archiveList.appendChild(yearListElement);
  archiveList.appendChild(divider);

  return yearListElement;
}

// * This function is for adding the years, months, and days lists inside the Archive accordion section
archivesData.map((archive) => {
  let year = addArchiveYearPanel(archive, archive.year);
  addArchiveElements(archive, year);
});
