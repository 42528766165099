export const archivesChannel5 = [
  // Year 2022
  {
    year: 2022,
    months: [
      {
        monthName: "Jan",
        days: [
          {
            day: "GMDO Full Moon 17 January 2022",
            description:
              "Global Mother Divine Organization And Mother Divine Program 2021 Achievements Report (2 hours)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2022-01-17_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Feb",
        days: [
          {
            day: "GMDO Full Moon 16 February 2022",
            description: "Maharishi on Love and God (50 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2022-02-16_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Mar",
        days: [
          {
            day: "GMDO Full Moon 18 March 2022",
            description:
              "How to Grip the Infinite Creative Power of the Universe (45 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2022-03-18_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Apr",
        days: [
          {
            day: "GMDO Full Moon 16 April 2022",
            description:
              "Celebrating Sita Navami: The Birth of the Dynamic Power Responsible for Expansion of Unmanifest Pure Being into the Manifest Creation so that Wholeness Can Be Experienced in Life (45 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2022-04-16_GMDO_Fullmoon.mp4",
          },
        ],
      },
    ],
  },
  // Year 2021
  {
    year: 2021,
    months: [
      {
        monthName: "Jan",
        days: [
          {
            day: "GMDO Full Moon 28 Jan 21",
            description:
              "Research in Consciousness as a Member of the Mother Divine Programme and Doctoral Student in Maharishi Vedic Science—the Science of Consciousness (1 hour 8 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2021-01-28_GMDO_Fullmoon.mp4",
          },
          {
            day: "GMDO Achievements 12 Jan 21",
            description:
              "Global Mother Divine Organization and Mother Divine Programme 2020 Achievements Report (1 hour 5 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2021-01-12_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Feb",
        days: [
          {
            day: "GMDO Full Moon 27 Feb 21",
            description:
              "Vastu Vidya of Maharishi Sthapatya Veda: Celebrating the Structuring Intelligence of Natural Law and Building Ladies’ Peace Palaces (1 hour 14 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2021-02-27_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Mar",
        days: [
          {
            day: "GMDO Full Moon 28 Mar 21",
            description:
              "Maharishi Vedic Organic Agriculture—Celebrating the Nourishing Role of Cows: The Cows Foundation, Moo Matri Products, and a delightful new book, Through the Eyes of Priya (47 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2021-03-28_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Apr",
        days: [
          {
            day: "GMDO Full Moon 27 Apr 21",
            description:
              "Maharishi AyurVeda to Transform Pragyaparadh into the State of Enlightenment for Perfect Health to Enjoy Bliss, Longevity, and Lasting Achievements in Life (48 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2021-04-27_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "May",
        days: [
          {
            day: "GMDO Full Moon 26 May 21",
            description:
              "Celebrating Mother Ganga—Embodiment of the Nourishing and Purifying Power of the flow of Pure Knowledge (44 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2021-05-26_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "June",
        days: [
          {
            day: "GMDO Full Moon 24 June 21",
            description:
              "Absolute Celebration: The Delightful Role of Celebration in Creating Heaven on Earth (41 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2021-06-24_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "July",
        days: [],
      },
      {
        monthName: "Aug",
        days: [
          {
            day: "40th Annivesary of MD 31 Aug 21",
            description:
              "Mother Divine 40th Anniversary: Celebrating Every Woman Living Freedom, Fullness, and Enlightenment (48 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2021-08-31_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Sept",
        days: [
          {
            day: "GMDO Full Moon 20 Sept 21",
            description:
              "Maharishi AyurVeda: Its Timeless Tradition, Holistic Knowledge and Application, Purity, Efficacy, Modern Research and Development for a Global Outreach, and its Unique Application for the Health of Women of All Ages (48 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2021-09-20_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Oct",
        days: [
          {
            day: "GMDO Full Moon 20 Oct 21",
            description:
              "Celebrating Depavali—Enlivening the Light of Enlightenment in Every Individual and Transforming the World into Heaven on Earth (43 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2021-10-20_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Nov",
        days: [
          {
            day: "GMDO Full Moon 19 Nov 21",
            description:
              "Understanding the Meaning and Application of Rasayana in Maharishi AyurVeda (42 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2021-11-19_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Dec",
        days: [
          {
            day: "GMDO Full Moon 19 Dec 21",
            description:
              "The Global Peace Initiative — Supporting the Maharishi Vedic Pandits (1 hour 12 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2021-12-19_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Other",
        days: [
          {
            day: "GMDO Achievements Guru Purnima",
            description:
              "Global Mother Divine Organization and the Mother Divine Programme Guru Purnima 2021 Achievements Report (1 hour 27 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2021-08-05_RR-GP-Achievement-report.mp4",
          },
        ],
      },
    ],
  },
  // Year 2020
  {
    year: 2020,
    months: [
      {
        monthName: "Jan",
        days: [
          {
            day: "GMDO Achievements 12 Jan 20",
            description:
              "Offering of Achievements on all Continents from the Global Mother Divine Organization. (I) ASIA, (II) EUROPE (III) AFRICA (IV) AMERICAS (V) AUSTRALASIA (1 hour 49 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2020-01-12_GMDO_Achievements.mp4",
          },
        ],
      },
      {
        monthName: "Feb",
        days: [
          {
            day: "GMDO Full Moon 9 Feb 20",
            description:
              "Maharishi on Love and God—Love Is the Sweet Expression of Life; It Is the Supreme Content of Life (50 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2020-02-09_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Mar",
        days: [
          {
            day: "GMDO Full Moon 9 Mar 20",
            description:
              "Extraordinary Education for Extraordinary You: Develop your total brain functioning and higher consciousness as a firm foundation for a fulfilling life (57 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2020-03-09_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Apr",
        days: [
          {
            day: "GMDO Full Moon 8 Apr 20",
            description:
              "How the Small Brain Experiences Infinity (52 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2020-04-08_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "May",
        days: [
          {
            day: "GMDO Full Moon 7 May 20",
            description:
              "Celebrating the Presentation of the Raj Rajeshwari Rona Abramson Outstanding Achievement Award (1 hour 18 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2020-05-07_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "June",
        days: [
          {
            day: "GMDO Full Moon 5 June 20",
            description:
              "Indisputable Fact: We Can Change the World! (52 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2020-06-05_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "July",
        days: [
          {
            day: "GMDO Full Moon 5 July 20",
            description:
              "The Vedic Tradition of Masters: The Voice of Eternity (48 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2020-07-05_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Aug",
        days: [
          {
            day: "GMDO Full Moon 3 August 20",
            description:
              "Celebrating Krishna Janmasthami—the Birth of the Birthless Absolute (1 hour 9 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2020-08-03_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Sept",
        days: [
          {
            day: "GMDO Full Moon 2 September 20",
            description:
              "Celebration of Graduation for Students Receiving the Bachelor of Arts Degree in Science of Creative Intelligence for Management, Rajapark Institute, Thailand (1 hour 45 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2020-09-02_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Oct",
        days: [
          {
            day: "GMDO Full Moon 30 October 20",
            description:
              "Celebrating Dhanvantari and Mahalakshmi—Creating Perfect Health and Achieving the Great Goal (50 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2020-10-30_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Nov",
        days: [
          {
            day: "GMDO Full Moon 30 November 20",
            description:
              "Celebrating Gita Jayanti and the Anniversary of the Founding of the Global Mother Divine Organization (54 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2020-11-30_GMDO_Fullmoon.mp4",
          },
        ],
      },
    ],
  },
  // Year 2019
  {
    year: 2019,
    months: [
      {
        monthName: "Jan",
        days: [
          {
            day: " GMDO Achievements 12 Jan 19",
            description:
              "Offering of Achievements on all Continents from the Global Mother Divine Organization:(I) ASIA, (II) MIDDLE EAST (III) EUROPE (IV) AMERICAS (V) AUSTRALASIA (VI) AFRICA (2 hours 9 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2019-01-12_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Feb",
        days: [
          {
            day: " GMDO Full Moon 19 Feb 19",
            description:
              "Maharishi AyurVeda: Knowledge and Technology to Enliven the Connection Between Consciousness and Physiology (54 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2019-02-19_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Mar",
        days: [
          {
            day: " GMDO Full Moon 20 Mar 19",
            description:
              "How to Grip the Infinite Creative Power of the Universe (45 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2019-03-20_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Apr",
        days: [
          {
            day: " GMDO Full Moon 19 Apr 19",
            description:
              "Maharishi’s Practical Principles of Ideal Communication: Speak from the Unified Field (53 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2019-04-19_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "May",
        days: [
          {
            day: " GMDO Full Moon 18 May 19",
            description:
              "A Firsthand Report: Bringing Consciousness-Based Education to the Russian-speaking Nations—First Training Course Held in Russian (58 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2019-05-18_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "June",
        days: [
          {
            day: " GMDO Full Moon 17 June 19",
            description:
              "The Very Great Science on Three Fingertips—Self-Pulse Reading. Maintaining Balance for a Healthy Mind and Body (55 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2019-06-17_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "July",
        days: [
          {
            day: " GMDO Achievements 16 July 19",
            description:
              "Offering of Achievements on all Continents from the Global Mother Divine Organization:(I) ASIA, (II) EURASIA, (III) EUROPE, (IV) NORTH AMERICA, (V) CARIBBEAN, (VI) SOUTH AMERICA, (VII) AFRICA, (VIII) AUSTRALASIA (2 hours 19 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2019-07-16_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Aug",
        days: [
          {
            day: " GMDO Full Moon 15 Aug 19",
            description:
              "Religion and Culture: Fathoming the Essence of Ancient Traditions and Beliefs for Greater Organizing Power and Support of Nature (56 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2019-08-15_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Sept",
        days: [
          {
            day: " GMDO Full Moon 14 Sept 19",
            description: "The Origin of True Genius (52 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2019-09-14_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Oct",
        days: [
          {
            day: " GMDO Full Moon 13 Oct 19",
            description:
              "Maharishi College of Perfect Health—Introducing the Department of Dance Therapy (1 hour 26 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2019-10-13_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Nov",
        days: [
          {
            day: " GMDO Full Moon 12 Nov 19",
            description:
              "Maximizing the Intelligent Use of the Environment—Maharishi’s Contribution (52 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2019-11-12_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Dec",
        days: [
          {
            day: " GMDO Full Moon 12 Dec 19",
            description:
              "Mothers of the World Are True Custodians of Peace (1 hour 2 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2019-12-12_GMDO_Fullmoon.mp4",
          },
        ],
      },
    ],
  },
  // Year 2018
  {
    year: 2018,
    months: [
      {
        monthName: "Jan",
        days: [
          {
            day: " GMDO Full Moon 31 Jan 18",
            description:
              "Vastu Vidya of Maharishi Sthapatya Veda: Celebrating the Structuring Intelligence of Natural Law and Building Ladies’ Peace Palaces (1 hour 14 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2018-01-31_GMDO_Fullmoon.mp4",
          },
          {
            day: " GMDO Achievements 12 Jan 18",
            description:
              "Offering of Achievements on all Continents from the Global Mother Divine Organization. (I) ASIA, (II) EUROPE, (III) MIDDLE EAST (IV) AFRICA (V) SOUTH AMERICA (VI) NORTH AMERICA (1 hour 35 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2018-01-12_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Mar",
        days: [
          {
            day: " GMDO Full Moon 31 Mar 18",
            description:
              "Celebrating Sita Navami—The Birth of the Dynamic Power Responsible for Expansion of Unmanifest Pure Being into the Manifest Creation so that Wholeness Can Be Experienced in Life (45 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2018-03-31_GMDO_Fullmoon.mp4",
          },
          {
            day: " GMDO Full Moon 1 Mar 18",
            description:
              "Documenting the Rise of Higher States of Consciousness: Experiences of the 40 Qualities of Intelligence Displayed in the 40 Aspects of Veda and Vedic Literature Correlated with Expressions in the First Verse of Brahm Upanishad (1 hour 2 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2018-03-1_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Apr",
        days: [
          {
            day: " GMDO Full Moon 30 Apr 18",
            description:
              "Celebrating Mother Ganga—Embodiment of the Nourishing and Purifying Power of the flow of Pure Knowledge (44 mintues)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2018-04-30_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "May",
        days: [
          {
            day: " GMDO Full Moon 29 May 18",
            description:
              "Indisputable Fact: We Can Change the World! (53 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2018-05-29_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "June",
        days: [
          {
            day: " GMDO Full Moon 28 June 18",
            description:
              "Maharishi Gandharva Veda—the Music of Nature: An Interview with Reshma Srivastava, Internationally Renowned Gandharva Veda Sitarist (1 hour)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2018-06-28_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "July",
        days: [
          {
            day: " GMDO Achievements 27 July 18",
            description:
              "Global Mother Divine Organisation’s Achievements reports from 36 countries—(I) ASIA, (II) AUSTRALASIA, (III) AFRICA, (IV) MIDDLE EAST, (V) EURASIA, (VI) EUROPE, (VII) SOUTH AND CENTRAL AMERICA, (VIII) NORTH AMERICA (2 hours, 1 minute)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2018-07-27_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Aug",
        days: [
          {
            day: " GMDO Full Moon 26 Aug 18",
            description:
              "The Global Peace Initiative Supporting the Maharishi Vedic Pandits (1 hour 12 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2018-08-26_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Sept",
        days: [
          {
            day: " GMDO Full Moon 25 Sept 18",
            description:
              "Maharishi Jyotish and Maharishi Yagya—the Science and Technology of Prediction and Transformation (59 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2018-09-25_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Oct",
        days: [
          {
            day: " GMDO Full Moon 24 Oct 18",
            description:
              "The Human Physiology Is Made of Vedic Vibrations: Three Maharishi Ayurvedic Technologies which Use Vibrations of Consciousness as Available in the Veda and Vedic Literature to Improve Health (53 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2018-10-24_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Nov",
        days: [
          {
            day: " GMDO Full Moon 23 Nov 18",
            description:
              "Celebrating Gita Jayanti, The Bhagavad-Gita: Song of Divinity (57 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2018-11-23_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Dec",
        days: [
          {
            day: " GMDO Full Moon 22 Dec 18",
            description:
              "Everyone Can Learn How to Engage Natural Law to Work for Them to Fulfil Their Desires (54 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2018-12-22_GMDO_Fullmoon.mp4",
          },
        ],
      },
    ],
  },
  // Year 2017
  {
    year: 2017,
    months: [
      {
        monthName: "Jan",
        days: [
          {
            day: " GMDO Achievements 12 Jan 17",
            description:
              "Offering of Achievements on all Continents from the Global Mother Divine Organization: (I) Asia, (II) Asia-Europe, (III) Eastern Europe, (IV) Western Europe, (V) Africa, (VI) South America, (VII) North America, (VIII) Australasia (1 hour 34 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2017-01-12_GMDO_Achievements.mp4",
          },
        ],
      },
      {
        monthName: "Feb",
        days: [
          {
            day: " GMDO Full Moon 11 Feb 17",
            description:
              "Documenting the Rise of Higher States of Consciousness: Experiences of the 40 Qualities of Intelligence Displayed in the 40 Aspects of Veda and Vedic Literature Correlated with Expressions in the First Verse of Isha Upanishad (1 hour 7 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2017-02-11_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Mar",
        days: [
          {
            day: " GMDO Full Moon 12 Mar 17",
            description:
              "The Universal Divine Feminine Qualities Found in the Bhagavad-Gita: Exploring Their Expression in the Light of Maharishi Vedic Science Throughout Time and Various Cultures, in Great Women and in Our Own Lives (1 hour 13 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2017-03-12_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Apr",
        days: [
          {
            day: " GMDO Full Moon 11 Apr 17",
            description:
              "Veda Is the Supreme Authority on Immortality (51 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2017-04-11_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "May",
        days: [
          {
            day: " GMDO Full Moon 10 May 17",
            description:
              "Celebrating Mother: The First Teacher, the All-Nourishing Sustainer, and the Promoter of Evolution in Life (40 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2017-05-10_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "June",
        days: [
          {
            day: " GMDO Full Moon 9 June 17",
            description: "Veda Leela: The Play of Pure Knowledge (57 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2017-06-09_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "July",
        days: [
          {
            day: " GMDO Achievements 8 July 17",
            description:
              "Global Mother Divine Organisation: Offering Our Achievements from 33 Countries on Guru Purnima—(I) ASIA, (II) AUSTRALASIA, (III) AFRICA, (IV) MIDDLE EAST, (V) EUROPE, (VI) NORTH AMERICA, (VII) CARIBBEAN, (VIII) SOUTH AMERICA (1 hour 49 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2017-07-08_GMDO_Achievements.mp4",
          },
        ],
      },
      {
        monthName: "Aug",
        days: [
          {
            day: " GMDO Full Moon 7 Aug 17",
            description:
              "Global Mother Divine Organisation: Celebrating Three Years of Matri Mandala—Mothers of the World—Women Growing in Enlightenment and Creating Peace (1 hour 9 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2017-08-07_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Sept",
        days: [
          {
            day: " GMDO Full Moon 6 Sept 17",
            description:
              "Documenting the Rise of Higher States of Consciousness: Correlating Experience with Verse 2 of the Pashupata Brahmana Upanishad and the 40 Qualities of the Veda and Vedic Literature (54 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2017-09-06_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Oct",
        days: [
          {
            day: " GMDO Full Moon 5 Oct 17",
            description:
              "Celebrating Dhanvantari and Mahalakshmi—Creating Perfect Health and Achieving the Great Goal (51 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2017-10-05_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Nov",
        days: [
          {
            day: " GMDO Full Moon 4 Nov 17",
            description:
              "Teaching Principles Derived from Maharishi’s Commentary on the Bhagavad-Gita (54 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2017-11-04_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Dec",
        days: [
          {
            day: " GMDO Full Moon 3 Dec 17",
            description:
              "Celebrating the Nourishing role of Cows: The Cows Foundation, Moo Matri Products, and a Delightful New Book Through the Eyes of Priya (46 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2017-12-03_GMDO_Fullmoon.mp4",
          },
        ],
      },
    ],
  },
  // Year 2016
  {
    year: 2016,
    months: [
      {
        monthName: "Jan",
        days: [
          {
            day: " GMDO Full Moon 23 Jan 16",
            description:
              "The Supreme Role of Para Prakriti—the Divine Feminine Principle—in Structuring Unity as a Living Reality (1 hour 24 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2016-01-23_GMDO_Fullmoon.mp4",
          },
          {
            day: " GMDO Achievements 12 Jan 16",
            description:
              "Offering of Achievements on all Continents from the Global Mother Divine Organization: (I) Asia, (II) Eastern Europe, (III) Middle East, (IV) Western Europe, (V) Africa, (VI) North America, (VII) Central and South America, (VIII) South Pacific (1 hour 40 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2016-01-12_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Feb",
        days: [
          {
            day: " GMDO Full Moon 22 Feb 16",
            description:
              "Living Wholeness of Life:  Enjoying Bliss, Soma, and Ojas for Perfect Health and Enlightenment (58 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2016-02-22_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Mar",
        days: [
          {
            day: " GMDO Full Moon 23 March 16",
            description:
              "Research in Consciousness as a Member of the Mother Divine Programme and Doctoral Student in Maharishi Vedic Science—the Science of Consciousness (1 hour 12 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2016-03-23_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Apr",
        days: [
          {
            day: " GMDO Full Moon 22 April 16",
            description:
              "Maharishi AyurVeda to Transform Pragyaparadh into the State of Enlightenment for Perfect Health to Enjoy Bliss, Longevity, and Lasting Achievements in Life (50 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2016-04-22_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "May",
        days: [
          {
            day: " GMDO Full Moon 21 May 16",
            description:
              "Absolute Celebration: The Delightful Role of Celebration in Creating Heaven on Earth (49 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2016-05-21_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "June",
        days: [
          {
            day: " GMDO Full Moon 20 June 16",
            description:
              "Maharishi AyurVeda: Its Timeless Tradition, Holistic Knowledge and Application, Purity, Efficacy, Modern Research and Development for a Global Outreach, and its Unique Application for the Health of Women of All Ages (48 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2016-06-20_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "July",
        days: [
          {
            day: " GMDO Achievements 19 July 16",
            description:
              "Global Mother Divine Organization: Offering Our Achievements from 36 Countries on Guru Purnima:(I) Asia, (II) Western Europe, (III) Eastern Europe, (IV) Africa (V) Pacific, (VI) The Americas (2 hours 14 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2016-07-19_GMDO_GP_Achievements.mp4",
          },
        ],
      },
      {
        monthName: "Aug",
        days: [
          {
            day: " GMDO Full Moon 18 August 16",
            description:
              "Celebrating Krishna Janmasthami—the Birth of the Birthless Absolute (1 hour 16 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2016-08-18_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Sept",
        days: [
          {
            day: " GMDO Full Moon 16 September 16",
            description:
              "Vedic Dance: Enlivening Health, Happiness, and Higher States of Consciousness through the Dance of Wholeness (52 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2016-09-16_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Oct",
        days: [
          {
            day: " GMDO Full Moon 16 October 16",
            description:
              "Celebrating Depavali—Enlivening the Light of Enlightenment in Every Individual and Transforming the World into Heaven on Earth (48 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2016-10-16_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Nov",
        days: [
          {
            day: " GMDO Full Moon 14 November 16",
            description:
              "Celebrating Gita Jayanti, the Bhagavad-Gita–Song of Divinity (57 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2016-11-14_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Dec",
        days: [
          {
            day: " GMDO Full Moon 13 December 16",
            description:
              "Understanding the Meaning and Application of Rasayana in Maharishi AyurVeda (37 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2016-12-13_GMDO_Fullmoon.mp4",
          },
        ],
      },
    ],
  },
  // Year 2015
  {
    year: 2015,
    months: [
      {
        monthName: "Jan",
        days: [
          {
            day: "GMDO Achievements 12 Jan 15",
            description:
              "Offering of Achievements on all Continents from the Global Mother Divine Organization: (I) Asia, (II) Europe, (III) Middle East, (IV) Africa, (V) Oceania, (VI) The Caribbean, (VII) The Americas (1 hour 40 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2015-01-12_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Feb",
        days: [
          {
            day: "GMDO Full Moon 3 Feb 15",
            description:
              "Maharishi Ayur-Veda Family Health Series, Course 1: Healthy Happy Mother and Baby Program Optimizing Health for Preconception, Pregnancy, Delivery and Post-Partum An Advanced Paradigm in Mother and Baby Health Care—Simple Traditional Time-tested Knowledge (1 Hour 25 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2015-02-03_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Mar",
        days: [
          {
            day: "GMDO Full Moon 4 Mar 15",
            description:
              "Introducing Matri Mandala—Enlivening the Mutually Nourishing Flow of Knowledge and Support between the Mother Divine Programme and the ‘Circles of Mothers’ Everywhere (48 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2015-03-04_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Apr",
        days: [
          {
            day: "GMDO Full Moon 4 Apr 15",
            description:
              "Consciousness is Primary: Enlivening it in Human Awareness is the Basis of Successful Action and Lasting Achievemen (40 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2015-04-04_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "May",
        days: [
          {
            day: "GMDO Full Moon 3 May 15",
            description:
              "Bliss in the Land of Smiles: Celebrating the Mother Divine Programme in Thailand (Vedic Siam)—Welcoming ladies from all countries to join us for our special courses and programmes (57 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2015-05-03_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "June",
        days: [
          {
            day: "GMDO Full Moon 2 June 15",
            description:
              "Promoting Physical, Mental, and Emotional Health through the Use of Essential Oils. Maharishi Aroma Therapy: Enlivening Consciousness—the Inner Intelligence of Body and Mind—to Restore Wholeness and Balance for Perfect Health (1 hour 35 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2015-06-02_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "July",
        days: [
          {
            day: "GMDO Achievements 31 July 15",
            description:
              "Global Mother Divine Organisation: Offering Our Achievements on Guru Purnima (I) Asia, (II) Europe, (III) Middle East, (IV) Africa, (V) The South Pacific, (VI) The Americas (1 hour 50 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2015-07-31_GP_GMDO_Achievements.mp4",
          },
          {
            day: "GMDO Full Moon 1 July 15",
            description: "Enlightenment for Everyone (47 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2015-07-01_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Aug",
        days: [
          {
            day: "GMDO Full Moon 29 Aug 15",
            description:
              "Culturing the Intelligence in the Soil, the Plant, and the Farmer for the Fulfilment of Agriculture (1 hour)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2015-08-29_GMDO_Fullmoon.mp4",
          },
          {
            day: "Alliance 11 August 15 Eve",
            description:
              "Fifth International Conference Alliance of Women Scientists and Scholars for a Better World • Conclusion (11 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/17_2015-08-11E_Alliance_Conclusion.mp4",
          },
          {
            day: "Alliance 11 August 15 Aft",
            description:
              "Fifth International Conference Alliance of Women Scientists and Scholars for a Better World • Dr Catherine O’Brien, PhD: Sustainable Happiness (27 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/16_2015-08-11A_Alliance_Catherine_OBrien.mp4",
          },
          {
            day: "Alliance 11 August 15 Aft",
            description:
              "Fifth International Conference Alliance of Women Scientists and Scholars for a Better World • Dr Saskia Kersenboom, PhD: Songs for Kali—Transformations of the Goddess during the Festival of the Nine Nights (37 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/15_2015-08-11A_Alliance_Saskia_Kersenboom.mp4",
          },
          {
            day: "Alliance 11 August 15 Aft",
            description:
              "Fifth International Conference Alliance of Women Scientists and Scholars for a Better World • Prof Dr Swati Mohite, BAMS, MS (Ayu), BA (Sanskrit): Women's Lifestyle According to Veda—Application in the Modern Era (36 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/14_2015-08-11A_Alliance_Swati_Mohite.mp4",
          },
          {
            day: "Alliance 11 August 15 Aft",
            description:
              "Fifth International Conference Alliance of Women Scientists and Scholars for a Better World • Dr Snehalatha SN Dornala, BAMS, MD, PhD: Attaining a Healthy, Happy Mother and Baby, through the Programmes of Maharishi AyurVeda; Translating Vedic Knowledge for the Transformation of Global Living (27 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/13_2015-08-11Af_Alliance_Snehalata_Dornala.mp4",
          },
          {
            day: "Alliance 11 August 15 Morn",
            description:
              "Fifth International Conference Alliance of Women Scientists and Scholars for a Better World • Prof Dr Enkhtuya Bavuudorj, PhD: Social Responsibility in Mongolian Companies (26 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/12_2015-08-11M_Alliance_Enkhtuya_Bavuudorj.mp4",
          },
          {
            day: "Alliance 11 August 15 Morn",
            description:
              "Fifth International Conference Alliance of Women Scientists and Scholars for a Better World • Dr Amanda Weltman, PhD: Why Science Needs More Love and Tears (55 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/11_2015-08-11M_Alliance_Amanda_Weltman.mp4",
          },
          {
            day: "Alliance 11 August 15 Morn",
            description:
              "Fifth International Conference Alliance of Women Scientists and Scholars for a Better World • Dr Marcia Murphy PhD: The Source, Course and Goal of the Alliance (11 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/10_2015-08-11_M_Alliance_Marcia_Murphy.mp4",
          },
          {
            day: "Alliance 10 August 15 Eve",
            description:
              "Fifth International Conference Alliance of Women Scientists and Scholars for a Better World • Reshma Srivastava MA (Music): Gandharva Veda Concert (41 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/09_2015-08-10E_Alliance_Reshma_Shrivastava.mp4",
          },
          {
            day: "Alliance 10 August 15 Eve",
            description:
              "Fifth International Conference Alliance of Women Scientists and Scholars for a Better World • Dr Cynthia Arenander, BA (Journalism), BS (Physiology), DSCI (MERU Switzerland): Live EEG Demonstration (30 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/08_2015-08-10E_Alliance_Demo_Cynthia_Arenander.mp4",
          },
          {
            day: "Alliance 10 August 15 Eve",
            description:
              "Fifth International Conference Alliance of Women Scientists and Scholars for a Better World • France Fournet, MA: Demonstrating the Forbrain Technology (26 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/07_2015-08-10E_Alliance_Demo_France_Fournet.mp4",
          },
          {
            day: "Alliance 10 August 15 Aft",
            description:
              "Fifth International Conference Alliance of Women Scientists and Scholars for a Better World • Dr Rachel Goodman, PhD: Global Sustainability through Development of Consciousness (37 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/06_2015-08-10A_Alliance_Rachel_Goodman.mp4",
          },
          {
            day: "Alliance 10 August 15 Aft",
            description:
              "Fifth International Conference Alliance of Women Scientists and Scholars for a Better World • France Fournet, MA: Dynamizing Our Brain through Audio-vocal Training (30 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/05_2015-08-10A_Alliance_France_Fournet.mp4",
          },
          {
            day: "Alliance 10 August 15 Aft",
            description:
              "Fifth International Conference Alliance of Women Scientists and Scholars for a Better World • Ashley Fargnoli, MA: Moving through Trauma: Dance : Dance/Movement Therapy for Refugees and Trafficking Survivors (36 minutes)",
            videoSrc:
              "https://streaming.mou.org/MOU2/GMDO/04_2015-08-10A_Alliance_Ashley_Fargnoli.mp4",
          },
          {
            day: "Alliance 10 August 15 Aft",
            description:
              "Fifth International Conference Alliance of Women Scientists and Scholars for a Better World • Dr Cynthia Arenander, BA (Journalism), BS (Physiology), DSCI (MERU Switzerland): Developing Better Brains for a Better World through Maharishi Technologies of Consciousness (51 minutes)",
            videoSrc:
              "https://streaming.mou.org/MOU2/GMDO/04_2015-08-10A_Alliance_Ashley_Fargnoli.mp4",
          },
          {
            day: "Alliance 10 August 15 Morn",
            description:
              "Fifth International Conference Alliance of Women Scientists and Scholars for a Better World • Claudette Maurin, BA (Botany) MA: Presenting Maharishi Aroma Therapy: Consultations, Treatments and a Few Testimonials (29 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/02_2015-08-10M_Alliance_Claudett_ Maurin.mp4",
          },
          {
            day: "Alliance 10 August 15 Morn",
            description:
              "Fifth International Conference Alliance of Women Scientists and Scholars for a Better World • Kesang Tshomo, BSc Appl (Agri), MSc (Hort): Organic, a Way of Life in Bhutan—Women as the Custodians of Life (51 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/01_2015-08-10M_Alliance_Kesang_Tshomo.mp4",
          },
        ],
      },
      {
        monthName: "Sept",
        days: [
          {
            day: "GMDO Full Moon 28 Sept 15",
            description:
              "Documenting the Rise of Higher States of Consciousness: Experiences of the 40 Qualities of Intelligence Displayed in the 40 Aspects of Veda and Vedic Literature Correlated with Expressions in the First Verse of Subala Upanishad (1 hour 14 minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2015-09-28_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Oct",
        days: [
          {
            day: "GMDO Full Moon 26 Oct 15",
            description:
              "Celebrating the three auspicious Vedic Holidays of Dhanvantari Jayanti, Hanuman Jayanti, and Mahalakshmi—Dipavali, the Festival of Lights: Using Maharishi's Vedic Science and Technologies to gain perfect health, culture self-referral action to achieve any goal, and to become established in abundance on every level of life (50 Minutes)",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2015-10-26_GMDO_Fullmoon.mp4",
          },
        ],
      },
      {
        monthName: "Nov",
        days: [
          {
            day: "GMDO Full Moon 25 Nov 15",
            description:
              "Celebrating the Achievements of Five Years of the Alliance of Women Scientists and Scholars for a Better World",
            videoSrc:
              "http://streaming.mou.org/MOU2/GMDO/2015-11-25_GMDO_Fullmoon.mp4",
          },
        ],
      },
    ],
  },
];
