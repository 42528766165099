export const archivesData = [
  // Year 2022
  {
    id: 11,
    year: 2022,
    months: [
      {
        monthName: "Jan",
        days: [
          {
            day: 31,
            description:
              "News of great wave of expansion in Hamsa, with National Director Peter Gajdos",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_Jan_22.mp4",
          },
          {
            day: 30,
            description:
              "Maharishi Vedic University Exhibition, read by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Jan_22.mp4",
          },
          {
            day: 27,
            description:
              "Maharishi’s Global Press Conference from 6 July 2005, Part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Jan_22.mp4",
          },
          {
            day: 23,
            description:
              "Maharishi Vedic University Exhibitions, read by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Jan_22.mp4",
          },
          {
            day: 22,
            description:
              "Celebrating the birthday of David Lynch with Tony Nader, MD, PhD, MARR, Dr Bevan Morris, Raja Luis and other leaders",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Jan_22.mp4",
          },
          {
            day: 20,
            description:
              "Sankalpas and reports for 12 January celebration, with Raja Steve Heaton, Raja Konhaus, Japan, poem by Susan Watterson",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Jan_22.mp4",
          },
          {
            day: 18,
            description:
              "Conference for virtual Super-Radiance in North, Central, and South America, with Dr Tony Nader, MD, PhD, MARR, Dr John Hagelin, Dr Bevan Morris, Raja Luis, and others",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Jan_22.mp4",
          },
          {
            day: 16,
            description:
              "Maharishi Vedic University Exhibitions, read by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Jan_22.mp4",
          },
          {
            day: 09,
            description:
              "Summary of 2021 achievements, Part 3, by Dr Bevan Morris",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Jan_22.mp4",
          },
          {
            day: 08,
            description: "Achievements of 2021, part 2, with Dr Bevan Morris",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Jan_22.mp4",
          },
        ],
      },
      {
        monthName: "Feb",
        days: [
          {
            day: 27,
            description:
              "Maharishi Vedic University Exhibition, last section, reading by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Feb_22.mp4",
          },
          {
            day: 25,
            description:
              "Announcing the Maharishi Yoga Asana Master Class, with Dr Walter Mölk, Livia Kägi, and Cornelia Jung",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Feb_22.mp4",
          },
          {
            day: 24,
            description:
              "Maharishi’s Global Press Conference from 20 July 2005, part",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Feb_22.mp4",
          },
          {
            day: 23,
            description:
              "Top Movement artists give insights into Maharishi’s guidance for the rising Age of Enlightenment, with Dr Susie Swan, Dr Lawrence Sheaff, Dr Eike Hartmann, Dr Frances Knight, Raja Anthony, Paul Levy",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Feb_22.mp4",
          },
          {
            day: 22,
            description:
              "The Maharishi Peace Palace in Lebanon is ready to start, with Raja Omar and Sophia Bitar",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Feb_22.mp4",
          },
          {
            day: 20,
            description:
              "Maharishi Vedic University Exhibition, reading by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Feb_22.mp4",
          },
          {
            day: 18,
            description:
              "Opening of the new Soma Maharishi AyurVeda Health Centre, with Dr Wolfgang Schachinger and family, Raja Eckart, and Dr Rainer Picha",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Feb_22.mp4",
          },
          {
            day: 17,
            description:
              "Maharishi’s Global Press Conference from 20 July 2005, Part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Feb_22.mp4",
          },
          {
            day: 16,
            description:
              "Celebrating the birthday of Dr Neil Paterson with Maharaja, Dr Bevan Morris, and many others",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Feb_22.mp4",
          },
          {
            day: 14,
            description:
              "Enjoy Raja Chris Crowell’s beautiful presentation on celebrating his birthday",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Feb_22.mp4",
          },
          {
            day: 13,
            description:
              "Maharishi Vedic University Exhibition, read by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Feb_22.mp4",
          },
          {
            day: 12,
            description:
              "Many groups of Meditators in East and West Africa, with Raja Steve and Raja Graham",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Feb_22.mp4",
          },
          {
            day: 10,
            description:
              "Maharishi’s Global Press Conference from 13 July 2005, Part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Feb_22.mp4",
          },
          {
            day: 08,
            description:
              "Conference call to organize and inspire group practise, Part 2, with Maharaja, Dr Morris, and others",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Feb_22.mp4",
          },
          {
            day: 0o7,
            description:
              "Conference call to organize and inspire group practise, Part 1, with Maharaja, Dr Morris, and others",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Feb_22.mp4",
          },
          {
            day: 0o6,
            description:
              "Maharishi Vedic University Exhibition, reading by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Feb_22.mp4",
          },
          {
            day: 0o3,
            description:
              "Maharishi’s Global Press Conference from 13 July 2005, Part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Feb_22.mp4",
          },
          {
            day: 0o2,
            description:
              "Report and sankalpa from Australia for 12 Junuary 2022 with Dr Tim Carr, Raja Rogers, and Raj Rajeshwari Candace",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Feb_22.mp4",
          },
        ],
      },
      {
        monthName: "Mar",
        days: [
          {
            day: 31,
            description:
              "Maharishi’s Global Press Conference from 3 August 2005, part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_Mar_22.mp4",
          },
          {
            day: 30,
            description:
              "Three World Peace Assemblies creating harmony in eastern Europe, with Raja Felix and Livia, Raja Kingsley, and Raja Bjarne",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Mar_22.mp4",
          },
          {
            day: 29,
            description:
              "Signs of rising invincibility in the world, by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Mar_22.mp4",
          },
          {
            day: 27,
            description: "Alliance for Knowledge book reading by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Mar_22.mp4",
          },
          {
            day: 24,
            description:
              "Maharishi’s Global Press Conference from 27 July 2005, part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Mar_22.mp4",
          },
          {
            day: 21,
            description:
              "Celebrating the birthday of Raja Rafael, with Maharaja-ji and other leaders",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Mar_22.mp4",
          },
          {
            day: 20,
            description:
              "Alliance for Knowledge, published in 1973, read by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Mar_22.mp4",
          },
          {
            day: 19,
            description:
              "Maharishi explains how action alternating with transcending raises us to enlightenment, with Mario Orsatti and Leah Cho",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Mar_22.mp4",
          },
          {
            day: 17,
            description:
              "Maharishi’s Global Press Conference, 27 July 2005, part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Mar_22.mp4",
          },
          {
            day: 13,
            description:
              "Alliance for Knowledge, book published in 1973, read by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Mar_22.mp4",
          },
          {
            day: 12,
            description: "Interview with Dr Vernon Katz from Open Yagya Day",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Mar_22.mp4",
          },
          {
            day: 11,
            description:
              "News update from Vedic Pandit project and Russia and Ukraine, with Raja Harris, from Open Yagya Day",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Mar_22.mp4",
          },
          {
            day: 10,
            description:
              "Maharishi’s Global Press Conference from 20 July 2005, part 3",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Mar_22.mp4",
          },
          {
            day: 0o3,
            description:
              "Celebrating the birthday of Dr Bevan Morris, with Dr Tony Nader, MD, PhD, (Maharaja), and many other leaders",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Mar_22.mp4",
          },
        ],
      },
      {
        monthName: "Apr",
        days: [
          {
            day: 30,
            description:
              "Tony Nader, MD, PhD, MARR, discusses the nature of consciousness, with Rajiv Malhotra, founder of Infinity Foundation (part 2)",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Sep_22.mp4",
          },
          {
            day: 29,
            description:
              "Tony Nader, MD, PhD, MARR, discusses the nature of consciousness, with Rajiv Malhotra, founder of Infinity Foundation (part 1)",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Sep_22.mp4",
          },
          {
            day: 28,
            description:
              "Maharishi’s Global Press Conference of 10 August 2005, part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Sep_22.mp4",
          },
          {
            day: 27,
            description:
              "Tony Nader, MD, PhD, MARR, discusses Reality with Robert Kuhn, PhD.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Sep_22.mp4",
          },
          {
            day: 26,
            description:
              "The Science of Creative Intelligence Course, lesson 1, with Dr Bevan Morris and Dr Ashley Deans",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Sep_22.mp4",
          },
          {
            day: 24,
            description:
              "Maharishi University of Management pocketbook read by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Sep_22.mp4",
          },
          {
            day: 21,
            description:
              "Maharishi’s Global Press Conference of 3 August 2005, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Sep_22.mp4",
          },
          {
            day: 20,
            description:
              "New MA degree in Enlightenment and Leadership at MIU, with Dr Susie Dillbeck and Dr Cathy Gorini.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Sep_22.mp4",
          },
          {
            day: 17,
            description:
              "Maharishi’s address to Educators at the National Conference on Higher Education, Chicago, USA, 13 March1973, read by Dr Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Sep_22.mp4",
          },
          {
            day: 14,
            description:
              "David Lynch invites philanthropists to fund the establishment of permanent large groups of Yogic Flyers for world peace.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Sep_22.mp4",
          },
          {
            day: 11,
            description:
              "Tony Nader, MD, PhD, MARR, interviews Phil Stieg, MD, PhD, renowned neurosurgeon and researcher, on the subject of Consciousness and the brain: developing full human potential.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Sep_22.mp4",
          },
          {
            day: 0o1,
            description:
              "Maharaja-ji, Tony Nader MD, PhD, interviews Donald Hoffman, PhD, on the nature of reality",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Sep_22.mp4",
          },
        ],
      },
      {
        monthName: "May",
        days: [
          {
            day: 22,
            description:
              "Maharishi’s book Maharishi University of Management reading by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_May_22.mp4",
          },
          {
            day: 21,
            description: "News of rising global invincibility by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_May_22.mp4",
          },
          {
            day: 20,
            description:
              "Discussion between Tony Nader, MD, PhD, MARR, and Bernardo Kastrup, PhD, on Idealism and the Nature of Ultimate Reality: Are We All in a Shared Dream?",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_May_22.mp4",
          },
          {
            day: 19,
            description:
              "Maharishi’s Global Press Conference from 24 August 2005, part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_May_22.mp4",
          },
          {
            day: 18,
            description:
              "A wonderful new video about ‘Ending the War Within’, with RR Maureen, Tigran Nersisian, Dr Michael Williams, David Shapiro",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_May_22.mp4",
          },
          {
            day: 17,
            description:
              "Universal Mind and the Non-collapse of the Wave Function, Dr John Hagelin",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_May_22.mp4",
          },
          {
            day: 16,
            description: "The establishment of MIU with Founding Faculty",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_May_22.mp4",
          },
          {
            day: 15,
            description:
              "Maharishi University of Management, Wholeness on the Move, book by Maharishi read by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_May_22.mp4",
          },
          {
            day: 14,
            description:
              "The rise of Higher States of Consciousness in Education, with Dr Doug Birx, Dr Hagelin, Dr Bevan Morris, Maharaja",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_May_22.mp4",
          },
          {
            day: 13,
            description:
              "The story of the Founding of Maharishi International University from those who were there, with Lenny Goldman, and entertainment by Flipped SU5 band of Dr John Hagelin",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_May_22.mp4",
          },
          {
            day: 12,
            description:
              "The story of the founding of Maharishi International University from those who were there, with Dr Nat Goldhaber, Dr Keith Wallace, founding President",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_May_22.mp4",
          },
          {
            day: 11,
            description:
              "A vision of the next 5 years at MIU, part 2, with President Hagelin, Dr Craig Pearson, and next generation leaders",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_May_22.mp4",
          },
          {
            day: 10,
            description:
              "A vision of the next 5 years at MIU, part 1, with President Hagelin, Dr Craig Pearson, and next generation leaders",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_May_22.mp4",
          },
          {
            day: 09,
            description:
              "Inauguration of the Global Peace Village at MIU on 3 May 2022, with Maharaja, Raja Hagelin, Raja Howard",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_May_22.mp4",
          },
          {
            day: 08,
            description:
              "Maharishi University of Management, Wholeness on the Move, reading by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_May_22.mp4",
          },
          {
            day: 0o7,
            description:
              "Keynote speech by Tony Nader, MD, PhD, MARR, at MIU's Golden Jubilee celebration, on 1 May 2022",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_May_22.mp4",
          },
          {
            day: 0o6,
            description:
              "Inauguration of the Dr Tony Nader Institute for Research in Consciousness at MIU on 3 May 2022",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_May_22.mp4",
          },
          {
            day: 0o5,
            description:
              "Maharishi’s Global Press Conference from 10 August 2005, part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_May_22.mp4",
          },
          {
            day: 0o4,
            description:
              "Tony Nader, MD, PhD, MARR, discusses what is reality, Consciousness and",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_May_22.mp4",
          },
          {
            day: 0o2,
            description:
              "The history of Maharishi International University presented by Dr Bevan Morris",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_May_22.mp4",
          },
          {
            day: 0o1,
            description:
              "Maharishi’s book, Maharishi University of Management, read by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_May_22.mp4",
          },
        ],
      },
    ],
  },
  // Year 2021
  {
    id: 10,
    year: 2021,
    months: [
      {
        monthName: "Jan",
        days: [
          {
            day: 31,
            description:
              "Dr Peter Swan starts reading from the book by Maharishi Mahesh Yogi entitled, Alliance with Natural Law, published in 1982.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_Jan_21.mp4",
          },
          {
            day: 30,
            description:
              "The trends indicating the direction of change in the world, with Dr Peter Swan, Minister of communication of the Global Country of World Peace.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Jan_21.mp4",
          },
          {
            day: 28,
            description:
              "Maharishi’s Global Press Conference from 23 March 2005 part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Jan_21.mp4",
          },
          {
            day: 24,
            description:
              "Dr Peter Swan reads from the book by Maharishi Mahesh Yogi entitled, The Divine Plan, published in March 1962.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Jan_21.mp4",
          },
          {
            day: 21,
            description:
              "The birthday celebration of Dr. Tony Nader, MD, PhD – Maharaja Adhiraj Rajaraam – from the 10th January 2021, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Jan_21.mp4",
          },
          {
            day: 19,
            description:
              "The birthday celebration of Dr. Tony Nader, MD, PhD – Maharaja Adhiraj Rajaraam – from the 10th January 2021, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Jan_21.mp4",
          },
          {
            day: 17,
            description:
              "Dr Peter Swan starts reading a book by Maharishi Mahesh Yogi entitled, The Divine Plan, published in March 1962.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Jan_21.mp4",
          },
          {
            day: 16,
            description:
              "Reports from the Rajas and Ministers of the Global Country of World Peace.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Jan_21.mp4",
          },
          {
            day: 15,
            description: "Birthday celebration of Dr David Lynch.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Jan_21.mp4",
          },
          {
            day: 09,
            description: "2020 Achievements with Dr. Bevan Morris part 4.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Jan_21.mp4",
          },
        ],
      },
      {
        monthName: "Feb",
        days: [
          {
            day: 28,
            description:
              "Living Peace—The Contribution of Maharishi Vedic Science to Global Security, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Feb_21.mp4",
          },
          {
            day: 27,
            description:
              "‘Global Maharishi Effect’ presented by Dr. Keith Wallace, Maharishi International University, Fairfield, IA, USA.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Feb_21.mp4",
          },
          {
            day: 25,
            description:
              "Maharishi’s Global Press Conference from 30 March 2005 part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Feb_21.mp4",
          },
          {
            day: 22,
            description:
              "Old and New Principles for Retired People with Dr. Bevan Morris.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Feb_21.mp4",
          },
          {
            day: 21,
            description:
              "Dr Peter Swan starts reading from the book by Maharishi Mahesh Yogi entitled, Alliance with Natural Law for National Self-Reliance, published in 1982.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Feb_21.mp4",
          },
          {
            day: 18,
            description:
              "Maharishi’s Global Press Conference from 23 March 2005 part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Feb_21.mp4",
          },
          {
            day: 14,
            description:
              "Dr Peter Swan continues reading from the book by Maharishi Mahesh Yogi entitled, Maharishi Cities of Immortal, published in 1989.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Feb_21.mp4",
          },
          {
            day: 13,
            description:
              "Dr Kenneth Chandler, PhD, professor emeritus at Maharishi International University, IA, USA, talking on ‘Ancient traditional cultures, part 1 (Americas).’",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Feb_21.mp4",
          },
          {
            day: 11,
            description:
              "Maharishi’s Global Press Conference from 21 April 2004 part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Feb_21.mp4",
          },
          {
            day: 0o7,
            description:
              "Dr Peter Swan starts reading from the book by Maharishi Mahesh Yogi entitled, Maharishi Cities of Immortal, published in 1989.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Feb_21.mp4",
          },
          {
            day: 0o6,
            description:
              "Dr Kenneth Chandler, PhD, professor emeritus at Maharishi International University, IA, USA, talking on ‘The Experience of Transcending and Pure Consciousness – Statemen, Poets, and Writers of the Twentieth Century.’",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Feb_21.mp4",
          },
          {
            day: 0o4,
            description:
              "Maharishi’s Global Press Conference from 21 April 2004 part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Feb_21.mp4",
          },
          {
            day: 0o2,
            description:
              "Review of the last year in terms of finance with Raja Bob LoPinto, Minister of Finance and Planning of the Global Country of World Peace.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Feb_21.mp4",
          },
        ],
      },
      {
        monthName: "Mar",
        days: [
          {
            day: 31,
            description:
              "Global trends exposed in recent news reports, by Dr Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_Mar_21.mp4",
          },
          {
            day: 28,
            description:
              "Living Peace—The Contribution of Maharishi Vedic Science to Global Security, part 4.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Mar_21.mp4",
          },
          {
            day: 25,
            description:
              "Dr Tony Nader, MD, PhD, MARR, presents his book, ‘One Unbounded Ocean of Consciousness’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Mar_21.mp4",
          },
          {
            day: 22,
            description:
              "Old and new principles of education, Dr Ashley Deans.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Mar_21.mp4",
          },
          {
            day: 21,
            description:
              "Celebrating the birthday of Raja Rafael, with Maharaja Adhiraj Rajaraam.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Mar_21.mp4",
          },
          {
            day: 16,
            description:
              "Birthdays of Raja Paul and Raja Andreas with Dr Morris and Dr Patterson.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Mar_21.mp4",
          },
          {
            day: 15,
            description:
              "The new book, ‘The Coherence Effect’, by Dr Keith Wallace and Jay Marcus.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Mar_21.mp4",
          },
          {
            day: 14,
            description:
              "Living Peace—The Contribution of Maharishi Vedic Science to Global Security, part 3.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Mar_21.mp4",
          },
          {
            day: 13,
            description:
              "Maharishi’s contribution to the historical development of knowledge by Dr Ken Chandler, professor emeritus at Maharishi International University, IA, USA",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Mar_21.mp4",
          },
          {
            day: 12,
            description:
              "Old and New Principles in Business and Industry with Raja Bob LoPinto.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Mar_21.mp4",
          },
          {
            day: 09,
            description:
              "Dr Kenneth Chandler, PhD, professor emeritus at Maharishi International University, IA, USA, talking on ‘Ancient traditional cultures part 3 (Australia, New Zealand)’",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Mar_21.mp4",
          },
          {
            day: 08,
            description:
              "Overview of the post graduate course in Maharishi AyurVeda, presented by Dr Rainer Picha and guests.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Mar_21.mp4",
          },
          {
            day: 0o7,
            description:
              "Living Peace—The Contribution of Maharishi Vedic Science to Global Security, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Mar_21.mp4",
          },
          {
            day: 0o6,
            description:
              "Global Giving Bonus donation project for the Dhammajarinee girl school in Thailand.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Mar_21.mp4",
          },
          {
            day: 0o4,
            description:
              "Maharishi’s Global Press Conference from 30 March 2005 part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Mar_21.mp4",
          },
          {
            day: 0o3,
            description:
              "Celebrating Dr Bevan Morris’s birthday, Prime Minister of the Global Country of World Peace.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Mar_21.mp4",
          },
          {
            day: 0o2,
            description:
              "Dr Kenneth Chandler, PhD, professor emeritus at Maharishi International University, IA, USA, talking on ‘Ancient traditional cultures part 2 (Black Elk)’",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Mar_21.mp4",
          },
          {
            day: 0o1,
            description:
              "New online ‘detox’ programme with Dr Wolfgang Schachinger and Dr Rainer Picha.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Mar_21.mp4",
          },
        ],
      },
      {
        monthName: "Apr",
        days: [
          {
            day: 29,
            description:
              "Maharishi’s Global Press Conference from 13 April 2005 part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Sep_21.mp4",
          },
          {
            day: 23,
            description:
              "Dr Ken Chandler described how he became involved in the knowledge and experience of Pure Consciousness.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Sep_21.mp4",
          },
          {
            day: 22,
            description:
              "Maharishi’s Global Press Conference from 13 April 2005 part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Sep_21.mp4",
          },
          {
            day: 12,
            description:
              "Dr Tony Nader discussed his new book in light of Nyaya and Itihas aspects of Vedic literature.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Sep_21.mp4",
          },
          {
            day: 08,
            description:
              "Maharishi’s Global Press Conference from 6 April 2005 part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Sep_21.mp4",
          },
          {
            day: 0o4,
            description:
              "Living Peace—The Contribution of Maharishi Vedic Science to Global Security, part 5.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Sep_21.mp4",
          },
          {
            day: 0o3,
            description:
              "Dr Tony Nader’s launch of his new book ‘One Unbounded Ocean of Consciousness’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Sep_21.mp4",
          },
          {
            day: 0o1,
            description: "Dr Tony Nader’s book is available, with Dr Nader.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Sep_21.mp4",
          },
        ],
      },
      {
        monthName: "May",
        days: [
          {
            day: 31,
            description:
              "Inauguration of MIU's Navin and Pratima Doshi Centre for Integrative Heath in Los Angeles on 14 May 2021; Part 1, Dr John Hagelin and Dr Doshi.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_May_21.mp4",
          },
          {
            day: 29,
            description: "A vision of Spring at MERU, Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_May_21.mp4",
          },
          {
            day: 27,
            description:
              "Maharishi’s Global Press Conference from 27 April 2005 part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_May_21.mp4",
          },
          {
            day: 26,
            description:
              "Programme for PTSD recovery in Armenia, with RR Maureen Wynne, David Shapiro, Svetlana Gastyan, and Dr Sandy Nidich.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_May_21.mp4",
          },
          {
            day: 24,
            description:
              "Reports from the global media indicating rising invincibility, by Dr Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_May_21.mp4",
          },
          {
            day: 21,
            description:
              "Flag raising ceremonies around the world during the Vedic Festival of Akshaya Tritiya.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_May_21.mp4",
          },
          {
            day: 20,
            description:
              "Maharishi’s Global Press Conference from 27 April 2005 part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_May_21.mp4",
          },
          {
            day: 13,
            description:
              "Maharishi’s Global Press Conference from 20 April 2005 part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_May_21.mp4",
          },
          {
            day: 08,
            description:
              "Dr Peter Swan reads from the book by Maharishi Mahesh Yogi entitled, The Treasury and the Market, published in 1961.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_May_21.mp4",
          },
          {
            day: 0o6,
            description:
              "Maharishi’s Global Press Conference from 20 April 2005 part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_May_21.mp4",
          },
          {
            day: 0o2,
            description:
              "Dr Peter Swan starts reading from the book by Maharishi Mahesh Yogi entitled, The Treasury and the Market, published in 1961.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_May_21.mp4",
          },
          {
            day: 0o1,
            description:
              "A Pilot Randomized Controlled Trial of Transcendental Meditation as Treatment for Posttraumatic Stress Disorder in Veterans, with Dr. Sanford Nidich.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_May_21.mp4",
          },
        ],
      },
      {
        monthName: "June",
        days: [
          {
            day: 28,
            description:
              "Signs of rising invincibility reflected by the global press presented by Dr Peter Swan at the Open Yagya Day on 5 June 2021.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Jun_21.mp4",
          },
          {
            day: 27,
            description:
              "Dr Peter Swan continues reading from the book entitled, Inauguration of the Dawn of the Age of Enlightenment, published in 1975.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Jun_21.mp4",
          },
          {
            day: 25,
            description:
              "International Day of Yoga on 21 June 2021, address by Maharaja, Dr Tony Nader MD, PhD, introduced by Amarjeet Bhamra, All Party Parliamentary Group on Indian Traditional Sciences, UK Parliament.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Jun_21.mp4",
          },
          {
            day: 24,
            description:
              "Maharishi’s Global Press Conference from 11 May 2005, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Jun_21.mp4",
          },
          {
            day: 23,
            description:
              "Beyond individual immunity to global immunity, with Dr Robert Schneider",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Jun_21.mp4",
          },
          {
            day: 22,
            description:
              "MIU online conference 'Beyond Immunity: Transforming Health’, Part 2, with Dr Tony Nader, MD, PhD, MARR; Suzanne Steinbaum, DO; Robert Schneider, MD; Mimi Guarneri, MD; and Remy Coeytaux, MD, PhD.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Jun_21.mp4",
          },
          {
            day: 21,
            description:
              "MIU online conference 'Beyond Immunity: Transforming Health’, Part 1, with Dr Robert Schneider, Suzanne Steinbaum, DO.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Jun_21.mp4",
          },
          {
            day: 20,
            description:
              "Dr Peter Swan continues reading from the book entitled, Inauguration of the Dawn of the Age of Enlightenment, published in 1975.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Jun_21.mp4",
          },
          {
            day: 19,
            description:
              "Dr John Hagelin gives an update on Dark Matter and research on it at MIU, part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Jun_21.mp4",
          },
          {
            day: 18,
            description:
              "Dr John Hagelin gives an update on Dark Matter and research on it at MIU, part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Jun_21.mp4",
          },
          {
            day: 17,
            description:
              "Maharishi’s Global Press Conference from 4 May 2005 part 3.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Jun_21.mp4",
          },
          {
            day: 16,
            description:
              "Dr Tony Nader MD, PhD, Consciousness-Based Education, the Secret to Creating Inner Wholeness.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Jun_21.mp4",
          },
          {
            day: 14,
            description:
              "Dr Tony Nader, MD, PhD, Interview on Dutch TV Iedereen Verlicht.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Jun_21.mp4",
          },
          {
            day: 13,
            description:
              "Dr Peter Swan continues reading from the book entitled, Inauguration of the Dawn of the Age of Enlightenment, published in 1975, a record of Maharishi’s Inauguration of the Age of Enlightenment.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Jun_21.mp4",
          },
          {
            day: 12,
            description:
              "Raja Michael Busch and Dr Bevan Morris discuss plans to increase the number of Yogic Flyers in the Golden Domes, Part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Jun_21.mp4",
          },
          {
            day: 11,
            description:
              "Raja John Hagelin and Dr Howard Settle discuss plans to increase the number of Yogic Flyers in the Golden Domes, Part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Jun_21.mp4",
          },
          {
            day: 10,
            description:
              "Maharishi’s Global Press Conference from 4 May 2005 part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Jun_21.mp4",
          },
          {
            day: 09,
            description: "Celebrating Raja Hagelin’s birthday.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Jun_21.mp4",
          },
          {
            day: 0o6,
            description:
              "Dr Peter Swan starts reading from the book entitled, Inauguration of the Dawn of the Age of Enlightenment, published in 1975, a record of Maharishi’s Inauguration of the Age of Enlightenment.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Jun_21.mp4",
          },
          {
            day: 0o4,
            description:
              "The International Conference on Consciousness-Based Leadership & Management—Questions and Answers with Dr Tony Nader, MD, PhD.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Jun_21.mp4",
          },
          {
            day: 0o3,
            description:
              "Maharishi’s Global Press Conference from 4 May 2005 part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Jun_21.mp4",
          },
          {
            day: 0o2,
            description:
              "The International Conference on Consciousness-Based Leadership & Management—Opening remarks by Dr. John Hagelin.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Jun_21.mp4",
          },
          {
            day: 0o1,
            description:
              "Inauguration of MIU's Navin and Pratima Doshi Centre for Integrative Heath in Los Angeles on 14 May 2021; Part 2, Dr Schneider, Dr Manohar, Dr Morehead, and others.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Jun_21.mp4",
          },
        ],
      },
      {
        monthName: "July",
        days: [
          {
            day: 31,
            description:
              "Maharaja confers the Maharishi Medal of Enlightened Leadership, Part 4.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_Jul_21.mp4",
          },
          {
            day: 30,
            description:
              "Maharaja confers the Maharishi Medal of Enlightened Leadership, Part 3.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Jul_21.mp4",
          },
          {
            day: 29,
            description:
              "Maharaja confers the Maharishi Medal of Enlightened Leadership, Part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Jul_21.mp4",
          },
          {
            day: 28,
            description:
              "Maharaja confers the Maharishi Medal of Enlightened Leadership, Part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Jul_21.mp4",
          },
          {
            day: 26,
            description:
              "First session of Symposium on Consciousness: What is Consciousness?",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Jul_21.mp4",
          },
          {
            day: 16,
            description:
              "Dr Tony Nader, Maharaja, speaks to Debre Tabor University, Ethiopia, about Transcendental University, 4 June 2021.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Jul_21.mp4",
          },
          {
            day: 15,
            description:
              "Maharishi’s Global Press Conference from 18 May 2005, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Jul_21.mp4",
          },
          {
            day: 12,
            description:
              "The programme for the Guru Purnima Celebrations 2021, Dr Neil Paterson and Dr Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Jul_21.mp4",
          },
          {
            day: 11,
            description:
              "Dr Peter Swan continues reading from the book entitled, Inauguration of the Dawn of the Age of Enlightenment, published in 1975.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Jul_21.mp4",
          },
          {
            day: 08,
            description:
              "Maharishi’s Global Press Conference from 18 May 2005, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Jul_21.mp4",
          },
          {
            day: 0o5,
            description:
              "Celebrating the birthday of Raja Peter with Maharaja and other leaders.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Jul_21.mp4",
          },
          {
            day: 0o4,
            description:
              "Dr Peter Swan continues reading from the book entitled, Inauguration of the Dawn of the Age of Enlightenment, published in 1975.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Jul_21.mp4",
          },
          {
            day: 0o2,
            description:
              "Celebrating the birthday of Raja Luis with Maharaja and Movement leadership.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Jul_21.mp4",
          },
          {
            day: 0o1,
            description:
              "Maharishi’s Global Press Conference from 11 May 2005, Part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Jul_21.mp4",
          },
        ],
      },
      {
        monthName: "Aug",
        days: [
          {
            day: 31,
            description:
              "International Symposium on the role of Consciousness in solving the world’s problems. Session 3, Mental Health, Part 1, Dr John Hagelin, Dr Tony Nader MD, PhD, Dr Sandy Nidich, Dr Yolanda Lewis-Ragland, Scott Gould.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_Aug_21.mp4",
          },
          {
            day: 29,
            description:
              "Consciousness is Primary, Update on Physics by Dr John Hagelin and Maharaja Adhiraj Rajaraam, reading by Dr Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Aug_21.mp4",
          },
          {
            day: 28,
            description:
              "International Symposium on the role of Consciousness in solving the world’s problems, Session 2, Part 1, Dr John Hagelin, Dr Tony Nader MD, PhD, Dr Bevan Morris, Dr Susie Dillbeck.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Aug_21.mp4",
          },
          {
            day: 27,
            description:
              "International Symposium on the role of Consciousness in solving the world’s problems, Education; Session 2, Part 2, Mrs Margaret Bolt, Dr John Hagelin, message from Dr Ramesh Pokhriyal, Dr Celso Niskier, Dr Tony Nader MD, message from Father Mejia.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Aug_21.mp4",
          },
          {
            day: 26,
            description:
              "Maharishi’s Global Press Conference from 1 June 2005, Part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Aug_21.mp4",
          },
          {
            day: 25,
            description:
              "International Symposium on the role of Consciousness in solving the world’s problems, Session 1, Part 2, Dr John Hagelin, Dr Tony Nader MD, PhD, Dr Fred Travis, Dr Gary Kaplan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Aug_21.mp4",
          },
          {
            day: 24,
            description:
              "International Symposium on the role of Consciousness in solving the world’s problems, Session 1, Part 1, Dr John Hagelin, Dr Tony Nader MD, PhD, Dr Keith Wallace.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Aug_21.mp4",
          },
          {
            day: 23,
            description:
              "Guru Purnima address to American Governors by Dr Bevan Morris; Guru Purnima offerings by Raja Steve Heaton.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Aug_21.mp4",
          },
          {
            day: 22,
            description:
              "Achievements of The World Government for the Age of Enlightenment, 1977, and Consciousness is Primary, Introduction and Welcome, reading by Dr Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Aug_21.mp4",
          },
          {
            day: 21,
            description: "Guru Purnima offerings by Raja John Hagelin.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Aug_21.mp4",
          },
          {
            day: 19,
            description:
              "Maharishi’s Global Press Conference from 25 May 2005, Part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Aug_21.mp4",
          },
          {
            day: 17,
            description:
              "Signs of rising collective consciousness reflected in the Global media, by Dr Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Aug_21.mp4",
          },
          {
            day: 15,
            description:
              "Achievements of the World Government of the Age of Enlightenment, 1977, read by Dr Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Aug_21.mp4",
          },
          {
            day: 12,
            description:
              "Maharishi’s Global Press Conference from 25 May 2005, Part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Aug_21.mp4",
          },
          {
            day: 11,
            description: "Guru Purnima offering from Raja Anthony.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Aug_21.mp4",
          },
          {
            day: 0o7,
            description:
              "Guru Purnima offerings by Raja Thrasy and Linda, Raja Graham, Raja Bernard, Raja Shizuo, and Raja Willem.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Aug_21.mp4",
          },
          {
            day: 0o6,
            description:
              "Guru Purnima offerings by Raja Richard and Raja Vikram.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Aug_21.mp4",
          },
          {
            day: 0o4,
            description:
              "Guru Purnima offerings by Raja Chris and Dr Ann Crowell.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Aug_21.mp4",
          },
          {
            day: 0o3,
            description:
              "Guru Purnima offerings by Raja Kingsley and Mother of Domain Leslie.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Aug_21.mp4",
          },
          {
            day: 0o2,
            description:
              "Guru Purnima offerings of the Ministry of Architecture, by Dr Eike Hartmann, Christian Schweizer, Raja Alberto, Wojtek Skalski.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Aug_21.mp4",
          },
          {
            day: 0o1,
            description:
              "Achievements of the World Government of the Age of Enlightenment, 1977, read by Dr Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Aug_21.mp4",
          },
        ],
      },
      {
        monthName: "Sept",
        days: [
          {
            day: 30,
            description:
              "Maharishi’s Global Press Conference from 15 June 2005, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Sep_21.mp4",
          },
          {
            day: 29,
            description:
              "Congress on Veda, Science, and Consciousness with Raja Eckart and Lila-Maria.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Sep_21.mp4",
          },
          {
            day: 28,
            description:
              "Introduction to Maharishi Ayurveda by Dr Tony Nader, MD, PhD, MARR.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Sep_21.mp4",
          },
          {
            day: 27,
            description:
              "Maharishi Ayurveda tips for good health and vitality in the coming season, Part 2, by Dr Donn Brennan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Sep_21.mp4",
          },
          {
            day: 26,
            description:
              "One Unbounded Ocean – Simple answers to the big questions in life, by Dr Tony Nader, Chapter 4, read by Dr Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Sep_21.mp4",
          },
          {
            day: 25,
            description:
              "Maharishi Ayurveda tips for good health and vitality in the coming season, Part 1, by Dr Donn Brennan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Sep_21.mp4",
          },
          {
            day: 24,
            description:
              "Good news about the Brahmasthan of India and the Veda App, with Raja Harris.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Sep_21.mp4",
          },
          {
            day: 23,
            description:
              "Maharishi’s Global Press Conference from 15 June 2005.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Sep_21.mp4",
          },
          {
            day: 22,
            description:
              "Maharishi Invincibility Institute, South Africa – inspiring insights, with Dr Taddy Blecher, Dr Annie Blecher, Dr Richard Peycke.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Sep_21.mp4",
          },
          {
            day: 21,
            description:
              "International Symposium on the role of Consciousness in solving the world’s problems; Session 6, Part 2, The primary role of consciousness in Leadership, Governance, and Problem-solving, part 2, Business and Economic Development, with Dr John Hagelin, Dr Tony Nader MD, PhD, Dr Rosalia Artega Serrano, Marcelo Zambrano Lozano, Pratima Thakar, Pat Reid, Mrinalini Ingram, Dr Anil Maheshwari.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Sep_21.mp4",
          },
          {
            day: 20,
            description:
              "Update on recent successes in Pakistan, Dr Tony Nader, RR Maureen, Dr Kumar, Dr Bevan Morris.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Sep_21.mp4",
          },
          {
            day: 19,
            description:
              "One Unbounded Ocean, chapter 3, reading by Dr Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Sep_21.mp4",
          },
          {
            day: 18,
            description:
              "Helping Armenia recover from the stresses of war, with RR Maureen and David Shapiro.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Sep_21.mp4",
          },
          {
            day: 16,
            description:
              "Maharishi’s Global Press Conference from 8 June 2005, Part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Sep_21.mp4",
          },
          {
            day: 15,
            description:
              "The Maharishi Peace Palace in Edinburgh nears completion, with Keith Parker, Dr Angela Landers, Dr David Rae, and Andy Pringle.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Sep_21.mp4",
          },
          {
            day: 14,
            description:
              "Grand dedication of Maharishi Veda Bhavan at MIU in honour of Dr Vernon Katz, Part 2, with Dr John Hagelin, Dr Tony Nader MD, PhD, Dr Bevan Morris, Barbara McLaughlin, Raja Rogers, RR Candace, Dr Susan Brown, Dr Sama, Dr Vernon Katz, Dr Judy Booth.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Sep_21.mp4",
          },
          {
            day: 13,
            description:
              "Grand dedication of Maharishi Veda Bhavan at MIU in honour of Dr Vernon Katz, Part 1, with Dr John Hagelin, Dr Tony Nader MD, PhD, Dr Bevan Morris, Dr Fred Travis, Dr Tom Egenes, Dr Linda Egenes, Raja Harris.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Sep_21.mp4",
          },
          {
            day: 12,
            description:
              "One Unbounded Ocean of Consciousness, by Dr Tony Nader MD, PhD, book reading by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Sep_21.mp4",
          },
          {
            day: 11,
            description:
              "International Symposium on the role of Consciousness in solving the world’s problems; Session 6, Part 1, The primary role of consciousness in Leadership, Governance, and Problem-solving, part 2, Business and Development, with Dr John Hagelin, Dr Tony Nader MD, PhD, Dr Rosalia Artega Serrano, Marcelo Zambrano Lozano, Pratima Thakar, Pat Reid, Mrinalini Ingram, Dr Anil Maheshwari",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Sep_21.mp4",
          },
          {
            day: 09,
            description:
              "Maharishi’s Global Press Conference from 8 June 2005, Part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Sep_21.mp4",
          },
          {
            day: 08,
            description:
              "Guru Purnima offering from Australia with Raja Rogers, RR Candace, National Director Dr Tim Carr, and Governors of Australia.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Sep_21.mp4",
          },
          {
            day: 0o7,
            description:
              "International Symposium on the role of Consciousness in solving the world’s problems; Session 5, Part 2, The primary role of consciousness in health and wellness; strengthening collective health, with Dr John Hagelin, Dr Tony Nader MD, PhD, Dr Jose Saraiva Felipe, Dr James Bray, Dr Julia T Arnold, Dr Ravi Patel, Dr Nancy Lonsdorf, Dr Robert Schneider, Dr Keith Wallace.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Sep_21.mp4",
          },
          {
            day: 0o6,
            description:
              "International Symposium on the role of Consciousness in solving the world’s problems; Session 5, Part 1, The primary role of consciousness in health and wellness; strengthening collective health, with Dr John Hagelin, Dr Tony Nader MD, PhD, Dr Jose Saraiva Felipe, Dr James Bray, Dr Julia T Arnold, Dr Ravi Patel, Dr Nancy Lonsdorf, Dr Robert Schneider, Dr Keith Wallace",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Sep_21.mp4",
          },
          {
            day: 0o5,
            description: "Consciousness is Primary, reading by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Sep_21.mp4",
          },
          {
            day: 0o4,
            description:
              "International Symposium on the role of Consciousness in solving the world’s problems; Session 4, Part 2, The primary role of consciousness in leadership, governance, and problem-solving, with Dr John Hagelin, Dr Tony Nader MD, PhD, Dr Suzanne Camelia-Römer, Dr Jose Luis Ramirez Leon, Pat Reid, Mrinalini Ingram, Dr Ash Pachauri",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Sep_21.mp4",
          },
          {
            day: 0o3,
            description:
              "International Symposium on the role of Consciousness in solving the world’s problems; Session 4, Part 1, The primary role of consciousness in leadership, governance, and problem-solving, with Dr John Hagelin, Dr Tony Nader MD, PhD, Dr Suzanne Camelia-Römer, Dr Jose Luis Ramirez Leon, Pat Reid, Mrinalini Ingram, Dr Ash Pachauri",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Sep_21.mp4",
          },
          {
            day: 0o2,
            description:
              "Maharishi’s Global Press Conference from 1 June 2005, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Sep_21.mp4",
          },
          {
            day: 0o1,
            description:
              "International Symposium on the role of Consciousness in solving the world’s problems. Session 3, Mental Health, Part 2, Dr John Hagelin, Dr Tony Nader MD, PhD, Dr Sandy Nidich, Dr Yolanda Lewis-Ragland, Scott Gould.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Sep_21.mp4",
          },
        ],
      },
      {
        monthName: "Oct",
        days: [
          {
            day: 31,
            description:
              "Dr Tony Nader’s book One Unbounded Ocean, chapter 6, reading by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_Oct_21.mp4",
          },
          {
            day: 28,
            description:
              "Maharishi’s Global Press Conference from 22nd June 2005, Part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Oct_21.mp4",
          },
          {
            day: 24,
            description:
              "One Unbounded Ocean by Dr Tony Nader, MD, PhD, MARR, read by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Oct_21.mp4",
          },
          {
            day: 21,
            description:
              "Maharishi’s Global Press Conference, 15 July 2005, part 3.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Oct_21.mp4",
          },
          {
            day: 20,
            description:
              "Raising the Flag of Invincibility around the world on Victory Day.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Oct_21.mp4",
          },
          {
            day: 17,
            description:
              "One Unbounded Ocean – Simple answers to the big questions in life, by Dr Tony Nader, Chapter 5 continued, read by Dr Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Oct_21.mp4",
          },
          {
            day: 16,
            description:
              "Wonderful news of the expansion of Maharishi AyurVeda, with Lothar Pirc and Dr Girish Momaya.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Oct_21.mp4",
          },
          {
            day: 0o4,
            description:
              "Artifical intelligence (AI) – are machines conscious? with Dr Tony Nader, MD, PhD, MARR, and Mo Gawdat, AI expert and author.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Oct_21.mp4",
          },
          {
            day: 0o3,
            description:
              "One Unbounded Ocean – Simple answers to the big questions in life, by Dr Tony Nader, Chapter 5, read by Dr Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Oct_21.mp4",
          },
          {
            day: 0o2,
            description:
              "International Symposium on the role of Consciousness in solving the world’s problems; Session 7, Part 2, Higher states of consciousness, with Dr John Hagelin, Dr Tony Nader MD, PhD, Dr Craig Pearson, Dr Fred Travis, Dr Bevan Morris.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Oct_21.mp4",
          },
          {
            day: 0o1,
            description:
              "International Symposium on the role of Consciousness in solving the world’s problems; Session 7, Part 1, Higher states of consciousness, with Dr John Hagelin, Dr Tony Nader MD, PhD, Dr Craig Pearson, Dr Fred Travis, Dr Bevan Morris.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Oct_21.mp4",
          },
        ],
      },
      {
        monthName: "Nov",
        days: [
          {
            day: 29,
            description:
              "Global Giving opportunity for Dhammajarinee school Thailand, Acharn Yai, RR Annarbha",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Nov_21.mp4",
          },
          {
            day: 28,
            description:
              "One Unbounded Ocean of Consciousness by Tony Nader, MD, PhD, MARR, Chapter 12, read by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Nov_21.mp4",
          },
          {
            day: 27,
            description:
              "Integrative cardiology including Maharishi AyurVeda and modern medical approaches with Dr John Zamarra and Vaidya Manohar, The Doshi Center for Integrative Medicine, Los Angeles, CA",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Nov_21.mp4",
          },
          {
            day: 26,
            description:
              "One Unbounded Ocean of Consciousness by Tony Nader, MD, PhD, MARR, Chapter 11, read by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Nov_21.mp4",
          },
          {
            day: 25,
            description:
              "Maharishi’s Global Press Conference from 29 June 2005, part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Nov_21.mp4",
          },
          {
            day: 24,
            description:
              "Optimising sleep to live life fully, with Nancy Rosenstein",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Nov_21.mp4",
          },
          {
            day: 23,
            description:
              "One Unbounded Ocean by Tony Nader, MD, PhD, MARR, Chapter 11, read by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Nov_21.mp4",
          },
          {
            day: 21,
            description:
              "One Unbounded Ocean by Tony Nader, MD, PhD, MARR, Chapter 9, 10, read by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Nov_21.mp4",
          },
          {
            day: 20,
            description:
              "Tony Nader, MD, PhD’s new book One Unbounded Ocean of Consciousness launched online, with Tony Nader, MD, PhD, MARR, David Lynch, Dr Bevan Morris, Raja Luis, Velia Nader, and others.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Nov_21.mp4",
          },
          {
            day: 14,
            description:
              "One Unbounded Ocean by Tony Nader, MD, PhD, MARR, read by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Nov_21.mp4",
          },
          {
            day: 11,
            description:
              "Maharishi’s Global Press Conference from 22 June 2005, part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Nov_21.mp4",
          },
          {
            day: 10,
            description:
              "Raja Luis reports on the good progress in Latin America",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Nov_21.mp4",
          },
          {
            day: 0o7,
            description:
              "One Unbounded Ocean of Counsciousness, Chapter 7, by Dr Tony Nader MD, PhD, reading by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Nov_21.mp4",
          },
          {
            day: 0o5,
            description: "News from Nepal, with Lothar Pirc",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Nov_21.mp4",
          },
          {
            day: 0o1,
            description:
              "Graduation of Maharishi Aromatherapy Consultant Training Course, with Dr Walter Mölk and Andreia Castilho",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Nov_21.mp4",
          },
        ],
      },
      {
        monthName: "Dec",
        days: [
          {
            day: 30,
            description:
              "Summary of Global Movement Achievements of 2021 by Dr Bevan Morris",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Dec_21.mp4",
          },
          {
            day: 29,
            description:
              "Summary of achievements of MIU and the US by Raja Hagelin",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Dec_21.mp4",
          },
          {
            day: 28,
            description:
              "Summary of inspiring global news for 2021 by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Dec_21.mp4",
          },
          {
            day: 23,
            description:
              "Maharishi’s Global Press Conference, 6 July 2005, Part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Dec_21.mp4",
          },
          {
            day: 21,
            description:
              "Raja Dr. Eckart Stein presents A New Paradigm in Objective Science – Consciousness Is All That There Is.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Dec_21.mp4",
          },
          {
            day: 20,
            description:
              "Restoring the dignity of Kashi Vishwanath in Varanasi, with Dr Eike Hartmann",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Dec_21.mp4",
          },
          {
            day: 18,
            description:
              "Mahayagya in Ayodhya to support Maharishi’s goals in India, with Tony Nader MD, PhD, MARR, and Dr Eike Hartman",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Dec_21.mp4",
          },
          {
            day: 17,
            description:
              "Signs of rising collective consciousness reflected in the Global media, by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Dec_21.mp4",
          },
          {
            day: 16,
            description:
              "Maharishi’s Global Press Conference from 29 June 2005, Part 3",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Dec_21.mp4",
          },
          {
            day: 15,
            description:
              "A glimpse of Maharishi’s vision for India and the world, with Dr Eike Hartmann, Raja Harris, and Dr Bevan Morris",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Dec_21.mp4",
          },
          {
            day: 14,
            description:
              "One Unbounded Ocean of Consciousness by Tony Nader, MD, PhD, MARR, Chapter 21 to the end of the book, read by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Dec_21.mp4",
          },
          {
            day: 13,
            description:
              "One Unbounded Ocean of Consciousness by Tony Nader, MD, PhD, MARR, Chapter 20 (Part 3), and start chapter 21, read by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Dec_21.mp4",
          },
          {
            day: 12,
            description:
              "One Unbounded Ocean of Consciousness by Tony Nader, MD, PhD, MARR, Chapter 20 (Part 2), read by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Dec_21.mp4",
          },
          {
            day: 11,
            description:
              "One Unbounded Ocean of Consciousness by Tony Nader, MD, PhD, MARR, Chapter 20 (Part 1), read by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Dec_21.mp4",
          },
          {
            day: 10,
            description:
              "One Unbounded Ocean of Consciousness by Tony Nader, MD, PhD, MARR, Chapter 19, read by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Dec_21.mp4",
          },
          {
            day: 08,
            description:
              "One Unbounded Ocean of Consciousness by Tony Nader, MD, PhD, MARR, Chapter 17, read by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Dec_21.mp4",
          },
          {
            day: 0o7,
            description:
              "One Unbounded Ocean of Consciousness by Tony Nader, MD, PhD, MARR, Chapter 17, read by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Dec_21.mp4",
          },
          {
            day: 0o6,
            description:
              "One Unbounded Ocean of Consciousness by Tony Nader, MD, PhD, MARR, Chapters 15 (part 2) and 16, read by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Dec_21.mp4",
          },
          {
            day: 0o5,
            description:
              "One Unbounded Ocean of Consciousness by Tony Nader, MD, PhD, MARR, Chapter 15, read by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Dec_21.mp4",
          },
          {
            day: 0o4,
            description:
              "One Unbounded Ocean of Consciousness by Tony Nader, MD, PhD, MARR, Chapter 14, read by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Dec_21.mp4",
          },
          {
            day: 0o3,
            description:
              "Celebrating the birthday of Raja Chancellor, with Maharaja, Dr Bevan Morris, and many other leaders",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Dec_21.mp4",
          },
          {
            day: 0o2,
            description:
              "Maharishi’s Global Press Conference from 29 June 2005, Part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Dec_21.mp4",
          },
          {
            day: 0o1,
            description:
              "One Unbounded Ocean of Consciousness by Tony Nader, MD, PhD, MARR, read by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Dec_21.mp4",
          },
        ],
      },
    ],
  },
  // Year 2020
  {
    id: 9,
    year: 2020,
    months: [
      {
        monthName: "Jan",
        days: [
          {
            day: 31,
            description:
              "Raja Kingsley elaborates on the projects to promote Maharishi Vedic Pandits in Nepal and to introduce Transcendental Meditation in schools in Nepal.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_Jan_20.mp4",
          },
          {
            day: 27,
            description:
              "Raja Kingsley presents special guests from Colombia, Hagen Rainbow and Jenna De Freitas, working with him on Maharishi Vedic Foundation projects in Nepal and Ukraine.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Jan_20.mp4",
          },
          {
            day: 26,
            description:
              "Dr. Peter Swan reads from the book Enlightenment and Invincibility, 1978.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Jan_20.mp4",
          },
          {
            day: 24,
            description:
              "Raja Luis further lays out the success in schools in South America that have introduced Transcendental Meditation as part of their curriculum with striking videos and images.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Jan_20.mp4",
          },
          {
            day: 23,
            description:
              "Maharishi’s Global Press Conference, from 6 October 2004, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Jan_20.mp4",
          },
          {
            day: 21,
            description:
              "Raja Luis explores the new possibilities for the expantion of  the Transcendental Meditation programme in the countries of his domain during the new year.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Jan_20.mp4",
          },
          {
            day: 20,
            description: "Dr. David Lynch’s birthday celebration.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Jan_20.mp4",
          },
          {
            day: 19,
            description:
              "Dr. Peter Swan reads from the book Enlightenment and Invincibility, 1978.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Jan_20.mp4",
          },
          {
            day: 16,
            description:
              "Maharishi’s Global Press Conference, from 6 October 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Jan_20.mp4",
          },
          {
            day: 13,
            description:
              "Inauguration of the Thirthteenth Year of Global Raam Raj.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Jan_20.mp4",
          },
        ],
      },
      {
        monthName: "Feb",
        days: [
          {
            day: 27,
            description:
              "Maharishi’s Global Press Conference, from 27 October 2004, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Feb_20.mp4",
          },
          {
            day: 23,
            description:
              "Dr. Peter Swan reads from the book Enlightenment and Invincibility, 1978.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Feb_20.mp4",
          },
          {
            day: 22,
            description:
              "News of rising invincibility throughout the world with Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Feb_20.mp4",
          },
          {
            day: 20,
            description:
              "Maharishi’s Global Press Conference, from 27 October 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Feb_20.mp4",
          },
          {
            day: 19,
            description:
              "Raja Kingsley and Mother of the Domain Leslie reporting on the achievements in their domain. After that, a brief report by Raja Willem, and then the Sankalpa by Dr. Susie Swan for the Ambassador Resource Office in promoting the Maharishi Vedic Pandits groups in India.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Feb_20.mp4",
          },
          {
            day: 17,
            description:
              "Birthday celebration of Dr. Neil Paterson, Minister of Administration of the Global Country of World Peace.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Feb_20.mp4",
          },
          {
            day: 16,
            description:
              "Dr. Peter Swan reads from the book Enlightenment and Invincibility, 1978.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Feb_20.mp4",
          },
          {
            day: 14,
            description:
              "Talk by Dr Nancy Lonsdorf, MD, on The Healthy Brain Solution, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Feb_20.mp4",
          },
          {
            day: 13,
            description:
              "Maharishi’s Global Press Conference, from 13 October 2004, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Feb_20.mp4",
          },
          {
            day: 12,
            description:
              "Talk by Dr Nancy Lonsdorf, MD, on The Healthy Brain Solution, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Feb_20.mp4",
          },
          {
            day: 10,
            description:
              "Dr Rainer Picha, Minister of Health of the Global Country of World Peace, discusses how to protect ourselves from virus.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Feb_20.mp4",
          },
          {
            day: 09,
            description:
              "Dr. Peter Swan reads from the book Enlightenment and Invincibility, 1978.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Feb_20.mp4",
          },
          {
            day: 0o6,
            description:
              "Maharishi’s Global Press Conference, from 13 October 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Feb_20.mp4",
          },
          {
            day: 0o2,
            description:
              "Dr. Peter Swan reads from the book Enlightenment and Invincibility, 1978.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Feb_20.mp4",
          },
        ],
      },
      {
        monthName: "Mar",
        days: [
          {
            day: 24,
            description:
              "Latest news from Raja Luis in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Mar_20.mp4",
          },
          {
            day: 22,
            description:
              "Dr. Peter Swan reads the latest paper by Dr Tony Nader, MD, PhD, Maharaja Adhiraj Rajaraam, entitled Questions and Answers about the Theory that Cosnciousness is All There Is, published in IJMAC, Vol. 4, No. 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Mar_20.mp4",
          },
          {
            day: 20,
            description:
              "Robert H. Schneider, M.D., F.A.C.C., Author of Total Heart Health, and Leading Authority on the Maharishi Ayurvedic Approach To Health shares his view on the current global health situation.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Mar_20.mp4",
          },
          {
            day: 19,
            description:
              "Maharishi’s Global Press Conference, from 10 November 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Mar_20.mp4",
          },
          {
            day: 18,
            description:
              "Maharishi AyurVeda Doctor Don Brennan presents the Maharishi AyurVeda perspective on increasing our immunity.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Mar_20.mp4",
          },
          {
            day: 17,
            description:
              "Maharaja Adhiraj Rajaraam spoke on Maharishi’s Global Family Chat with important practical points to take action on–see below. Everyone should watch.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Mar_20.mp4",
          },
          {
            day: 13,
            description:
              "Prof. Cathy Gorini, PhD, explains some details found in the paper entitled ‘Consciousness is All There Is’ by Tony Nader, MD, PhD, Maharaja Adhiraj Rajaraam, published in the International Journal of Mathematics and Consciousness. Dr Gorini also summarises her paper, ‘Geometry For The Artist’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Mar_20.mp4",
          },
          {
            day: 12,
            description:
              "Maharishi’s Global Press Conference, from 3 November 2004, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Mar_20.mp4",
          },
          {
            day: 10,
            description:
              "Raja Luis summarizes the latest achievements in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Mar_20.mp4",
          },
          {
            day: 08,
            description:
              "Dr. Peter Swan reads from the book Enlightenment and Invincibility, 1978.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Mar_20.mp4",
          },
          {
            day: 0o6,
            description:
              "Honouring Maharishi’s Long-Time Transcendental Meditation Sidhi Administrators, Brahmasthan of India, part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Mar_20.mp4",
          },
          {
            day: 0o5,
            description:
              "Maharishi’s Global Press Conference, from 3 November 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Mar_20.mp4",
          },
          {
            day: 0o4,
            description:
              "Honouring Maharishi’s Long-Time Transcendental Meditation Sidhi Administrators, Brahmasthan of India, part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Mar_20.mp4",
          },
          {
            day: 0o3,
            description:
              "Celebration of Dr Bevan Morris, Prime Minister of the Global Country of World Peace.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Mar_20.mp4",
          },
          {
            day: 0o2,
            description:
              "Report by Raja John Hagelin on the progress of Maharishi International University, Fairfield, IA.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Mar_20.mp4",
          },
          {
            day: 0o1,
            description:
              "Dr. Peter Swan reads from the book Enlightenment and Invincibility, 1978.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Mar_20.mp4",
          },
        ],
      },
      {
        monthName: "Apr",
        days: [
          {
            day: 30,
            description:
              "Maharishi’s Global Press Conference, from 24 November 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Sep_20.mp4",
          },
          {
            day: 29,
            description:
              "Flag Raising Ceremonies on Akshaya Tritiya in various countries around the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Sep_20.mp4",
          },
          {
            day: 28,
            description:
              "Latest news from Raja Luis in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Sep_20.mp4",
          },
          {
            day: 27,
            description:
              "Raja Peter talks about the new online courses coming up.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Sep_20.mp4",
          },
          {
            day: 19,
            description:
              "Dr Peter Swan reads from the book by Maharishi entitled, The Vedas, Source of the Subtle Science published in 1964.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Sep_20.mp4",
          },
          {
            day: 17,
            description:
              "Vaidya Rohit Bartake, Transcendental Meditation Teacher and Project Head for Maharishi’s Institute of Management Projects in Mauritius, presents ‘Immunity in the classical Ayurvedic texts’, part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Sep_20.mp4",
          },
          {
            day: 16,
            description:
              "Maharishi’s Global Press Conference, from 10 November 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Sep_20.mp4",
          },
          {
            day: 13,
            description:
              "Dr Keith Wallace presents the principles of Health and Immunity.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Sep_20.mp4",
          },
          {
            day: 12,
            description:
              "Dr Peter Swan starts the reading of a book by Maharishi entitled, The Vedas, Source of the Subtle Science published in 1964.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Sep_20.mp4",
          },
          {
            day: 11,
            description: "News of Rising Invincibility throughout the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Sep_20.mp4",
          },
          {
            day: 10,
            description:
              "Presentation by Dr. Craig Pearson, Vice-President of Maharishi International University, Fairfield, IA, USA.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Sep_20.mp4",
          },
          {
            day: 09,
            description:
              "Maharishi’s Global Press Conference, from 10 November 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Sep_20.mp4",
          },
          {
            day: 0o5,
            description:
              "Dr. Peter Swan reads the latest paper by Dr Tony Nader, MD, PhD, Maharaja Adhiraj Rajaraam, entitled Questions and Answers about the Theory that Cosnciousness is All There Is, published in IJMAC, Vol. 4, No. 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Sep_20.mp4",
          },
        ],
      },
      {
        monthName: "May",
        days: [
          {
            day: 31,
            description:
              "Dr Peter Swan continues reading from the book ‘Deep Meditation’, by Maharishi, published in 1961.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_May_20.mp4",
          },
          {
            day: 30,
            description:
              "Talk by Dr John Hagelin, President of Maharishi International University, USA, recorded on the 23rd May, in which Dr. Hagelin takes a deep dive into the latest findings about the Physics of the Maharishi Effect, Part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_May_20.mp4",
          },
          {
            day: 29,
            description:
              "Talk by Dr John Hagelin, President of Maharishi International University, USA, recorded on the 23rd May, in which Dr. Hagelin takes a deep dive into the latest findings about the Physics of the Maharishi Effect, Part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_May_20.mp4",
          },
          {
            day: 28,
            description:
              "Maharishi’s Global Press Conference, from 8 December 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_May_20.mp4",
          },
          {
            day: 27,
            description:
              "Dr John Hagelin, President of Maharishi International University, USA, with achievements and news on the University with other special guests part two.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_May_20.mp4",
          },
          {
            day: 26,
            description:
              "Dr John Hagelin, President of Maharishi International University, USA, with achievements and news on the University with other special guests part one.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_May_20.mp4",
          },
          {
            day: 25,
            description:
              "Dr Kenneth Chandler, The knowledge and experience of pure consciousness in Ancient Egypt, Mesopotamia, and Persia.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_May_20.mp4",
          },
          {
            day: 24,
            description:
              "Dr Peter Swan starts reading from the book ‘Deep Meditation’, by Maharishi, published in 1961.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_May_20.mp4",
          },
          {
            day: 22,
            description:
              "Dr Sandy Nidich presents results of research on the effect of Transcendental Meditation on high-performing students.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_May_20.mp4",
          },
          {
            day: 21,
            description:
              "Maharishi’s Global Press Conference, from 1 December 2004, part 4.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_May_20.mp4",
          },
          {
            day: 20,
            description:
              "Playing the TM Talks recorded in USA with famous movie director Dr. David Lynch.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_May_20.mp4",
          },
          {
            day: 19,
            description:
              "Raja Luis reports on the progress of giving online courses during this time for the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_May_20.mp4",
          },
          {
            day: 18,
            description:
              "Playing the TM Talks recorded in USA entitled ‘Maharishi speaking on the value of music’ with Mario Ursati interviewing classical guitarist Sharon Isbin.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_May_20.mp4",
          },
          {
            day: 17,
            description:
              "Dr Peter Swan finishes reading from the book by Maharishi entitled, The Treasury and the Market published in 1960.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_May_20.mp4",
          },
          {
            day: 14,
            description:
              "Maharishi’s Global Press Conference, from 1 December 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_May_20.mp4",
          },
          {
            day: 13,
            description:
              "Playing the TM Talks recorded in USA with Dr Fred Travis, PhD, leading researcher on the effects of Transcendental Meditation on brain functioning.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_May_20.mp4",
          },
          {
            day: 11,
            description:
              "Dr Kenneth Chandler, The knowledge and experience of pure consciousness in Ancient Greece.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_May_20.mp4",
          },
          {
            day: 10,
            description:
              "Dr Peter Swan continues reading from the book by Maharishi entitled, The Treasury and the Market published in 1960.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_May_20.mp4",
          },
          {
            day: 09,
            description:
              "Playing the first of the TM Talks recorded on 12th April in USA with Dr Norman Rosenthal, MD.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_May_20.mp4",
          },
          {
            day: 08,
            description:
              "Raja Harris’s talk during the Online Together Open Yagya Day held on 2nd May 2020 at MERU.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_May_20.mp4",
          },
          {
            day: 0o7,
            description:
              "Maharishi’s Global Press Conference, from 24 November 2004, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_May_20.mp4",
          },
          {
            day: 0o5,
            description:
              "Creating a new home for Mother Divine, in Paarlo, The Netherlands with Conny Postel, the project leader.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_May_20.mp4",
          },
          {
            day: 0o4,
            description:
              "Vaidya Rohit Bartake, Transcendental Meditation Teacher and Project Head for Maharishi’s Institute of Management Projects in Mauritius, presents ‘Immunity in the classical Ayurvedic texts’, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_May_20.mp4",
          },
          {
            day: 0o3,
            description:
              "Dr Peter Swan completes reading from the book by Maharishi entitled, The Vedas, Source of the Subtle Science published in 1964 and starts reading from The Treasury and the Market, by Maharishi published in 1960.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_May_20.mp4",
          },
          {
            day: 0o2,
            description:
              "Presentation of Maharishi’s Global Super-Radiance Programme 2020 with Raja Eckart and Lila-Maria Stein.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_May_20.mp4",
          },
        ],
      },
      {
        monthName: "June",
        days: [
          {
            day: 30,
            description:
              "Raja Luis gives us the news of rising invincibility in his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Jun_20.mp4",
          },
          {
            day: 28,
            description:
              "Dr Peter Swan continues reading from Maharishi’s book, ‘Transcendental Meditation – Questions and Answers’, first published in 1967, third edition in 2011.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Jun_20.mp4",
          },
          {
            day: 27,
            description:
              "Maharishi International University Commencement Ceremony, held on the 20th June 2020, in Fairfield, IA, USA., part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Jun_20.mp4",
          },
          {
            day: 26,
            description:
              "Maharishi International University Commencement Ceremony, held on the 20th June 2020, in Fairfield, IA, USA., part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Jun_20.mp4",
          },
          {
            day: 25,
            description:
              "Maharishi’s Global Press Conference, from 22 December 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Jun_20.mp4",
          },
          {
            day: 24,
            description:
              "Barry Spivack and Patricia Anne Saunders talk about their new book, ‘An Antidode to Violence, Evaluating the Evidence’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Jun_20.mp4",
          },
          {
            day: 22,
            description:
              "Dr Ken Chandler, PhD, professor emeritus at Maharishi International University, IA, USA, talking on Jesus’ Teaching of Transcending and Pure Consciousness.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Jun_20.mp4",
          },
          {
            day: 21,
            description:
              "Dr Peter Swan starts reading from Maharishi’s book, ‘Transcendental Meditation – Questions and Answers’, first published in 1967, third edition in 2011.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Jun_20.mp4",
          },
          {
            day: 18,
            description:
              "Maharishi’s Global Press Conference, from 15 December 2004, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Jun_20.mp4",
          },
          {
            day: 11,
            description:
              "Maharishi’s Global Press Conference, from 15 December 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Jun_20.mp4",
          },
          {
            day: 09,
            description:
              "Celebrating the birthday of Raja John Hagelin, Raja of the United States of America.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Jun_20.mp4",
          },
          {
            day: 08,
            description:
              "Dr Ken Chandler, PhD, professor emeritus at Maharishi International University, IA, USA, talking on ‘The Experience of Pure Consciousness in China, Israel, and Rome’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Jun_20.mp4",
          },
          {
            day: "07",
            description:
              "Dr Peter Swan continues reading from the book ‘Deep Meditation’, by Maharishi, published in 1961.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Jun_20.mp4",
          },
          {
            day: "06",
            description:
              "Talk by Dr John Hagelin, President of Maharishi International University, USA, entitled, ‘The Universal Mind and the Non-Collapse of the Wave Function – Consciousness Is All That There Is’, Part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Jun_20.mp4",
          },
          {
            day: "05",
            description:
              "Talk by Dr John Hagelin, President of Maharishi International University, USA, entitled, ‘The Universal Mind and the Non-Collapse of the Wave Function – Consciousness Is All That There Is’, Part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Jun_20.mp4",
          },
          {
            day: "04",
            description:
              "Maharishi’s Global Press Conference, from 8 December 2004, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Jun_20.mp4",
          },
          {
            day: "03",
            description:
              "Dr Peter Swan reviews signs of increasing positivity and invincibility in various domains of life throughout the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Jun_20.mp4",
          },
          {
            day: "02",
            description:
              "Raja Luis explores positive trends in the health of world’s population in this pandemic.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Jun_20.mp4",
          },
          {
            day: "01",
            description:
              "Dr Rainer Picha, Minister of Health of the Country of World Peace summarizes the online International Webinar on Maharishi AyurVeda held on the 23rd May 2020 about the current pandemic.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Jun_20.mp4",
          },
        ],
      },
      {
        monthName: "July",
        days: [
          {
            day: 31,
            description:
              "Dr Kenneth Chandler, PhD, professor emeritus at Maharishi International University, IA, USA, talking on ‘The Northern Renaissance – Liebniz, Spinoza, Locke, Berkeley, Newton, etc.’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_Jul_20.mp4",
          },
          {
            day: 30,
            description:
              "Maharishi’s Global Press Conference, from 29 December 2004, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Jul_20.mp4",
          },
          {
            day: 26,
            description:
              "Dr Peter Swan continues reading from Maharishi’s book, ‘Transcendental Meditation – Questions and Answers’, first published in 1967, third edition in 2011.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Jul_20.mp4",
          },
          {
            day: 24,
            description:
              "Presentation of the luxury apartment building to be built at MERU.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Jul_20.mp4",
          },
          {
            day: 23,
            description:
              "Maharishi’s Global Press Conference, from 29 December 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Jul_20.mp4",
          },
          {
            day: 22,
            description: "Guru Purnima reports by Raja John and Sara Konhaus.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Jul_20.mp4",
          },
          {
            day: 20,
            description:
              "Dr Kenneth Chandler, PhD, professor emeritus at Maharishi International University, IA, USA, talking on ‘The Northern Renaissance, René Descartes’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Jul_20.mp4",
          },
          {
            day: 19,
            description:
              "Dr Peter Swan continues reading from Maharishi’s book, ‘Transcendental Meditation – Questions and Answers’, first published in 1967, third edition in 2011.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Jul_20.mp4",
          },
          {
            day: 17,
            description:
              "Dr Kenneth Chandler, PhD, professor emeritus at Maharishi International University, IA, USA, talking on ‘The Renaissance’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Jul_20.mp4",
          },
          {
            day: 16,
            description:
              "Maharishi’s Global Press Conference, from 22 December 2004, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Jul_20.mp4",
          },
          {
            day: 15,
            description:
              "Dr Nadine Thomas announces the next online course on Maharishi Aroma Therapy starting 10 August. Click here for applications.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Jul_20.mp4",
          },
          {
            day: 13,
            description: "Highlights of Guru Purnima 2020.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Jul_20.mp4",
          },
          {
            day: 12,
            description:
              "Dr Peter Swan continues reading from Maharishi’s book, ‘Transcendental Meditation – Questions and Answers’, first published in 1967, third edition in 2011.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Jul_20.mp4",
          },
          {
            day: 10,
            description:
              "More reports of Guru Purnima 2020 from Rajas of the Global Country of World Peace, part 1. Dr Swan also reviews the latest news of rising invincibility in the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Jul_20.mp4",
          },
          {
            day: 09,
            description:
              "Raja Luis introduces Shri G. S. Kale ji, managing director of SEACO foundry, Pune, India, creator of Maharishi Veda Vision in India.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Jul_20.mp4",
          },
          {
            day: 08,
            description:
              "Dr Kenneth Chandler, PhD, professor emeritus at Maharishi International University, IA, USA, talking on ‘The Christian and Islamic Heritage—The Unity of Religion’, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Jul_20.mp4",
          },
          {
            day: 0o2,
            description:
              "Celebrating the birthday of our dear Raja Luis, Raja of Invincible Latin America including the wishes from Dr Tony Nader, MD, PhD, Maharaja Adhiraj Rajaraam, Dr. Bevan Morris, and many other guests from his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Jul_20.mp4",
          },
        ],
      },
      {
        monthName: "Aug",
        days: [
          {
            day: 30,
            description:
              "Dr Peter Swan continues reading from Maharishi’s book, ‘Transcendental Meditation – Questions and Answers’, first published in 1967, third edition in 2011.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Aug_20.mp4",
          },
          {
            day: 29,
            description:
              "Dr Kenneth Chandler, PhD, professor emeritus at Maharishi International University, IA, USA, talking on ‘The Experience of Eternal Pure Consciousness and Total Natural Law—Hegel.’",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Aug_20.mp4",
          },
          {
            day: 28,
            description:
              "Raja Luis and Shri GS Kaleji discuss the 73 gardens of the Maharishi Veda land that is being built in India—part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Aug_20.mp4",
          },
          {
            day: 23,
            description:
              "Dr Peter Swan continues reading from Maharishi’s book, ‘Transcendental Meditation – Questions and Answers’, first published in 1967, third edition in 2011.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Aug_20.mp4",
          },
          {
            day: 20,
            description:
              "Maharishi’s Global Press Conference from 19 January 2005 part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Aug_20.mp4",
          },
          {
            day: 18,
            description:
              "Raja Luis and Shri GS Kaleji discuss the 73 gardens of the Maharishi Veda land that is being built in India—part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Aug_20.mp4",
          },
          {
            day: 16,
            description:
              "Dr Peter Swan continues reading from Maharishi’s book, ‘Transcendental Meditation – Questions and Answers’, first published in 1967, third edition in 2011.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Aug_20.mp4",
          },
          {
            day: 15,
            description:
              "Dr Peter Swan reviews signs of increasing positivity and invincibility in various domains of life throughout the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Aug_20.mp4",
          },
          {
            day: 13,
            description:
              "Maharishi’s Global Press Conference from 19 January 2005, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Aug_20.mp4",
          },
          {
            day: 10,
            description:
              "The experience and understanding of Pure Consciousness by Immanuel Kant, presented by Dr Ken Chandler.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Aug_20.mp4",
          },
          {
            day: 09,
            description:
              "Dr Peter Swan continues reading from Maharishi’s book, ‘Transcendental Meditation – Questions and Answers’, first published in 1967, third edition in 2011.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Aug_20.mp4",
          },
          {
            day: 0o7,
            description:
              "Raja Harris introduces the updated version of the Veda App with many new features.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Aug_20.mp4",
          },
          {
            day: 0o6,
            description:
              "Maharishi’s Global Press Conference from 12 January 2005.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Aug_20.mp4",
          },
          {
            day: 0o5,
            description:
              "Four young leaders going on TM Teacher Training Course – Caspar Jung, Hubert Hol, Julia Stibane, Mai Lan Khong.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Aug_20.mp4",
          },
          {
            day: 0o4,
            description:
              "Raja Luis describes programmes implemented during the pandemic.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Aug_20.mp4",
          },
          {
            day: 0o3,
            description:
              "Jon Lipman, Maharishi Vedic Architect from USA, interviews a few owners of Maharishi Vedic Architecture homes.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Aug_20.mp4",
          },
          {
            day: 0o2,
            description:
              "Dr Peter Swan continues reading from Maharishi’s book, ‘Transcendental Meditation – Questions and Answers’, first published in 1967, third edition in 2011.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Aug_20.mp4",
          },
          {
            day: 0o1,
            description:
              "Presntation on the new Master of Science on Aroma Therapy degree programme offered by Maharishi International University.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Aug_20.mp4",
          },
        ],
      },
      {
        monthName: "Sept",
        days: [
          {
            day: 30,
            description:
              "MIU Founder’s Day celebration with Dr Hagelin and faculty, 12 September 2020, Part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Sep_20.mp4",
          },
          {
            day: 29,
            description:
              "Viewing the territory of the Veda Land project in India with Raja Luis and Sri GS Kale-ji.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Sep_20.mp4",
          },
          {
            day: 28,
            description:
              "The knowledge and experience of Pure Consciousness in the early twentieth century, by Dr Kenneth Chandler.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Sep_20.mp4",
          },
          {
            day: 27,
            description:
              "Dr Peter Swan reads from the books ‘The Individual Mind and the Deep Meditation of Maharishi Mahesh Yogi’, and ‘Education and the Deep Meditation of Maharishi Mahesh Yogi’, printed in 1962 in Oslo, Norway.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Sep_20.mp4",
          },
          {
            day: 26,
            description:
              "Maharishi International University Founder’s Day celebration of 12 September 2020, Dr John Hagelin and MIU faculty.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Sep_20.mp4",
          },
          {
            day: 25,
            description:
              "Dr Kenneth Chandler, PhD, professor emeritus at Maharishi International University, IA, USA, talking on ‘The Experience of Transcending and Pure Consciousness, XIII, The Nineteenth Century.’",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Sep_20.mp4",
          },
          {
            day: 24,
            description:
              "Maharishi’s Global Press Conference from 2 February 2005 part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Sep_20.mp4",
          },
          {
            day: 23,
            description: "The Vedic Sanskaras with Sri GS Kale and Raja Luis",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Sep_20.mp4",
          },
          {
            day: 21,
            description:
              "Dr Kenneth Chandler, PhD, professor emeritus at Maharishi International University, IA, USA, talking on ‘The Experience of Transcending and Pure Consciousness, XII, The Late Nineteenth Century.’",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Sep_20.mp4",
          },
          {
            day: 20,
            description:
              "Dr Peter Swan continues reading from Maharishi’s book, ‘Transcendental Meditation – Questions and Answers’, first published in 1967, third edition in 2011.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Sep_20.mp4",
          },
          {
            day: 14,
            description:
              "Opportunity for matching donations for Dhammajarinee School",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Sep_20.mp4",
          },
          {
            day: 10,
            description:
              "Maharishi’s Global Press Conference from 2 February 2005 part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Sep_20.mp4",
          },
          {
            day: 09,
            description:
              "The knowledge and experience of Pure Consciousness in 17–19th Century America, by Dr Ken Chandler",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Sep_20.mp4",
          },
          {
            day: 0o7,
            description:
              "Jeffrey Abramson interviewed on The Do One Better Podcast",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Sep_20.mp4",
          },
          {
            day: 0o6,
            description:
              "Dr Peter Swan continues reading from Maharishi’s book, ‘Transcendental Meditation – Questions and Answers’, first published in 1967, third edition in 2011.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Sep_20.mp4",
          },
          {
            day: 0o3,
            description:
              "Maharishi’s Global Press Conference from 26 January 2005 part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Sep_20.mp4",
          },
          {
            day: 0o2,
            description:
              "Inspiring update on news from Thailand’s Dhammajarinee Witthaya School for girls.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Sep_20.mp4",
          },
          {
            day: 0o1,
            description:
              "Raja Luis explains the trends from Maharishi Jyotish and the increased interest in our programmes during the time of pandemic",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Sep_20.mp4",
          },
        ],
      },
      {
        monthName: "Oct",
        days: [
          {
            day: 31,
            description: "Good news update from the global media",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_Oct_20.mp4",
          },
          {
            day: 30,
            description: "Flag raising around the world on Victory Day",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Oct_20.mp4",
          },
          {
            day: 29,
            description:
              "Maharishi’s Global Press Conference from 23 February 2005 part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Oct_20.mp4",
          },
          {
            day: 28,
            description:
              "Replay of ‘The treasury within us – Discovering the core message in the world’s spiritual traditions’, Dr Craig Pearson, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Oct_20.mp4",
          },
          {
            day: 27,
            description:
              "Raja Luis presents a film about the creation of the Veda Land project in India with Sri GS Kale-ji.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Oct_20.mp4",
          },
          {
            day: 26,
            description:
              "Replay of ‘The treasury within us – Discovering the core message in the world’s spiritual traditions’, Dr Craig Pearson, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Oct_20.mp4",
          },
          {
            day: 16,
            description:
              "Dr Nancy Lonsdorf, Secrets of emotional balance, Part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Oct_20.mp4",
          },
          {
            day: 15,
            description:
              "Maharishi’s Global Press Conference from 16 February 2005 part 3.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Oct_20.mp4",
          },
          {
            day: 14,
            description:
              "Dr Nancy Lonsdorf, Secrets of emotional balance, Part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Oct_20.mp4",
          },
          {
            day: 13,
            description:
              "Celebrating the coronation of Maharaja Adhiraj Rajaraam by Maharishi on 12 October 2000, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Oct_20.mp4",
          },
          {
            day: 12,
            description:
              "Celebrating the coronation of Maharaja Adhiraj Rajaraam by Maharishi on 12 October 2000, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Oct_20.mp4",
          },
          {
            day: 11,
            description:
              "Dr Peter Swan begins reading the book, ‘Creating an Ideal Society – A Global Undertaking’, published in 1977 by Maharishi.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Oct_20.mp4",
          },
          {
            day: 10,
            description: "A tour of Maharishi Peace Palaces around the world",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Oct_20.mp4",
          },
          {
            day: 09,
            description:
              "‘The treasury within us – Discovering the core message in the world’s spiritual traditions’, Dr Craig Pearson, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Oct_20.mp4",
          },
          {
            day: 08,
            description:
              "Maharishi’s Global Press Conference from 16 February 2005 part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Oct_20.mp4",
          },
          {
            day: 0o7,
            description:
              "‘The treasury within us – Discovering the core message in the world’s spiritual traditions’, Dr Craig Pearson, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Oct_20.mp4",
          },
          {
            day: 0o5,
            description:
              "Dr Eike Hartmann gives a tour of current Vastu projects around the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Oct_20.mp4",
          },
          {
            day: 0o4,
            description:
              "The 1962 pamphlets Health and Social Welfare and the Deep Meditation of Maharishi Mahesh Yogi, read by Dr Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Oct_20.mp4",
          },
          {
            day: 0o3,
            description:
              "Cardiologist Dr Cesar Molina on prevention, interviewed by Mario Orsatti on TM Talks",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Oct_20.mp4",
          },
          {
            day: 0o2,
            description:
              "MIU Founder’s Day celebration with Dr Hagelin and faculty, 12 September 2020, Part 3.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Oct_20.mp4",
          },
          {
            day: 0o1,
            description:
              "Maharishi’s Global Press Conference from 16 February 2005 part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Oct_20.mp4",
          },
        ],
      },
      {
        monthName: "Nov",
        days: [
          {
            day: 30,
            description: "‘Heal the Healers Now’, Dr Stuart Rothenberg.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Nov_20.mp4",
          },
          {
            day: 29,
            description:
              "Dr Peter Swan reads from the book, ‘Creating an Ideal Society – A Global Undertaking’, published in 1977 by Maharishi.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Nov_20.mp4",
          },
          {
            day: 28,
            description:
              "A great chance to support Dhammajarinee Witthaya School, Thailand.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Nov_20.mp4",
          },
          {
            day: 27,
            description:
              "“The dawn of unboundedness with Maharishi Yoga Asanas”, with Dr Paul Morehead.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Nov_20.mp4",
          },
          {
            day: 26,
            description:
              "Maharishi’s Global Press Conference from 9 March 2005 part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Nov_20.mp4",
          },
          {
            day: 23,
            description:
              "Sleep – how to get it and why it matters, by Dr Nancy Lonsdorf.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Nov_20.mp4",
          },
          {
            day: 22,
            description:
              "Dr Peter Swan reads from the book, ‘Creating an Ideal Society – A Global Undertaking’, published in 1977 by Maharishi.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Nov_20.mp4",
          },
          {
            day: 21,
            description:
              "The first seminar of the Academy of Arts for Enlightenment, with Raja Anthony",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Nov_20.mp4",
          },
          {
            day: 20,
            description:
              "The Maasai people of East Africa are enjoying Transcendental Meditation, by Raja Steve Heaton",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Nov_20.mp4",
          },
          {
            day: 19,
            description:
              "Maharishi’s Global Press Conference from 2 March 2005 part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Nov_20.mp4",
          },
          {
            day: 18,
            description:
              "The extraordinary story of how Maharishi began the world’s first national Super-radiance Programme and how this turned the tide of time, by Dr Bevan Morris",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Nov_20.mp4",
          },
          {
            day: 17,
            description:
              "Examples of drops of progress that are leading us to a better situation, by Raja Luis.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Nov_20.mp4",
          },
          {
            day: 16,
            description:
              "Singer-songwriter Jane Roman Pitt interviewed by Mario Orsatti.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Nov_20.mp4",
          },
          {
            day: 15,
            description:
              "Dr Peter Swan reads from the book, ‘Creating an Ideal Society – A Global Undertaking’, published in 1977 by Maharishi.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Nov_20.mp4",
          },
          {
            day: 11,
            description:
              "Transforming Lives from the Inside Out with members of the David Lynch Foundation.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Nov_20.mp4",
          },
          {
            day: 10,
            description:
              "Raja Luis presents the Next Generation Governors in Latin America, Asia, and Africa.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Nov_20.mp4",
          },
          {
            day: 9,
            description:
              "Dr Bob Roth speaks about bringing Transcendental Meditation to people suffering from trauma.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Nov_20.mp4",
          },
          {
            day: 8,
            description:
              "Dr Peter Swan reads from the book, ‘Creating an Ideal Society – A Global Undertaking’, published in 1977 by Maharishi.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Nov_20.mp4",
          },
          {
            day: 7,
            description:
              "Graduation of the first online Maharishi Yoga Asana Instructor Training Course.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Nov_20.mp4",
          },
          {
            day: 5,
            description:
              "Maharishi’s Global Press Conference from 23 February 2005 part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Nov_20.mp4",
          },
          {
            day: 4,
            description:
              "Enlightenment Leadership with Dr Tony Nader, MD, PhD, Maharaja Adhiraj Rajaraam, presented by Dr John Hagelin, PhD, Raja of United States..",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Nov_20.mp4",
          },
          {
            day: 2,
            description:
              "Dr Ken Chandler, 20th Century philosophers and inventors.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Nov_20.mp4",
          },
          {
            day: 1,
            description:
              "Dr Peter Swan reads from the book, ‘Creating an Ideal Society – A Global Undertaking’, published in 1977 by Maharishi.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Nov_20.mp4",
          },
        ],
      },
      {
        monthName: "Dec",
        days: [
          {
            day: 31,
            description: "2020 Achievements with Dr. Bevan Morris part 3.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_Dec_20.mp4",
          },
          {
            day: 30,
            description: "2020 Achievements with Dr. Bevan Morris part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Dec_20.mp4",
          },
          {
            day: 29,
            description: "2020 Achievements with Dr. Bevan Morris part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Dec_20.mp4",
          },
          {
            day: 28,
            description:
              "News of rising invicibility for the year 2020, presented by Dr Peter Swan, Minister of Communication of the Global Country of World Peace.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Dec_20.mp4",
          },
          {
            day: 27,
            description:
              "Dr Peter Swan reads from the book, ‘History of the Movement’, published by The International Meditation Centre, Kashmir, India, 1969.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Dec_20.mp4",
          },
          {
            day: 24,
            description:
              "Maharishi’s Global Press Conference from 16 March 2005 part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Dec_20.mp4",
          },
          {
            day: 21,
            description:
              "What are Veda and Vedic recitations, by Raja Harris .",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Dec_20.mp4",
          },
          {
            day: 19,
            description:
              "News from the countries of their domain with Raja Graham de Freitas and Mother of the Domain Leanne.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Dec_20.mp4",
          },
          {
            day: 18,
            description:
              "Scientific research on TM in San Francisco schools, Noah Schechtman. Inspiring report from Uganda and Mali, Raja Graham.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Dec_20.mp4",
          },
          {
            day: 14,
            description:
              "David Lynch presents his illustrious guests participating in the benefit concert and celebration, Meditate America, including an address by Tony Nader, MD, PhD., Maharaja Adhiraj Rajaraam.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Dec_20.mp4",
          },
          {
            day: 13,
            description:
              "Dr Peter Swan starts reading from the book, ‘History of the Movement’, published by The International Meditation Centre, Kashmir, India, 1969.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Dec_20.mp4",
          },
          {
            day: 11,
            description:
              "Replay of The Physics of the Maharishi Effect with Dr. John Hagelin, President of Maharishi International University, Fairfield, IA, USA.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Dec_20.mp4",
          },
          {
            day: 10,
            description:
              "Maharishi’s Global Press Conference from 9 March 2005 part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Dec_20.mp4",
          },
          {
            day: 0o7,
            description:
              "Daily Ayurvedic habits with Sankari Menon Wegman, PhD at Maharishi International University, Fairfield, IA, USA.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Dec_20.mp4",
          },
          {
            day: 0o6,
            description:
              "Dr Peter Swan reads from the book, ‘Creating an Ideal Society – A Global Undertaking’, published in 1977 by Maharishi.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Dec_20.mp4",
          },
          {
            day: 0o5,
            description:
              "Higher States: Bursting the bounds of Waking Consciousness, Dr John Hagelin.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Dec_20.mp4",
          },
          {
            day: 0o4,
            description:
              "TM talks: ‘Transforming yourselves while transforming the world’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Dec_20.mp4",
          },
          {
            day: 0o3,
            description:
              "Celebrating the birthday of Raja Chancellor, Purusha Raja with universal domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Dec_20.mp4",
          },
          {
            day: 0o2,
            description:
              "Dr Rainer Picha’s presentation on Maharishi  Ayur-Veda.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Dec_20.mp4",
          },
        ],
      },
    ],
  },
  // Year 2019
  {
    id: 8,
    year: 2019,
    months: [
      {
        monthName: "Jan",
        days: [
          {
            day: 31,
            description:
              "Maharishi’s Global Press Conference, from 14 April 2004, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_Jan_19.mp4",
          },
          {
            day: 27,
            description:
              "Dr. Peter Swan reads from the book Maharishi’s Master Plan to Create Heaven on Earth, published in 1991.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Jan_19.mp4",
          },
          {
            day: 24,
            description:
              "Maharishi’s Global Press Conference, from 14 April 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Jan_19.mp4",
          },
          {
            day: 20,
            description:
              "Dr. Peter Swan reads from the book Maharishi’s Master Plan to Create Heaven on Earth, published in 1991.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Jan_19.mp4",
          },
          {
            day: 17,
            description:
              "Maharishi’s Global Press Conference, from 7 April 2004, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Jan_19.mp4",
          },
          {
            day: 15,
            description:
              "New Year’s progress report from Raja Hugo Guido and Mother of the Domain Catheline from the Philippines.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Jan_19.mp4",
          },
          {
            day: 14,
            description:
              "New Year’s progress report from Raja Richard and Mother of the Domain Vicky Broome from South Africa.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Jan_19.mp4",
          },
          {
            day: 13,
            description:
              "New Year’s progress report from Raja Michael and Mother of the Domain Margaret Busch.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Jan_19.mp4",
          },
        ],
      },
      {
        monthName: "Feb",
        days: [
          {
            day: 28,
            description:
              "Maharishi’s Global Press Conference, from 5 May 2004, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Feb_19.mp4",
          },
          {
            day: 27,
            description:
              "Dr Tony Nader, Md, PhD, Maharaja Adhiraj Rajaraam, addresses the Maharishi Vedic Pandits Ambassador reception on the 2 February 2019.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Feb_19.mp4",
          },
          {
            day: 25,
            description:
              "The Changemakers Conference – Is College Bad for Your Brain? – at Maharishi University of Management, Fairfield, IA, USA, part 3.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Feb_19.mp4",
          },
          {
            day: 23,
            description:
              "The Changemakers Conference – Is College Bad for Your Brain? – at Maharishi University of Management, Fairfield, IA, USA, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Feb_19.mp4",
          },
          {
            day: 22,
            description:
              "The Changemakers Conference – Is College Bad for Your Brain? – at Maharishi University of Management, Fairfield, IA, USA, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Feb_19.mp4",
          },
          {
            day: 21,
            description:
              "Maharishi’s Global Press Conference, from 5 May 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Feb_19.mp4",
          },
          {
            day: 20,
            description:
              "A new study on Post Traumatic Stress Disorder (PTSD) done with the participants of students of the Maharishi Institute in Johannesburg, South Africa presented by David Shapiro.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Feb_19.mp4",
          },
          {
            day: 18,
            description:
              "Dr Eike Hartmann presents a graphic summary of the Inauguration of the Maharishi Smarak at Prayag (Allahabad), India that was held on the 15th February during the on-going Kumbha Mela.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Feb_19.mp4",
          },
          {
            day: 17,
            description:
              "Dr. Peter Swan reads from the book Maharishi’s Master Plan to Create Heaven on Earth, published in 1991.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Feb_19.mp4",
          },
          {
            day: 16,
            description:
              "Summary of global good news about health and the environment since the beginning of the new year.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Feb_19.mp4",
          },
          {
            day: 15,
            description:
              "New course on Introduction to Maharishi AyurVeda is being introduced with Lothar Pirc, director of Bad Elms Maharishi AyurVeda clinic, Germany.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Feb_19.mp4",
          },
          {
            day: 14,
            description:
              "Maharishi’s Global Press Conference, from 28 April 2004, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Feb_19.mp4",
          },
          {
            day: 12,
            description:
              "Report from Raja Luis in the creation of large groups of Yogic Flyers in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Feb_19.mp4",
          },
          {
            day: 0o7,
            description:
              "Maharishi’s Global Press Conference, from 28 April 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Feb_19.mp4",
          },
          {
            day: 0o3,
            description:
              "Dr. Peter Swan reads from the book Maharishi’s Master Plan to Create Heaven on Earth, published in 1991.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Feb_19.mp4",
          },
        ],
      },
      {
        monthName: "Mar",
        days: [
          {
            day: 31,
            description:
              "Dr. Peter Swan reads from the book Maharishi’s Master Plan to Create Heaven on Earth, published in 1991.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_Mar_19.mp4",
          },
          {
            day: 28,
            description:
              "Maharishi’s Global Press Conference, from 26 May 2004, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Mar_19.mp4",
          },
          {
            day: 26,
            description:
              "Raja Luis elaborate on the latest achievements in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Mar_19.mp4",
          },
          {
            day: 25,
            description:
              "Latest news of rising invincibility throughout the world with Dr Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Mar_19.mp4",
          },
          {
            day: 24,
            description:
              "Dr. Peter Swan reads from the book Maharishi’s Master Plan to Create Heaven on Earth, published in 1991.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Mar_19.mp4",
          },
          {
            day: 22,
            description:
              "Celebration of Raja Rafael’s birthday, Purusha Raja with universal domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Mar_19.mp4",
          },
          {
            day: 21,
            description:
              "Maharishi’s Global Press Conference, from 26 May 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Mar_19.mp4",
          },
          {
            day: 19,
            description:
              "Celebrating the birthday of Dr. Bevan Morris, Prime Minister of the Global Country of World Peace – part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Mar_19.mp4",
          },
          {
            day: 18,
            description:
              "Celebrating the birthday of Dr. Bevan Morris, Prime Minister of the Global Country of World Peace – part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Mar_19.mp4",
          },
          {
            day: 17,
            description:
              "Dr. Peter Swan reads from the book Maharishi’s Master Plan to Create Heaven on Earth, published in 1991.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Mar_19.mp4",
          },
          {
            day: 16,
            description:
              "Raja Paul with achievements in the countries of his domain, namely Canada and Turkey.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Mar_19.mp4",
          },
          {
            day: 14,
            description:
              "Maharishi’s Global Press Conference, from 19 May 2004, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Mar_19.mp4",
          },
          {
            day: 12,
            description:
              "Videos from the International Conference on Vedic Science and Modern Science held in Nepal from 1 to 3 March 2019 – Part 6: Raja Luis.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Mar_19.mp4",
          },
          {
            day: 11,
            description:
              "Videos from the International Conference on Vedic Science and Modern Science held in Nepal from 1 to 3 March 2019 – Part 5: Raja Chris Crowell and Dr. Ann Crowell.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Mar_19.mp4",
          },
          {
            day: 10,
            description:
              "Dr. Peter Swan reads from the book Maharishi’s Master Plan to Create Heaven on Earth, published in 1991.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Mar_19.mp4",
          },
          {
            day: 09,
            description:
              "Videos from the International Conference on Vedic Science and Modern Science held in Nepal from 1 to 3 March 2019 – Part 4: Dr. Neil Paterson and Dr. Ashley Deans.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Mar_19.mp4",
          },
          {
            day: 08,
            description:
              "Videos from the International Conference on Vedic Science and Modern Science held in Nepal from 1 to 3 March 2019 – Part 3: Dr Eike Hartmann, Minister of Architecture of the Global Country of World Peace.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Mar_19.mp4",
          },
          {
            day: 0o7,
            description:
              "Maharishi’s Global Press Conference, from 19 May 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Mar_19.mp4",
          },
          {
            day: 0o6,
            description:
              "Videos from the International Conference on Vedic Science and Modern Science held in Nepal from 1 to 3 March 2019 – Part 2: Raja John Hagelin.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Mar_19.mp4",
          },
          {
            day: 0o5,
            description:
              "Videos from the International Conference on Vedic Science and Modern Science held in Nepal from 1 to 3 March 2019 – Part 1: Dr. Tony Nader, Maharaja Adhiraj Rajaraam’s keynote address, followed by presentions by Raja Konhaus and Raja Fagan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Mar_19.mp4",
          },
          {
            day: 0o3,
            description:
              "Dr. Peter Swan reads from the book Maharishi’s Master Plan to Create Heaven on Earth, published in 1991.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Mar_19.mp4",
          },
          {
            day: 0o2,
            description:
              "Dr Bobby Roth, CEO of the David Lynch Foundation, with the latest news about scientific research on Transcendental Meditation.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Mar_19.mp4",
          },
        ],
      },
      {
        monthName: "Apr",
        days: [
          {
            day: 29,
            description:
              "Review of the Maharishi University of Management University Report 2017-2018 with Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Sep_19.mp4",
          },
          {
            day: 28,
            description:
              "Dr. Peter Swan reads from the book Maharishi’s Master Plan to Create Heaven on Earth, published in 1991.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Sep_19.mp4",
          },
          {
            day: 26,
            description:
              "Replay of the Facebook broadcast from 2 weeks ago by Tony Nader, Md, PhD, Maharaja Adhiraj Rajarram, entitled: How to Find Your Purpose and Achieve Your Dreams.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Sep_19.mp4",
          },
          {
            day: 25,
            description:
              "Maharishi’s Global Press Conference, from 9 June 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Sep_19.mp4",
          },
          {
            day: 23,
            description:
              "Raja Luis inspires everyone with the latest achievements in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Sep_19.mp4",
          },
          {
            day: 22,
            description:
              "News of the latest Maharishi Vastu architect seminar in Turkey with Raja Paul and other guests.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Sep_19.mp4",
          },
          {
            day: 21,
            description:
              "Dr. Peter Swan reads from the book Maharishi’s Master Plan to Create Heaven on Earth, published in 1991.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Sep_19.mp4",
          },
          {
            day: 19,
            description:
              "Latest news of rising invincibility throughout the world with Dr Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Sep_19.mp4",
          },
          {
            day: 18,
            description:
              "Maharishi’s Global Press Conference, from 2 June 2004, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Sep_19.mp4",
          },
          {
            day: 17,
            description:
              "Replay of the broadcast in which Tony Nader, Md, PhD, Maharaja Adhiraj Rajaraam, answers questions from Meditators in North America, on 14 April 2019 at Maharishi University of Management, Fairfileld, IA, USA.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Sep_19.mp4",
          },
          {
            day: 14,
            description:
              "Dr. Peter Swan reads from the book Maharishi’s Master Plan to Create Heaven on Earth, published in 1991.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Sep_19.mp4",
          },
          {
            day: 0o4,
            description:
              "Maharishi’s Global Press Conference, from 2 June 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Sep_19.mp4",
          },
        ],
      },
      {
        monthName: "May",
        days: [
          {
            day: 31,
            description:
              "Dr. Bevan Morris reviews his presentation, Maharishi in the World 1953-2008.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_May_19.mp4",
          },
          {
            day: 30,
            description:
              "Maharishi’s Global Press Conference, from 23 June 2004, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_May_19.mp4",
          },
          {
            day: 28,
            description:
              "Raja Luis with the latest achievements in many countries of his domain including advances in the implementation of Maharishi AyurVeda as a recognized health care system.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_May_19.mp4",
          },
          {
            day: 27,
            description:
              "Report on the Global Tour for Total Health with Robert Schneider, MD, FACC.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_May_19.mp4",
          },
          {
            day: 26,
            description:
              "Dr. Peter Swan reads from the book Maharishi’s Master Plan to Create Heaven on Earth, published in 1991.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_May_19.mp4",
          },
          {
            day: 24,
            description:
              "Raja Graham de Freitas and Raja Bernard Nevas, Rajas of many countries in Africa, present the latest developments in implementing Consciousness-Based Education in many schools.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_May_19.mp4",
          },
          {
            day: 23,
            description:
              "Maharishi’s Global Press Conference, from 23 June 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_May_19.mp4",
          },
          {
            day: 20,
            description:
              "Raja Harris’s address of 4th May 2019 on the occasion of a Maharishi Vedic Pandit Ambassadors’ meeting.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_May_19.mp4",
          },
          {
            day: 19,
            description:
              "Dr. Peter Swan reads from the book Maharishi’s Master Plan to Create Heaven on Earth, published in 1991.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_May_19.mp4",
          },
          {
            day: 16,
            description:
              "Maharishi’s Global Press Conference, from 16 June 2004, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_May_19.mp4",
          },
          {
            day: 15,
            description:
              "Raja Peter inspires us all with the news of International Courses for Young Meditators.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_May_19.mp4",
          },
          {
            day: 14,
            description:
              "Raja Luis with the latest news in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_May_19.mp4",
          },
          {
            day: 13,
            description:
              "Replay of Maharishi’s address on Akshaya Tritiya from 4 May 2003 and the second part of the Akshaya Tritiya 2019 flag raising ceremonies in many parts of the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_May_19.mp4",
          },
          {
            day: 12,
            description:
              "Dr. Peter Swan reads from the book Maharishi’s Master Plan to Create Heaven on Earth, published in 1991.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_May_19.mp4",
          },
          {
            day: 10,
            description:
              "Raja Bruce reviews the latest achievements in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_May_19.mp4",
          },
          {
            day: 09,
            description:
              "Maharishi’s Global Press Conference, from 16 June 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_May_19.mp4",
          },
          {
            day: 08,
            description:
              "Dr. Swan connects with some members of Purusha at the Maharishi Purusha Capital in WV, USA, and Uedem, Germany.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_May_19.mp4",
          },
          {
            day: 0o6,
            description:
              "Presentation of the new website of Dr Tony Nader, MD, PhD, Maharaja Adhiraj Rajaraam by Dr. Peter Swan: https:",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_May_19.mp4",
          },
          {
            day: 0o1,
            description:
              "Dr John Hagelin, Raja of the United States, reports that astronomers have finally captured the first-ever image of the famous gravitational sinkhole known as a black hole.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_May_19.mp4",
          },
        ],
      },
      {
        monthName: "June",
        days: [
          {
            day: 23,
            description:
              "Dr. Peter Swan reads from the book Maharishi’s Master Plan to Create Heaven on Earth, published in 1991.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Jun_19.mp4",
          },
          {
            day: 20,
            description:
              "Maharishi’s Global Press Conference, from 7 July 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Jun_19.mp4",
          },
          {
            day: 16,
            description:
              "Dr. Peter Swan reads from the book Maharishi’s Master Plan to Create Heaven on Earth, published in 1991.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Jun_19.mp4",
          },
          {
            day: 14,
            description:
              "Graduation ceremony of the latest Maharishi Light Therapy with Gems course that was held at MERU from 27 May to 15 June 2019.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Jun_19.mp4",
          },
          {
            day: 13,
            description:
              "Maharishi’s Global Press Conference, from 30 June 2004, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Jun_19.mp4",
          },
          {
            day: 09,
            description:
              "Dr. Peter Swan reads from the book Maharishi’s Master Plan to Create Heaven on Earth, published in 1991.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Jun_19.mp4",
          },
          {
            day: 0o7,
            description:
              "Dr Susie Swan interviews Dr Keith Wallace, from Maharishi University of Management, Fairfield, IA, USA. Dr. Wallace spoke about the ‘Neurophysiology of Transformation’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Jun_19.mp4",
          },
          {
            day: 0o6,
            description:
              "Maharishi’s Global Press Conference, from 30 June 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Jun_19.mp4",
          },
          {
            day: 0o5,
            description:
              "The Europe project to introduce Transcendental Meditation in schools throughout Europe, Part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Jun_19.mp4",
          },
          {
            day: 0o3,
            description:
              "The Europe project to introduce Transcendental Meditation in schools throughout Europe, Part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Jun_19.mp4",
          },
          {
            day: 0o2,
            description:
              "Dr. Peter Swan reads from the book Maharishi’s Master Plan to Create Heaven on Earth, published in 1991.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Jun_19.mp4",
          },
          {
            day: 0o1,
            description:
              "Latest news of rising invincibility throughout the world with Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Jun_19.mp4",
          },
        ],
      },
      {
        monthName: "July",
        days: [
          {
            day: 31,
            description:
              "Replay of the Guru Purnima presentations by Adrienne Schoenfeld, RR Emily, RR Mona, Conny Postel, and Lila-Maria Hartmann Stein.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_Jul_19.mp4",
          },
          {
            day: 30,
            description:
              "Replay of the Guru Purnima presentations by Raja Luis, from 17 July 2019.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Jul_19.mp4",
          },
          {
            day: 29,
            description:
              "Replay of the Guru Purnima presentations by Raja Kingsley, Raja Graham, and Dr. Volker Schanbacher from 18 July 2019.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Jul_19.mp4",
          },
          {
            day: 28,
            description:
              "Dr. Peter Swan reads from the book Enlightenment and Invincibility, 1978.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Jul_19.mp4",
          },
          {
            day: 27,
            description:
              "Replay of the Guru Purnima reports by Raja Chancellor, Raja Rafael, and Raja Steven from 17 July 2019.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Jul_19.mp4",
          },
          {
            day: 26,
            description:
              "Replay of the Guru Purnima presentations by Raja Harris and Dr. Eike Hartmann, from 17 July 2019.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Jul_19.mp4",
          },
          {
            day: 25,
            description:
              "Maharishi’s Global Press Conference, from 14 July 2004, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Jul_19.mp4",
          },
          {
            day: 24,
            description:
              "Award ceremony of the Maharishi Medals of Enlightened Leadership, during the last Guru Purnima celebration 2019.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Jul_19.mp4",
          },
          {
            day: 23,
            description:
              "Replay of the address by Maharaja Adhiraj Rajaraam, First Ruler of the Global Country of World Peace,given on Guru Purnima day, 16 July 2019.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Jul_19.mp4",
          },
          {
            day: 21,
            description:
              "Dr. Peter Swan starts reading from the book Enlightenment and Invincibility, 1978.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Jul_19.mp4",
          },
          {
            day: 19,
            description:
              "Dr David Smith, MD, from New Zealand, expert in Maharishi AyurVeda, presents his findings in the field of digestion and good health.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Jul_19.mp4",
          },
          {
            day: 0o4,
            description:
              "Maharishi’s Global Press Conference, from 14 July 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Jul_19.mp4",
          },
          {
            day: 0o2,
            description: "Celebration of the birthday of Raja Luis.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Jul_19.mp4",
          },
        ],
      },
      {
        monthName: "Aug",
        days: [
          {
            day: 29,
            description:
              "Maharishi’s Global Press Conference, from 4 August 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Aug_19.mp4",
          },
          {
            day: 22,
            description:
              "Maharishi’s Global Press Conference, from 28 July 2004, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Aug_19.mp4",
          },
          {
            day: 18,
            description:
              "Dr. Peter Swan reads from the book Enlightenment and Invincibility, 1978.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Aug_19.mp4",
          },
          {
            day: 16,
            description:
              "An Overview of Maharishi’s Achievement’s in the World—Part 2 with Dr. Bevan Morris, recorded during the Maharishi Vedic Pandits Ambassadors reception, 3rd August 2019.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Aug_19.mp4",
          },
          {
            day: 15,
            description:
              "Maharishi’s Global Press Conference, from 28 July 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Aug_19.mp4",
          },
          {
            day: 14,
            description:
              "An Overview of Maharishi’s Achievement’s in the World—Part 1 with Dr. Bevan Morris, recorded during the Maharishi Vedic Pandits Ambassadors reception, 3rd August 2019.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Aug_19.mp4",
          },
          {
            day: 13,
            description:
              "Review of achievements in the countries of his domain with Raja Luis.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Aug_19.mp4",
          },
          {
            day: 12,
            description:
              "News of rising invincibility in the world with Dr. Peter Swan, Minister of Communication of the Global Country of World Peace.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Aug_19.mp4",
          },
          {
            day: 11,
            description:
              "Dr. Peter Swan reads from the book Enlightenment and Invincibility, 1978.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Aug_19.mp4",
          },
          {
            day: 09,
            description:
              "Replay of the Guru Purnima presentations by Raja Bruce, Raja Vikram, Raja Antonio, and closing remarks from Maharaja Adhiraj Rajaraam, from 18 July 2019.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Aug_19.mp4",
          },
          {
            day: 08,
            description:
              "Maharishi’s Global Press Conference, from 21 July 2004, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Aug_19.mp4",
          },
          {
            day: 0o7,
            description:
              "Replay of the Guru Purnima presentations by Raja Stanley, Raja Paul, Raja Willem, and Col. Gunter Chassé, from 18 July 2019.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Aug_19.mp4",
          },
          {
            day: 0o5,
            description:
              "Conclusion of the course, ‘Training to Bring Consciousness-Based Education programs to Your Nations’, directed by Dr. Susie Dillbeck, President, International Foundation of Consciousness-Based Education.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Aug_19.mp4",
          },
          {
            day: 0o3,
            description:
              "Replay of the Guru Purnima presentations by Raja Konhaus, Raja Kingsley, Raja Rogers, and Raja Stanley from 18 July 2019.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Aug_19.mp4",
          },
          {
            day: 0o2,
            description:
              "Replay of the Guru Purnima presentations by Dr Rainer Picha, Raja Richard Broome, and Raja Kingsley from 17 July 2019",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Aug_19.mp4",
          },
          {
            day: 0o1,
            description:
              "Maharishi’s Global Press Conference, from 21 July 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Aug_19.mp4",
          },
        ],
      },
      {
        monthName: "Sept",
        days: [
          {
            day: 28,
            description:
              "Concluding the Maharishi Vastu Architect and Consultant training course with Dr. Eike Hartmann and course participants, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Sep_19.mp4",
          },
          {
            day: 27,
            description:
              "Concluding the Maharishi Vastu Architect and Consultant training course with Dr. Eike Hartmann and course participants, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Sep_19.mp4",
          },
          {
            day: 26,
            description:
              "Maharishi’s Global Press Conference, from 18 August 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Sep_19.mp4",
          },
          {
            day: 22,
            description:
              "Dr. Peter Swan reads from the book Enlightenment and Invincibility, 1978.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Sep_19.mp4",
          },
          {
            day: 16,
            description:
              "Harald S. Harung PHD presents  his new book, Excellence through Mind Brain Development The Secrets of World Class Performers.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Sep_19.mp4",
          },
          {
            day: 12,
            description:
              "Maharishi’s Global Press Conference, from 11 August 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Sep_19.mp4",
          },
          {
            day: 08,
            description:
              "Dr. Peter Swan reads from the book Enlightenment and Invincibility, 1978.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Sep_19.mp4",
          },
          {
            day: 0o5,
            description:
              "Maharishi’s Global Press Conference, from 4 August 2004, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Sep_19.mp4",
          },
          {
            day: 0o3,
            description:
              "Raja Luis updates us with the news of rising invincibility in Latin America.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Sep_19.mp4",
          },
          {
            day: 0o1,
            description:
              "Dr. Peter Swan reads from the book Enlightenment and Invincibility, 1978.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Sep_19.mp4",
          },
        ],
      },
      {
        monthName: "Oct",
        days: [
          {
            day: 31,
            description:
              "Maharishi’s Global Press Conference, from 1 September 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_Oct_19.mp4",
          },
          {
            day: 28,
            description:
              "Latest news on Maharishi AyurVeda with Dr. Girish Momaya and Dr. Rainer Picha, MD.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Oct_19.mp4",
          },
          {
            day: 24,
            description:
              "Maharishi’s Global Press Conference, from 25 August 2004, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Oct_19.mp4",
          },
          {
            day: 20,
            description:
              "Dr. Peter Swan reads from the book Enlightenment and Invincibility, 1978.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Oct_19.mp4",
          },
          {
            day: 17,
            description:
              "Maharishi’s Global Press Conference, from 25 August 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Oct_19.mp4",
          },
          {
            day: 14,
            description:
              "Latest events attended by Dr. Girish Momaya, director of the Maharishi AyurVeda clinic at MERU, Holland, and Lothar Pirc, director of the Maharishi AyurVeda clinic in Bad Elms, Germany",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Oct_19.mp4",
          },
          {
            day: 13,
            description:
              "Dr. Peter Swan reads from the book Enlightenment and Invincibility, 1978.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Oct_19.mp4",
          },
          {
            day: 12,
            description:
              "Raja Peter, Raja of United Kingdom gives the latest news about political events happening in the UK, and also about his Total Knowledge on-going courses, Part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Oct_19.mp4",
          },
          {
            day: 11,
            description:
              "Raja Peter, Raja of United Kingdom gives the latest news about political events happening in the UK, and also about his Total Knowledge on-going courses, Part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Oct_19.mp4",
          },
          {
            day: 10,
            description:
              "Maharishi’s Global Press Conference, from 18 August 2004, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Oct_19.mp4",
          },
        ],
      },
      {
        monthName: "Nov",
        days: [
          {
            day: 26,
            description:
              "News of rising invincibility in the world with Dr. Peter Swan, Minister of Communication of the Global Country of World Peace.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Nov_19.mp4",
          },
          {
            day: 24,
            description:
              "Dr. Peter Swan reads from the book Enlightenment and Invincibility, 1978.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Nov_19.mp4",
          },
          {
            day: 23,
            description:
              "Review of the paper ‘Indestructibility of Wholeness‘ by Paul Corazza, PhD that will appear in the journal Fundamenta Mathematicae in 2020.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Nov_19.mp4",
          },
          {
            day: 22,
            description:
              "Raja Dominique Lemoine, Raja of French Overseas Territories, Madagascar, Monaco, and Morocco, and TM-Sidhi Administrator, recounts his latest trip in China, Taiwan, Hong-Kong, and Thailand.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Nov_19.mp4",
          },
          {
            day: 21,
            description:
              "Maharishi’s Global Press Conference, from 8 September 2004, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Nov_19.mp4",
          },
          {
            day: 17,
            description:
              "Dr. Peter Swan reads from the book Enlightenment and Invincibility, 1978.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Nov_19.mp4",
          },
          {
            day: 16,
            description:
              "Interview with Dr. Bobby Roth, CEO of the David Lynch Foundation, USA.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Nov_19.mp4",
          },
          {
            day: 14,
            description:
              "Maharishi’s Global Press Conference, from 8 September 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Nov_19.mp4",
          },
          {
            day: 10,
            description:
              "Dr. Peter Swan reads from the book Enlightenment and Invincibility, 1978.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Nov_19.mp4",
          },
          {
            day: 0o7,
            description:
              "Maharishi’s Global Press Conference, from 1 September 2004, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Nov_19.mp4",
          },
          {
            day: 0o3,
            description:
              "Dr. Peter Swan reads from the book Enlightenment and Invincibility, 1978.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Nov_19.mp4",
          },
          {
            day: 0o1,
            description:
              "Update on Science of Rising Invincibility from the World Press.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Nov_19.mp4",
          },
        ],
      },
      {
        monthName: "Dec",
        days: [
          {
            day: 30,
            description:
              "Review of the accomplishments of the Global Country of World Peace in 2019 throughout the world with Dr Bevan Morris, Part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Dec_19.mp4",
          },
          {
            day: 29,
            description:
              "Review of the accomplishments of the Global Country of World Peace in 2019 throughout the world with Dr Bevan Morris, Part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Dec_19.mp4",
          },
          {
            day: 28,
            description:
              "News of rising invincibility in the world in 2019 with Dr. Peter Swan, Minister of Communication of the Global Country of World Peace..",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Dec_19.mp4",
          },
          {
            day: 27,
            description:
              "Raja Paul reviews the achievements of the countries of his domain, including Canada.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Dec_19.mp4",
          },
          {
            day: 26,
            description:
              "Maharishi’s Global Press Conference, from 22 September 2004, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Dec_19.mp4",
          },
          {
            day: 25,
            description:
              "Inauguration of the new Maharishi Yogic Flying Hall at the Dhammajarinee Witthaya School, Thailand on November 14, 2019 with Raja Vikram and Dr. Bevan Morris followed by news of rising invincibility around the world with Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Dec_19.mp4",
          },
          {
            day: 23,
            description:
              "Raja Paul reviews the achievements of the countries of his domain, including Turkey and Cyprus.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Dec_19.mp4",
          },
          {
            day: 22,
            description:
              "Dr. Peter Swan reads from the book Enlightenment and Invincibility, 1978.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Dec_19.mp4",
          },
          {
            day: 20,
            description:
              "Summary of the the recent scientific researches on Transcendental Meditation with Dr. David Orme-Johnson, PhD.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Dec_19.mp4",
          },
          {
            day: 19,
            description:
              "Maharishi’s Global Press Conference, from 29 September 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Dec_19.mp4",
          },
          {
            day: 18,
            description:
              "‘Digestion and Eating According to Hunger’ with Dr Smith from New Zealand.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Dec_19.mp4",
          },
          {
            day: 16,
            description:
              "Maharishi University of Management, Fairfield, IA, USA reverts back to its original name, Maharishi International University. More on this with the Executive Vice-President of academic affairs, Craig Pearson, PhD.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Dec_19.mp4",
          },
          {
            day: 15,
            description:
              "Dr. Peter Swan reads from the book Enlightenment and Invincibility, 1978.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Dec_19.mp4",
          },
          {
            day: 14,
            description:
              "Presentation of the Euro Project in December 2019 at the Royal Library in Brussels with Dr. Girish Momaya.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Dec_19.mp4",
          },
          {
            day: 12,
            description:
              "Maharishi’s Global Press Conference, from 22 September 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Dec_19.mp4",
          },
          {
            day: 10,
            description:
              "Raja Luis reviews the latest achievements in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Dec_19.mp4",
          },
          {
            day: 09,
            description:
              "Christian Schweizer, a Maharishi Vastu architect, gave an overview of the MERU Master plan for the construction of the MERU Campus according to Maharishi Sthapatya Veda.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Dec_19.mp4",
          },
          {
            day: 08,
            description:
              "Dr. Peter Swan reads from the book Enlightenment and Invincibility, 1978.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Dec_19.mp4",
          },
          {
            day: 0o5,
            description:
              "Maharishi’s Global Press Conference, from 15 September 2004, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Dec_19.mp4",
          },
          {
            day: 0o3,
            description:
              "Celebrating the birthday of Raja Chancellor, Purusha Raja with Universal Domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Dec_19.mp4",
          },
          {
            day: 0o1,
            description:
              "Dr. Peter Swan reads from the book Enlightenment and Invincibility, 1978.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Dec_19.mp4",
          },
        ],
      },
    ],
  },
  // Year 2018
  {
    id: 7,
    year: 2018,
    months: [
      {
        monthName: "Jan",
        days: [
          {
            day: 29,
            description:
              "David Lynch representative tells the story about how teaching Transcendental Meditation started in Norwich University, Vermont, USA .",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Jan_18.mp4",
          },
          {
            day: 28,
            description:
              "Dr. Peter Swan reads ‘Celebrating Perfection in Education’, published by Maharishi Vedic University in 1997.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Jan_18.mp4",
          },
          {
            day: 27,
            description:
              "Report on World News reflecting the rise in the collective consciousness towards more harmony, ordeliness, and progress in the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Jan_18.mp4",
          },
          {
            day: 25,
            description:
              "Maharishi’s Global Press Conference, from 15 October 2003, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Jan_18.mp4",
          },
          {
            day: 24,
            description:
              "Achievements and resolutions from Raja Bjarne, Raja Vikram, Invincible Marketing, and Raja Ior.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Jan_18.mp4",
          },
          {
            day: 23,
            description:
              "Raja Luis inspires us all with new developments and prospects in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Jan_18.mp4",
          },
          {
            day: 21,
            description:
              "Dr. Peter Swan reads ‘Celebrating Perfection in Education’, published by Maharishi Vedic University in 1997.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Jan_18.mp4",
          },
          {
            day: 20,
            description:
              "Achievements and resolutions from the Ministry of Health of the Global Country of World Peace with Dr. Rainer Picha and Dr. Walter Moelk.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Jan_18.mp4",
          },
          {
            day: 19,
            description:
              "Achievements and resolutions from the Ministry of Architecture of the Global Country of World Peace with Dr. Eike Hartmann. In part 2, achievements and resolutions from Raja Graham in the countries of his domain, mainly in Africa.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Jan_18.mp4",
          },
          {
            day: 18,
            description:
              "Maharishi’s Global News Conference, from 15 October 2003, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Jan_18.mp4",
          },
          {
            day: 17,
            description:
              "Achievements and resolutions from Raja Wynne for the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Jan_18.mp4",
          },
          {
            day: 16,
            description:
              "New Year’s resolutions from Col. Gunter Chassé in the field of defense around the world. News by the directors of the University of Management and Economics (UME), in Kampong Cham, Cambodia.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Jan_18.mp4",
          },
          {
            day: 15,
            description:
              "Dr. Robert Herron presents his study showing that war veterans suffering from post-traumatic stress disorder (PTSD) found significant relief from their symptoms as a result of practicing the Transcendental Meditation technique.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Jan_18.mp4",
          },
          {
            day: 14,
            description:
              "Raja Kingsley and his guest from Ukraine. Raja Kingsley reports the achievements in the countries of his domain including Nepal and Ukraine.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Jan_18.mp4",
          },
          {
            day: 09,
            description:
              "News of rising invincibility in Latin America in 2017.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Jan_18.mp4",
          },
          {
            day: 08,
            description: "News of rising invincibility in 2017.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Jan_18.mp4",
          },
        ],
      },
      {
        monthName: "Feb",
        days: [
          {
            day: 27,
            description: "Raja Luis with the latest news from Latin America.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Feb_18.mp4",
          },
          {
            day: 26,
            description:
              "Harald S. Harung, PhD, from Oslo University College, gives an overview of his latest research entitled, ‘World-class Performance through Higher Mind-Brain Development’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Feb_18.mp4",
          },
          {
            day: 25,
            description:
              "Dr. Peter Swan reads ‘Maharishi Vedic University in Ayodhya’, published in 1992.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Feb_18.mp4",
          },
          {
            day: 24,
            description:
              "Overview of the project ‘Global Peace Initiative’ including the creation and maintenance of a large group of Yogic Flying Vedic Pandits in the Brahmasthan of India, with Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Feb_18.mp4",
          },
          {
            day: 22,
            description:
              "Maharishi’s Global Press Conference, from 22 October 2003, part 3.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Feb_18.mp4",
          },
          {
            day: 21,
            description:
              "Interviews with Dr. Bobby Roth on various TV channels in USA. Also Dr. Peter Swan list the newest news of rising invincibility throughout the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Feb_18.mp4",
          },
          {
            day: 19,
            description:
              "Third course on Natural Law Based Art graduation including a video by Maharishi.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Feb_18.mp4",
          },
          {
            day: 18,
            description:
              "Dr. Peter Swan reads ‘Maharishi Vedic University in Ayodhya’, published in 1992.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Feb_18.mp4",
          },
          {
            day: 17,
            description:
              "Current News of Invincibility report with Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Feb_18.mp4",
          },
          {
            day: 16,
            description:
              "Conversation on Meditation, Creativity and Light between David Lynch and Bobby Roth.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Feb_18.mp4",
          },
          {
            day: 15,
            description:
              "Maharishi’s Global Press Conference, from 22 October 2003, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Feb_18.mp4",
          },
          {
            day: 12,
            description:
              "Dr. Travis announces his new course, ‘The Evolving Brain: From Birth to Enlightenment’ starting Feb. 12 at Maharishi University of Management, Fairfield, IA. His course will be recorded for online viewing in the future.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Feb_18.mp4",
          },
          {
            day: 11,
            description:
              "Dr. Peter Swan begins to read ‘Maharishi Vedic University in Ayodhya’, published in 1992.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Feb_18.mp4",
          },
          {
            day: 09,
            description:
              "Raja Peter reports on the achievements in the countries of his domain as well as on his Total Knowledge courses in many countries.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Feb_18.mp4",
          },
          {
            day: 08,
            description:
              "Maharishi’s Global Press Conference, from 22 October 2003, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Feb_18.mp4",
          },
          {
            day: 0o4,
            description:
              "Dr. Peter Swan reads ‘Celebrating Perfection in Education’, published by Maharishi Vedic University in 1997.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Feb_18.mp4",
          },
          {
            day: 0o1,
            description:
              "Maharishi’s Global Press Conference, from 15 October 2003, part 3",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Feb_18.mp4",
          },
        ],
      },
      {
        monthName: "Mar",
        days: [
          {
            day: 29,
            description:
              "Maharishi’s Global Press Conference, from 5 November 2003, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Mar_18.mp4",
          },
          {
            day: 28,
            description:
              "Current News of rising Invincibility report with Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Mar_18.mp4",
          },
          {
            day: 27,
            description:
              "Report on the lastest Ayurveda conference in Rio, Brazil with Raja Luis and Dr. Bevan Morris.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Mar_18.mp4",
          },
          {
            day: 26,
            description:
              "Raja Rogers and Raj Rajeshwari Candace on the tour by Bob Roth in Australia to promulgate his new book, Strength in Stillness—The Power of Transcendental Meditation.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Mar_18.mp4",
          },
          {
            day: 16,
            description:
              "Celebrating Raja Paul’s birthday with the latest news of Invincibility in the countries of his domain, including Turkey, Cyprus, Belaruse, and Canada.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Mar_18.mp4",
          },
          {
            day: 15,
            description:
              "Maharishi’s Global Press Conference, from 5 November 2003, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Mar_18.mp4",
          },
          {
            day: 09,
            description:
              "Raja Peter tells us about Dr. Bob Roth’s visit in the UK to promote his new book, Strength in Stillness.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Mar_18.mp4",
          },
          {
            day: 08,
            description:
              "Maharishi’s Global Press Conference, from 29 October 2003, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Mar_18.mp4",
          },
          {
            day: 0o7,
            description:
              "Review of the International Ayurveda Congress Proceedings held in London in April 2017 in the light of the upcoming 3rd Ayurveda Congress in Rio, Brazil.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Mar_18.mp4",
          },
          {
            day: 0o6,
            description:
              "Raja Luis getting ready to go to the 3rd International Ayurveda Congress from 12-15 March 2018, in Rio de Janeiro, Brazil.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Mar_18.mp4",
          },
          {
            day: 0o4,
            description:
              "Dr. Peter Swan reads ‘Maharishi Vedic University in Ayodhya’, published in 1992.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Mar_18.mp4",
          },
          {
            day: 0o3,
            description:
              "Celebrating Dr. Bevan Morris’s birthday with a historic presentation by Dr. Morris on the History of Maharishi in the World.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Mar_18.mp4",
          },
          {
            day: 0o2,
            description:
              "Current News of Invincibility report with Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Mar_18.mp4",
          },
          {
            day: 0o1,
            description:
              "Maharishi’s Global Press Conference, from 29 October 2003, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Mar_18.mp4",
          },
        ],
      },
      {
        monthName: "Apr",
        days: [
          {
            day: 29,
            description:
              "Dr. Peter Swan reads ‘Maharishi Vedic University in Ayodhya’, published in 1992.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Apr_18.mp4",
          },
          {
            day: 27,
            description:
              "Current News of rising Invincibility report with Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Apr_18.mp4",
          },
          {
            day: 26,
            description:
              "Maharishi’s Global Press Conference, from 12 November 2003, part 3.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Apr_18.mp4",
          },
          {
            day: 25,
            description:
              "Dr Bevan Morris presents the events of the inauguration of the new Maharishi AyurVeda building in Rain, Switzerland.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Apr_18.mp4",
          },
          {
            day: 24,
            description: "Flag raising ceremonies during Akshaya Tritiya 2018.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Apr_18.mp4",
          },
          {
            day: 23,
            description:
              "Inauguration of the new Maharishi AyurVeda building in Rain, Switzerland held on Akshaya Tritiya 2018.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Apr_18.mp4",
          },
          {
            day: 22,
            description:
              "Dr. Peter Swan reads ‘Maharishi Vedic University in Ayodhya’, published in 1992.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Apr_18.mp4",
          },
          {
            day: 21,
            description:
              "Raja Emanuel, Raja of Germany, inaugurated the property where a Maharishi Tower of Invincibility will be built.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Apr_18.mp4",
          },
          {
            day: 20,
            description:
              "Raja Peter reviews the latest news of the countries of his domain, including the extension to the Maharishi Garden Village in Rendlesham, UK, and the new Maharishi School in Lusaka, Zambia.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Apr_18.mp4",
          },
          {
            day: 19,
            description:
              "Maharishi’s Global Press Conference, from 12 November 2003, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Apr_18.mp4",
          },
          {
            day: 16,
            description:
              "Replay of the live Facebook broadcast by Maharaja Adhiraj Rajaraam on the principles of Transcendental Meditation.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Apr_18.mp4",
          },
          {
            day: 15,
            description:
              "Dr. Peter Swan reads ‘Maharishi Vedic University in Ayodhya’, published in 1992.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Apr_18.mp4",
          },
          {
            day: 14,
            description:
              "Extract of the conference called ‘Change Makers—Disruptors Transforming the World for Good, Part 1’ held at Maharishi University of Management, Fairfield, IA, USA. Youtube link.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Apr_18.mp4",
          },
          {
            day: 13,
            description:
              "Dr Hari Sharma, Prof. Emeritus at the Ohio State University, College of Medicine presents his latest research and insights.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Apr_18.mp4",
          },
          {
            day: 12,
            description:
              "Maharishi’s Global Press Conference, from 12 November 2003, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Apr_18.mp4",
          },
          {
            day: 10,
            description:
              "Raja Luis reviews the successes of the latest Ayurveda Congress in Rio, Brazil and presents a video from Raja Hagelin about Yoga and health in the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Apr_18.mp4",
          },
          {
            day: 09,
            description:
              "A video lecture by Maharishi Mahesh Yogi on the ‘Art of Taking Right Decision’, 17 July 1971, Amherts, MA, USA",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Apr_18.mp4",
          },
          {
            day: 08,
            description:
              "Dr. Peter Swan reads ‘Maharishi Vedic University in Ayodhya’, published in 1992.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Apr_18.mp4",
          },
          {
            day: 0o7,
            description:
              "New development at Maharishi University of Management, Fairfield, IA, USA presented by Jon Lipman, Maharishi Vastu architect.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Apr_18.mp4",
          },
          {
            day: 0o6,
            description:
              "Dr Peter Swan reads extract from Dr Bob Roth’s new book, Strength in Stillness—The Power of Transcendental Meditation, and presents a video recording of Dr Roth interviewed by Ellen Denegeres, host of a famous talk show in USA.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Apr_18.mp4",
          },
          {
            day: 0o5,
            description:
              "Maharishi’s Global Press Conference, from 5 November 2003, part 3.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Apr_18.mp4",
          },
          {
            day: 0o4,
            description:
              "Inauguration of a new Maharishi Peace Palace in Boone, NC, USA presented by Jon Lipman and Peter Mullins, both Maharishi Vastu architects from USA.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Apr_18.mp4",
          },
          {
            day: 0o3,
            description:
              "Raja Luis summarizes the success of the last Ayurveda Conference in Rio, Brazil, and presents the video address recorded by Maharaja Adhiraj Rajaraam for the occasion.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Apr_18.mp4",
          },
          {
            day: 0o1,
            description:
              "Dr. Peter Swan reads ‘Maharishi Vedic University in Ayodhya’, published in 1992.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Apr_18.mp4",
          },
        ],
      },
      {
        monthName: "May",
        days: [
          {
            day: 31,
            description:
              "Maharishi’s Global Press Conference, from 3 December 2003, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_May_18.mp4",
          },
          {
            day: 29,
            description:
              "Raja Luis, Raja of Latin America, creating large groups of Yogic Flyers throughout the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_May_18.mp4",
          },
          {
            day: 27,
            description:
              "Dr. Peter Swan starts the reading of Maharishi’s Master Plan for Perfect Health, 1985.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_May_18.mp4",
          },
          {
            day: 26,
            description:
              "News from Dr. Alarik and Cynthia Arenander on latest research on brain physiology.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_May_18.mp4",
          },
          {
            day: 26,
            description:
              "News from Dr. Alarik and Cynthia Arenander on latest research on brain physiology.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_May_18.mp4",
          },
          {
            day: 25,
            description:
              "Conclusion of the latest Maharishi Aroma Therapy with course particpants.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_May_18.mp4",
          },
          {
            day: 24,
            description:
              "Maharishi’s Global Press Conference, from 26 November 2003, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_May_18.mp4",
          },
          {
            day: 20,
            description:
              "Dr. Peter Swan reads the foldout posters of ‘Maharishi Vedic University in Ayodhya’, published in 1992.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_May_18.mp4",
          },
          {
            day: 18,
            description:
              "Conclusion of the 26th Maharishi Aroma Therapy course held at MERU from 7 – 14 May 2018.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_May_18.mp4",
          },
          {
            day: 17,
            description:
              "Maharishi’s Global Press Conference, from 26 November 2003, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_May_18.mp4",
          },
          {
            day: 16,
            description:
              "Raja Peter presents the latest developments in the UK, including the building of new Vastu homes near the newly built Maharishi Peace Palace.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_May_18.mp4",
          },
          {
            day: 15,
            description:
              "Raja Luis reviews the latest developments in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_May_18.mp4",
          },
          {
            day: 14,
            description:
              "On the 28th April 2018, Dr. Bobby Roth and Katy Perry present on ‘Impacting children’s health through meditation’, at the Vatican City.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_May_18.mp4",
          },
          {
            day: 13,
            description:
              "Dr. Peter Swan reads ‘Maharishi Vedic University in Ayodhya’, published in 1992.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_May_18.mp4",
          },
          {
            day: 12,
            description:
              "A review of Dr. Peter Swan latest researches in Maharishi Vedic Organic Agriculture.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_May_18.mp4",
          },
          {
            day: 11,
            description:
              "Maharishi AyurVeda Health Centre in Bad Ems, Germany, celebrated its 25 year anniversary with Lothar Pirc, director.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_May_18.mp4",
          },
          {
            day: 10,
            description:
              "Maharishi’s Global Press Conference, from 19 November 2003, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_May_18.mp4",
          },
          {
            day: 09,
            description:
              "Presentation by Branko Cicic, Maharishi Vedic Organic Agriculture expert in Serbia.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_May_18.mp4",
          },
          {
            day: 0o7,
            description:
              "Dr. Girish Momaya introduces the upcoming MERU Assembly and courses including the Guru Purnima Assembly in July and the Maharishi AyurVeda conference in Leiden, The Netherlands, 1 – 2 September 2018.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_May_18.mp4",
          },
          {
            day: 0o6,
            description:
              "Dr. Peter Swan reads ‘Maharishi Vedic University in Ayodhya’, published in 1992.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_May_18.mp4",
          },
          {
            day: 0o3,
            description:
              "Maharishi’s Global Press Conference, from 19 November 2003, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_May_18.mp4",
          },
        ],
      },
      {
        monthName: "June",
        days: [
          {
            day: 30,
            description:
              "Dr. Bobby Roth, CEO of the David Lynch Foundation, founded by David Lynch to reach out to people and bring TM and all other Maharishi’s programmes to at-risk communities, reviews the latest achievements of the Foundation.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Jun_18.mp4",
          },
          {
            day: 29,
            description:
              "20-21 June – First International Yoga Conference in New York City attended by leaders of Maharishi’s movement.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Jun_18.mp4",
          },
          {
            day: 28,
            description:
              "Maharishi’s Global Press Conference, from 10 December 2003, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Jun_18.mp4",
          },
          {
            day: 27,
            description:
              "Maharishi University of Management Forty-First Commencement Ceremony, held on 23 June 2018, Part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Jun_18.mp4",
          },
          {
            day: 25,
            description:
              "Maharishi University of Management Forty-First Commencement Ceremony, held on 23 June 2018, Part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Jun_18.mp4",
          },
          {
            day: 22,
            description:
              "David Orme-Johnston, PhD, from Maharishi University of Management, IA, USA, explains what is the Maharishi Effect, the scientific approach to create world peace.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Jun_18.mp4",
          },
          {
            day: 20,
            description:
              "Raja Harris reviews the achievements in the Brahmasthan of India and the future prospects for the coming years.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Jun_18.mp4",
          },
          {
            day: 19,
            description:
              "Raja Luis joins in with the latest news on large groups of Yogic Flyers being established in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Jun_18.mp4",
          },
          {
            day: 18,
            description:
              "Raja John Konhaus with the latest developments in Egypt and Jamaica, two countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Jun_18.mp4",
          },
          {
            day: 17,
            description:
              "Dr. Peter Swan starts reading the book Maharishi’s Master Plan to Create Heaven on Earth, published in 1991.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Jun_18.mp4",
          },
          {
            day: 16,
            description:
              "Review of latest scientific researches on Transcendental Meditation by Dr. Keith Wallace and Dr. David Orme-Johnson from Maharishi University of Management, IA, USA.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Jun_18.mp4",
          },
          {
            day: 14,
            description:
              "Maharishi’s Global Press Conference, from 10 December 2003, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Jun_18.mp4",
          },
          {
            day: 13,
            description:
              "Conclusion of the Maharishi Yoga Asana course with course leaders and course pariticipants.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Jun_18.mp4",
          },
          {
            day: 11,
            description:
              "Raja Peter introduces members of ELI (Enlightened Leadership International) from around the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Jun_18.mp4",
          },
          {
            day: 10,
            description:
              "Dr. Peter Swan continues the reading of Maharishi’s Master Plan for Perfect Health, 1985.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Jun_18.mp4",
          },
          {
            day: 0o7,
            description:
              "Maharishi’s Global Press Conference, from 3 December 2003, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Jun_18.mp4",
          },
          {
            day: 0o6,
            description:
              "News from Japan with Raja Konhaus. Dr. Susie Dillbeck announces the next Consciousness-Based Education Programs to Your Nation course at MERU, and Dr Girish Momaya announces the next Maharishi AyurVeda Congress in Leiden, The Netherlands",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Jun_18.mp4",
          },
          {
            day: 0o5,
            description:
              "Raja Luis latest achievements in introducing Maharishi’s programmes in the schools in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Jun_18.mp4",
          },
          {
            day: 0o4,
            description:
              "Dr. Alarik Arenander talks about the latest developements in EEG measuring techniques to monitor the growth brain coherence in individuals practicing Transcendental Meditation.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Jun_18.mp4",
          },
          {
            day: 0o3,
            description:
              "Dr. Peter Swan continues the reading of Maharishi’s Master Plan for Perfect Health, 1985.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Jun_18.mp4",
          },
          {
            day: 0o1,
            description:
              "News and progress in Turkey with Raja Paul and Dr. Bevan Morris, Prime Minister of the Globla Country of World Peace.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Jun_18.mp4",
          },
        ],
      },
      {
        monthName: "July",
        days: [
          {
            day: 31,
            description:
              "Interview with Raja Dr. John Fagan, senior administrator of the Global Country of World Peace, with special responsibility in the areas of food purity and safety.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_Jul_18.mp4",
          },
          {
            day: 30,
            description:
              "Interview with Dr. Rainer Picha, Minister of Health of the Global Country of World Peace.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Jul_18.mp4",
          },
          {
            day: 18,
            description:
              "The Significance of Raja Training by Maharishi Mahesh Yogi, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Jul_18.mp4",
          },
          {
            day: 17,
            description:
              "The Significance of Raja Training by Maharishi Mahesh Yogi, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Jul_18.mp4",
          },
          {
            day: 16,
            description:
              "Overview of the Annual Global Celebration of Guru Purnima including the coronation of 16 new Rajas of the Global Country of World Peace.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Jul_18.mp4",
          },
          {
            day: 15,
            description:
              "Dr. Peter Swan reads from the book Maharishi’s Master Plan to Create Heaven on Earth, published in 1991.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Jul_18.mp4",
          },
          {
            day: 14,
            description:
              "More news from Dr. Ashley Deans, Global Ambassador for Consciousness-Based Education, on Consciousness-Based Education implementation in schools around the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Jul_18.mp4",
          },
          {
            day: 13,
            description:
              "A talk Dr. Ashley Deans on Consciousness, Education, and Physics. Dr. Deans is the director of the Maharishi School of the Age of Enligthenment, Fairfield, IA, USA, and Global Ambassador for Consciousness-Based Education.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Jul_18.mp4",
          },
          {
            day: 12,
            description:
              "Maharishi’s Global Press Conference, from 17 December 2003, part 3.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Jul_18.mp4",
          },
          {
            day: 11,
            description:
              "Dr. Bobby Roth and Adam Pressman on the word of the David Lynch Foundation.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Jul_18.mp4",
          },
          {
            day: 09,
            description:
              "Graduation ceremony of the latest Maharishi Light Therapy with Germs course.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Jul_18.mp4",
          },
          {
            day: 08,
            description:
              "Dr. Peter Swan reads from the book Maharishi’s Master Plan to Create Heaven on Earth, published in 1991.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Jul_18.mp4",
          },
          {
            day: 0o7,
            description:
              "Rasayana for Long Life with Vaidya Manohar Palakurthi, Maharishi Vaidya, professor of Clinical Medicine at Maharishi University of Management, USA–part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Jul_18.mp4",
          },
          {
            day: 0o6,
            description:
              "Rasayana for Long Life with Vaidya Manohar Palakurthi, Maharishi Vaidya, professor of Clinical Medicine at Maharishi University of Management, USA–part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Jul_18.mp4",
          },
          {
            day: 0o5,
            description:
              "Maharishi’s Global Press Conference, from 17 December 2003, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Jul_18.mp4",
          },
          {
            day: 0o2,
            description:
              "Celebrating the birthday of Raja Luis, Raja of Latin America.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Jul_18.mp4",
          },
          {
            day: 0o1,
            description:
              "Dr. Peter Swan reads from the book Maharishi’s Master Plan to Create Heaven on Earth, published in 1991.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Jul_18.mp4",
          },
        ],
      },
      {
        monthName: "Aug",
        days: [
          {
            day: 31,
            description:
              "Dr Bevan Morris, Prime Minister of the Global Country of World Peace reviews the latest news of Invincibility throughout the world after the new Raja training that took place during the Guru Purnima celebrations of 2018.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_Aug_18.mp4",
          },
          {
            day: 30,
            description:
              "Maharishi’s Global Press Conference, from 25 February 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Aug_18.mp4",
          },
          {
            day: 26,
            description:
              "Dr. Peter Swan reads from the book Maharishi’s Master Plan to Create Heaven on Earth, published in 1991.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Aug_18.mp4",
          },
          {
            day: 22,
            description:
              "Raja Paul presents the latest news of Turkey and the upcoming inauguration of the first Maharishi Peace Palace in Turkey. News also from Raja Omar Hamza and Raja Antonio, both supporting Rajas of activities in Turkey and the Middle East.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Aug_18.mp4",
          },
          {
            day: 19,
            description:
              "Dr. Peter Swan reads from the book Maharishi’s Master Plan to Create Heaven on Earth, published in 1991.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Aug_18.mp4",
          },
          {
            day: 18,
            description:
              "Concluding ceremony of the latest ‘Training to Bring Consciousness-Based Education to their Nations’ course with Dr. Ashley Deans.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Aug_18.mp4",
          },
          {
            day: 17,
            description:
              "Report from Guru Purnima by Raja Rogers Badgett and News from around the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Aug_18.mp4",
          },
          {
            day: 16,
            description:
              "Maharishi’s Global Press Conference, from 18 February 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Aug_18.mp4",
          },
          {
            day: 15,
            description:
              "Dr. Tony Nader, MARR, explains the role of the Rajas of the Global Country of World Peace during the last Guru Purnima celebrations.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Aug_18.mp4",
          },
          {
            day: 13,
            description:
              "Third part of the second session with Dr. Tony Nader, MD, PhD – Maharaja Adhiraj Rajaraam – of ‘questions and answers’ organized by Raja Hagelin, Raja of the United States, and broadcast during the Guru Purnima celebrations of 2018.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Aug_18.mp4",
          },
          {
            day: 12,
            description:
              "Dr. Peter Swan reads from the book Maharishi’s Master Plan to Create Heaven on Earth, published in 1991.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Aug_18.mp4",
          },
          {
            day: 11,
            description:
              "Second part of the second session with Dr. Tony Nader, MD, PhD – Maharaja Adhiraj Rajaraam – of ‘questions and answers’ organized by Raja Hagelin, Raja of the United States, and broadcast during the Guru Purnima celebrations of 2018.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Aug_18.mp4",
          },
          {
            day: 10,
            description:
              "Presenting Dr. David-Orme Jonhson as the ‘research desk’ of scientific research on Transcendental Meditation throughout the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Aug_18.mp4",
          },
          {
            day: 09,
            description:
              "Maharishi’s Global Press Conference, from 24 December 2003, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Aug_18.mp4",
          },
          {
            day: 08,
            description:
              "Second session with Dr. Tony Nader, MD, PhD – Maharaja Adhiraj Rajaraam – of ‘questions and answers’ organized by Raja Hagelin, Raja of the United States, and broadcast during the Guru Purnima celebrations of 2018.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Aug_18.mp4",
          },
          {
            day: 0o7,
            description: "Interview with Raja Luis, Raja of Latin America.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Aug_18.mp4",
          },
          {
            day: 0o6,
            description:
              "Interview with Raja Kingsley who is creating huge changes in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Aug_18.mp4",
          },
          {
            day: 0o5,
            description:
              "Dr. Peter Swan reads from the book Maharishi’s Master Plan to Create Heaven on Earth, published in 1991.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Aug_18.mp4",
          },
          {
            day: 0o4,
            description:
              "First session with Dr. Tony Nader, MD, PhD – Maharaja Adhiraj Rajaraam – of ‘questions and answers’ organized by Raja Hagelin, Raja of the United States, and broadcast during the Guru Purnima celebrations of 2018.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Aug_18.mp4",
          },
          {
            day: 0o3,
            description:
              "Interview with Raja Graham Lodge, international director of the Transcendental Meditation Teacher Training courses.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Aug_18.mp4",
          },
          {
            day: 0o2,
            description:
              "Maharishi’s Global Press Conference, from 24 December 2003, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Aug_18.mp4",
          },
          {
            day: 0o1,
            description:
              "Raja Luis, Raja of Invincible America on the new group of Yogic Flyers established.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Aug_18.mp4",
          },
        ],
      },
      {
        monthName: "Sept",
        days: [
          {
            day: 30,
            description:
              "Dr. Peter Swan reads from the book Maharishi’s Master Plan to Create Heaven on Earth, published in 1991.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Sep_18.mp4",
          },
          {
            day: 28,
            description:
              "Report on the inauguration of the new Maharishi Peace Palace in Turkey held on the 2nd of Sept. 2018 with Raja Paul and guests.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Sep_18.mp4",
          },
          {
            day: 27,
            description:
              "Maharishi’s Global Press Conference, from 3 March 2004, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Sep_18.mp4",
          },
          {
            day: 26,
            description:
              "Maharishi Jyotish Consultation introduction with Raja Dr. Chris Crowell and Mother of the Domain Dr. Anne Crowell.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Sep_18.mp4",
          },
          {
            day: 24,
            description:
              "Report by Dr. Bevan Morris on his recent trips to Turkey, Lebanon, and Egypt.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Sep_18.mp4",
          },
          {
            day: 23,
            description:
              "Dr. Peter Swan reads from the book Maharishi’s Master Plan to Create Heaven on Earth, published in 1991.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Sep_18.mp4",
          },
          {
            day: 20,
            description:
              "Maharishi’s Global Press Conference, from 3 March 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Sep_18.mp4",
          },
          {
            day: 19,
            description:
              "Concluding ceremony of the course The Structuring Dynamics of the Universe Explained by Physics and Applied by Vedic Architecture with Raja Dr Eckart Stein.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Sep_18.mp4",
          },
          {
            day: 16,
            description:
              "Dr. Peter Swan reads from the book Maharishi’s Master Plan to Create Heaven on Earth, published in 1991.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Sep_18.mp4",
          },
          {
            day: 15,
            description:
              "Report on the recent inauguration of the new Maharishi Peace Palace in Turkey including the address by Maharaja Adhiraj Rajaraam.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Sep_18.mp4",
          },
          {
            day: 12,
            description:
              "Report on the Fourth International Ayurveda Congress, held in Leiden, The Netherlands, from 1 to 2 September 2018.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Sep_18.mp4",
          },
          {
            day: 11,
            description:
              "Latest achievements from the domain of Raja Luis, focussing on the benefic effects on the collective consciousness where Maharishi’s programmes are introduced in schools.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Sep_18.mp4",
          },
          {
            day: 09,
            description:
              "Dr. Peter Swan reads from the book Maharishi’s Master Plan to Create Heaven on Earth, published in 1991.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Sep_18.mp4",
          },
          {
            day: 0o7,
            description:
              "Dr Tony Nader, MD, PhD, MARR’s keynote address at the inauguration of the Fourth International Ayurveda Congress, Leiden, The Netherlands, on 1st September 2018.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Sep_18.mp4",
          },
          {
            day: 0o6,
            description:
              "Maharishi’s Global Press Conference, from 25 February 2004, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Sep_18.mp4",
          },
          {
            day: 0o5,
            description:
              "Achievements from Raja Graham in many countries in Africa, including Mali.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Sep_18.mp4",
          },
          {
            day: 0o4,
            description:
              "Raja Luis inspires us all with more achievements in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Sep_18.mp4",
          },
          {
            day: 0o1,
            description:
              "Raja Harris gives us a glimpse of the tremendous expansion throughout India – land of the Veda – Ved Bhumi Bharat.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Sep_18.mp4",
          },
        ],
      },
      {
        monthName: "Oct",
        days: [
          {
            day: 25,
            description: "Maharishi’s Global Press Conference",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Oct_18.mp4",
          },
          {
            day: 0o7,
            description:
              "Dr. Peter Swan reads from the book Maharishi’s Master Plan to Create Heaven on Earth, published in 1991.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Oct_18.mp4",
          },
          {
            day: 0o4,
            description:
              "Maharishi’s Global Press Conference, from 10 March 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Oct_18.mp4",
          },
        ],
      },
      {
        monthName: "Nov",
        days: [
          {
            day: 30,
            description:
              "Latest news about the Vedic Model of Human Physiology with Dr. Rainer Picha, Minister of Health of the Global Country of World Peace.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Nov_18.mp4",
          },
          {
            day: 29,
            description:
              "Maharishi’s Global Press Conference, from 24 March 2004, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Nov_18.mp4",
          },
          {
            day: 28,
            description:
              "A new study on PTSD (Post Traumatic Stress Disorder) in The Lancet Psychiatry by Dr Sanford Nidich, director of the Center for Social-Emotional Health and Consciousness, and Professor of Physiology, and Education at Maharishi University of Management, USA.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Nov_18.mp4",
          },
          {
            day: 27,
            description:
              "Report from Raja Luis in the countries of his domain in Latin America with Dr. Joan Roura.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Nov_18.mp4",
          },
          {
            day: 26,
            description:
              "Report on the Dhammajarinee School in Northern Thailand, a school for girls where every students learn Transcendental Meditation.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Nov_18.mp4",
          },
          {
            day: 25,
            description:
              "Dr. Peter Swan reads from the book Maharishi’s Master Plan to Create Heaven on Earth, published in 1991.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Nov_18.mp4",
          },
          {
            day: 24,
            description:
              "News of rising Invincibility throughout the world with Dr. Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Nov_18.mp4",
          },
          {
            day: 23,
            description:
              "Report on the 12th Global Wellness Summit held in Cesena, Italy with Summit speaker Gerry Bodeker, PhD, University of Oxford; Columbia University. Dr Bodeker researches and advises on traditional and complementary medicine and wellness and is a Governor of the Age of Enlightenment.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Nov_18.mp4",
          },
          {
            day: 22,
            description:
              "Maharishi’s Global Press Conference, from 24 March 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Nov_18.mp4",
          },
          {
            day: 15,
            description:
              "Maharishi’s Global Press Conference, from 17 March 2004, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Nov_18.mp4",
          },
          {
            day: 08,
            description:
              "Review of the upcoming courses with Dr Tony Nader, Md, PhD, Maharaja Adhiraj Rajaraam with Dr. Bevan Morris and Raja Paul.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Nov_18.mp4",
          },
          {
            day: 0o1,
            description:
              "Maharishi’s Global Press Conference, from 17 March 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Nov_18.mp4",
          },
        ],
      },
      {
        monthName: "Dec",
        days: [
          {
            day: 30,
            description:
              "Dr Bevan Morris gives a comprehensive review of the Global Country of  World Peace’s achievements for the year 2018.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Dec_18.mp4",
          },
          {
            day: 29,
            description:
              "Review of the worldwide news reflecting the rise of Invincibility during 2018.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Dec_18.mp4",
          },
          {
            day: 28,
            description:
              "Report on the inauguration of the new solar array installed at Maharishi University of Management, Fairfield, IA, USA.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Dec_18.mp4",
          },
          {
            day: 27,
            description:
              "Maharishi’s Global Press Conference, from 7 April 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Dec_18.mp4",
          },
          {
            day: 26,
            description:
              "Raja Chancellor reviews the achievements of last year by Maharaja Adhiraj Rajaraam, Dr Tony Nader, Md, PhD. and his own activities with ELI (Enlightened Leadership International). See https:",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Dec_18.mp4",
          },
          {
            day: 25,
            description:
              "Inspiring news from Raja Luis in this holiday season.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Dec_18.mp4",
          },
          {
            day: 24,
            description:
              "Raja Kingsley gives a comprehensive report of the achievements of the year 2018 in the countries of his domain, including Georgia, Israel, Ukraine, Nepal, and Palestine.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Dec_18.mp4",
          },
          {
            day: 23,
            description:
              "Dr. Peter Swan reads from the book Maharishi’s Master Plan to Create Heaven on Earth, published in 1991.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Dec_18.mp4",
          },
          {
            day: 20,
            description:
              "Maharishi’s Global Press Conference, from 31 March 2004, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Dec_18.mp4",
          },
          {
            day: 19,
            description:
              "Continued North Campus Village expansion, north side of the Maharishi University of Management Campus, Fairfield, IA, USA.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Dec_18.mp4",
          },
          {
            day: 17,
            description:
              "Dr. Ashley Deans with the latest news on Consciousness-Based Education throughout the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Dec_18.mp4",
          },
          {
            day: 16,
            description:
              "Dr. Peter Swan reads from the book Maharishi’s Master Plan to Create Heaven on Earth, published in 1991.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Dec_18.mp4",
          },
          {
            day: 15,
            description:
              "Overview of heart and cardiovascular diseases with Dr. Rainer Picha, MD, Minister of Health of the Global Country of World Peace.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Dec_18.mp4",
          },
          {
            day: 12,
            description:
              "Presentation of the Maharishi Veda App by Dr. Larry Chroman, MInister of Trade and Commerce of the Global Country of World Peace.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Dec_18.mp4",
          },
          {
            day: 11,
            description:
              "Maharishi’s Global Press Conference, from 31 April 2003, part 1 and then a report by Raja Luis about the achievements in Latin America, particulary in schools.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Dec_18.mp4",
          },
          {
            day: 09,
            description:
              "Dr. Peter Swan reads from the book Maharishi’s Master Plan to Create Heaven on Earth, published in 1991.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Dec_18.mp4",
          },
          {
            day: 0o6,
            description:
              "Maharishi’s Global Press Conference, from 31 March 2004, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Dec_18.mp4",
          },
          {
            day: 0o3,
            description:
              "Celebration of the birthday of Raja Chancellor, Purusha Raja with Universal Domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Dec_18.mp4",
          },
          {
            day: 0o2,
            description:
              "Dr. Peter Swan reads from the book Maharishi’s Master Plan to Create Heaven on Earth, published in 1991.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Dec_18.mp4",
          },
        ],
      },
    ],
  },
  // Year 2017
  {
    id: 6,
    year: 2017,
    months: [
      {
        monthName: "Jan",
        days: [
          {
            day: 29,
            description:
              "Dr. Peter Swan reads, ‘The Flow of Consciousness, Maharishi Mahesh Yogi on Literature and Language’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Jan_17.mp4",
          },
          {
            day: 26,
            description:
              "Maharishi’s Global News Conference, from 4 June 2003 part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Jan_17.mp4",
          },
          {
            day: 22,
            description:
              "Dr. Peter Swan reads, ‘The Flow of Consciousness, Maharishi Mahesh Yogi on Literature and Language’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Jan_17.mp4",
          },
          {
            day: 20,
            description:
              "Evolutionary changes in the world in 2016 presented by sectors of society.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Jan_17.mp4",
          },
          {
            day: 19,
            description:
              "Maharishi’s Global News Conference, from 4 June 2003 part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Jan_17.mp4",
          },
          {
            day: 17,
            description:
              "Achievements of 2016 and resolutions for 2017 from Raja Luis.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Jan_17.mp4",
          },
          {
            day: 15,
            description:
              "Dr. Peter Swan reads, ‘The Flow of Consciousness, Maharishi Mahesh Yogi on Literature and Language’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Jan_17.mp4",
          },
          {
            day: 14,
            description: "Achievements of 2016.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Jan_17.mp4",
          },
        ],
      },
      {
        monthName: "Feb",
        days: [
          {
            day: 27,
            description:
              "Harmonious trends in the world presented by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Feb_17.mp4",
          },
          {
            day: 26,
            description:
              "Dr. Peter Swan reads, ‘The Flow of Consciousness, Maharishi Mahesh Yogi on Literature and Language’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Feb_17.mp4",
          },
          {
            day: 25,
            description:
              "A report from Dr. Ashley Deans, Ambassador for Consciousness-Based Education, on his latest trip in Israel, Bali, and Malaysia.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Feb_17.mp4",
          },
          {
            day: 23,
            description:
              "Maharishi’s Global News Conference, from 11 June 2003 part 3.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Feb_17.mp4",
          },
          {
            day: 22,
            description:
              "Review of the latest University Report 2015-2016 from Maharishi University of Management, Fairfield, Iowa, USA – Part 3.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Feb_17.mp4",
          },
          {
            day: 21,
            description:
              "Raja Luis news about Maharishi University of the Caribbeans and Latin America in Curaçao to promote Maharishi’s knowledge at the university level.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Feb_17.mp4",
          },
          {
            day: 19,
            description:
              "Dr. Peter Swan reads, ‘The Flow of Consciousness, Maharishi Mahesh Yogi on Literature and Language’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Feb_17.mp4",
          },
          {
            day: 17,
            description:
              "Raja Wynne presents the achievements in the countries of his domain with Solomon Mwangi, national director of Kenya.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Feb_17.mp4",
          },
          {
            day: 14,
            description:
              "Raja Luis inspiring talk about the bright future of Maharishi’s Movement.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Feb_17.mp4",
          },
          {
            day: 13,
            description:
              "Col. Gunter Chassé, Minister of Defence of the Global Country of World Peace and Dimitrios Karasis of Enlightenment Leadership International present future events surrounding Yogic Flying.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Feb_17.mp4",
          },
          {
            day: 12,
            description:
              "Dr. Peter Swan reads, ‘The Flow of Consciousness, Maharishi Mahesh Yogi on Literature and Language’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Feb_17.mp4",
          },
          {
            day: 11,
            description:
              "Review of the latest University Report 2015-2016 from Maharishi University of Management, Fairfield, Iowa, USA – Part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Feb_17.mp4",
          },
          {
            day: 10,
            description:
              "Review of the latest University Report 2015-2016 from Maharishi University of Management, Fairfield, Iowa, USA – Part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Feb_17.mp4",
          },
          {
            day: 09,
            description:
              "Maharishi’s Global News Conference, from 11 June 2003 part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Feb_17.mp4",
          },
          {
            day: 0o7,
            description: "Latest news from Latin America with Raja Luis.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Feb_17.mp4",
          },
          {
            day: 0o6,
            description:
              "A presentation of Global Family Farms in Nepal with Raja Fagan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Feb_17.mp4",
          },
          {
            day: 0o5,
            description:
              "Dr. Peter Swan reads, ‘The Flow of Consciousness, Maharishi Mahesh Yogi on Literature and Language’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Feb_17.mp4",
          },
          {
            day: 0o2,
            description:
              "Maharishi’s Global News Conference, from 4 June 2003 part 3.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Feb_17.mp4",
          },
        ],
      },
      {
        monthName: "Mar",
        days: [
          {
            day: 27,
            description:
              "Review of the upcoming course by Dr. Swan on ‘A holistic experience of Maharishi Vedic Agriculture and Gardening’ to be held at Maharishi Peace Palace, UK on 20th April 2017.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Mar_17.mp4",
          },
          {
            day: 26,
            description:
              "Dr. Peter Swan reads from ‘The Unmanifest Canvas – Maharishi Mahesh Yogi on the Arts, Creativity and Perception, 2017’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Mar_17.mp4",
          },
          {
            day: 23,
            description:
              "Maharishi’s Global News Conference, from 18 June 2003 part 3.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Mar_17.mp4",
          },
          {
            day: 22,
            description: "Latest achievements in West Africa with Raja Graham",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Mar_17.mp4",
          },
          {
            day: 21,
            description:
              "Birthday celebration of Raja Rafael with special appreciation by Maharaja Adhiraj Rajaraam and Dr. Bevan Morris.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Mar_17.mp4",
          },
          {
            day: 19,
            description:
              "Dr. Peter Swan reads, ‘The Flow of Consciousness, Maharishi Mahesh Yogi on Literature and Language’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Mar_17.mp4",
          },
          {
            day: 18,
            description:
              "News of rising invincibility throughout the world with Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Mar_17.mp4",
          },
          {
            day: 16,
            description:
              "Raja Paul with the latest invincibility news in the countries of his domain on the occasion of the celebration of his birthday.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Mar_17.mp4",
          },
          {
            day: 12,
            description:
              "Dr. Peter Swan reads, ‘The Flow of Consciousness, Maharishi Mahesh Yogi on Literature and Language’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Mar_17.mp4",
          },
          {
            day: 11,
            description:
              "Vaidya Manohar Palakurthi presents ‘Eating in Tune with Natural Law according to Maharishi AyurVeda—Part 2’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Mar_17.mp4",
          },
          {
            day: 10,
            description:
              "Vaidya Manohar Palakurthi presents ‘Eating in Tune with Natural Law according to Maharishi AyurVeda—Part 1’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Mar_17.mp4",
          },
          {
            day: 09,
            description:
              "Maharishi’s Global News Conference, from 18 June 2003 part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Mar_17.mp4",
          },
          {
            day: 08,
            description:
              "Dr Graeme and Raylene Lodge review the latest Teacher Training Course in Bali that was held from 4th September 2016 to 9th February 2017.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Mar_17.mp4",
          },
          {
            day: 0o3,
            description:
              "Birthday celebrations of Dr. Bevan Morris, Prime Minister of the Global Country of World Peace.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Mar_17.mp4",
          },
          {
            day: 0o2,
            description:
              "Maharishi’s Global News Conference, from 18 June 2003 part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Mar_17.mp4",
          },
        ],
      },
      {
        monthName: "Apr",
        days: [
          {
            day: 30,
            description:
              "Dr. Peter Swan reads from ‘The Unmanifest Canvas – Maharishi Mahesh Yogi on the Arts, Creativity and Perception, 2017’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Sep_17.mp4",
          },
          {
            day: 28,
            description: "Activities in the new Maharishi Peace Palace in UK",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Sep_17.mp4",
          },
          {
            day: 27,
            description:
              "Maharishi’s Global News Conference, from 2 July 2003 part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Sep_17.mp4",
          },
          {
            day: 25,
            description:
              "‘Support the Maharishi Vedic Pandit’ presentation by Dr. Neil Paterson, Minister of Administration of the Global Country of World Peace",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Sep_17.mp4",
          },
          {
            day: 23,
            description:
              "Dr. Peter Swan reads from ‘The Unmanifest Canvas – Maharishi Mahesh Yogi on the Arts, Creativity and Perception, 2017’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Sep_17.mp4",
          },
          {
            day: 20,
            description:
              "Maharishi’s Global News Conference, from 2 July 2003 part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Sep_17.mp4",
          },
          {
            day: 19,
            description:
              "Latest news on Science and Technology throughout the world with Dr. Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Sep_17.mp4",
          },
          {
            day: 18,
            description:
              "Dr. Bevan Morris shows slides of his latest travels throughout the world – part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Sep_17.mp4",
          },
          {
            day: 17,
            description:
              "Dr. Bevan Morris shows slides of his latest travels throughout the world – part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Sep_17.mp4",
          },
          {
            day: 16,
            description:
              "Dr. Peter Swan reads from ‘The Unmanifest Canvas – Maharishi Mahesh Yogi on the Arts, Creativity and Perception, 2017’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Sep_17.mp4",
          },
          {
            day: 15,
            description:
              "Another review of the Second International Ayurveda Congress held in London form 1-2 April 2017 with a slide show presentation by Dr. Bevan Morris",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Sep_17.mp4",
          },
          {
            day: 13,
            description:
              "Maharishi’s Global News Conference, from 25 June 2003 part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Sep_17.mp4",
          },
          {
            day: 12,
            description:
              "Replay of the last Ambassador reception including a presentation on Maharishi Aroma therapy.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Sep_17.mp4",
          },
          {
            day: 09,
            description:
              "Dr. Peter Swan reads from ‘The Unmanifest Canvas – Maharishi Mahesh Yogi on the Arts, Creativity and Perception, 2017’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Sep_17.mp4",
          },
          {
            day: 08,
            description: "Maharishi AyurVeda heath tips with Vaidya Vyas.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Sep_17.mp4",
          },
          {
            day: 0o7,
            description:
              "Summary of the success of the Second International Ayurveda Congress, 1-2 April 2017, London.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Sep_17.mp4",
          },
          {
            day: 0o6,
            description:
              "Maharishi’s Global News Conference, from 25 June 2003 part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Sep_17.mp4",
          },
        ],
      },
      {
        monthName: "May",
        days: [
          {
            day: 31,
            description:
              "Review of the next Transcendental Meditation Teacher Training Course with Raja Chancellor and Graeme Lodge, from New Zealand.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_May_17.mp4",
          },
          {
            day: 30,
            description:
              "News of rising invincibility and coherence in world consciousness with Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_May_17.mp4",
          },
          {
            day: 28,
            description:
              "Dr. Peter Swan reads from ‘The Unmanifest Canvas – Maharishi Mahesh Yogi on the Arts, Creativity and Perception, 2017’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_May_17.mp4",
          },
          {
            day: 25,
            description:
              "Maharishi’s Global News Conference, from 16 July 2003 part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_May_17.mp4",
          },
          {
            day: 24,
            description:
              "Inauguration of the Maharishi Peace Palace in Kuala Lumpur, Malaysia on 26 November 2016, part 5.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_May_17.mp4",
          },
          {
            day: 23,
            description:
              "Inauguration of the Maharishi Peace Palace in Kuala Lumpur, Malaysia on 26 November 2016, part 4.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_May_17.mp4",
          },
          {
            day: 22,
            description:
              "Inauguration of the Maharishi Peace Palace in Kuala Lumpur, Malaysia on 26 November 2016, part 3.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_May_17.mp4",
          },
          {
            day: 21,
            description:
              "Dr. Peter Swan reads from ‘The Unmanifest Canvas – Maharishi Mahesh Yogi on the Arts, Creativity and Perception, 2017’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_May_17.mp4",
          },
          {
            day: 20,
            description:
              "Inauguration of the Maharishi Peace Palace in Kuala Lumpur, Malaysia on 26 November 2016, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_May_17.mp4",
          },
          {
            day: 19,
            description:
              "Inauguration of the Maharishi Peace Palace in Kuala Lumpur, Malaysia on 26 November 2016, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_May_17.mp4",
          },
          {
            day: 18,
            description:
              "Maharishi’s Global News Conference, from 9 July 2003 part 3.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_May_17.mp4",
          },
          {
            day: 17,
            description:
              "ELI Summer course overview with Raja Chancellor and ELI group. Click here for more details.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_May_17.mp4",
          },
          {
            day: 16,
            description:
              "Raja Luis with latest news about Maharishi Yogic Flying groups in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_May_17.mp4",
          },
          {
            day: 15,
            description:
              "Invited guest Supaya Wenuganen, PhD graduate from Maharishi University of Management, Fairfield, USA on how the Transcendental Meditation technique influences gene expression and ojas, the life energy of the body.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_May_17.mp4",
          },
          {
            day: 14,
            description:
              "Dr. Peter Swan reads from ‘The Unmanifest Canvas – Maharishi Mahesh Yogi on the Arts, Creativity and Perception, 2017’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_May_17.mp4",
          },
          {
            day: 13,
            description:
              "Guy Hatchard, Ph.D. graduate from Maharishi University of Management Fairfield IA, USA explains the result of his research on the economic effect of the Maharishi Effect. Dr. Hatchard connects from New Zealand.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_May_17.mp4",
          },
          {
            day: 12,
            description:
              "Raja Bruce Plaut talks about a country of his domain, Invincible Belgium, with invited guest National Director Stijn Vandenbosch",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_May_17.mp4",
          },
          {
            day: 11,
            description:
              "Maharishi’s Global News Conference, from 9 July 2003 part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_May_17.mp4",
          },
          {
            day: 0o7,
            description:
              "Dr. Peter Swan reads from ‘The Unmanifest Canvas – Maharishi Mahesh Yogi on the Arts, Creativity and Perception, 2017’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_May_17.mp4",
          },
          {
            day: 0o5,
            description:
              "Maharishi’s Global News Conference, from 9 July 2003 part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_May_17.mp4",
          },
          {
            day: 0o2,
            description:
              "Akshaya Tritiya flag raising ceremonies and other projects from around the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_May_17.mp4",
          },
          {
            day: 0o1,
            description:
              "Dr. Kenneth Cavanaugh, Senior Research Scientist at MUM's Institute of Science, Technology and Public Policy, presents his research with Raja Dr. Michael Dillbeck on the effect of the Invincible America Assembly at Maharishi University of Management, Fairfield, IA, USA",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_May_17.mp4",
          },
        ],
      },
      {
        monthName: "June",
        days: [
          {
            day: 30,
            description: "International Governors Conference Opening Session.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Jun_17.mp4",
          },
          {
            day: 29,
            description:
              "Commencement 2017 at Maharishi University of Management, Fairfield, IA, USA",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Jun_17.mp4",
          },
          {
            day: 28,
            description:
              "Dr Bob Roth’s birthday celebration, CEO of the David Lynch Foundation, USA",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Jun_17.mp4",
          },
          {
            day: 27,
            description:
              "Raja Luis with the latest development in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Jun_17.mp4",
          },
          {
            day: 26,
            description: "International Day of Yoga at MERU",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Jun_17.mp4",
          },
          {
            day: 25,
            description:
              "Dr. Peter Swan reads from ‘The Unmanifest Canvas – Maharishi Mahesh Yogi on the Arts, Creativity and Perception, 2017’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Jun_17.mp4",
          },
          {
            day: 22,
            description:
              "Maharishi’s Global News Conference, from 30 July 2003 part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Jun_17.mp4",
          },
          {
            day: 21,
            description:
              "Raja Luis with the latest development in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Jun_17.mp4",
          },
          {
            day: 19,
            description:
              "Review of the news of rising invincibility throughout the world with Dr. Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Jun_17.mp4",
          },
          {
            day: 18,
            description:
              "Dr. Peter Swan reads from ‘The Unmanifest Canvas – Maharishi Mahesh Yogi on the Arts, Creativity and Perception, 2017’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Jun_17.mp4",
          },
          {
            day: 17,
            description:
              "Video presentation by Ex-President of Mozambique, H.E. Joaquim Alberto Chissano Rama, at the Global Peace Summit in Kiev, Ukraine that was held from 14 to 15 June 2017.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Jun_17.mp4",
          },
          {
            day: 16,
            description:
              "Live report by Dr Peter Swan, Prince Nikolaus Blücher, and Dr. Eckart Stein on the GLOBAL PEACE SUMMIT June 14–15, 2017 • Kiev, Ukraine.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Jun_17.mp4",
          },
          {
            day: 14,
            description:
              "Live report by Dr Peter Swan and Colonel Gunter Chasse on the GLOBAL PEACE SUMMIT June 14–15, 2017 • Kiev, Ukraine.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Jun_17.mp4",
          },
          {
            day: 12,
            description:
              "Raja John Konhaus on the Knowledge of Maharishi Yagyas.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Jun_17.mp4",
          },
          {
            day: 11,
            description:
              "Dr. Peter Swan reads from ‘The Unmanifest Canvas – Maharishi Mahesh Yogi on the Arts, Creativity and Perception, 2017’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Jun_17.mp4",
          },
          {
            day: 09,
            description:
              "Closing ceremony of the latest course on Maharishi Light Therapy with Gems, held at MERU from 22 May to 10 June 2017.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Jun_17.mp4",
          },
          {
            day: "07",
            description:
              "Dr. Eckart Stein, National Director of the Erfurt Maharishi Peace Palace, Germany reports on the latest World Peace Assembly held in the Maharishi Peace Palace.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Jun_17.mp4",
          },
          {
            day: "06",
            description:
              "Raja Luis with latest news about Maharishi Yogic Flying groups in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Jun_17.mp4",
          },
          {
            day: "05",
            description:
              "Maharishi on Vedanta: presentation by Dr. Bevan Morris, Prime Minister of the Global Country of World Peace. (Click here for details)",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Jun_17.mp4",
          },
          {
            day: "04",
            description:
              "Dr. Peter Swan reads from ‘The Unmanifest Canvas – Maharishi Mahesh Yogi on the Arts, Creativity and Perception, 2017’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Jun_17.mp4",
          },
          {
            day: "03",
            description:
              "Robert Herron, PhD, (Director of the Research Department at Maharishi University of Management in Fairfield, Iowa, USA) on reducing health care cost through Transcendental Meditation.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Jun_17.mp4",
          },
          {
            day: "02",
            description:
              "Dr. Evan Finkelstein, from Maharishi University of Management, USA relates the universal principles of Maharishi Vedic Science to the scriptures of various major world religions",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Jun_17.mp4",
          },
          {
            day: "01",
            description:
              "Maharishi’s Global News Conference, from 16 July 2003 part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Jun_17.mp4",
          },
        ],
      },
      {
        monthName: "July",
        days: [
          {
            day: 31,
            description:
              "Training To Bring Consciousness-Based Education Programmes to Your Nation, with Dr. Susie Dillbeck and course participants.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_Jul_17.mp4",
          },
          {
            day: 30,
            description:
              "Dr. Peter Swan starts reading a new book by Maharishi entitled",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Jul_17.mp4",
          },
          {
            day: 29,
            description:
              "Dr. Larry Chroman, Minister of Industry and Commerce, expresses his Sankalpas on the occasion of the Guru Purnima celebrations. Also Lothar Pirc, CEO of Maharishi AyurVeda Center, Bad Ems Germany reports on the continuing growth of his Center for now 25 years.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Jul_17.mp4",
          },
          {
            day: 28,
            description:
              "Dr. Bevan Morris, Prime Minister of the Global Country of World Peace, presents his slide show, ‘Maharishi on Vedānt’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Jul_17.mp4",
          },
          {
            day: 27,
            description:
              "Maharishi’s Global News Conference, from 6 August 2003, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Jul_17.mp4",
          },
          {
            day: 25,
            description:
              "Report by Raja Luis on new achiements in the countries of his domain, Latin America and the Carribeans.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Jul_17.mp4",
          },
          {
            day: 24,
            description:
              "Raja Chancellor, Purusha Raja of Universal Domain, introduces Maharaja Adhiraj Rajaraam’s publicist, Adrienne Schoenfeld.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Jul_17.mp4",
          },
          {
            day: 23,
            description:
              "Dr. Peter Swan reads from ‘The Unmanifest Canvas – Maharishi Mahesh Yogi on the Arts, Creativity and Perception, 2017 ’– Subject: ‘Art’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Jul_17.mp4",
          },
          {
            day: 22,
            description:
              "Offering of Sankalpas from the Ministry of Health of the Global Country of World Peace with Dr. Rainer Picha and Dr. Walter Moelk.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Jul_17.mp4",
          },
          {
            day: 21,
            description:
              "Offering of Sankalpas from Raja Antonio, Raja of Spain, and Raja Willem, Raja of The Netherlands. Also a review of Consciousness-Based Education with Dr. Volker Schanbacher and Dr. Susie Dillbeck. Lastly the offering of sankalpas from Raja Graham and Leanne Graham, Raja of Norway, Trinidad and Tobago, Uganda, Togo, Benin, Mali, Niger, Guinea, Ghana, Côte d’Ivoire, and Sierra Leone.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Jul_17.mp4",
          },
          {
            day: 20,
            description:
              "Maharishi’s Global News Conference, from 6 August 2003, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Jul_17.mp4",
          },
          {
            day: 19,
            description:
              "Offering of Sankalpas from Raja Vikram Aditya Charoen-Rajapark, Raja of Thailand, Raja Kingsley, Raja of Israel and Palestine and guests.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Jul_17.mp4",
          },
          {
            day: 18,
            description:
              "Raja Luis latest news on the achievements in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Jul_17.mp4",
          },
          {
            day: 17,
            description:
              "Offering of Sankalpas from Raja Emanuel, Raja of Germany, and Dr. Eckart Stein, National Director of Germany.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Jul_17.mp4",
          },
          {
            day: 16,
            description:
              "Dr. Peter Swan reads from ‘The Unmanifest Canvas – Maharishi Mahesh Yogi on the Arts, Creativity and Perception, 2017’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Jul_17.mp4",
          },
          {
            day: 15,
            description:
              "Offering of Sankalpas from Raja Peter, Raja of United Kingdom, Raja Bjarne, Raja of Denmark, Dr. Ashley Deans, Global Ambassador of Consciousness-Based Education, Col. Gunter Chassé, Deputy Minister of Defence of the Global Country of World Peace, Dimitrios Karasis, Ministry of Defence.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Jul_17.mp4",
          },
          {
            day: 13,
            description:
              "Maharishi’s Global News Conference, from 30 July 2003 part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Jul_17.mp4",
          },
          {
            day: 11,
            description:
              "Presentation to the United Kingdom (UK) Parliament by Maharaja Adhiraj Rajaraam about the Maharishi Vedic Science and Technology of Consciousness with Transcendental Meditation.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Jul_17.mp4",
          },
          {
            day: 10,
            description:
              "Final presentation by Enlightened Leadership International (ELI) organization concluding the Guru Purnima Celebrations 2017.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Jul_17.mp4",
          },
          {
            day: 08,
            description:
              "Honoring the Transcendental Meditation Sidhi Programme Administrators.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Jul_17.mp4",
          },
          {
            day: "06",
            description:
              "Presentation by Raja John Hagelin, Raja of Invincible USA, about the annual achievements in the United States.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Jul_17.mp4",
          },
          {
            day: "05",
            description:
              "International Governors Conference continued with presentation of success in Africa with Dr. Chris Crowell.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Jul_17.mp4",
          },
          {
            day: "04",
            description:
              "Presentation by Raja Hagelin at the Global Peace Summit held in Kiev, Ukraine from June 14–15, 2017.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Jul_17.mp4",
          },
          {
            day: "03",
            description:
              "Highlights of the International Ayurveda Congress, London, England, April 1-2 2017, part 2 with Maharaja Adhiraj Rajaraam.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Jul_17.mp4",
          },
          {
            day: "02",
            description:
              "Highlights of the International Ayurveda Congress, London, England, April 1-2 2017, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Jul_17.mp4",
          },
        ],
      },
      {
        monthName: "Aug",
        days: [
          {
            day: 31,
            description:
              "Maharishi’s Global News Conference, from 27 August 2003, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_Aug_17.mp4",
          },
          {
            day: 28,
            description:
              "Dr Girish Momaya presents the upcoming courses at MERU for the rest of the year with highlights on the Maharishi Gandharva Veda courses. Visit: www.merucourses.com",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Aug_17.mp4",
          },
          {
            day: 27,
            description:
              "Dr. Peter Swan reads ‘Celebrating Perfection in Education’, published by Maharishi Vedic University in 1997.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Aug_17.mp4",
          },
          {
            day: 26,
            description:
              "Dr. Bobby Roth, CEO of the David Lynch Foundation, USA presents his up-coming new book and recent events.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Aug_17.mp4",
          },
          {
            day: 24,
            description:
              "Maharishi’s Global News Conference, from 27 August 2003, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Aug_17.mp4",
          },
          {
            day: 23,
            description:
              "An invitation to an experience of a lifetime with Maharishi University of Management's next Rotating University to India, January 15 -February 21, 2018. For more info CLICK HERE.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Aug_17.mp4",
          },
          {
            day: 22,
            description:
              "Raja Luis is establishing large groups of Yogic Flyers in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Aug_17.mp4",
          },
          {
            day: 20,
            description:
              "Dr. Peter Swan reads ‘Celebrating Perfection in Education’, published by Maharishi Vedic University in 1997.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Aug_17.mp4",
          },
          {
            day: 18,
            description: "Raja Kingsley with latest achievements in Nepal.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Aug_17.mp4",
          },
          {
            day: 17,
            description:
              "Maharishi’s Global News Conference, from 14 August 2003, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Aug_17.mp4",
          },
          {
            day: 16,
            description:
              "Raja Kingsley’s latest achievements in the countries of his domain (Georgia and Ukraine among others) including a David Lynch event held in Washington DC at the beginning of June 2017.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Aug_17.mp4",
          },
          {
            day: 15,
            description: "Dr. Bevan Morris’s presentation on Vedant.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Aug_17.mp4",
          },
          {
            day: 13,
            description:
              "Dr. Peter Swan reads ‘Celebrating Perfection in Education’, published by Maharishi Vedic University in 1997.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Aug_17.mp4",
          },
          {
            day: 12,
            description:
              "Live report by Dr. Peter Swan of the MERU Knowledge Fair, held on 10th July 2017.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Aug_17.mp4",
          },
          {
            day: 11,
            description:
              "Graduation of the course, ‘Consciousness Is All There Is—A Mathematical Approach with Applications; hosted by Dr. Ashley Deans.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Aug_17.mp4",
          },
          {
            day: 0o6,
            description:
              "Dr. Peter Swan reads ‘Celebrating Perfection in Education’, published by Maharishi Vedic University in 1997.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Aug_17.mp4",
          },
          {
            day: 0o4,
            description:
              "News of rising trends of life around the world in all fields of life including sustainable energy.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Aug_17.mp4",
          },
          {
            day: 0o3,
            description:
              "Maharishi’s Global News Conference, from 14 August 2003, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Aug_17.mp4",
          },
          {
            day: 0o2,
            description:
              "Sankalpa on the occasion of the Guru Purnima celebrations from Italy. Also a video review of the MERU Open Day of 16th July 2017.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Aug_17.mp4",
          },
        ],
      },
      {
        monthName: "Sept",
        days: [
          {
            day: 20,
            description:
              "Dr. Peter Swan reviews the latest news of Invincibility rising throughout the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Sep_17.mp4",
          },
          {
            day: 19,
            description:
              "Advanced Maharishi Aroma Therapy graduation. The graduation was recorded on 7th September 2017.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Sep_17.mp4",
          },
          {
            day: 18,
            description: "Raja Peter with news in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Sep_17.mp4",
          },
          {
            day: 14,
            description:
              "Maharishi’s Global News Conference, from 3 Sept 2003, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Sep_17.mp4",
          },
          {
            day: 10,
            description:
              "Dr. Peter Swan reads ‘Celebrating Perfection in Education’, published by Maharishi Vedic University in 1997.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Sep_17.mp4",
          },
          {
            day: 0o7,
            description:
              "Maharishi’s Global News Conference, from 3 Sept 2003, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Sep_17.mp4",
          },
        ],
      },
      {
        monthName: "Oct",
        days: [
          {
            day: 27,
            description:
              "Dr. Keith Wallace presents his new research in his book, Gut Crisis: How Diet, Probiotics, and Friendly Bacteria Help You Lose Weight and Heal Your Body and Mind.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Oct_17.mp4",
          },
          {
            day: 26,
            description:
              "Maharishi’s Global News Conference, from 10 Sept 2003, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Oct_17.mp4",
          },
          {
            day: 25,
            description:
              "Health series continues with Dr. Walter Moelk on how to maintain the full range and purity of Maharishi AyurVeda.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Oct_17.mp4",
          },
          {
            day: 24,
            description:
              "Raja Luis gives us the news from Latin America: creating large groups of Yogic Flyers to generate waves of invincibility for the whole world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Oct_17.mp4",
          },
          {
            day: 23,
            description:
              "Dr. Rainer Picha with latest findings on how to consume milk.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Oct_17.mp4",
          },
          {
            day: 22,
            description:
              "Dr. Peter Swan reads ‘Celebrating Perfection in Education’, published by Maharishi Vedic University in 1997.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Oct_17.mp4",
          },
          {
            day: 21,
            description:
              "Dr. Eckart Stein, director of the Maharishi Peace Palace in Erfurt, Germany, talks to the German meditators and sidhas assembled at MERU for the weekend.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Oct_17.mp4",
          },
          {
            day: 15,
            description:
              "Dr. Peter Swan reads ‘Celebrating Perfection in Education’, published by Maharishi Vedic University in 1997.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Oct_17.mp4",
          },
          {
            day: 13,
            description:
              "On the 26th of August, 2017, the leaders of the Dhammajarinee Witthaya School for girls in Thailand, visited Maharishi University of Management, Fairfield, IA, USA to receive their Honorary Doctorate degrees – part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Oct_17.mp4",
          },
          {
            day: 12,
            description:
              "Maharishi’s Global News Conference, from 10 Sept 2003, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Oct_17.mp4",
          },
          {
            day: 11,
            description:
              "On the 26th of August, 2017, the leaders of the Dhammajarinee Witthaya School for girls in Thailand, visited Maharishi University of Management, Fairfield, IA, USA to receive their Honorary Doctorate degrees – part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Oct_17.mp4",
          },
          {
            day: 10,
            description:
              "Raja Luis presents special guests from Brazil implementing the Transcendental Meditation in many schools in Brazil.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Oct_17.mp4",
          },
          {
            day: 09,
            description:
              "Dr. Adam Pressman, director for media production and development of the David Lynch Foundation, USA, reviews the latest achievements of the David Lynch Foundation to bring the knowledge of Transcendental Meditation to many schools in USA and throughout the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Oct_17.mp4",
          },
          {
            day: 08,
            description:
              "Dr. Peter Swan reads ‘Celebrating Perfection in Education’, published by Maharishi Vedic University in 1997.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Oct_17.mp4",
          },
          {
            day: 0o3,
            description:
              "News update from all around the world by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Oct_17.mp4",
          },
          {
            day: 0o2,
            description:
              "Dr Girish Momaya review two up-coming MERU courses: Maharishi’s Commentary on Rik Veda and Maharishi’s Commentary on Bhagavad Gita, ch. 7.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Oct_17.mp4",
          },
          {
            day: 0o1,
            description:
              "Dr. Peter Swan reads ‘Celebrating Perfection in Education’, published by Maharishi Vedic University in 1997.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Oct_17.mp4",
          },
        ],
      },
      {
        monthName: "Nov",
        days: [
          {
            day: 30,
            description:
              "Maharishi’s Global News Conference, from 24 Sept 2003, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Nov_17.mp4",
          },
          {
            day: 27,
            description:
              "Graduation of the Second Advanced course in Maharishi Light Therapy with Gems.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Nov_17.mp4",
          },
          {
            day: 26,
            description:
              "Dr. Peter Swan reads ‘Celebrating Perfection in Education’, published by Maharishi Vedic University in 1997.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Nov_17.mp4",
          },
          {
            day: 25,
            description:
              "Dr. Bevan Morris, Prime Minister of the Global Country of World Peace explains the significance of Thanksgiving in America and recounts the events that took place in his latest trip in UK and France.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Nov_17.mp4",
          },
          {
            day: 22,
            description:
              "Raja Chancellor, Purusha Raja of Universal domain gives an overview of the growth of coherence and invincibility in the TM movement and in the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Nov_17.mp4",
          },
          {
            day: 20,
            description:
              "Vaidya Pratmesh Vyas Ji gives precious advices on ‘Agni’, a very important subject in Maharishi Ayurveda concerning the digestive system.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Nov_17.mp4",
          },
          {
            day: 19,
            description:
              "Dr. Peter Swan reads ‘Celebrating Perfection in Education’, published by Maharishi Vedic University in 1997.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Nov_17.mp4",
          },
          {
            day: 18,
            description:
              "Dimitrios Karasis, Director of Invincible Defense Technology in the Ministry of Defence of the Global Country of World Peace with the latest news in the field of Defence.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Nov_17.mp4",
          },
          {
            day: 17,
            description:
              "Report from Raja Graham about his latest visit in Uganda.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Nov_17.mp4",
          },
          {
            day: 16,
            description:
              "Maharishi’s Global News Conference, from 24 Sept 2003, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Nov_17.mp4",
          },
          {
            day: 15,
            description:
              "Graduation of the first course of Maharishi Bhagavad Gita, Chapter 7 with Dr. William Sands and the course participants.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Nov_17.mp4",
          },
          {
            day: 14,
            description: "Latest news from Latin America with Raja Luis.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Nov_17.mp4",
          },
          {
            day: 13,
            description:
              "News of rising invincibility around the world with Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Nov_17.mp4",
          },
          {
            day: 12,
            description:
              "Dr. Peter Swan reads ‘Celebrating Perfection in Education’, published by Maharishi Vedic University in 1997.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Nov_17.mp4",
          },
          {
            day: 11,
            description:
              "David Lynch talked at an event on the 12th Sept. 2017 in Belgrade, Serbia.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Nov_17.mp4",
          },
          {
            day: 10,
            description:
              "Vaidya Pratmesh Vyas Ji gives precious advices on preventing diseases and maintaining a high level of health in our modern world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Nov_17.mp4",
          },
          {
            day: 09,
            description:
              "Maharishi’s Global News Conference, from 17 Sept 2003, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Nov_17.mp4",
          },
          {
            day: 08,
            description:
              "Dr. Schrott, Ayurvedic doctor in Germany, introduces Marma Therapy as a part of Maharishi AyurVeda, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Nov_17.mp4",
          },
          {
            day: 0o5,
            description:
              "Dr. Peter Swan reads ‘Celebrating Perfection in Education’, published by Maharishi Vedic University in 1997.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Nov_17.mp4",
          },
          {
            day: 0o2,
            description:
              "Maharishi’s Global News Conference, from 17 Sept 2003, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Nov_17.mp4",
          },
          {
            day: 0o1,
            description:
              "Dr. Schrott, Ayurvedic doctor in Germany, introduces Marma Therapy as a part of Maharishi AyurVeda, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Nov_17.mp4",
          },
        ],
      },
      {
        monthName: "Dec",
        days: [
          {
            day: 30,
            description: "Achievements from 2017 with Dr. Bevan Morris .",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Dec_17.mp4",
          },
          {
            day: 28,
            description:
              "Maharishi’s Global News Conference, from 8 October 2003, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Dec_17.mp4",
          },
          {
            day: 27,
            description:
              "Presentations at the conference sponsored by the David Lynch Foundation, A New Science for Peace – Research on the Transcendental Meditation, the Brain, Trauma, and Peace at the Abramson Center for Peace, in Washington, DC, USA – Part 4—Section on Education.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Dec_17.mp4",
          },
          {
            day: 25,
            description:
              "Presentations at the conference sponsored by the David Lynch Foundation, A New Science for Peace – Research on the Transcendental Meditation, the Brain, Trauma, and Peace at the Abramson Center for Peace, in Washington, DC, USA – Part 3.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Dec_17.mp4",
          },
          {
            day: 24,
            description:
              "Dr. Peter Swan reads ‘Celebrating Perfection in Education’, published by Maharishi Vedic University in 1997.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Dec_17.mp4",
          },
          {
            day: 23,
            description:
              "Presentations at the conference sponsored by the David Lynch Foundation, A New Science for Peace – Research on the Transcendental Meditation, the Brain, Trauma, and Peace at the Abramson Center for Peace, in Washington, DC, USA, Part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Dec_17.mp4",
          },
          {
            day: 22,
            description:
              "Dr. Evan Finkelstein, from Maharishi University of Management, USA presents an essay about Maharishi Vedic Science and Buddhism.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Dec_17.mp4",
          },
          {
            day: 21,
            description:
              "Maharishi’s Global News Conference, from 8 October 2003, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Dec_17.mp4",
          },
          {
            day: 19,
            description:
              "Dr. Peter Swan gives an overview of the great trends happening in the world bringing a better quality of life for everyone.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Dec_17.mp4",
          },
          {
            day: 18,
            description:
              "Raja Peter and his colleagues explain the progress that is taking place in Great Britain. They are talking from the Maharishi Peace Palace in Rendlesham, Suffolk, UK.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Dec_17.mp4",
          },
          {
            day: 17,
            description:
              "Dr. Peter Swan reads ‘Celebrating Perfection in Education’, published by Maharishi Vedic University in 1997.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Dec_17.mp4",
          },
          {
            day: 16,
            description:
              "News of rising invincibility from Turkey and Cyprus with Raja Paul.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Dec_17.mp4",
          },
          {
            day: 15,
            description:
              "Raja Kingsley recounts the recent visit by Dr. David Lynch to Ukraine and Georgia.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Dec_17.mp4",
          },
          {
            day: 14,
            description:
              "Maharishi’s Global News Conference, from 1 October 2003, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Dec_17.mp4",
          },
          {
            day: 13,
            description:
              "Raja Peter explains how Maharishi developed the course on Total Knowledge and entrusted him to present it to the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Dec_17.mp4",
          },
          {
            day: 10,
            description:
              "Dr. Peter Swan reads ‘Celebrating Perfection in Education’, published by Maharishi Vedic University in 1997.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Dec_17.mp4",
          },
          {
            day: 08,
            description:
              "Keynote address by Dr. John Hagelin given at the conference entitled, ‘A New Science for Peace – Research on Transcendental Meditation, the Brain, Trauma, and Peace’ on the 5th of December at the American University, Washington DC.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Dec_17.mp4",
          },
          {
            day: 0o7,
            description:
              "Maharishi’s Global News Conference, from 1 October 2003, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Dec_17.mp4",
          },
          {
            day: 0o6,
            description:
              "Introductory talk on Transcendental Meditation by Dr. David Lynch recorded live at the Abramson Center for Peace under the auspices of the David Lynch Foundation, USA, followed by an introduction to Maharishi Light Therapy with Gems with Eva Bergmann.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Dec_17.mp4",
          },
          {
            day: 0o4,
            description:
              "Dr David Orme-Johnson, holding a research desk at the Maharishi Foundation, USA, presents the latest research on Transcendental Meditation.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Dec_17.mp4",
          },
          {
            day: 0o3,
            description:
              "Dr. Peter Swan reads ‘Celebrating Perfection in Education’, published by Maharishi Vedic University in 1997.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Dec_17.mp4",
          },
          {
            day: 0o1,
            description:
              "Wojtek Skalski, Maharishi Vastu architect, explains the concept of ‘small houses’ in the context of Maharishi Sthapatya Veda.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Dec_17.mp4",
          },
        ],
      },
    ],
  },
  // Year 2016
  {
    id: 5,
    year: 2016,
    months: [
      {
        monthName: "Jan",
        days: [
          {
            day: 31,
            description:
              "Dr. Peter Swan reads Maharaja’s paper on Mathematics: “Consciousness Is All there Is” by Tony Nader, Md, PhD, Maharaja Adhiraj Rajaraam.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_Jan_16.mp4",
          },
          {
            day: 30,
            description:
              "Maharaja’s visit to the Maharishi Sidhaland in Lelystad, Holland with Dr. Paul Gelderloos.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Jan_16.mp4",
          },
          {
            day: 29,
            description:
              "Response of Dr. David Lynch to his birthday celebration and a report from the Maharishi European Sidhaland in the UK with Raja Peter.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Jan_16.mp4",
          },
          {
            day: 28,
            description:
              "Maharishi’s Global News Conference, from 5 February 2003 part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Jan_16.mp4",
          },
          {
            day: 27,
            description:
              "Raja Bjarne gives an overview of the achievements in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Jan_16.mp4",
          },
          {
            day: 26,
            description:
              "News on renewable energies from around the world with Dr. Peter Swan and Announcement of the next EU Summit Peace Assembly, 18-21 Feb. in Brussels with Dr Stijn Vandenbosch.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Jan_16.mp4",
          },
          {
            day: 25,
            description:
              "Birthday celebration of Dr. Joe Hayden, National Director of Ireland and Raja Paul invites everyone to attend the World Peace Assembly in Antalya, Turkey, from 30 January to 30 April, 2016 . Visit.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Jan_16.mp4",
          },
          {
            day: 24,
            description:
              "Dr. Peter Swan reads Maharaja’s paper on Mathematics: “Consciousness Is All there Is” by Tony Nader, Md, PhD, Maharaja Adhiraj Rajaraam.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Jan_16.mp4",
          },
          {
            day: 21,
            description:
              "Maharishi’s Global News Conference, from 5 February 2003 part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Jan_16.mp4",
          },
          {
            day: 20,
            description:
              "Celebration of the birthday of Dr. David Lynch with special wishes from Maharaja Adhiraj Rajaraam, Raja Hagelin, and Adam Pressman presenting an inspiring video on the achievements of the David Lynch Foundation.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Jan_16.mp4",
          },
          {
            day: 19,
            description:
              "Birthday celebration of Raja Bjarne with special presentations by Dr Bevan Morris and Dr. Eckart Stein on the visit by Maharaja Ji at the Peace Palace in Erfurt.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Jan_16.mp4",
          },
          {
            day: 17,
            description:
              "Dr. Peter Swan reads Maharaja’s paper on Mathematics: “Consciousness Is All there Is” by Tony Nader, Md, PhD, Maharaja Adhiraj Rajaraam.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Jan_16.mp4",
          },
          {
            day: 16,
            description:
              "Dr. Rainer Picha, Minister of Health of the Global Country of World Peace, talks about the health benefits of exercise.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Jan_16.mp4",
          },
          {
            day: 14,
            description:
              "Maharishi’s Global News Conference, from January 29, 2003.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Jan_16.mp4",
          },
          {
            day: 13,
            description:
              "Offerings for the new year from Raja Charoen Rajapark Krichkchai, Raja of Thailand, Dr. Lee Fergusson reporting for Dr. Larry Chroman, and other news from around the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Jan_16.mp4",
          },
        ],
      },
      {
        monthName: "Feb",
        days: [
          {
            day: 28,
            description:
              "Dr. Peter Swan reads Maharaja’s paper on Mathematics: “Consciousness Is All there Is” by Tony Nader, Md, PhD, Maharaja Adhiraj Rajaraam.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Feb_16.mp4",
          },
          {
            day: 27,
            description:
              "Raja Paul connects from Antalya, Turkey where he is conducting an extended World Peace Assembly until the April 3th 2016. Click here to register.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Feb_16.mp4",
          },
          {
            day: 26,
            description:
              "Dr Stijn Vandenbosch, National Leader of Belgium celebrates the 19th European Peace Assembly in Brussels.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Feb_16.mp4",
          },
          {
            day: 25,
            description:
              "Maharishi’s Global News Conference, from 19 February 2003 part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Feb_16.mp4",
          },
          {
            day: 24,
            description:
              "Raja Harris, Raja of Invincible India gave an uplifting presentation from the Brahmasthan of India with invited guests sharing their experiences at the Brahmasthan of India.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Feb_16.mp4",
          },
          {
            day: 23,
            description:
              "Raja Luis, Raja of Latin America, gave some glimpses of the progress to create groups of Yogic Flyers throughout his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Feb_16.mp4",
          },
          {
            day: 21,
            description:
              "Dr. Peter Swan reads Maharaja’s paper on Mathematics: “Consciousness Is All there Is” by Tony Nader, Md, PhD, Maharaja Adhiraj Rajaraam.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Feb_16.mp4",
          },
          {
            day: 20,
            description:
              "Natural Law-Based Art graduation ceremony part 2: appreciation by the teacher and course participants.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Feb_16.mp4",
          },
          {
            day: 19,
            description:
              "Natural Law-Based Art graduation ceremony part 1: Dr. Volker Schanbacher on science in art.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Feb_16.mp4",
          },
          {
            day: 18,
            description:
              "Maharishi’s Global News Conference, from 12 February 2003 part 3.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Feb_16.mp4",
          },
          {
            day: 17,
            description:
              "Maharaja Adhiraj Rajaraam’s address to the Maharishi Vedic Pandits’ Ambassadors monthly meeting on February 6th, 2016.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Feb_16.mp4",
          },
          {
            day: 16,
            description:
              "Celebration of Dr. Neil Paterson’s birthday, Minister of Administration of the Global Country of World Peace.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Feb_16.mp4",
          },
          {
            day: 15,
            description:
              "Report of the achievements of the countries in the domain of Raja Konhaus.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Feb_16.mp4",
          },
          {
            day: 14,
            description:
              "Dr. Peter Swan reads Maharaja’s paper on Mathematics: “Consciousness Is All there Is” by Tony Nader, Md, PhD, Maharaja Adhiraj Rajaraam.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Feb_16.mp4",
          },
          {
            day: 12,
            description:
              "Raja Paul connects from Antalya, Turkey where a long WPA is going on. Visit this SITE for more details and how to participate.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Feb_16.mp4",
          },
          {
            day: 11,
            description:
              "Maharishi’s Global News Conference, from 12 February 2003 part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Feb_16.mp4",
          },
          {
            day: 10,
            description:
              "Dr Girish Momaya reviews all the new courses offered by MERU for the beginning of this year. Visit: www.merucourses.com",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Feb_16.mp4",
          },
          {
            day: 09,
            description:
              "Review of achievements in the countries of his domain by Raja Luis",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Feb_16.mp4",
          },
          {
            day: 08,
            description:
              "News from the Enlightened Leadership International announcing the new Teacher Training Course for the next generation in Bali. For more information visit: www.enlightenedleadershipprogram.org",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Feb_16.mp4",
          },
          {
            day: 0o7,
            description:
              "Dr. Peter Swan reads Maharaja’s paper on Mathematics: “Consciousness Is All there Is” by Tony Nader, Md, PhD, Maharaja Adhiraj Rajaraam.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Feb_16.mp4",
          },
          {
            day: 0o6,
            description: "News from around the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Feb_16.mp4",
          },
          {
            day: 0o4,
            description:
              "Maharishi’s Global News Conference, from 12 February 2003 part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Feb_16.mp4",
          },
          {
            day: 0o3,
            description:
              "Raja Paul announces a new World Peace Assembly in Antalya from 30 January to 30 April 2016. Click to visit the site.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Feb_16.mp4",
          },
        ],
      },
      {
        monthName: "Mar",
        days: [
          {
            day: 31,
            description:
              "Maharishi’s Global News Conference, from 5 March 2003 part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_Mar_16.mp4",
          },
          {
            day: 30,
            description:
              "A Scientific Solution to Terrorism and Conflict—A proposal to governments and leaders of society by the Global Union of Scientists for Peace (www.gusp.org)",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Mar_16.mp4",
          },
          {
            day: 27,
            description:
              "Dr. Peter Swan reads ‘Maharishi Mahesh Yogi—30 Years Around the World—Dawn of the Age of Enlightenment’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Mar_16.mp4",
          },
          {
            day: 25,
            description:
              "A video presentation of ‘The benefits of the Transcendental Meditation program in a girl’s school in Thailand’ and a video on ‘The Dr. Alberto Iria School in Olhão, Portugal’",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Mar_16.mp4",
          },
          {
            day: 24,
            description:
              "Maharishi’s Global News Conference, from 26 February 2003 part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Mar_16.mp4",
          },
          {
            day: 22,
            description:
              "Latest achievements by Raja Luis in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Mar_16.mp4",
          },
          {
            day: 21,
            description:
              "Birthday celebrations for Raja Rafael, Purusha Raja of Universal Domain and presentation by Nadine Thomas of the Maharishi Aroma Therapy Training Courses to be held in Holland on the 30th of April and USA on the 24th June 2016.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Mar_16.mp4",
          },
          {
            day: 20,
            description:
              "Dr. Peter Swan reads ‘Maharishi Mahesh Yogi—30 Years Around the World—Dawn of the Age of Enlightenment’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Mar_16.mp4",
          },
          {
            day: 18,
            description:
              "News of increasing coherence from around the world starting with a special video from the Maharishi Next Generation Chat.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Mar_16.mp4",
          },
          {
            day: 17,
            description:
              "Maharishi’s Global News Conference, from 26 February 2003 part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Mar_16.mp4",
          },
          {
            day: 16,
            description:
              "Birthday celebrations of Raja Paul from his World Peace Assembly in Antalya, Turkey.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Mar_16.mp4",
          },
          {
            day: 11,
            description:
              "Reports on Maharishi AyurVeda achievements after the International Ayurveda Congress in Roermond held from 11-12 April 2015, with Dr. Girish Momaya, Dr Walter Moelk, Dr. Lothar Pirc.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Mar_16.mp4",
          },
          {
            day: 10,
            description:
              "Maharishi’s Global News Conference, from 19 February 2003 part 3.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Mar_16.mp4",
          },
          {
            day: 08,
            description:
              "The resolutions and goals for 2016 for Ukraine presented by Mykola Didukh, Ph.D., the National Director of Ukraine, followed by the updates on the projects being undertaken in Latin America to create large groups of Yogic Flyers in the schools.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Mar_16.mp4",
          },
          {
            day: 0o6,
            description:
              "Presenting the new edition of the book Maharishi Mahesh Yogi on the Bhagavad-Gita, published by MUM Press.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Mar_16.mp4",
          },
          {
            day: 0o5,
            description:
              "Replay of the Maharishi Vedic Pandits Ambassadors meeting.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Mar_16.mp4",
          },
          {
            day: 0o3,
            description:
              "Birthday celebration of Dr. Bevan Morris, Prime Minister of the Global Country of World Peace with a special video message from Maharaja Adhiraj Rajaraam.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Mar_16.mp4",
          },
          {
            day: 0o1,
            description:
              "Good news from the press around the world. Also a video from the David Lynch Foundation featuring an interview with Ray Dalio, Founder Bridgewater Associates, and Martin Scorsese, filmmaker introduced by Dr. Robert Roth, from the David Lynch Foundation.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Mar_16.mp4",
          },
        ],
      },
      {
        monthName: "Apr",
        days: [
          {
            day: 28,
            description:
              "Maharishi’s Global News Conference, from 12 March 2003 part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Apr_16.mp4",
          },
          {
            day: 27,
            description:
              "Best-selling author Dr Norman Rosenthal has written a new book entitled Super Mind—How to boost performance and live a rich and happier life through Transcendental Meditation.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Apr_16.mp4",
          },
          {
            day: 26,
            description:
              "Raja Luis presents the latest achievements in many countries of his domain. Lots of inspiring photos are shown. Also the latest Next Generation Chat programme is presented.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Apr_16.mp4",
          },
          {
            day: 24,
            description:
              "Dr. Peter Swan reads ‘Maharishi Mahesh Yogi—30 Years Around the World—Dawn of the Age of Enlightenment’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Apr_16.mp4",
          },
          {
            day: 23,
            description:
              "Latest news on the AyurVeda Health Center project in Austria by Dr. Rainer Picha",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Apr_16.mp4",
          },
          {
            day: 21,
            description:
              "Maharishi’s Global News Conference, from 12 March 2003 part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Apr_16.mp4",
          },
          {
            day: 20,
            description:
              "Raja Peter shows pictures and video of the construction progress of the new Maharishi Peace Palace in UK. He also introduced his new up-coming course entitled ‘Total Knowledge and the Evolutionary Perspective of Religious Codes’ and also ‘Maharishi’s Total Knowledge with Raja Peter - Parts 1 & 2’. Click HERE for more details.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Apr_16.mp4",
          },
          {
            day: 18,
            description:
              "Maharishi Tower Full Presentation Architecture Floor Unveiled",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Apr_16.mp4",
          },
          {
            day: 17,
            description:
              "Dr. Peter Swan reads ‘Maharishi Mahesh Yogi—30 Years Around the World—Dawn of the Age of Enlightenment’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Apr_16.mp4",
          },
          {
            day: 16,
            description:
              "Prof. John Collins, assistant professor of Maharishi Vedic Science and instructor in sustainable living at Maharishi University of Management, Fairfield, IA, USA, talks about sustainability in our world today",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Apr_16.mp4",
          },
          {
            day: "05",
            description:
              "Report from Raja Luis about scientific research on the beneficial effects of Transcendental Meditation practiced by the students in many schools of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Apr_16.mp4",
          },
          {
            day: "03",
            description:
              "Dr. Peter Swan reads ‘Maharishi Mahesh Yogi—30 Years Around the World—Dawn of the Age of Enlightenment’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Apr_16.mp4",
          },
          {
            day: "02",
            description:
              "Maharishi Next Generation Chat and a report from Lothar Pirc about his trip to Russia.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Apr_16.mp4",
          },
          {
            day: "01",
            description:
              "Raja Paul reports from the World Peace Assembly, in Antalya, Turkey. To register visit: www.maharishiglobalcalendar.org",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Apr_16.mp4",
          },
        ],
      },
      {
        monthName: "May",
        days: [
          {
            day: 31,
            description:
              "Raja Luis with more inspiring news in the implementation of Transcendental Meditation in schools and military academies in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_May_16.mp4",
          },
          {
            day: 28,
            description:
              "New online course presented: Maharishi Vedic Science and World Religion, taught by Prof. Evan Finkelstein of Maharishi University of Management, Fairfield, IA, USA",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_May_16.mp4",
          },
          {
            day: 27,
            description:
              "Report from Africa with Raja Graham, Raja of many countries in Africa.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_May_16.mp4",
          },
          {
            day: 23,
            description:
              "Second report by Dr. Branco Cicic elaborate on the success of Second Symposium of Mind-Body Medicine in Maharishi AyurVeda, and a report from Lothar Pirc, Director of the Maharishi AyurVeda clinic in Bad Elms, Germany.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_May_16.mp4",
          },
          {
            day: 22,
            description:
              "Dr. Peter Swan reads ‘Maharishi Mahesh Yogi—30 Years Around the World—Dawn of the Age of Enlightenment’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_May_16.mp4",
          },
          {
            day: 21,
            description:
              "Dr. Peter Swan relates the major trends rising in the world showing growth of invincibility and sustainability.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_May_16.mp4",
          },
          {
            day: 19,
            description:
              "Maharishi’s Global News Conference, from 26 March 2003 part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_May_16.mp4",
          },
          {
            day: 17,
            description:
              "More great news from Raja Luis in the achievements in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_May_16.mp4",
          },
          {
            day: 16,
            description:
              "Dr. Branco Cicic gives the story of a great success with a Maharishi AyurVeda conference, and also the steps how to arrange one in any country.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_May_16.mp4",
          },
          {
            day: 15,
            description:
              "Dr. Peter Swan reads ‘Maharishi Mahesh Yogi—30 Years Around the World—Dawn of the Age of Enlightenment’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_May_16.mp4",
          },
          {
            day: 12,
            description:
              "Maharishi’s Global News Conference, from 19 March 2003 part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_May_16.mp4",
          },
          {
            day: 11,
            description:
              "Next Generation Chat and Collection of news items from the popular press indicating rising trend of invincibility and purity of life around the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_May_16.mp4",
          },
          {
            day: 10,
            description:
              "Report from Raja Luis, Raja of Latin America and pictures of the Flag Raising ceremonies held all around the world on Akshaya Tritiya.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_May_16.mp4",
          },
          {
            day: 08,
            description:
              "Dr. Peter Swan reads ‘Maharishi Mahesh Yogi—30 Years Around the World—Dawn of the Age of Enlightenment’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_May_16.mp4",
          },
          {
            day: 0o7,
            description:
              "Conclusion of the Maharishi Aroma Therapy course with special guest expert and course leader Malte and Fabienne Hozzel.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_May_16.mp4",
          },
          {
            day: 0o5,
            description:
              "Maharishi’s Global News Conference, from 19 March 2003 part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_May_16.mp4",
          },
          {
            day: 0o4,
            description:
              "Raja Paul with the latest achievements at the World Peace Assembly in Antalya, Turkey",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_May_16.mp4",
          },
          {
            day: 0o1,
            description:
              "Dr. Peter Swan reads ‘Maharishi Mahesh Yogi—30 Years Around the World—Dawn of the Age of Enlightenment’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_May_16.mp4",
          },
        ],
      },
      {
        monthName: "June",
        days: [
          {
            day: 30,
            description:
              "Maharishi’s Global News Conference, from 16 April 2003 part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Jun_16.mp4",
          },
          {
            day: 29,
            description:
              "Dr. Ashley Deans, Global Ambassador of Consciousness-Based Education hosting Raja Willem, Raja of The Netherlands, on a recent visit to Bali, Indonesia.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Jun_16.mp4",
          },
          {
            day: 28,
            description:
              "Raja Luis reports on large group of Maharishi Yogic Flyers being created in the countries of his domain, bringing long-awaited peace in Colombia.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Jun_16.mp4",
          },
          {
            day: 26,
            description:
              "Dr. Peter Swan reads ‘Maharishi Mahesh Yogi—30 Years Around the World—Dawn of the Age of Enlightenment’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Jun_16.mp4",
          },
          {
            day: 25,
            description:
              "Special Address by Maharaja Adhiraj Rajaraam on the recent ‘Brexit’ vote in the UK.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Jun_16.mp4",
          },
          {
            day: 23,
            description:
              "Maharishi’s Global News Conference, from 9 April 2003 part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Jun_16.mp4",
          },
          {
            day: 22,
            description:
              "Maharishi Next Generation Chat, presented by Mustafa Nuristani and guests.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Jun_16.mp4",
          },
          {
            day: 21,
            description:
              "Raja Luis reports on Maharishi Yogic Flying Effect in society.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Jun_16.mp4",
          },
          {
            day: 20,
            description:
              "Raja Paul plans for the future and the Invincible Middle-East Assembly in Turkey.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Jun_16.mp4",
          },
          {
            day: 19,
            description:
              "Dr. Peter Swan reads ‘Maharishi Mahesh Yogi—30 Years Around the World—Dawn of the Age of Enlightenment’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Jun_16.mp4",
          },
          {
            day: 16,
            description:
              "Maharishi’s Global News Conference, from 9 April 2003 part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Jun_16.mp4",
          },
          {
            day: 13,
            description:
              "Announcing the Next EU Summit Peace Assembly, 22-30 June (new dates), Brussels. Permanent Peace Creating Group in the Heart of Europe: Brussels 108. In part two, Dr. Peter Swan reports on good news giving signs of invincibility in the world with guests Dr. Bevan Morris and Peter Leadbetter.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Jun_16.mp4",
          },
          {
            day: 12,
            description:
              "Dr. Peter Swan reads ‘Maharishi Mahesh Yogi—30 Years Around the World—Dawn of the Age of Enlightenment’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Jun_16.mp4",
          },
          {
            day: 11,
            description:
              "Dr Eckart Stein, National Director of Invincible German presents a new video for presenting Transcendental Meditation (in German).",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Jun_16.mp4",
          },
          {
            day: 10,
            description:
              "Graduation of the 15th Maharishi Light Therapy with Gem consultance training course with Dr. Walter Moelk.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Jun_16.mp4",
          },
          {
            day: 09,
            description:
              "Maharishi’s Global News Conference, from 2 April 2003 part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Jun_16.mp4",
          },
          {
            day: 08,
            description:
              "Maharishi Next Generation Chat, presented by Mustafa Nuristani with guest Vaidya Dinesh Gyawali, Maharishi AyurVeda Consultant, Maharishi University of Management, Fairfield, IA., the David Lynch Foundation Women’s Health Initiative, and more.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Jun_16.mp4",
          },
          {
            day: "07",
            description:
              "The International Day of Yoga 2016 introduced and explained by Raja Luis.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Jun_16.mp4",
          },
          {
            day: "05",
            description:
              "Dr. Peter Swan reads ‘Maharishi Mahesh Yogi—30 Years Around the World—Dawn of the Age of Enlightenment’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Jun_16.mp4",
          },
          {
            day: "04",
            description:
              "Announcing the Next EU Summit Peace Assembly, 22-26 June, Brussels. Permanent Peace Creating Group in the Heart of Europe: Brussels 108. In part two, Dr. Peter Swan reports on good news giving signs of invincibility in the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Jun_16.mp4",
          },
          {
            day: "03",
            description:
              "Prof. Evan Finkelstein of Maharishi University of Management, Fairfield, IA, USA, explores ‘the Nature of God’ from his new upcoming course: Maharishi Vedic Science and World Religion.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Jun_16.mp4",
          },
          {
            day: "02",
            description:
              "Maharishi’s Global News Conference, from 2 April 2003 part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Jun_16.mp4",
          },
          {
            day: "01",
            description:
              "Report from Raja Bjarne, Raja of Denmark and Russia and more.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Jun_16.mp4",
          },
        ],
      },
      {
        monthName: "July",
        days: [
          {
            day: 31,
            description:
              "Dr. Peter Swan reads ‘Maharishi Mahesh Yogi—30 Years Around the World—Dawn of the Age of Enlightenment’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_Jul_16.mp4",
          },
          {
            day: 30,
            description:
              "Dr Larry Clark, board member of the Maharishi School in Melbourne, Australia and Dr. Bevan Morris, Prime Minister of the Global Country of World Peace.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Jul_16.mp4",
          },
          {
            day: 29,
            description:
              "Guru Purnima report and wishes from Raja Antonio, Raja of Invincible Spain, Portugal, Lebanon, and Syria .",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Jul_16.mp4",
          },
          {
            day: 28,
            description:
              "Guru Purnima report and wishes from Dr. Gelderloos, National Director of Invincible Holland.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Jul_16.mp4",
          },
          {
            day: 27,
            description: "Maharishi Next Generation Chat.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Jul_16.mp4",
          },
          {
            day: 26,
            description:
              "Guru Purnima wishes from Dr. Bevan Morris, Prime Minister of the Global Country of World Peace.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Jul_16.mp4",
          },
          {
            day: 25,
            description:
              "Dr. Rod Eason, Dean of Student Life at Maharishi University of Management, Fairfield, USA explains the Maharishi University of Management Ambassador program.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Jul_16.mp4",
          },
          {
            day: 24,
            description:
              "Guru Purnima wishes from Raja Vikram Adithya, Raja of Invincible Thailand, and a report from the national office of Invincible Japan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Jul_16.mp4",
          },
          {
            day: 23,
            description:
              "Guru Purnima celebration offerings: Ministry of Health with Dr. Picha and Moelk with the participation of Dr. Girish Momaya, MERU director and other guests",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Jul_16.mp4",
          },
          {
            day: 22,
            description:
              "National director of Poland, Andrzej Baran with Wojtek Skalski, Maharishi Vastu architect.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Jul_16.mp4",
          },
          {
            day: 21,
            description: "International Governors Conference, Final session.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Jul_16.mp4",
          },
          {
            day: 20,
            description:
              "International Governors Conference, Afternoon session.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Jul_16.mp4",
          },
          {
            day: 19,
            description: "International Governors Conference, Evening session.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Jul_16.mp4",
          },
          {
            day: 17,
            description: "International Governors Conference, Day 7 Session 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Jul_16.mp4",
          },
          {
            day: 16,
            description: "International Governors Conference, Day 6 Session 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Jul_16.mp4",
          },
          {
            day: 15,
            description:
              "Raja Harris’s lecture from 2 July 2016 on the occasion of a Maharishi Vedic Pandits Ambassador meeting.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Jul_16.mp4",
          },
          {
            day: 14,
            description:
              "Maharishi University of Management 2016 graduation ceremony featuring Dr. David Lynch.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Jul_16.mp4",
          },
          {
            day: 12,
            description:
              "Raja Kingsley, Raja of Israel and Alex Kutai, National Director of Israel.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Jul_16.mp4",
          },
          {
            day: 10,
            description:
              "Dr. Peter Swan reads ‘Maharishi Mahesh Yogi—30 Years Around the World—Dawn of the Age of Enlightenment’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Jul_16.mp4",
          },
          {
            day: 09,
            description:
              "Dr Neil Paterson, Minister of Administration of the Global Country of World Peace—Initiative to Create Peace in the World.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Jul_16.mp4",
          },
          {
            day: "07",
            description:
              "Maharishi’s Global News Conference, from 16 April 2003 part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Jul_16.mp4",
          },
          {
            day: "05",
            description: "Birthday celebration of Raja Peter.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Jul_16.mp4",
          },
          {
            day: "04",
            description:
              "Vedic Mathematics presentation by mathematician Dr. Hans-Egon Kolsdorf.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Jul_16.mp4",
          },
          {
            day: "03",
            description:
              "Dr. Peter Swan reads ‘Maharishi Mahesh Yogi—30 Years Around the World—Dawn of the Age of Enlightenment’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Jul_16.mp4",
          },
          {
            day: "02",
            description:
              "Birthday celebration of Raja Luis, Raja of Latin America.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Jul_16.mp4",
          },
          {
            day: "01",
            description:
              "Dr. Chris Crowell’s presents his visit to Brussels where he attended the EU Summit Peace Assembly.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Jul_16.mp4",
          },
        ],
      },
      {
        monthName: "Aug",
        days: [
          {
            day: 26,
            description:
              "Graduation ceremony of the European Young Meditators Course that was held at MERU from 20 August to 27 August 2016.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Aug_16.mp4",
          },
          {
            day: 21,
            description:
              "Dr. Peter Swan reads ‘Maharishi Mahesh Yogi—30 Years Around the World—Dawn of the Age of Enlightenment’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Aug_16.mp4",
          },
          {
            day: 20,
            description: "Review of positive news from around the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Aug_16.mp4",
          },
          {
            day: 19,
            description:
              "Guru Purnima report and wishes from Raja Rogers, Raja of Australia and other countries.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Aug_16.mp4",
          },
          {
            day: 18,
            description:
              "Maharishi’s Global News Conference, from 23 April 2003 part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Aug_16.mp4",
          },
          {
            day: 17,
            description: "Maharishi Tower of Invincibility presentation.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Aug_16.mp4",
          },
          {
            day: 14,
            description:
              "Dr. Peter Swan reads ‘Maharishi Mahesh Yogi—30 Years Around the World—Dawn of the Age of Enlightenment’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Aug_16.mp4",
          },
          {
            day: 13,
            description: "Interview with Prince Nikolaus Blücher.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Aug_16.mp4",
          },
          {
            day: 12,
            description:
              "Graduation ceremony of the TM-Sidhi course held at MERU, with Dominique Lemoine, International TM-Sidhi administrator and course participants from France.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Aug_16.mp4",
          },
          {
            day: 11,
            description:
              "Graduation ceremony of the course on Consciousness-Based Education with Dr. Susie Dillbeck, teachers, and course participants.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Aug_16.mp4",
          },
          {
            day: 10,
            description: "Maharishi Next Generation Chat.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Aug_16.mp4",
          },
          {
            day: 09,
            description:
              "Raja Luis with very inspiring news from the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Aug_16.mp4",
          },
          {
            day: 0o7,
            description:
              "Dr. Peter Swan reads ‘Maharishi Mahesh Yogi—30 Years Around the World—Dawn of the Age of Enlightenment’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Aug_16.mp4",
          },
          {
            day: 0o6,
            description:
              "Guru Purnima report and wishes from Dr. Eike Hartmann, Minister of Architecture of the Global Country of World Peace.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Aug_16.mp4",
          },
          {
            day: 0o5,
            description:
              "Guru Purnima report and wishes from the Czech Republic and a report from the Cows Foundation in USA.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Aug_16.mp4",
          },
          {
            day: 0o4,
            description:
              "Maharishi’s Global News Conference, from 23 April 2003 part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Aug_16.mp4",
          },
          {
            day: 0o3,
            description:
              "Guru Purnima report and wishes from Raja Felix, on the National Day of Switzerland.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Aug_16.mp4",
          },
          {
            day: 0o2,
            description:
              "Guru Purnima report and wishes from Raja Pat Barrett and from Dr. Hannu Heikkil, National Director of Finland.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Aug_16.mp4",
          },
          {
            day: 0o1,
            description:
              "Guru Purnima report and wishes from Raja Bruce, Raja of Malaysia, Sweden and other countries.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Aug_16.mp4",
          },
        ],
      },
      {
        monthName: "Sept",
        days: [
          {
            day: 30,
            description:
              "Dr Alarik Arenander reports on his latest course on EEG.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Sep_16.mp4",
          },
          {
            day: 29,
            description:
              "Maharishi’s Global News Conference, from 7 May 2003 part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Sep_16.mp4",
          },
          {
            day: 28,
            description:
              "Dr Stijn Vandenbosch, National Leader of Belgium reports on the 108 Yogic Flying group in Brussels",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Sep_16.mp4",
          },
          {
            day: 25,
            description:
              "Dr. Peter Swan reads ‘Maharishi Mahesh Yogi—30 Years Around the World—Dawn of the Age of Enlightenment’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Sep_16.mp4",
          },
          {
            day: 22,
            description:
              "Maharishi’s Global News Conference, from 7 May 2003 part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Sep_16.mp4",
          },
          {
            day: 20,
            description:
              "Maharishi University of Management Founders’ Day celebration part 3 that took place on the 12th September 2016.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Sep_16.mp4",
          },
          {
            day: 19,
            description:
              "Maharishi University of Management Founders’ Day celebration part 2 that took place on the 12th September 2016.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Sep_16.mp4",
          },
          {
            day: 18,
            description:
              "Dr. Peter Swan reads ‘Maharishi Mahesh Yogi—30 Years Around the World—Dawn of the Age of Enlightenment’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Sep_16.mp4",
          },
          {
            day: 16,
            description:
              "Maharishi University of Management Founders’ Day celebration part 1 that took place on the 12th September 2016.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Sep_16.mp4",
          },
          {
            day: 15,
            description:
              "Maharishi’s Global News Conference, from 30 April 2003 part 3.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Sep_16.mp4",
          },
          {
            day: 14,
            description:
              "News from the World Peace Assembly in Odessa, Ukraine",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Sep_16.mp4",
          },
          {
            day: 13,
            description:
              "Historical details of increase coherence in the world with Col. Gunter Chassé.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Sep_16.mp4",
          },
          {
            day: 12,
            description:
              "Vedic Mathematics second presentation by mathematician Dr. Hans-Egon Kolsdorf.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Sep_16.mp4",
          },
          {
            day: 11,
            description:
              "Dr. Peter Swan reads ‘Maharishi Mahesh Yogi—30 Years Around the World—Dawn of the Age of Enlightenment’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Sep_16.mp4",
          },
          {
            day: 10,
            description:
              "Coherence-creating programme hosted by Dr. Eckart Stein in Germany.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Sep_16.mp4",
          },
          {
            day: 09,
            description: "Building a Peace Palace in UK with Raja Peter.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Sep_16.mp4",
          },
          {
            day: "07",
            description:
              "Special report from Dr. Keith Wallace and Dr. Fred Travis, researchers at Maharishi University of Management, Fairfield, IA, USA.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Sep_16.mp4",
          },
          {
            day: "04",
            description:
              "Dr. Peter Swan reads ‘Maharishi Mahesh Yogi—30 Years Around the World—Dawn of the Age of Enlightenment’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Sep_16.mp4",
          },
          {
            day: "02",
            description:
              "Maharishi’s Next Generation Chat hosted by Jonathon Phillips.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Sep_16.mp4",
          },
          {
            day: "01",
            description:
              "Maharishi’s Global News Conference, from 30 April 2003 part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Sep_16.mp4",
          },
        ],
      },
      {
        monthName: "Oct",
        days: [
          {
            day: 27,
            description:
              "Maharishi’s Global News Conference, from 14 May 2003 part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Oct_16.mp4",
          },
          {
            day: 24,
            description:
              "Report from Dr. Ashley Deans on his latest trip in Mongolia.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Oct_16.mp4",
          },
          {
            day: 20,
            description:
              "Maharishi’s Global News Conference, from 14 May 2003 part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Oct_16.mp4",
          },
          {
            day: 19,
            description: "Maharishi Tower of Invincibility update.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Oct_16.mp4",
          },
          {
            day: 17,
            description:
              "Report from Dr. Ashley Deans on the latest development in Ukraine.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Oct_16.mp4",
          },
          {
            day: 16,
            description:
              "Dr. Peter Swan reads ‘Maharishi Mahesh Yogi—30 Years Around the World—Dawn of the Age of Enlightenment’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Oct_16.mp4",
          },
          {
            day: 15,
            description:
              "Inauguration of Maharishi Peace Palace, Rendlesham, UK, part 5.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Oct_16.mp4",
          },
          {
            day: 14,
            description:
              "Victory Day 2017—Maharishi Celebrates Invincibility to Every Nation in the Year of Ideal Society and the Inauguration of Maharishi Peace Palace, Rendlesham, UK, part 4.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Oct_16.mp4",
          },
          {
            day: 13,
            description:
              "Victory Day 2017—Maharishi Celebrates Invincibility to Every Nation in the Year of Ideal Society, part 3.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Oct_16.mp4",
          },
          {
            day: 12,
            description:
              "Inauguration of Maharishi Peace Palace, Rendlesham, UK, part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Oct_16.mp4",
          },
          {
            day: 11,
            description:
              "Inauguration of Maharishi Peace Palace, Rendlesham, UK, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Oct_16.mp4",
          },
        ],
      },
      {
        monthName: "Nov",
        days: [
          {
            day: 29,
            description:
              "Raja Luis achievements and news in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Nov_16.mp4",
          },
          {
            day: 27,
            description:
              "Dr. Peter Swan reads ‘Maharishi Mahesh Yogi—30 Years Around the World—Dawn of the Age of Enlightenment’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Nov_16.mp4",
          },
          {
            day: 22,
            description:
              "Graduation ceremony of the latest Maharishi Light Therapy with Gems Phase 2 course.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Nov_16.mp4",
          },
          {
            day: 20,
            description:
              "Dr. Peter Swan reads ‘Maharishi Mahesh Yogi—30 Years Around the World—Dawn of the Age of Enlightenment’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Nov_16.mp4",
          },
          {
            day: 17,
            description:
              "Maharishi’s Global News Conference, from 21 May 2003 part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Nov_16.mp4",
          },
          {
            day: 16,
            description:
              "Dr. Bevan Morris reviews his latest travels in many countries.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Nov_16.mp4",
          },
          {
            day: 15,
            description:
              "Interview with Dr. Keith Wallace on the ‘Microbiome’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Nov_16.mp4",
          },
          {
            day: 13,
            description:
              "Dr. Peter Swan reads ‘Maharishi Mahesh Yogi—30 Years Around the World—Dawn of the Age of Enlightenment’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Nov_16.mp4",
          },
          {
            day: 12,
            description:
              "Maharishi explains about collective consciousness governing the country.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Nov_16.mp4",
          },
          {
            day: 11,
            description:
              "Jim Brooks, MD, presents his latest book Reflections on Maharishi AyurVeda and Mental Health. Available at www.mumpress.com",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Nov_16.mp4",
          },
          {
            day: 09,
            description:
              "Interview with Dr. Fred Travis, Director of the Center for Brain, Consciousness and Cognition at Maharishi University of Management, Fairfield IA, USA.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Nov_16.mp4",
          },
          {
            day: 08,
            description:
              "Replay of Maharaja’s inauguration of the Maharishi Peace Palace, UK.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Nov_16.mp4",
          },
          {
            day: 0o7,
            description:
              "Report from Dr. Ashley Deans on the latest achievements of Consciousness-Based Education in schools around the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Nov_16.mp4",
          },
          {
            day: 0o6,
            description:
              "Dr. Peter Swan reads ‘Maharishi Mahesh Yogi—30 Years Around the World—Dawn of the Age of Enlightenment’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Nov_16.mp4",
          },
          {
            day: 0o5,
            description:
              "News about the Enlightened Leadership International (ELI) summit to be held from 9-16 January at MERU Holland. More info.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Nov_16.mp4",
          },
          {
            day: 0o3,
            description:
              "Maharishi’s Global News Conference, from 14 May 2003 part 3.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Nov_16.mp4",
          },
          {
            day: 0o1,
            description:
              "Dr. Peter Swan presents news of increased coherence in countries around the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Nov_16.mp4",
          },
        ],
      },
      {
        monthName: "Dec",
        days: [
          {
            day: 29,
            description:
              "Maharishi’s Global News Conference, from 28 May 2003 part 3.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Dec_16.mp4",
          },
          {
            day: 28,
            description:
              "Steps of growth of the Movement throughout the year in Canada, with Raja Paul.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Dec_16.mp4",
          },
          {
            day: 26,
            description:
              "Steps of growth of the Movement throughout the year in Turkey, with Raja Paul and Dr. Albert Baruh, National Director of Turkey.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Dec_16.mp4",
          },
          {
            day: 25,
            description:
              "Dr. Peter Swan reads, ‘The Flow of Consciousness, Maharishi Mahesh Yogi on Literature and Language’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Dec_16.mp4",
          },
          {
            day: 24,
            description:
              "Steps of growth of the Movement throughout the year, with Dr. Ashley Deans and Raja Kingsley.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Dec_16.mp4",
          },
          {
            day: 23,
            description:
              "Review of news of Invincibility throughout the world for 2016.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Dec_16.mp4",
          },
          {
            day: 22,
            description:
              "Maharishi’s Global News Conference, from 28 May 2003 part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Dec_16.mp4",
          },
          {
            day: 20,
            description: "Latest news from Raja Luis, Raja of Latin America.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Dec_16.mp4",
          },
          {
            day: 19,
            description:
              "Announcing the upcoming Ayurveda Congress, 1-2 April 2017, London. For more information visit: www.internationalayurvedacongress.com.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Dec_16.mp4",
          },
          {
            day: 18,
            description:
              "Dr. Peter Swan reads, ‘The Flow of Consciousness, Maharishi Mahesh Yogi on Literature and Language’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Dec_16.mp4",
          },
          {
            day: 16,
            description:
              "Report on the Inauguration of the first Maharishi Peace Palace in Malaysia.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Dec_16.mp4",
          },
          {
            day: 15,
            description:
              "Maharishi’s Global News Conference, from 28 May 2003 part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Dec_16.mp4",
          },
          {
            day: 14,
            description: "Latest news on the Maharishi Tower of Invincibility.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Dec_16.mp4",
          },
          {
            day: 08,
            description:
              "Maharishi’s Global News Conference, from 21 May 2003 part 3.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Dec_16.mp4",
          },
          {
            day: 0o2,
            description:
              "Raja Peter on the Inauguration of the Maharishi Peace Palace in the UK.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Dec_16.mp4",
          },
          {
            day: 0o1,
            description:
              "Maharishi’s Global News Conference, from 21 May 2003 part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Dec_16.mp4",
          },
        ],
      },
    ],
  },
  // Year 2015
  {
    id: 4,
    year: 2015,
    months: [
      {
        monthName: "Jan",
        days: [
          {
            day: 30,
            description:
              "Raja Peter with achievements in the countries of his domain including the International Leadership Conference held on 12 January 2015 at MERU, Holland",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Jan_15.mp4",
          },
          {
            day: 29,
            description:
              "Maharishi Global Press Conference: 11 September 2002 part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Jan_15.mp4",
          },
          {
            day: 27,
            description:
              "News from Raja Luis with the latest achievements in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Jan_15.mp4",
          },
          {
            day: 25,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Jan_15.mp4",
          },
          {
            day: 23,
            description:
              "News of a great international event: International AyurVeda Congress, 11-12 April 2015 presented by the ministry of Health of the Global Country of World Peace. www.internationalayurvedacongress.com",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Jan_15.mp4",
          },
          {
            day: 21,
            description:
              "Raja Peter gives an overview on the different trends in world affairs.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Jan_15.mp4",
          },
          {
            day: 20,
            description:
              "Birthday celebration of Dr. David Lynch and Col. Gunter Chassé",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Jan_15.mp4",
          },
          {
            day: 19,
            description: "Birthday celebrations of Raja Felix and Raja Bjarne",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Jan_15.mp4",
          },
          {
            day: 18,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Jan_15.mp4",
          },
          {
            day: 16,
            description:
              "Stijn van den Bosch with the accomplishment of 2014 from Belgium, and Raja Willem, Raja of Invincible Holland. Also Larry Chroman with accomplishment in Business and Industries in 2014.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Jan_15.mp4",
          },
          {
            day: 15,
            description: "Maharishi Press Conference: 11 September 2002",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Jan_15.mp4",
          },
          {
            day: 14,
            description:
              "Resolutions of Rajas and Ministers of the Global Country of World Peace: Col. Gunter Chassé, Raja Konhaus, Maharaja’s Address on 12th January 2015",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Jan_15.mp4",
          },
          {
            day: 13,
            description:
              "Raja Peter reviews the achievements in the countries of his domain for 2014 and reflects on the events in the world. Also Dr. Keith Wallace, Raja Bob LoPinto, Raja Stanley, Dr. Richard Beal, Raja Graham, and Dr. William Sam also reported.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Jan_15.mp4",
          },
          {
            day: 09,
            description: "Maharishi Press Conference: 4 September 2002, part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Jan_15.mp4",
          },
        ],
      },
      {
        monthName: "Feb",
        days: [
          {
            day: 27,
            description:
              "Dr. Ashley Deans talks about the history of Maharishi University of Management in Fairfield, IA, USA.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Feb_15.mp4",
          },
          {
            day: 26,
            description:
              "Maharishi’s Press Conference from 25 Sept. 2002 part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Feb_15.mp4",
          },
          {
            day: 24,
            description:
              "Dr Swan reports from India on the second and third day of the International Conference to Re-Establish Ideal Vedic India, New Delhi, that is held from 20-22 February 2015",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Feb_15.mp4",
          },
          {
            day: 21,
            description:
              "Dr Swan reports from India on the first day of the International Conference to Re-Establish Ideal Vedic India, New Delhi, that is held from 20-22 February 2015",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Feb_15.mp4",
          },
          {
            day: 20,
            description:
              "Dr. Ashley Deans’s guest, Vedic Architect Christian Schweizer, presents the architecture of the Maharishi Tower of Invincibility",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Feb_15.mp4",
          },
          {
            day: 19,
            description:
              "Maharishi’s Press Conference from 25 Sept. 2002 part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Feb_15.mp4",
          },
          {
            day: 15,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Feb_15.mp4",
          },
          {
            day: 14,
            description:
              "More news about the Maharishi Tower of Invincibility with Dr. Chris and Ann Crowell.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Feb_15.mp4",
          },
          {
            day: 13,
            description:
              "Dr. Ashley Deans reviews the progress in building the Maharishi Tower of Invincibility and details the exhibition technology that will be used to present Maharishi’s knowledge.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Feb_15.mp4",
          },
          {
            day: 12,
            description:
              "Maharishi’s Press Conference from 18 Sept. 2002 part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Feb_15.mp4",
          },
          {
            day: 08,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Feb_15.mp4",
          },
          {
            day: 0o6,
            description:
              "Latest development on the Maharishi Tower of Invincibility",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Feb_15.mp4",
          },
          {
            day: 0o5,
            description:
              "Maharishi’s Press Conference from 18 Sept. 2002 part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Feb_15.mp4",
          },
          {
            day: 0o2,
            description:
              "Raja Paul video conferencing with guests from all over Canada. Hosted by Dr. Ashley Deans.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Feb_15.mp4",
          },
          {
            day: 0o1,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Feb_15.mp4",
          },
        ],
      },
      {
        monthName: "Mar",
        days: [
          {
            day: 31,
            description:
              "Raja Luis: Latin America is exploding with requests to implement Consciousness-based Education",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_Mar_15.mp4",
          },
          {
            day: 30,
            description:
              "Col. Gunter Chassé and Peter Leadbetter talk about the Invincible Europe Month, from 28 March to 28 April 2015.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Mar_15.mp4",
          },
          {
            day: 29,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Mar_15.mp4",
          },
          {
            day: 20,
            description:
              "Dr Mona Kagi Causemann gives an overview of the Maharishi Vedic Pandit projects in the Brahmasthan of India.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Mar_15.mp4",
          },
          {
            day: 19,
            description:
              "Maharishi’s Press Conference from 9 October 2002 part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Mar_15.mp4",
          },
          {
            day: 18,
            description:
              "International Conference to Re-Establish Ideal Vedic India, 20-22 February, 2015, New Delhi:\nDay 3: Dr. Eike Hartmann, Vedic Architect – Master Plan to Create Ideal Vedic India through the application of Vedic Architecture",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Mar_15.mp4",
          },
          {
            day: 17,
            description:
              "International Conference to Re-Establish Ideal Vedic India, 20-22 February, 2015, New Delhi:\nDay 3: Dr. Neil Paterson – Vedic Administration – Restoring the role of Vedic Pandits in Creating Invincible India and World Peace",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Mar_15.mp4",
          },
          {
            day: 16,
            description:
              "Celebration of Raja Paul Potter’s birthday with Maharishi’s worldwide family.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Mar_15.mp4",
          },
          {
            day: 15,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Mar_15.mp4",
          },
          {
            day: 14,
            description:
              "International Conference to Re-Establish Ideal Vedic India, 20-22 February, 2015, New Delhi:\nDay 2—Dr. John Hagelin (Ph.D. Quantum Physics) – Practical Application of Vedic Science for a Unified Field-Based, Vedic Civilization\nDay 2—Dr. John Fagan (Ph.D. Biochemistry and Molecular Biology) – Vedic Agriculture – The key to empowering Indian farmers to feed the nation and the world",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Mar_15.mp4",
          },
          {
            day: 13,
            description:
              "International Conference to Re-Establish Ideal Vedic India, 20-22 February, 2015, New Delhi:\nDay 2— Dr. Bevan Morris, President, Maharishi University of Management, USA – a model of modern Vedic living in the heart of America",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Mar_15.mp4",
          },
          {
            day: 12,
            description:
              "Maharishi’s Press Conference from 2 October 2002 part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Mar_15.mp4",
          },
          {
            day: 11,
            description:
              "International Conference to Re-Establish Ideal Vedic India, 20-22 February, 2015, New Delhi:\nDay 1— Dr. Michael Dillbeck (Ph.D. Psychology) – Scientific Research on the Physiological, Psychological, and Sociological Effects of the Vedic Technology of Consciousness\nDay 2— Dr. Keith Wallace (Ph.D. Physiology) – Application of Veda in Health Care and Veda and Gene Expression – Scientific research and case studies on treating chronic disease.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Mar_15.mp4",
          },
          {
            day: 10,
            description:
              "Day 3 of the International Conference to Re-Establish Ideal Vedic India, 20-22 February, 2015, New Delhi:\n• Professor Tony Nader (M.D., Ph.D. Brain and Cognitive Science), Maharaja Adhiraj Rajaraam – Re-establishing Vedic India on a Scientific Foundation\n• Honourable Dr. Harsh Vardhan M.D., Minister of Science & Technology of India – Science and Technology in Vedas\n• Dr. John Hagelin (Ph.D. Quantum Physics) – Science of Yagya: Global Transformation through Vedic Recitation",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Mar_15.mp4",
          },
          {
            day: 9,
            description:
              "The Minister of Health of the Global Country of World Peace presents the upcoming International Ayurveda Congress, 11-12 April 2015, Roermond, The Netherlands. Visit the web site.\nAlso Stijn van den Bosch announces the next EU Summit Peace Assembly in Brussels from 19 to 22 March 2015. Contact: Brussels@Maharishi.net. The assembly is free of charge.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Mar_15.mp4",
          },
          {
            day: 7,
            description:
              "International Conference to Re-Establish Ideal Vedic India, 20-22 February, 2015, New Delhi, Day 1, Session 2—Dr. John Hagelin (Ph.D. Quantum Physics): Veda and Physics: The Science and Technology of the Unified Field",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Mar_15.mp4",
          },
          {
            day: 6,
            description:
              "International Conference to Re-Establish Ideal Vedic India, 20-22 February, 2015, New Delhi, Day 1, Session 1—Keynote Address by Professor Tony Nader (M.D., Ph.D. Brain and Cognitive Science), Maharaja Adhiraj Rajaraam: Scientific Reality of Veda – Discovery of Veda as the Source of the Laws of Nature in the Consciousness of Everyone",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Mar_15.mp4",
          },
          {
            day: 3,
            description:
              "Celebration of the birthday of Dr. Bevan Morris, Prime Minister of the Global Country of World Peace",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Mar_15.mp4",
          },
          {
            day: 1,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Mar_15.mp4",
          },
        ],
      },
      {
        monthName: "Apr",
        days: [
          {
            day: 30,
            description:
              "Maharishi’s Press Conference from 23 October 2002 part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Apr_15.mp4",
          },
          {
            day: 26,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Apr_15.mp4",
          },
          {
            day: 23,
            description:
              "Maharishi’s Press Conference from 23 October 2002 part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Apr_15.mp4",
          },
          {
            day: 22,
            description:
              "Raja Peter explains that building Maharishi Peace Palaces in all parts of the world will accelerate the descent of Heaven on Earth. This is a continuation of the Chat on 8 April about the Foundation Stone ceremony for Peace Palace to be built in Suffolk UK (Part 3).",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Apr_15.mp4",
          },
          {
            day: 20,
            description:
              "Col. Gunter Chasse and Peter Leadbetter, deputy ministers of Defence of the Global Country of World Peace with achievements of the Invincible Europe Month project.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Apr_15.mp4",
          },
          {
            day: 19,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Apr_15.mp4",
          },
          {
            day: 18,
            description:
              "Dr Ashley Deans interview Dr Robert Schneider on new Integrative Medicine Program, MD, MS offered by St Martinus University.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Apr_15.mp4",
          },
          {
            day: 17,
            description:
              "Raja Peter explains that building Maharishi Peace Palaces in all parts of the world will accelerate the descent of Heaven on Earth. This is a continuation of the Chat on 8 April about the Foundation Stone ceremony for Peace Palace to be built in Suffolk UK (Part 2). There are delightful videos of the architects as well as audience reaction to this moving event.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Apr_15.mp4",
          },
          {
            day: 16,
            description:
              "Maharishi’s Press Conference from 16 October 2002 part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Apr_15.mp4",
          },
          {
            day: 14,
            description:
              "Dr Bevan Morris introduces Dr Rainer Picha to tell everyone of the success of the International AyurVeda Congress that was held in The Netherlands from 11-12 April 2015",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Apr_15.mp4",
          },
          {
            day: 12,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Apr_15.mp4",
          },
          {
            day: 09,
            description:
              "Maharishi’s Press Conference from 16 October 2002 part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Apr_15.mp4",
          },
          {
            day: 08,
            description:
              "Raja Peter announces the inauguration of a new Maharishi Peace Palace in the UK",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Apr_15.mp4",
          },
          {
            day: 0o5,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Apr_15.mp4",
          },
          {
            day: 0o2,
            description:
              "Maharishi’s Press Conference from 9 October 2002 part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Apr_15.mp4",
          },
          {
            day: 0o1,
            description:
              "Dr. Richard Beal: Principal of the Maharishi School of the Age of Enlightenment, Fairfield, IA.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Apr_15.mp4",
          },
        ],
      },
      {
        monthName: "May",
        days: [
          {
            day: 28,
            description:
              "Maharishi’s Press Conference from 6 November 2002 part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_May_15.mp4",
          },
          {
            day: 27,
            description:
              "Special Vastu presentation by Christian Schweitzer and Eike Hartmann, Ministry of Architecture of the Global Country of World Peace",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_May_15.mp4",
          },
          {
            day: 25,
            description:
              "Part 1: Review of a new book on genetically modified food: ‘Altered Genes, Twisted Truth’ by Steven Druker.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_May_15.mp4",
          },
          {
            day: 24,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_May_15.mp4",
          },
          {
            day: 21,
            description:
              "Maharishi’s Press Conference from 6 November 2002 part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_May_15.mp4",
          },
          {
            day: 18,
            description:
              "Graduation of the fifth Maharishi Yoga Asana Training Course",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_May_15.mp4",
          },
          {
            day: 16,
            description:
              "Report from the graduation for the Transcendental Meditation Teacher Training Course that was held at the Purusha Capital for the Western World, West Virginia, USA",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_May_15.mp4",
          },
          {
            day: 15,
            description:
              "Raja Kingsley with the latest news from Nepal including Maharishi Vedic University campus master plan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_May_15.mp4",
          },
          {
            day: 14,
            description:
              "Maharishi’s Press Conference from 30 October 2002 part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_May_15.mp4",
          },
          {
            day: 12,
            description: "Raja Luis with latest developments in Latin America",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_May_15.mp4",
          },
          {
            day: 11,
            description:
              "News from New Zealand with Dr. Martin Davy, National Director and Dr. David Lovell-Smith, M.D. and Maharishi Ayur-Veda practitioner for 30 years.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_May_15.mp4",
          },
          {
            day: 09,
            description:
              "Conclusion of the Aroma Therapy Consultant Training Course.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_May_15.mp4",
          },
          {
            day: 0o7,
            description:
              "Maharishi’s Press Conference from 30 October 2002 part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_May_15.mp4",
          },
          {
            day: 0o3,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_May_15.mp4",
          },
          {
            day: 0o2,
            description:
              "Dr. Alarik Arenander presents his new course: Become an Exponent of EEG and Enlightenment.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_May_15.mp4",
          },
          {
            day: 0o1,
            description:
              "Dr. Eike Hartmann, Minister of Architecture of the Global Country of World Peace, presents the new master plan of MERU, The Netherlands",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_May_15.mp4",
          },
        ],
      },
      {
        monthName: "June",
        days: [
          {
            day: 29,
            description:
              "Interview with Prof. Lynette Abbott, Vice Dean, Faculty of Natural and Agricultural Sciences, University of Western Australia",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Jun_15.mp4",
          },
          {
            day: 28,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Jun_15.mp4",
          },
          {
            day: 27,
            description:
              "Dr. David Shapiro talks about the National PTSD (Post Traumatic Stress Disorder) Awareness month (June) for the United States and advances a solution to PTSD.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Jun_15.mp4",
          },
          {
            day: 26,
            description:
              "Dr. Felix Kagi, Raja of Switzerland with achievements in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Jun_15.mp4",
          },
          {
            day: 25,
            description:
              "Maharishi’s Press Conference from 20 November 2002 part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Jun_15.mp4",
          },
          {
            day: 24,
            description:
              "Report from Raja Chancellor, Purusha Raja of Universal Domain about the Purusha Capital of the Western World, West Virginia, USA and also on the upcoming Next Generation course.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Jun_15.mp4",
          },
          {
            day: 23,
            description:
              "Continuing report on the first International Yoga Day with Raja Luis, more pictures, and William F. Sands, PhD, Maharishi University of Management, USA.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Jun_15.mp4",
          },
          {
            day: 22,
            description:
              "Dr Girish Momaya shows illustrations of the celebrations of the International Yoga Day starting with celebrations at MERU, Holland, in many Maharishi Invincibility Centers, and in public places throughout the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Jun_15.mp4",
          },
          {
            day: 21,
            description:
              "International Yoga Day celebrations by Maharishi Global Country of World Peace throughout the world with Maharishi Vedic Pandits, and Maharishi’s and Maharaja Adhiraj Rajaraam’s addresses.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Jun_15.mp4",
          },
          {
            day: 20,
            description:
              "Celebrating the International Yoga Day on the 21 June 2015, founded by the United Nations: inspiring address by Maharishi about the Philosophy of Yoga, recorded on 16 August 1971, Humboldt, CA, USA",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Jun_15.mp4",
          },
          {
            day: 18,
            description:
              "Maharishi’s Press Conference from 20 November 2002 part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Jun_15.mp4",
          },
          {
            day: 17,
            description:
              "Dr Peter Swan with a summary of the latest news on Maharishi Vedic Organic Agriculture and Food",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Jun_15.mp4",
          },
          {
            day: 16,
            description:
              "Raja Luis inspires us all with the latest achievements in the countries of his domain including Colombia, Brazil, Bolivia, Paragua, and the upcoming International Yoga Day that will be celebrated throughout the world on the 21th June 2015.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Jun_15.mp4",
          },
          {
            day: 14,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Jun_15.mp4",
          },
          {
            day: 11,
            description:
              "Maharishi’s Press Conference from 13 November 2002 part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Jun_15.mp4",
          },
          {
            day: 09,
            description:
              "Raja Luis gives the latest news from Latin America and an update about the International Yoga Day.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Jun_15.mp4",
          },
          {
            day: 0o7,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Jun_15.mp4",
          },
          {
            day: 0o4,
            description:
              "Maharishi’s Press Conference from 13 November 2002 part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Jun_15.mp4",
          },
          {
            day: 0o3,
            description:
              "Maharishi Light Therapy with Gem course (16 May to 4 June 2015) graduation ceremony",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Jun_15.mp4",
          },
          {
            day: 0o1,
            description:
              "Announcing the next European Union Peace Summit with Dr. Stijn van den Bosch, national director of Belgium, 25-28 June at the Brussel’s Transcendental Meditation Center",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Jun_15.mp4",
          },
        ],
      },
      {
        monthName: "July",
        days: [
          {
            day: 21,
            description:
              "Raja Peter summarizes the latest Total Knowledge Course that was held at Maharishi University of Management, Fairfield, IA, USA with testimonies by many course participants.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Jul_15.mp4",
          },
          {
            day: 20,
            description:
              "Celebration of the birthday of Raja Graham who presented achievements in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Jul_15.mp4",
          },
          {
            day: 19,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Jul_15.mp4",
          },
          {
            day: 18,
            description:
              "Vaidya Vaidya, specialist in Panchakarma, expounds profound principles of Maharishi AyurVeda.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Jul_15.mp4",
          },
          {
            day: 17,
            description:
              "Dr Girish Momaya presents the celebrations of Guru Purnima 2015",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Jul_15.mp4",
          },
          {
            day: 16,
            description:
              "Maharishi’s Press Conference from 27 November 2002 part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Jul_15.mp4",
          },
          {
            day: 15,
            description:
              "Raja Felix announced a new World Peace Assembly in Hvar, Croatia. See the announcement. Nadine Thomas also introduced the Maharishi Aroma Therapy Course that will be given during the assembly.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Jul_15.mp4",
          },
          {
            day: 12,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Jul_15.mp4",
          },
          {
            day: 10,
            description:
              "Special guest: Dr. David Fisher, Founder and Director of the Sustaining Living Programme at Maharishi University of Management, Fairfield, IA, USA.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Jul_15.mp4",
          },
          {
            day: 0o7,
            description:
              "Latest report from Raja Luis with plans and achievements in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Jul_15.mp4",
          },
          {
            day: 0o3,
            description:
              "Prof. Gregory Guthrie, Dean and Professor of Computer Science at Maharishi University of Management, Fairfield, IA, USA",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Jul_15.mp4",
          },
          {
            day: 0o2,
            description:
              "Birthday Celebrations of the birthdays of Raja Luis with talks from Maharaja Ji, Prime Minister, and many Rajas and ministers and also the birthdays of Dr. Susie Swan and Dr. Ole Markmann.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Jul_15.mp4",
          },
        ],
      },
      {
        monthName: "Aug",
        days: [
          {
            day: 30,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Aug_15.mp4",
          },
          {
            day: 27,
            description:
              "Maharishi’s Press Conference from 12 April, 2002 part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Aug_15.mp4",
          },
          {
            day: 25,
            description:
              "Raja Luis introduces special guests from the Caribbean: Dr. Eddie Gob, National Director of Haiti, and a special guest from Haiti, Prof. Josué Merilien.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Aug_15.mp4",
          },
          {
            day: 24,
            description:
              "Raja Harris presents the latest development in the facilities of the Brahmasthan of India and invites everyone to visit. Dominique Lemoine gives a compelling testimony of the benefits of visiting the Brahmasthan of India.\nVisit Maharishi India Courses for more information.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Aug_15.mp4",
          },
          {
            day: 23,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Aug_15.mp4",
          },
          {
            day: 20,
            description:
              "Presentations to the Enlightened Leadership International conference— August 2015, by the Ministry of Agriculture, the Ministry of Communications, and the Ministry of Finance and Planning.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Aug_15.mp4",
          },
          {
            day: 19,
            description:
              "Presentation from the Ministry of Defence of the Global Country of World Peace by Col. Gunter Chassé and from the Ministry of Agriculture with Dr. John Fagan and Raja John Konhaus.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Aug_15.mp4",
          },
          {
            day: 18,
            description:
              "Raja Luis implementing all the beautiful Vedic technologies brought to light by Maharishi to bring enlightenment to every individuals and invincibility to the nations of his domain and to the whole world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Aug_15.mp4",
          },
          {
            day: 16,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Aug_15.mp4",
          },
          {
            day: 15,
            description:
              "Guru Purnima offerings by the Minister of Religion and Culture, Dr. Chris and Ann Crowell. In part 2, is the offering from the Minister of Trade and Commerce, Dr. Larry Chroman, including the VedaApp presented by Purusha Raja Bright.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Aug_15.mp4",
          },
          {
            day: 13,
            description:
              "Guru Purnima offerings by Raja Vikram reviewing the achievements in the countries of his domain including the support for the Maharishi Vedic Pandits in the Brahmasthan of India. Also an inspiring presentation on the dramatically rising positive trends in world consciousness by Raja Bob LoPinto.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Aug_15.mp4",
          },
          {
            day: 12,
            description:
              "Guru Purnima offerings by Raja Bruce reviewing the achievements in the countries of his domain (including Sweden and Malaysia).",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Aug_15.mp4",
          },
          {
            day: 11,
            description:
              "Guru Purnima offerings by Raja Luis, Raja of Latin America, reviewing last year’s achievements in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Aug_15.mp4",
          },
          {
            day: 10,
            description:
              "Guru Purnima offerings by the Ministry of Architecture of the Global Country of World Peace.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Aug_15.mp4",
          },
          {
            day: 09,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Aug_15.mp4",
          },
          {
            day: 08,
            description:
              "Raja Peter reviews the latest TM-Sidhi course in his domain and the construction of the new Peace Palace in the Maharishi Garden Village in Rendlesham, UK.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Aug_15.mp4",
          },
          {
            day: 0o5,
            description:
              "Definition, Vision, and Goals of the Ministry of Administration, with Dr. Neil Paterson",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Aug_15.mp4",
          },
          {
            day: 0o4,
            description:
              "Maharaja Adhiraj Rajaraam’s welcome to the Next Generation leaders.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Aug_15.mp4",
          },
        ],
      },
      {
        monthName: "Sept",
        days: [
          {
            day: 30,
            description:
              "News of rising invincibility from around the world. Announcement for the new Mother Divine courses. Please visit: www.motherdivine.org.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Sep_15.mp4",
          },
          {
            day: 28,
            description:
              "Presentation by Wojtek Skalski on new Maharishi Sthapatya Veda projects in Croatia, Latvia, Turkey, Scotland, Ireland, Poland, Ukraine, Russia, France, and Nepal.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Sep_15.mp4",
          },
          {
            day: 27,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Sep_15.mp4",
          },
          {
            day: 26,
            description:
              "Rotating University Course with the theme: “Discovering Maharishi Vedic India” offered by Maharishi University of Management, Fairfield, IA.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Sep_15.mp4",
          },
          {
            day: 25,
            description:
              "ShoPeace Fund raising programme explained. Visit: https:",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Sep_15.mp4",
          },
          {
            day: 24,
            description:
              "Maharishi’s Global Press Conference from 18 December 2002 – Part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Sep_15.mp4",
          },
          {
            day: 23,
            description:
              "First Next Generation Maharishi’s Global Family Chat. EU Summit Peace Assembly also announced; in part 2 we also have a report from Col. Gunter Chassé, from the Ministry of Defense of the Global Country of World Peace.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Sep_15.mp4",
          },
          {
            day: 22,
            description:
              "Raja Luis reports on increasing coherence in the collective consciousness created by the groups of practitioners of Transcendental Meditation and TM Sidhi programme including Yogic Flying in South America.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Sep_15.mp4",
          },
          {
            day: 21,
            description:
              "International Day of Peace declared by the United Nations celebrated at MERU by invocation for peace by the Maharishi Vedic Pandits.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Sep_15.mp4",
          },
          {
            day: 20,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Sep_15.mp4",
          },
          {
            day: 18,
            description:
              "Raja Antonio, Raja of Invincible Syria, brings solutions for permanent peace in Syria and for the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Sep_15.mp4",
          },
          {
            day: 16,
            description:
              "An address by Maharishi: “Christianity, Transcendental Meditation and Religion”.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Sep_15.mp4",
          },
          {
            day: 15,
            description:
              "CBC’s film of Maharishi at Lake Louise in the Canadian Rockies.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Sep_15.mp4",
          },
          {
            day: 14,
            description:
              "Report from Raja Willem on the projects in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Sep_15.mp4",
          },
          {
            day: 13,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Sep_15.mp4",
          },
          {
            day: 08,
            description:
              "Raja Luis with new developments in the countries of his domain including a report from Dr Rafael de la Puente, National Director of Chile.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Sep_15.mp4",
          },
          {
            day: 0o7,
            description:
              "Raja Harris invites everyone to come and enjoy the unique experience of the Brahmasthan of India. Apply here.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Sep_15.mp4",
          },
          {
            day: 0o4,
            description:
              "Special video: “Maharishi introduces a new element [in the field of education]”.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Sep_15.mp4",
          },
          {
            day: 0o3,
            description: "Maharishi’s Press Conference from 4 December, 2002.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Sep_15.mp4",
          },
          {
            day: 0o2,
            description:
              "A short film from the David Lynch foundation. Inspirational video that can be presented through your local television station.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Sep_15.mp4",
          },
        ],
      },
      {
        monthName: "Oct",
        days: [
          {
            day: 31,
            description:
              "Raja Peter, on the day of concluding his Maharishi Total Knowledge Course at MERU, inspires us all with the latest news on the expanding building projects in the UK including the new Maharishi Peace Palace in Rendlesham, Suffolk, UK.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_Oct_15.mp4",
          },
          {
            day: 28,
            description:
              "Replay of the Victory Day Celebrations of 22 October 2015: Maharishi’s Address from 2005 and Dr. Bevan Morris summary.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Oct_15.mp4",
          },
          {
            day: 27,
            description:
              "Raja Luis with inspiring news from Mexico and the other countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Oct_15.mp4",
          },
          {
            day: 24,
            description:
              "Raja Paul with Dr. Albert Baruh brings out the latest achievements in Turkey.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Oct_15.mp4",
          },
          {
            day: 23,
            description:
              "Review of the Victory Day 2015 Flag Raising ceremonies from around the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Oct_15.mp4",
          },
          {
            day: 12,
            description:
              "Anniversary celebration of the Foundation of the Global Country of World Peace.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Oct_15.mp4",
          },
          {
            day: 11,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Oct_15.mp4",
          },
          {
            day: 10,
            description:
              "Birthday celebration of Dr. Robert Roth, executive director of the David Lynch Foundation, with many guests invited from around the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Oct_15.mp4",
          },
          {
            day: 09,
            description:
              "Discussion on health issues with Dr. Rainer Picha and Vaidya Vaidya. A follow up from the discussion of 5 October 2015.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Oct_15.mp4",
          },
          {
            day: 08,
            description:
              "Maharishi’s Global Press Conference from 25 December 2002 – Part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Oct_15.mp4",
          },
          {
            day: 0o7,
            description:
              "Maharishi’s Inauguration of the Global Country of World Peace on the 7th October 2000 with commentary by Dr. Bevan Morris, Prime Minister of the Global Country of World Peace. Also a ‘Maharishi‘Next Generation Chat’ video clips news. Follow on Twitter: @tmlive",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Oct_15.mp4",
          },
          {
            day: 0o6,
            description:
              "Raja Luis with news of rising invincibility in Latin America.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Oct_15.mp4",
          },
          {
            day: 0o5,
            description:
              "Study on High Blood Pressure explained by Dr Rainer Picha, Minister of Health of the Global Country of World Peace and Vaidya Vaidya.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Oct_15.mp4",
          },
          {
            day: 0o4,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Oct_15.mp4",
          },
          {
            day: 0o3,
            description:
              "Raja Peter on his up-coming Total Knowledge Course at MERU Holland and news from around the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Oct_15.mp4",
          },
          {
            day: 0o1,
            description:
              "Maharishi’s Global Press Conference from 18 December 2002 – Part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Oct_15.mp4",
          },
        ],
      },
      {
        monthName: "Nov",
        days: [
          {
            day: 29,
            description:
              "Dr Peter Swan finished reading Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Nov_15.mp4",
          },
          {
            day: 28,
            description:
              "Summary of the EU Summit Peace Assembly in Brussels by Stijn vandenbosch, National Leader of Belgium.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Nov_15.mp4",
          },
          {
            day: 27,
            description: "Health recommendation by Vaidya Vyas.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Nov_15.mp4",
          },
          {
            day: 26,
            description:
              "Maharishi’s Global News Conference from 22 January 2003 – Part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Nov_15.mp4",
          },
          {
            day: 25,
            description:
              "Maharaja Adhiraj Rajaraam’s Address to the Yogic Flyers of Europe on the 17th November 2015.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Nov_15.mp4",
          },
          {
            day: 24,
            description:
              "Using Maharishi’s technologies to create harmony at this time: Maharishi National Yagyas—Visit the donation form.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Nov_15.mp4",
          },
          {
            day: 21,
            description:
              "Report from Dr. Robert Roth, executive director of the David Lynch Foundation.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Nov_15.mp4",
          },
          {
            day: 20,
            description: "Health recommendation by Vaidya Vyas.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Nov_15.mp4",
          },
          {
            day: 19,
            description:
              "Maharishi’s Global Press Conference from 15 January 2003 – Part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Nov_15.mp4",
          },
          {
            day: 18,
            description: "Maharishi Next Generation Chat",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Nov_15.mp4",
          },
          {
            day: 15,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Nov_15.mp4",
          },
          {
            day: 14,
            description:
              "Maharaja Adhiraj Rajaraam gave a special address about the present world situation and offered practical solutions to increase coherence and peace in the world. Also a report from Dr. Bobby Roth about the latest David Lynch’s Transcendental Meditation Benefit Concert at the New York’s Carnegie Hall.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Nov_15.mp4",
          },
          {
            day: 13,
            description:
              "Dr Fred Travis and Dr Harald S. Harung present the latest researches in Mind-Brain Development at Maharishi University of Management, Fairfield, IA, USA.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Nov_15.mp4",
          },
          {
            day: 12,
            description:
              "Maharishi’s Global Press Conference from 15 January 2003 – Part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Nov_15.mp4",
          },
          {
            day: 08,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Nov_15.mp4",
          },
          {
            day: 0o6,
            description:
              "Dr. Rainer Picha, MD, Cardiologist, Minister of Health of the Global Country of World Peace, explains the role fats and sugar play in a healthy diet.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Nov_15.mp4",
          },
          {
            day: 0o5,
            description:
              "Maharishi’s Global Press Conference from 25 December 2002 – Part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Nov_15.mp4",
          },
          {
            day: 0o4,
            description:
              "Next generation group reports on the ‘Next Generation Chat’. Replay of the interview with Bobby Roth on Fox News.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Nov_15.mp4",
          },
          {
            day: 0o3,
            description:
              "Raja Luis with inspiring news from Latin America and the other countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Nov_15.mp4",
          },
          {
            day: 0o2,
            description:
              "Raja Wynne, Mayor of Maharishi Vedic City, presents the latest achievements in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Nov_15.mp4",
          },
          {
            day: 0o1,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Nov_15.mp4",
          },
        ],
      },
      {
        monthName: "Dec",
        days: [
          {
            day: 31,
            description: "Evening  Going into silence.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_Dec_15.mp4",
          },
          {
            day: 31,
            description:
              "Afternoon  Yearly achievements for Rajas and Ministers.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_Dec_15.mp4",
          },
          {
            day: 30,
            description:
              "Evening  Yearly achievements for Rajas and Ministers.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Dec_15.mp4",
          },
          {
            day: 30,
            description:
              "Afternoon  Yearly achievements for Rajas and Ministers.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Dec_15.mp4",
          },
          {
            day: 28,
            description:
              "Raja Paul invites several guests to talk about the upcoming course “Sixth Annual Global Winter World Peace Assembly” that will take place in Antalya, Turkey starting January 16th, 2016. Visit site.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Dec_15.mp4",
          },
          {
            day: 27,
            description:
              "Dr. Peter Swan reads Maharaja’s paper on Mathematics: “Consciousness Is All there Is” by Tony Nader, Md, PhD, Maharaja Adhiraj Rajaraam.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Dec_15.mp4",
          },
          {
            day: 26,
            description:
              "A tour of the Maharishi University Institute, Johannesburg, South Africa with Dr. Richard Peycke",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Dec_15.mp4",
          },
          {
            day: 25,
            description: "Dr Picha gives health-related advices for everyone.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Dec_15.mp4",
          },
          {
            day: 24,
            description: "Maharishi Next Generation Chat programme",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Dec_15.mp4",
          },
          {
            day: 20,
            description:
              "Dr. Peter Swan reads Maharaja’s paper on Mathematics: “Consciousness Is All there Is” by Tony Nader, Md, PhD, Maharaja Adhiraj Rajaraam",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Dec_15.mp4",
          },
          {
            day: 17,
            description:
              "Maharishi’s Global News Conference from 29 January 2003 – Part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Dec_15.mp4",
          },
          {
            day: 15,
            description:
              "Latest report from Raja Luis about the achievements in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Dec_15.mp4",
          },
          {
            day: 13,
            description:
              "Dr. Peter Swan reads Maharaja’s paper on Mathematics: “Consciousness Is All there Is” by Tony Nader, Md, PhD, Maharaja Adhiraj Rajaraam",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Dec_15.mp4",
          },
          {
            day: 12,
            description:
              "Reports by Raja Graham on the achievements in the countries of his domain, particularly in Africa. Announcing the upcoming Global Winter Assembly at MERU, and reviews of the latest signs of rising invincibility in the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Dec_15.mp4",
          },
          {
            day: 11,
            description:
              "Announcing the reading for next Sunday (13th December) of Maharaja’s paper on Mathematics: “Consciousness Is All there Is” by Tony Nader, Md, PhD, Maharaja Adhiraj Rajaraam. Review of the newer edition of the book “The Supreme Awakening” by Craig Pearson, PhD.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Dec_15.mp4",
          },
          {
            day: 10,
            description:
              "Maharishi’s Global News Conference from 22 January 2003 – Part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Dec_15.mp4",
          },
          {
            day: 09,
            description:
              "Giving Peace A Chance”. Dr. Swan gives a summary of the influence of Peace in the world by large groups Yogic Flyers, and the influence of Yagyas performed by Maharishi’s Pandits in the Brahmasthan of India. Please visit: vedicpandits.org",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Dec_15.mp4",
          },
          {
            day: 0o4,
            description:
              "Graduation of the 14th Maharishi Light Therapy with Gems course.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Dec_15.mp4",
          },
          {
            day: 0o3,
            description:
              "Birthday Celebration of Raja Chancellor, Raja of Universal Domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Dec_15.mp4",
          },
          {
            day: 0o2,
            description: "Maharishi Next Generation Chat",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Dec_15.mp4",
          },
        ],
      },
    ],
  },
  // Year 2014
  {
    id: 3,
    year: 2014,
    months: [
      {
        monthName: "Jan",
        days: [
          {
            day: 31,
            description:
              "Announcing the new site for Maharishi Yagya: www.specialyagya.org where one can order yagyas for birthday, wedding, anniversary, or newborn child. Dr. Bill Sands announces his new book: Maharishi Yoga: The Royal Path to Enlightenment.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_Jan_14.mp4",
          },
          {
            day: 29,
            description:
              "Jon Lipman presents achievements from his last trip to China.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Jan_14.mp4",
          },
          {
            day: 28,
            description:
              "Raja Chancellor with new developmenet at the Purusha Capital of the Western World in West Virginia, USA.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Jan_14.mp4",
          },
          {
            day: 27,
            description:
              "Col. retd. Gunter Chassé with inspiring news about the rise of Invincibility around the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Jan_14.mp4",
          },
          {
            day: 24,
            description:
              "Raja Wynne with achievements in the countries of his domain with Raj Rajeshwari of Pakistan, Dr. Kumar.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Jan_14.mp4",
          },
          {
            day: 22,
            description:
              "Raja John Fagan has started a project in Nepal to teach farmers to grow organic medicinal herbs and thereby improve their income and protect the environment.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Jan_14.mp4",
          },
          {
            day: 20,
            description: "Celebration of Dr. David Lynch’s birthday.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Jan_14.mp4",
          },
          {
            day: 19,
            description:
              "Reading “Maharishi's Absolute Theory of Government” by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Jan_14.mp4",
          },
          {
            day: 16,
            description:
              "Raja Chancellor and Raja Rafael recounts Maharaja Adhiraj Rajaraam's latest tour on the Ramayan in many countries",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Jan_14.mp4",
          },
          {
            day: 14,
            description:
              "12 January 2014 Continuation with the resolutions of the Rajas for the new year with Raja Emanuel and Raja Luis",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Jan_14.mp4",
          },
          {
            day: 13,
            description:
              "Continuation of January 12 celebrations with achievements by Rajas and global Ministers.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Jan_14.mp4",
          },
          {
            day: 7,
            description: "Coming out of silence.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Jan_14.mp4",
          },
        ],
      },
      {
        monthName: "Feb",
        days: [
          {
            day: 28,
            description:
              "Dr Bevan Morris made a presentation of the various Vastu projects around the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Feb_14.mp4",
          },
          {
            day: 26,
            description:
              "Dr. Branco Cicic, National Director of Serbia, with news on organic farming in Serbia.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Feb_14.mp4",
          },
          {
            day: 25,
            description:
              "News from Los Angeles, USA with Mario Orsatti, Tribute to Ringo Star and Raja Luis with new projects in Brasil and South America",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Feb_14.mp4",
          },
          {
            day: 23,
            description:
              "Reading “Maharishi's Absolute Theory of Government” by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Feb_14.mp4",
          },
          {
            day: 21,
            description:
              "Raja John Konhaus with achievements in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Feb_14.mp4",
          },
          {
            day: 19,
            description:
              "New charitable organization: African PTSD (Post Traumatic Stress Disorder) Relief has been established in Africa partening with the David Lynch Foundation. Interview with the founding President David Shapiro.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Feb_14.mp4",
          },
          {
            day: 17,
            description:
              "Dr. Ken Chandler, distinguished Visiting Professor at Maharishi University of Management, USA, presents Veda and Greece.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Feb_14.mp4",
          },
          {
            day: 16,
            description:
              "Reading “Maharishi's Absolute Theory of Government” by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Feb_14.mp4",
          },
          {
            day: 15,
            description:
              "New course developed by Dr. Chris and Ann Crowell, Minister of Cultural Integrity and Religion of the Global Country of World Peace: Culture, Consciousness, and Natural Law: Living Unity in the Field of Diversity. See the course details HERE.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Feb_14.mp4",
          },
          {
            day: 12,
            description:
              "Dr Girish Momaya presents the upcoming courses at MERU, Holland including the 16-hour short courses, Maharishi Gandharva Veda course, and others—PART 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Feb_14.mp4",
          },
          {
            day: 11,
            description:
              "David Lynch Foundation video 5th Annual Change Begins Within 3 Dec. at New York",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Feb_14.mp4",
          },
          {
            day: 10,
            description:
              "Dr Girish Momaya presents the upcoming courses at MERU, Holland including the 16-hour short courses, Maharishi Gandharva Veda course, and others—PART 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Feb_14.mp4",
          },
          {
            day: 7,
            description:
              "Raja Paul Potter reports on the success of the last Assembly to Create Peace in the Middle-East in Antalya, Turkey",
            videoSrc: "http://streaming.mou.org/MOU2/chat/7_Feb_14.mp4",
          },
          {
            day: 5,
            description:
              "Stress-free school project in Italy attended by David Lynch organized by Roberto Baitelli, director of communication for Italy.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/5_Feb_14.mp4",
          },
          {
            day: 2,
            description:
              "Reading “Maharishi's Absolute Theory of Government” by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/2_Feb_14.mp4",
          },
        ],
      },
      {
        monthName: "Mar",
        days: [
          {
            day: 30,
            description:
              "Reading “Maharishi's Absolute Theory of Government” by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Mar_14.mp4",
          },
          {
            day: 29,
            description:
              "Dr Alarik Arenander presents his up-coming course: ‘Become an Exponent of EEG and Enlightenment’ that will be held during the Guru Purnima Celebrations at MERU.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Mar_14.mp4",
          },
          {
            day: 28,
            description: "Presentation of Maharishi Ayur-Veda by Vaidya Garge.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Mar_14.mp4",
          },
          {
            day: 26,
            description:
              "Report from the Brahmasthan of India with Raja Michael, Raja of France and Dominique Lemoine, national director of France",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Mar_14.mp4",
          },
          {
            day: 25,
            description:
              "Raja Luis short message about Venezuela. Replay of Dr Craig Pearson, Executive Vice-President of Maharishi University of Management, Fairfield, IA presenting his new book",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Mar_14.mp4",
          },
          {
            day: 24,
            description:
              "Celebration of the birthday of Raja Emanuel, Raja of Germany with inspiring news of rising invincibiliby for Germany.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Mar_14.mp4",
          },
          {
            day: 23,
            description:
              "Reading “Maharishi's Absolute Theory of Government” by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Mar_14.mp4",
          },
          {
            day: 22,
            description: "News on Russian",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Mar_14.mp4",
          },
          {
            day: 19,
            description:
              "Dr Craig Pearson, Executive Vice-President of Maharishi University of Management, Fairfield, IA presents his new book: The Supreme Awakening: Experiences of Enlightenment throughout Time and How you Can Culture Them.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Mar_14.mp4",
          },
          {
            day: 18,
            description:
              "Dr. Andrew Bargerstock with interesting news on the Master Business Administration (MBA) programme at Maharishi University of Management, Fairfield, IA, USA.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Mar_14.mp4",
          },
          {
            day: 16,
            description:
              "Reading “Maharishi's Absolute Theory of Government” by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Mar_14.mp4",
          },
          {
            day: 10,
            description:
              "Dr Stijn Vandenbosch and Dr Joachim Claes with News and Progress from Belgium",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Mar_14.mp4",
          },
          {
            day: 09,
            description:
              "Reading “Maharishi's Absolute Theory of Government” by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Mar_14.mp4",
          },
          {
            day: 0o7,
            description:
              "Maharishi Ayur-Veda—its revival by Maharishi by connecting the knowledge of health with the knowledge of totality, with Dr. Walter Moek.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Mar_14.mp4",
          },
          {
            day: 0o5,
            description:
              "News from the Russia-Ukraine situation and overview by Dr. Swan, with commentaries by Raja Bjarne and Raja Kingsley bringing out the solution offered by the Global Country of World Peace to bring peace to the region",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Mar_14.mp4",
          },
          {
            day: 0o3,
            description:
              "Celebration of Dr. Bevan Morris’s birthday, Prime Minister of the Global Country of World Peace, with international guests.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Mar_14.mp4",
          },
          {
            day: 0o2,
            description:
              "Reading “Maharishi's Absolute Theory of Government” by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Mar_14.mp4",
          },
          {
            day: 0o1,
            description:
              "Foundations of Physics and Consciousness by Dr. John Hagelin. Video presenting his online course. To learn more and register for the course, click HERE.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Mar_14.mp4",
          },
        ],
      },
      {
        monthName: "Apr",
        days: [
          {
            day: 27,
            description:
              "Reading “Maharishi's Absolute Theory of Government” by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Apr_14.mp4",
          },
          {
            day: 23,
            description:
              "Dr. Peter and Dr. Susie Swan on their latest trip in Serbia, presenting Maharishi Vedic Organic Agriculture and inspiring everyone about the Maharishi Vedic Pandits project in the Brahmasthan of India",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Apr_14.mp4",
          },
          {
            day: 21,
            description:
              "Raja Graham de Freitas with news of achievements in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Apr_14.mp4",
          },
          {
            day: 13,
            description:
              "Reading “Maharishi's Absolute Theory of Government” by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Apr_14.mp4",
          },
          {
            day: 11,
            description:
              "Raja Peter with inspiring news from the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Apr_14.mp4",
          },
          {
            day: 09,
            description:
              "Raja Antonio with inspiring news from the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Apr_14.mp4",
          },
        ],
      },
      {
        monthName: "May",
        days: [
          {
            day: 31,
            description:
              "Birthday celebration of Dr. Ashley Deans, International Ambassador for Consciousness-Based Education.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_May_14.mp4",
          },
          {
            day: 30,
            description:
              "Maharishi University of Management, Fairfield, IA, USA Graduation Speech by Jim Carrey on 24th May 2014.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_May_14.mp4",
          },
          {
            day: 29,
            description:
              "Dr Girish Momaya presents the up-coming course for Guru Purnima 2014 at MERU, Holland",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_May_14.mp4",
          },
          {
            day: 27,
            description:
              "News from USA with Adam Pressman and Mario Ursatti. Also latest inspiring news from Raja Luis.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_May_14.mp4",
          },
          {
            day: 26,
            description:
              "Dr Peter Swan on sustainable energy and good news from the web site www.globalgoodnews.com.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_May_14.mp4",
          },
          {
            day: 25,
            description:
              "Starting reading “Maharishi' Vedic University—Introduction”, a book by Maharishi Mahesh Yogi. Read by Dr. Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_May_14.mp4",
          },
          {
            day: 24,
            description:
              "Raja Kingsley presents the up-coming Enlightenment Leadership International course.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_May_14.mp4",
          },
          {
            day: 23,
            description:
              "Prof. Anil Maheshwari, Professor of Maharishi University of Management, USA, presenting his new book on data mining.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_May_14.mp4",
          },
          {
            day: 19,
            description:
              "Fred Travis, PhD, Director, Center for Brain, Consciousness and Cognition, Maharishi University of Management, USA, presenting his latest research: Transcendental Meditation, Listening to Vedic Recitation and Optimal Brain Functioning.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_May_14.mp4",
          },
          {
            day: 18,
            description:
              "Reading “Maharishi's Absolute Theory of Government” by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_May_14.mp4",
          },
          {
            day: 17,
            description:
              "Raja Ior with news on the upcoming course at Delphi, Greece with Alberto Castano. For more information please contact: t.pizanis@tm.org",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_May_14.mp4",
          },
          {
            day: 16,
            description:
              "Raja Felix with achievements in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_May_14.mp4",
          },
          {
            day: 13,
            description:
              "Birthday celebration of Father Gabriel Mejia, the saint of Colombia, with translation of his speech to educators by Raja Luis",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_May_14.mp4",
          },
          {
            day: 11,
            description:
              "Reading “Maharishi's Absolute Theory of Government” by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_May_14.mp4",
          },
          {
            day: 10,
            description:
              "Flag raising ceremonies around the world hosted by Dr. Peter Swan, part 3. GMO news update by Dr. Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_May_14.mp4",
          },
          {
            day: 09,
            description:
              "Dr. Eckart Stein presents the achievements at the first German Peace Palace at Erfurt, near the center of Germany",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_May_14.mp4",
          },
          {
            day: 0o7,
            description:
              "Update on the new video building construction at MERU and also from Raja Kingsley on his trip to Ukraine.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_May_14.mp4",
          },
          {
            day: 0o5,
            description:
              "Flag raising ceremonies around the world hosted by Dr. Peter Swan, part 2, including India.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_May_14.mp4",
          },
          {
            day: 0o4,
            description:
              "Reading “Maharishi's Absolute Theory of Government” by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_May_14.mp4",
          },
          {
            day: 0o3,
            description:
              "Flag raising ceremonies around the world hosted by Dr. Bevan Morris, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_May_14.mp4",
          },
          {
            day: 0o1,
            description: "Dr Bevan Morris on his latest trips in Africa",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_May_14.mp4",
          },
        ],
      },
      {
        monthName: "June",
        days: [
          {
            day: 30,
            description:
              "Graduation ceremony of the Ligth Therapy with Gem course with course leaders and course participants.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Jun_14.mp4",
          },
          {
            day: 28,
            description:
              "Completion ceremony of the post-graduate training course on Maharishi Vedic Architecture, hosted by Dr Eike Hartmann",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Jun_14.mp4",
          },
          {
            day: 22,
            description:
              "“Maharishi' Vedic University—Introduction”, a book by Maharishi Mahesh Yogi. Read by Dr. Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Jun_14.mp4",
          },
          {
            day: 17,
            description:
              "Standard Transcendental Meditation Introductory Talk video by Dr. Robert Roth, USA.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Jun_14.mp4",
          },
          {
            day: 16,
            description:
              "Raja Mariano, Raja of Italy, with achievements in his domain including the new Marketing campaign.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Jun_14.mp4",
          },
          {
            day: 15,
            description:
              "“Maharishi' Vedic University—Introduction”, a book by Maharishi Mahesh Yogi. Read by Dr. Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Jun_14.mp4",
          },
          {
            day: 14,
            description:
              "Maharishi video tape from 23rd Nov 1971—State of Unity",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Jun_14.mp4",
          },
          {
            day: 13,
            description:
              "Raja Peter with latest news from the countries of his domain",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Jun_14.mp4",
          },
          {
            day: 11,
            description:
              "Maharishi University of Management, USA Graduation Celebration 24th May 2014 – Part 4",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Jun_14.mp4",
          },
          {
            day: 10,
            description:
              "Maharishi University of World Peace Doctoral Award giving ceremony to Brazilian Congressman Alex Canziania Silveira with Raja Luis and Dr. Volker Schanbacher.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Jun_14.mp4",
          },
          {
            day: 09,
            description:
              "Maharishi University of Management, USA Graduation Celebration 24th May 2014 – Part 3",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Jun_14.mp4",
          },
          {
            day: 08,
            description:
              "“Maharishi' Vedic University—Introduction”, a book by Maharishi Mahesh Yogi. Read by Dr. Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Jun_14.mp4",
          },
          {
            day: 0o7,
            description:
              "Maharishi University of Management, USA Graduation Celebration 24th May 2014 – Part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Jun_14.mp4",
          },
          {
            day: 0o6,
            description:
              "Maharishi University of Management, USA Graduation Celebration 24th May 2014 – Part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Jun_14.mp4",
          },
          {
            day: 0o4,
            description:
              "News from Belgium with Stijn Vandenbosch, National Director of Belgium",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Jun_14.mp4",
          },
          {
            day: 0o3,
            description:
              "Scientific research on the Transcendental Meditation technique with Dr. John Hagelin, PhD, Raja of the United States of America.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Jun_14.mp4",
          },
          {
            day: 0o2,
            description:
              "Inauguration of the new green house at MERU, Holland, for implementing a sample of Maharishi Vedic Organic Agriculture, and news from Japan with Raja Konhaus.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Jun_14.mp4",
          },
          {
            day: 0o1,
            description:
              "“Maharishi' Vedic University—Introduction”, a book by Maharishi Mahesh Yogi. Read by Dr. Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Jun_14.mp4",
          },
        ],
      },
      {
        monthName: "July",
        days: [
          {
            day: 30,
            description: "Good news from Cambodia in the field of education.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Jul_14.mp4",
          },
          {
            day: 29,
            description:
              "Video clip from actor Russel Brand at a David Lynch Foundation event; announcing the next Maharishi Aroma Therapy training course, and news from Latin America with Raja Luis",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Jul_14.mp4",
          },
          {
            day: 28,
            description:
              "Dr Anand Shrivastava gives an overview of Maharishi AyurVeda",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Jul_14.mp4",
          },
          {
            day: 27,
            description:
              "“Maharishi' Vedic University—Introduction”, a book by Maharishi Mahesh Yogi. Read by Dr. Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Jul_14.mp4",
          },
          {
            day: 25,
            description:
              "Raja Kingsley presents the achievements in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Jul_14.mp4",
          },
          {
            day: 24,
            description:
              "Presentation of the next International Ayurveda Conference next April 2014 at MERU. For more information please visit: www.meruevents.com",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Jul_14.mp4",
          },
          {
            day: 21,
            description:
              "Guru Purnima Celebration continued with wishes from the National Director of Ireland, Dr. Joe Hayden presenting the first Peace Palace of Ireland on the Island of World Peace. Visit www.islandofworldpeace.com",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Jul_14.mp4",
          },
          {
            day: 20,
            description:
              "“Maharishi' Vedic University—Introduction”, a book by Maharishi Mahesh Yogi. Read by Dr. Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Jul_14.mp4",
          },
          {
            day: 19,
            description:
              "Graduation ceremony of the course “EEG and Enlightenment” with Dr. Cynthia and Alarik Arenander",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Jul_14.mp4",
          },
          {
            day: 18,
            description:
              "Guru Purnima Celebration continued with wishes from Raja Vikram Aditya, Raja of Invincible Siam (Thailand) and short video about the inauguration of the new video buidling at MERU",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Jul_14.mp4",
          },
          {
            day: 17,
            description:
              "Guru Purnima Celebration continued with wishes from Suzuki san, National Director of Japan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Jul_14.mp4",
          },
          {
            day: 16,
            description:
              "Guru Purnima Celebration continued with wishes from Prince Blucher",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Jul_14.mp4",
          },
          {
            day: 15,
            description:
              "Guru Purnima Celebration continued with wishes from Raja Emanuel and Dr. Chris Crowell",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Jul_14.mp4",
          },
          {
            day: 14,
            description:
              "Guru Purnima Celebration continued with wishes from the Rajas",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Jul_14.mp4",
          },
          {
            day: 13,
            description:
              "Guru Purnima Celebration continued with wishes from the Rajas",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Jul_14.mp4",
          },
          {
            day: 12,
            description:
              "Guru Purnima Celebration continued with wishes from the Rajas",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Jul_14.mp4",
          },
          {
            day: 0o7,
            description:
              "Inauguration of the National Director Assembly for Guru Purnima 2014",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Jul_14.mp4",
          },
          {
            day: 0o2,
            description:
              "Celebration of the birthdays of Raja Luis, Raja of Latin America and Dr. Susie Swan, co-director of ARO.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Jul_14.mp4",
          },
          {
            day: 0o1,
            description:
              "Report from Raja Luis and from a visit that Dr. Swan made in Kiev, Ukraine where he gave a course on Maharishi Vedic Agriculture to the Invincible Ukraine Assembly.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Jul_14.mp4",
          },
        ],
      },
      {
        monthName: "Aug",
        days: [
          {
            day: 31,
            description:
              "“Maharishi' Vedic University—Introduction”, a book by Maharishi Mahesh Yogi. Read by Dr. Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_Aug_14.mp4",
          },
          {
            day: 30,
            description:
              "First Maharishi Global Family Chat from the new perfect Maharishi Vastu building. Raja Felix with achievements in his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Aug_14.mp4",
          },
          {
            day: 16,
            description:
              "Dr Bevan Morris’s trip to UK and Ireland including the Island of World Peace.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Aug_14.mp4",
          },
          {
            day: 15,
            description: "Interview with Vaidya Pratmesh Vyas Ji.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Aug_14.mp4",
          },
          {
            day: 14,
            description:
              "Weekly Maharishi’s Global Press Conference, Wednesday, 3 July 2002—Part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Aug_14.mp4",
          },
          {
            day: 10,
            description:
              "“Maharishi' Vedic University—Introduction”, a book by Maharishi Mahesh Yogi. Read by Dr. Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Aug_14.mp4",
          },
          {
            day: 09,
            description:
              "E-Books and printed books available on the Maharishi India Courses; David Lynch short video about Consciousness, Creativity and the Brain; and the announcement of the next Maharishi Aroma Therapy Course.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Aug_14.mp4",
          },
          {
            day: 08,
            description:
              "Part of the 11th Conference of the World Association of Vedic Studies (WAVES) conference hosted at Maharishi University of Management, Fairfield, USA from 31 July to 3 August 2014. This conference was also addressed by Dr. Alarik and Cynthia Arenander. Here we will see their recorded address and they will be commenting on it live from the Gold Room at Seelisberg, Switzerland.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Aug_14.mp4",
          },
          {
            day: 0o7,
            description:
              "Maharishi’s Maharishi’s World Press Conferences from 3 July 2002—Part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Aug_14.mp4",
          },
          {
            day: 0o6,
            description:
              "‘Training to be an Expert in Bringing Consciousness-Based Education to your Nation’ graduation ceremony.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Aug_14.mp4",
          },
          {
            day: 0o5,
            description:
              "Raja Luis with success in the countries of his domain including sustainable and renewal uses of energy.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Aug_14.mp4",
          },
          {
            day: 0o3,
            description:
              "“Maharishi' Vedic University—Introduction”, a book by Maharishi Mahesh Yogi. Read by Dr. Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Aug_14.mp4",
          },
        ],
      },
      {
        monthName: "Sept",
        days: [
          {
            day: 22,
            description:
              "Presentation of the Maharishi Island of World Peace in Ireland and its new Maharishi Peace Palace with special guest Dr. Michael Gallmeier and the national director of Ireland, Joe Hayden.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Sep_14.mp4",
          },
          {
            day: 21,
            description:
              "“Maharishi' Vedic University—Introduction”, a book by Maharishi Mahesh Yogi. Read by Dr. Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Sep_14.mp4",
          },
          {
            day: 20,
            description:
              "Dr. Girish Momaya introduces the presentation by Dr. Neil Paterson, delivered at the WAVES conference, 31 July - 3 August, at Maharishi University of Management, USA",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Sep_14.mp4",
          },
          {
            day: 19,
            description:
              "Replay of Raja Luis’ new plan to raise Latin America to Invincibility with the creation of 50,000 Maharishi Yogic Flyers.\nVisit: www.mum.edu",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Sep_14.mp4",
          },
          {
            day: 18,
            description:
              "Weekly Maharishi’s Global Press Conference, from Wednesday, 17 July 2002",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Sep_14.mp4",
          },
          {
            day: 16,
            description:
              "Raja Luis’ new plan to raise Latin America to Invincibility with the creation of 50,000 Maharishi Yogic Flyers.\nVisit: www.mum.edu",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Sep_14.mp4",
          },
          {
            day: 15,
            description:
              "Dr. Girish Momaya present his keynote lecture delivered at the WAVES conference, 31 July - 3 August, at Maharishi University of Management, USA, entitled: “VEDANTA – Human Life in Perfect and Complete Alliance with Natural Law”",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Sep_14.mp4",
          },
          {
            day: 14,
            description:
              "“Maharishi' Vedic University—Introduction”, a book by Maharishi Mahesh Yogi. Read by Dr. Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Sep_14.mp4",
          },
          {
            day: 11,
            description:
              "Weekly Maharishi’s Global Press Conference, from Wednesday, 10 July 2002, part 1, section 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Sep_14.mp4",
          },
          {
            day: 0o7,
            description:
              "“Maharishi' Vedic University—Introduction”, a book by Maharishi Mahesh Yogi. Read by Dr. Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Sep_14.mp4",
          },
          {
            day: 0o5,
            description:
              "Raja Harris invites everyone to attend courses at the Brahmasthan of India. See online: www.maharishiindiacourses.com",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Sep_14.mp4",
          },
          {
            day: 0o4,
            description:
              "Weekly Maharishi’s Global Press Conference, from Wednesday, 10 July 2002, part 1, section 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Sep_14.mp4",
          },
          {
            day: 0o3,
            description:
              "Dr. Girish Momaya reviews the upcoming MERU courses at MERU Capital of the Age of Enlightenment, Holland. www.merucourses.com",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Sep_14.mp4",
          },
          {
            day: 0o2,
            description:
              "First presentation of the WAVE conference (World Association of Vedic Studies) held at Maharishi University of Management, Fairfield, USA: Presentation by Dr. John Hagelin, Prof. of Physics, Maharishi University of Management, USA. In the second part, Raja Luis presented achievements in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Sep_14.mp4",
          },
          {
            day: 0o1,
            description:
              "Col. Gunter Chassé and his achievements in the domain of defence around the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Sep_14.mp4",
          },
        ],
      },
      {
        monthName: "Oct",
        days: [
          {
            day: 30,
            description:
              "Weekly Maharishi’s Global Press Conference, from Wednesday, 24 July 2002 - Part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Oct_14.mp4",
          },
          {
            day: 26,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001—Vedic Defence part.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Oct_14.mp4",
          },
          {
            day: 24,
            description:
              "Report from the Maharishi School, in Lelystad in The Netherlands.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Oct_14.mp4",
          },
          {
            day: 19,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Oct_14.mp4",
          },
          {
            day: 18,
            description:
              "More reports of participants to the Next Generation Course that was held from 25 to 28 September 2014 at Maharishi Vedic City, USA.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Oct_14.mp4",
          },
          {
            day: 16,
            description:
              "Weekly Maharishi’s Global Press Conference, from Wednesday, 24 July 2002 - Part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Oct_14.mp4",
          },
          {
            day: 15,
            description:
              "Flag raising ceremony at Maharishi Vedic City, USA and lecture by Maharaja Adhiraj Rajaraam summarising the Next Generation Course that was held from 25 to 28 September 2014 at Maharishi Vedic City, USA, with commentaries by Joachim Claes.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Oct_14.mp4",
          },
          {
            day: 14,
            description:
              "News from Raja Luis about the 50,000 Yogic Flying Project in the countries of his domain",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Oct_14.mp4",
          },
          {
            day: 13,
            description:
              "Conference of Scientists at Maharishi University of Management, Fairfield, USA with Maharaja Adhira Rajaraam and the leading scientists on Transcendental Meditation. Presentation by Kr. Keith Wallace and Dr. Bevan Morris",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Oct_14.mp4",
          },
          {
            day: 12,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Oct_14.mp4",
          },
          {
            day: 11,
            description:
              "Prof. Evan Finkelstein, Associate Professor of Maharishi Vedic Science, at the Maharishi University of Management, Fairfield, USA",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Oct_14.mp4",
          },
          {
            day: 10,
            description:
              "Raja Kingley with accomplishment in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Oct_14.mp4",
          },
          {
            day: 9,
            description:
              "Weekly Maharishi’s Global Press Conference, from Wednesday, 17 July 2002, part 2.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Oct_14.mp4",
          },
          {
            day: 8,
            description:
              "Raja Peter has so many projects on the go to enliven coherence, harmony, and orderliness in the collective consciousness of Great Britain and all the other countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Oct_14.mp4",
          },
          {
            day: 7,
            description:
              "Raja Luis updates us all on the great projects that he has started in Latin America.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Oct_14.mp4",
          },
          {
            day: 6,
            description:
              "Pictures of all the Flag Raising Ceremonies from around the world on Victory Day 2014, Vijaya Dashami",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Oct_14.mp4",
          },
          {
            day: 5,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Oct_14.mp4",
          },
          {
            day: 4,
            description:
              "Dr. Stijn van den Bosch from Brussels about the European Peace Assembly taken place",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Oct_14.mp4",
          },
        ],
      },
      {
        monthName: "Nov",
        days: [
          {
            day: 30,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Nov_14.mp4",
          },
          {
            day: 26,
            description:
              "Raja Paul, Raja of Invincible Turkey, introducing leading governors of Turkey including Albert Baruh and their achievements in making Turkey invincible.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Nov_14.mp4",
          },
          {
            day: 24,
            description:
              "Dr. Bevan Morris, Prime Minister of the Global Country of World Peace reviews the latest news from around the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Nov_14.mp4",
          },
          {
            day: 23,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Nov_14.mp4",
          },
          {
            day: 21,
            description:
              "News from Dr. Lothar Krenner, National Director of Invincible Austria",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Nov_14.mp4",
          },
          {
            day: 20,
            description:
              "Weekly Maharishi’s Global Press Conference, from Wednesday, 7 August 2002 - Part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Nov_14.mp4",
          },
          {
            day: 19,
            description:
              "Raja Paul presents achievements and new projects in Canada.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Nov_14.mp4",
          },
          {
            day: 18,
            description:
              "Raja Luis with new achievements and prospects in the countries of his domain",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Nov_14.mp4",
          },
          {
            day: 17,
            description:
              "Next EU Summit Peace assembly announced by Stijn van den Bosch",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Nov_14.mp4",
          },
          {
            day: 16,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Nov_14.mp4",
          },
          {
            day: 13,
            description:
              "Weekly Maharishi’s Global Press Conference, from Wednesday, 7 August 2002 - Part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Nov_14.mp4",
          },
          {
            day: 12,
            description:
              "Overview and introduction into the subject of the Maharishi Tower of Invincibility.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Nov_14.mp4",
          },
          {
            day: 11,
            description:
              "12th International Maharishi Light Therapy with Gem graduation ceremony.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Nov_14.mp4",
          },
          {
            day: 10,
            description:
              "Raja Paul discusses with Dr Evan Finkelstein the experiences of higher states of consciousness of saints in all traditions, and announces courses in Turkey from 24 January to 1 February for bringing peace to the Middle-East: www.maharishiglobalcalendar.org",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Nov_14.mp4",
          },
          {
            day: 0o6,
            description:
              "Weekly Maharishi’s Global Press Conference, from Wednesday, 24 July 2002 - Part 3",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Nov_14.mp4",
          },
          {
            day: 0o2,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Nov_14.mp4",
          },
        ],
      },
      {
        monthName: "Dec",
        days: [
          {
            day: 29,
            description:
              "Raja Steve Rubin, Raja of China, reports on the latest development in China and invites the National Director, Dr. Zhu to share the great news from China.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Dec_14.mp4",
          },
          {
            day: 28,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Dec_14.mp4",
          },
          {
            day: 27,
            description:
              "Raja John Kaunhaus reporting from the Brahmasthan of India with inspiring news from Hungary, Egypt, Japan, and Jamaica and and and update on Maharishi Vedic Organic Agriculture.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Dec_14.mp4",
          },
          {
            day: 26,
            description:
              "Exciting news of the upcoming Winter Assembly in Turkey with Raja Paul",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Dec_14.mp4",
          },
          {
            day: 25,
            description:
              "Weekly Maharishi’s Global Press Conference, from Wednesday, 25 December 2002",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Dec_14.mp4",
          },
          {
            day: 21,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Dec_14.mp4",
          },
          {
            day: 20,
            description:
              "Latest news from Dr Richard Peycke, leader of the Maharishi Institute created in 2007 to showcase South Africa as a leading innovator in education provision and support the worldwide breakthrough of Consciousness-Based Education.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Dec_14.mp4",
          },
          {
            day: 19,
            description:
              "News on the construction of the Maharishi Sthapatya Veda Fortune-Creating Buildings at MERU. News from Raja Graham from the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Dec_14.mp4",
          },
          {
            day: 18,
            description:
              "Weekly Maharishi’s Global Press Conference, from Wednesday, 28 August 2002, Part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Dec_14.mp4",
          },
          {
            day: 16,
            description:
              "Raja Luis in a brief message on the latest projects in Latin America to create 50,000 Yogic Flyers.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Dec_14.mp4",
          },
          {
            day: 14,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Dec_14.mp4",
          },
          {
            day: 12,
            description:
              "Dr. Ashley Deans, Executive Director of the Maharishi School of the Age of Enlightenment, Fairfield, IA, USA presents the continuing achievements of the school.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Dec_14.mp4",
          },
          {
            day: 11,
            description:
              "Weekly Maharishi’s Global Press Conference, from Wednesday, 28 August 2002, Part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Dec_14.mp4",
          },
          {
            day: 10,
            description:
              "Raja Paul invites Brad Cook, President and CEO of Endeavour Silver and Canada’s leading supporter of Maharishi Vedic Pandits to recount his trip to the Brahmasthan of India, home of the Maharishi Vedic Pandits in India.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Dec_14.mp4",
          },
          {
            day: 8,
            description:
              "Dr. Peter Swan recounts his latest trip in Serbia where he taught his course on Agriculture and Gardening in the Light of Maharishi Vedic Science.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/8_Dec_14.mp4",
          },
          {
            day: 7,
            description:
              "Dr Peter Swan reads Maharishi’s book, Ideal India—The Lighthouse of Peace on Earth, published in 2001",
            videoSrc: "http://streaming.mou.org/MOU2/chat/7_Dec_14.mp4",
          },
          {
            day: 4,
            description:
              "Raja Peter and guests report on two school projects, one in the UK and another one in Zambia.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/4_Dec_14.mp4",
          },
          {
            day: 3,
            description:
              "Birthday celebration of Raja Chancellor with introduction by Maharaja Adhiraj Rajaraam.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/3_Dec_14.mp4",
          },
          {
            day: 2,
            description:
              "Raja Luis connects with more achievements in South America. Hosted by Raja Paul.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/2_Dec_14.mp4",
          },
        ],
      },
    ],
  },
  // Year 2013
  {
    id: 2,
    year: 2013,
    months: [
      {
        monthName: "Jan",
        days: [
          {
            day: 29,
            description:
              "Maharishi University of Management, Fairfield, Iowa, USA: Distance Education Course on the Science and Technology of Consciousness",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Jan_13.mp4",
          },
          {
            day: 28,
            description:
              "Dr Robert Schneider, dean of MUM's Maharishi College of Perfect Health, visited India, Nepal, and Greece in the first section of a world tour to spread the knowledge of Maharishi Vedic Medicine.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Jan_13.mp4",
          },
          {
            day: 27,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Jan_13.mp4",
          },
          {
            day: 24,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Jan_13.mp4",
          },
          {
            day: 22,
            description:
              "Celebration of the birthday of Dr. David Lynch and replay of Raja Luis's presentation during the 12 January 2013 celebrations",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Jan_13.mp4",
          },
          {
            day: 21,
            description:
              "Report from Col. Chassé from the Brahmasthan of India about his various trips in different countries.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Jan_13.mp4",
          },
          {
            day: 20,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Jan_13.mp4",
          },
          {
            day: 19,
            description:
              "Raja Peter gives a detailed overview of acheivements in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Jan_13.mp4",
          },
          {
            day: 18,
            description:
              "Raja Felix replays of this presentation on January 12 on the occasion of his birthday. Replay of the BST Ambassador Resources Office presentation of January 13 featuring talks by Mona Kagi-Causemann and Susie Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Jan_13.mp4",
          },
          {
            day: 17,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Jan_13.mp4",
          },
          {
            day: 16,
            description:
              "Reports from Raja Bjarne on achievements in his domain, Raja Rogers Badgett, and Raja Antonio Bartolome",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Jan_13.mp4",
          },
          {
            day: 15,
            description:
              "MUM Department of Computer Science report, followed by two beautiful songs by Diana Leighton",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Jan_13.mp4",
          },
          {
            day: 9,
            description:
              "Peter Swan reads good news from around the world heralding a prosperous and happy upcoming new year 2013",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Jan_13.mp4",
          },
        ],
      },
      {
        monthName: "Feb",
        days: [
          {
            day: 28,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Feb_13.mp4",
          },
          {
            day: 27,
            description:
              "Dr. Peter Swan reviews the celebrations in India in the past week: Kumbha Mela and Inauguration of Maharishi Smarak and Maharishi Vedic Pandits in the Brahmasthan of India.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Feb_13.mp4",
          },
          {
            day: 24,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Feb_13.mp4",
          },
          {
            day: 23,
            description:
              "Dr. Eike Hartmann presents the Brahmasthan of India project.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Feb_13.mp4",
          },
          {
            day: 22,
            description:
              "Dr. Bevan Morris reads Maharaja Adhiraj Rajaraam's message on the occasion of the Maharishi Smarak Inauguration.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Feb_13.mp4",
          },
          {
            day: 21,
            description:
              "Trip to Brahmasthan of India for the International delegation and award ceremony for Maharishi's Sthapatya Veda team that worked on the Maharishi Smarak.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Feb_13.mp4",
          },
          {
            day: 20,
            description:
              "Raja John Hagelin reviews Yoga and Yagya in terms of Quantum Physics with the Maharishi Vedic Pandits from the Brahmasthan of India, translated in Hindi by Dr Girish Varma and Prof. Buvnesh Sharma. Introduced by Dr. Bevan Morris.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Feb_13.mp4",
          },
          {
            day: 17,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Feb_13.mp4",
          },
          {
            day: 16,
            description:
              "First report by Dr. Peter Swan on the events that took place on the 15 February 2013, including the trip to Triveni and the Maharishi Smarak Inauguaration.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Feb_13.mp4",
          },
          {
            day: 14,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Feb_13.mp4",
          },
          {
            day: 11,
            description:
              "Maharishi's video tape: Inauguration the Field of Knowledge and Technology of Art and Music.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Feb_13.mp4",
          },
          {
            day: 10,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Feb_13.mp4",
          },
          {
            day: 8,
            description:
              "Conference on Integrated Medicine at the University of Maryland, part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Feb_13.mp4",
          },
          {
            day: 7,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Feb_13.mp4",
          },
          {
            day: 6,
            description: "Raja Paul presents achievements in his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Feb_13.mp4",
          },
          {
            day: 5,
            description:
              "Raja Luis update on huge project to create large groups of Yogic Flyers for Latin America. Conference on Integrated Medicine at the University of Maryland, part 1.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Feb_13.mp4",
          },
          {
            day: 3,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Feb_13.mp4",
          },
        ],
      },
      {
        monthName: "Mar",
        days: [
          {
            day: 28,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Mar_13.mp4",
          },
          {
            day: 23,
            description:
              "Raja Emanuel, Raja of Germany, recounts his latest trip in India on the occasion of his birthday and Dr. Jonathan Lipman, director of Architecture for USA",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Mar_13.mp4",
          },
          {
            day: 22,
            description:
              "Dr. Alarik Arenander announcing the next courses of Individual is Cosmic and Development of Total Brain Potential in MERU, Vlodrop starting March 29, 2013",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Mar_13.mp4",
          },
          {
            day: 21,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Mar_13.mp4",
          },
          {
            day: 20,
            description:
              "The Maharishi Vedic Pandit Programme: Bringing fulfillment to the goals of every religion and culture through the enlivenment of Total Natural Law with Dr. Chris Crowell",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Mar_13.mp4",
          },
          {
            day: 17,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Mar_13.mp4",
          },
          {
            day: 16,
            description:
              "Raja Paul's achievement in his Domain, i.e. Turkey and Canada and his trip to India during the Maharishi Smarak inauguration and the Brahmasthan of India. Dr. Benjamin Feldman, Kuber ji, birthday celebration including a speech from Kuber ji.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Mar_13.mp4",
          },
          {
            day: 15,
            description:
              "Maharishi Smarak Inauguration on 15 February 2013 in India. Raja and Raj Rajeshwaris pledges to the Global Country of World Peace. Achievement of Raja John and Sara Konhaus in his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Mar_13.mp4",
          },
          {
            day: 14,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Mar_13.mp4",
          },
          {
            day: 13,
            description:
              "Training to be an expert in bringing Consciousness-Based Education programmes to your nation—14-day residence course, 1-15 August 2013 at MERU, Holland",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Mar_13.mp4",
          },
          {
            day: 12,
            description:
              "Dr. David Fisher explains the Sustainable Living Center at Maharishi University of Management, Fairfield, IA, USA. Dr. Fisher is the chairperson of the Center for Sustainable Living at MUM.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Mar_13.mp4",
          },
          {
            day: 7,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Mar_13.mp4",
          },
          {
            day: 6,
            description:
              "Dr. Stijn Vandenbosch with news from Belgium. Reports from Dr. Bevan Morris and Dr. Chris Crowell on the Inauguration of Maharishi Smarak and the Brahmasthan of India.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Mar_13.mp4",
          },
          {
            day: 5,
            description:
              "Birthday celebration of Dr. Bevan Morris in Malaysia.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Mar_13.mp4",
          },
          {
            day: 4,
            description:
              "Birthday celebration of Dr. Bevan Morris, Prime Minister of the Global Country of World Peace.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Mar_13.mp4",
          },
          {
            day: 3,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Mar_13.mp4",
          },
          {
            day: 2,
            description:
              "Dr. Alarik and Cynthya Arenander announcing the Individual is Cosmic courses at MERU this spring.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Mar_13.mp4",
          },
          {
            day: 1,
            description:
              "Reflections on electromagnetic field influences on our health.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Mar_13.mp4",
          },
        ],
      },
      {
        monthName: "Apr",
        days: [
          {
            day: 28,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Apr_13.mp4",
          },
          {
            day: 27,
            description:
              "Raja Paul hosts Col. Brian Rees, M.D., M.P.H., David Shapiro and Dr. Fred Travis on the subject of PTSD amongs veterans and other projects in Africa aiming at reducing PTSD.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Apr_13.mp4",
          },
          {
            day: 24,
            description:
              "Dr Fred Travis with news on Maharishi Ayur-Veda activities in Germany.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Apr_13.mp4",
          },
          {
            day: 23,
            description:
              "Dr. Robert Roth presents many important guests from USA including Dr. David Lynch.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Apr_13.mp4",
          },
          {
            day: 22,
            description:
              "Dr. Keith Wallace presents a new Maharishi Ayurveda Wellness consultant programme online.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Apr_13.mp4",
          },
          {
            day: 21,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Apr_13.mp4",
          },
          {
            day: 9,
            description:
              "Overview of the construction of the new Maharishi Peace Palace in Erfurt, Germany with Dr. Eckart and Lila-Maria Stein",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Apr_13.mp4",
          },
          {
            day: 8,
            description:
              "Celebration for the end of the course Development of Total Brain Potential with Alarik and Cynthia Arenander and the course participants.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Apr_13.mp4",
          },
          {
            day: 7,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Apr_13.mp4",
          },
          {
            day: 6,
            description: "Raja Peter and Paul Gelderloos on the new tm.org",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Apr_13.mp4",
          },
          {
            day: 4,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Apr_13.mp4",
          },
          {
            day: 3,
            description:
              "Report by Raja Peter and his guests on the visit to the Erfurt Peace Palace.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Apr_13.mp4",
          },
          {
            day: 2,
            description:
              "Celebration of the end of the Individual is Cosmic with course participants and Dr. Alarik and Cynthia Arenander.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Apr_13.mp4",
          },
          {
            day: 1,
            description:
              "Raja Peter introduces the new international web site launched for the UK and the Netherlands: tm.org",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Apr_13.mp4",
          },
        ],
      },
      {
        monthName: "May",
        days: [
          {
            day: 31,
            description:
              "Maharishi University of Management Graduation ceremony on the 25th May 2013 - Part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_May_13.mp4",
          },
          {
            day: 29,
            description:
              "Maharishi University of Management Graduation ceremony on the 25th May 2013 - Part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_May_13.mp4",
          },
          {
            day: 28,
            description:
              "Dr. Peter Swan announces his next Maharishi Vedic Agriculture Course on the Maharishi University of Management Campus in Fairfield Iowa, USA on the 8th of June 2013",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_May_13.mp4",
          },
          {
            day: 27,
            description:
              "Dr. Ashley Deans and Joachim Claes on success of Consciousness-Based Education (Stress-Free School) in Belgium",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_May_13.mp4",
          },
          {
            day: 26,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_May_13.mp4",
          },
          {
            day: 23,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_May_13.mp4",
          },
          {
            day: 22,
            description:
              "Dr. Peter Swan reviews the contribution of Maharishi in Maharishi Vedic Organic Agriculture",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_May_13.mp4",
          },
          {
            day: 21,
            description:
              "Good news from around the world including the new book Let Your Soul Sing and Raja Luis achievements in Latin America",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_May_13.mp4",
          },
          {
            day: 20,
            description:
              "Celebration of the anniversary of Maharishi's first trip to Malaysia with the governors of Malaysia and comments from Dr. Bevan Morris",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_May_13.mp4",
          },
          {
            day: 19,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_May_13.mp4",
          },
          {
            day: 18,
            description: "Raja Kingsley with success in his domain",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_May_13.mp4",
          },
          {
            day: 17,
            description:
              "Raja Bruce concludes a WPA meeting with Swedish govenors at MERU",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_May_13.mp4",
          },
          {
            day: 16,
            description:
              "Raja Bruce presents very successful governors Jim and Nina Meade with inspiring news on achievements in Tanzania.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_May_13.mp4",
          },
          {
            day: 15,
            description:
              "Dr. Paul Gelderloos reviews all the good news in the Netherlands in the past few weeks.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_May_13.mp4",
          },
          {
            day: 12,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_May_13.mp4",
          },
          {
            day: 11,
            description: "Dr. Colonel Chasse report on his trip in Sri Lanka.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_May_13.mp4",
          },
          {
            day: 10,
            description:
              "Dr. Colonel Chasse report on the Ministry of Defense.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_May_13.mp4",
          },
          {
            day: 9,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_May_13.mp4",
          },
          {
            day: 7,
            description:
              "The American Heart Association report on the Transcendental Meditation technique to be the only meditation practice that has been shown to lower blood pressure.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_May_13.mp4",
          },
          {
            day: 6,
            description:
              "Presentation of the upcoming Youth Conference at MERU from 16th to 20th May 2013, also available as a webinar. See MERUcourses.com",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_May_13.mp4",
          },
          {
            day: 5,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_May_13.mp4",
          },
          {
            day: 2,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_May_13.mp4",
          },
        ],
      },
      {
        monthName: "June",
        days: [
          {
            day: 30,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Jun_13.mp4",
          },
          {
            day: 29,
            description:
              "End of the Maharishi Light Therapy with Gems with Dr. Joachim and Iris Roller and the course participants.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Jun_13.mp4",
          },
          {
            day: 27,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Jun_13.mp4",
          },
          {
            day: 23,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Jun_13.mp4",
          },
          {
            day: 20,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Jun_13.mp4",
          },
          {
            day: 19,
            description:
              "Raja Wynne is the guest of Peter Swan at Maharishi Vedic City, Iowa, USA",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Jun_13.mp4",
          },
          {
            day: 18,
            description:
              "Global Chat from Maharishi University of Management (MUM) in Fairfield, Iowa, USA with Dr. Craig Pearson, Executive Vice-Presidenet of MUM and Paul Stokstad, director of Admission.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Jun_13.mp4",
          },
          {
            day: 16,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Jun_13.mp4",
          },
          {
            day: 15,
            description:
              "Dr Girish Momaya presented an overview of the short assemblies of the Guru Purnima Global Summer Assembly taking place at MERU from 13 to 31 July 2013.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Jun_13.mp4",
          },
          {
            day: 13,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Jun_13.mp4",
          },
          {
            day: 12,
            description:
              "Dr Alarik and Cynthia Arenander present their next course Youthful Brain that will be held at MERU from July 26th to July 31th 2013 part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Jun_13.mp4",
          },
          {
            day: 11,
            description:
              "Dr Alarik and Cynthia Arenander present their next course Youthful Brain that will be held at MERU from July 26th to July 31th 2013 part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Jun_13.mp4",
          },
          {
            day: 09,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Jun_13.mp4",
          },
          {
            day: 0o6,
            description:
              "Dr. Girish Momaya gave an inspiring presentation of the upcoming Global Summer Assembly outlining the courses and programmes that will be held during the whole assembly from 13 to 31 July 2013 including Guru Purnima Celebrations on 22 July.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Jun_13.mp4",
          },
          {
            day: 0o4,
            description:
              "News from USA with Adam Pressman and more news from Latin America with Raja Luis",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Jun_13.mp4",
          },
          {
            day: 0o2,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Jun_13.mp4",
          },
          {
            day: 0o1,
            description:
              "Completion of the Maharishi Vastu Builder Seminar held at MERU.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Jun_13.mp4",
          },
        ],
      },
      {
        monthName: "July",
        days: [
          {
            day: 31,
            description:
              "Raja Harris on the upcoming season at the Brahmasthan of India with Maharishi Vedic Pandits.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_Jul_13.mp4",
          },
          {
            day: 29,
            description: "Raja Kingsley and achievements in his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Jul_13.mp4",
          },
          {
            day: 28,
            description:
              "Dr. Alarik Arenander expresses his satisfaction in presenting the achievements of the course Youthful Brain at MERU.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Jul_13.mp4",
          },
          {
            day: 27,
            description:
              "Dr Lothar Pirc presents achievement in the field of Maharishi Ayur-Veda.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Jul_13.mp4",
          },
          {
            day: 25,
            description:
              "Offers for Guru Purnima from The Netherlands, Mongolia and news the course How to Present the Model of Vedic Literature with Dr. Alarik and Cynthia Arenander.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Jul_13.mp4",
          },
          {
            day: 24,
            description:
              "Dr Bevan Morris presents latest news from Maharishi University of Management, Fairfield, IA, USA",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Jul_13.mp4",
          },
          {
            day: 23,
            description: "10th evening of the National Directors Conference",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Jul_13.mp4",
          },
          {
            day: 22,
            description: "10th evening of the National Directors Conference",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Jul_13.mp4",
          },
          {
            day: 21,
            description: "9th evening of the National Directors Conference",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Jul_13.mp4",
          },
          {
            day: 20,
            description: "8th evening of the National Directors Conference",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Jul_13.mp4",
          },
          {
            day: 18,
            description: "6th evening of the National Directors Conference",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Jul_13.mp4",
          },
          {
            day: 17,
            description: "5th evening of the National Directors Conference",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Jul_13.mp4",
          },
          {
            day: 16,
            description: "4th evening of the National Directors Conference",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Jul_13.mp4",
          },
          {
            day: 15,
            description: "3rd evening of the National Directors Conference",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Jul_13.mp4",
          },
          {
            day: 14,
            description: "2nd evening of the National Directors Conference",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Jul_13.mp4",
          },
          {
            day: 13,
            description:
              "Dr Bevan Morris inaugurates the National Directors Conference evening programmes",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Jul_13.mp4",
          },
          {
            day: 12,
            description:
              "Dr Bevan Morris presents pictures from his latest trips around the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Jul_13.mp4",
          },
          {
            day: 11,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Jul_13.mp4",
          },
          {
            day: 10,
            description:
              "Training to be an expert in Consciousness-Based Education in your country",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Jul_13.mp4",
          },
          {
            day: 08,
            description:
              "Maharaja Adhiraj Rajaraam's message on a solution to bring peace and fulfilment to Syria, the middle east and the whole world",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Jul_13.mp4",
          },
          {
            day: 0o7,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Jul_13.mp4",
          },
          {
            day: 0o4,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Jul_13.mp4",
          },
          {
            day: 0o2,
            description:
              "Raja Luis and the Day of Invincibility for Latin America",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Jul_13.mp4",
          },
        ],
      },
      {
        monthName: "Aug",
        days: [
          {
            day: 30,
            description: "Maharishi Aroma Therapy graduation ceremony.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Aug_13.mp4",
          },
          {
            day: 29,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Aug_13.mp4",
          },
          {
            day: 27,
            description:
              "News from Invincible America with Michael Steinfelt and Latin America with Raja Luis",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Aug_13.mp4",
          },
          {
            day: 25,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Aug_13.mp4",
          },
          {
            day: 23,
            description:
              "Short courses on Self-Pulse, Diet, and Prevention presented by Maharishi Vaidya Vyas.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Aug_13.mp4",
          },
          {
            day: 22,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Aug_13.mp4",
          },
          {
            day: 20,
            description:
              "News from USA: Warrior Wellness Programme wiith Mario Orsatti and Raja Luis with achievements in his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Aug_13.mp4",
          },
          {
            day: 18,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Aug_13.mp4",
          },
          {
            day: 17,
            description:
              "Dr Lee Fergusson and Dr. Anna Bonshek on Maharishi Vedic University in Cambodia.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Aug_13.mp4",
          },
          {
            day: 16,
            description: "Dr. Fred Travis on his travel to Australia.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Aug_13.mp4",
          },
          {
            day: 15,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Aug_13.mp4",
          },
          {
            day: 12,
            description:
              "Graduation fo the second seminar on 'Training to be and Expert in Bringing Conciousness-Based Education to Your Nation'",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Aug_13.mp4",
          },
          {
            day: 11,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Aug_13.mp4",
          },
          {
            day: 10,
            description:
              "Dr. Girish Varma and Raja Harris on availability of courses and programmes in India",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Aug_13.mp4",
          },
          {
            day: 08,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Aug_13.mp4",
          },
          {
            day: 0o7,
            description:
              "Dr Girish Momaya reviews the upcoming courses being offered at MERU, Holland",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Aug_13.mp4",
          },
          {
            day: 0o6,
            description:
              "Two excellent new Videos available from the David Lynch Foundation; Latin America boosts invincibility with bigger groups in schools and defence",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Aug_13.mp4",
          },
          {
            day: 0o4,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Aug_13.mp4",
          },
          {
            day: 0o2,
            description:
              "Offerings for Guru Purnima from Raja Konhaus with achievemenets in Jamaica, and Uzbekistan, and offerings from Raja Felix, Raja of Switzerland",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Aug_13.mp4",
          },
        ],
      },
      {
        monthName: "Sept",
        days: [
          {
            day: 30,
            description:
              "Raja Paul announces a new World Peace Assembly in Turkey to create peace and invincibility for the middle-east. See the Announcement.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Sep_13.mp4",
          },
          {
            day: 29,
            description:
              "Reading “Maharishi's Absolute Theory of Government” by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Sep_13.mp4",
          },
          {
            day: 28,
            description:
              "Graduation ceremony of the Yoga Asana Teacher Training Course",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Sep_13.mp4",
          },
          {
            day: 25,
            description:
              "Review of the Tower of Invincibility at MERU, Holland",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Sep_13.mp4",
          },
          {
            day: 24,
            description:
              "Maharishi University of Management, Fairfield, USA Founder’s Day part 2 from 12 September 2013.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Sep_13.mp4",
          },
          {
            day: 23,
            description:
              "Maharishi University of Management, Fairfield, USA Founder’s Day from 12 September 2013.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Sep_13.mp4",
          },
          {
            day: 22,
            description:
              "Reading “Maharishi's Absolute Theory of Government” by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Sep_13.mp4",
          },
          {
            day: 20,
            description:
              "Dr. Raja Peter on achievements of the Maharishi Free School in the UK and the upcoming tour in the UK, 'The Ramayana in Human Physiology', by Dr. Tony Nader, Maharaja Adhiraj Rajaraam.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Sep_13.mp4",
          },
          {
            day: 18,
            description:
              "Raja Antonio, Dr. Ashley Deans, Col. Gunter Chasse, and Dr. Lothar Pirc with news on the achievements of the Global Country of World Peace for Syria and the middle-east region.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Sep_13.mp4",
          },
          {
            day: 16,
            description:
              "Dr. Peter Swan with latest news on the efforts to help the situation in Syria with guest Raja Kingsley Brooks and Dr. Lothar Pirc. Ending with celebrations of Raja Bob LoPinto's birthday.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Sep_13.mp4",
          },
          {
            day: 11,
            description:
              "Convocation 2013 of Maharishi University of Management.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Sep_13.mp4",
          },
          {
            day: 10,
            description:
              "'Our Conscious Future' with Dr. John Hagelin and achievements from Raja Luis in his domain. Hosted by Dr. Chris Crowell.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Sep_13.mp4",
          },
          {
            day: 08,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Sep_13.mp4",
          },
          {
            day: 0o7,
            description:
              "Raja Felix and Dr. Bevan Morris on achievements in Raja Felix’s domain",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Sep_13.mp4",
          },
          {
            day: 0o6,
            description:
              "Raja Pat Barrett, Raja of Singapore. Vedic Agriculture in the ligth of Maharishi Vedic Science by Dr. Peter Swan. Up-coming WPA in Hvar, Croatioa",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Sep_13.mp4",
          },
          {
            day: 0o5,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Sep_13.mp4",
          },
          {
            day: 0o4,
            description:
              "Dr Alarik and Cynthya Arenander review the latest finding in brain wave EEG technology.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Sep_13.mp4",
          },
          {
            day: 0o3,
            description:
              "Latest Transcendental Meditation Teacher Training Course graduation ceremony",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Sep_13.mp4",
          },
          {
            day: 0o1,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Sep_13.mp4",
          },
        ],
      },
      {
        monthName: "Oct",
        days: [
          {
            day: 29,
            description:
              "Report from Maharishi University of Management, Fairfield, IA, USA with Dr. Craig Pearson, Executive Vice President, and Dr Anil Maheshwari, Associate Professor of Management Information Systems",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Oct_13.mp4",
          },
          {
            day: 28,
            description:
              "Raja Michael Reports on Volume 7 of Collected Papers—\nScientific Research on the Transcendental Meditation and TM-Sidhi Programme\nPre order: USA | Holland",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Oct_13.mp4",
          },
          {
            day: 27,
            description:
              "Reading “Maharishi's Absolute Theory of Government” by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Oct_13.mp4",
          },
          {
            day: 25,
            description:
              "News from Raja Felix, Raja of Swizerland and many other Baltic countries.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Oct_13.mp4",
          },
          {
            day: 22,
            description:
              "Three powerful videos clips from the David Lynch Foundation were shown by Adam Pressman. A huge new step for rehabilitation in Latin America, by Raja Luis.\nYoutube links for the videos:\nNYC Family Justice Centers: Relieving Trauma through Meditation (3:38): https:",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Oct_13.mp4",
          },
          {
            day: 19,
            description:
              "The education summit in New York City in October 2012—Part 4",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Oct_13.mp4",
          },
          {
            day: 18,
            description:
              "Graduation ceremony of the latest Maharishi Gandharva Veda course with artists Anita Roy and Reshma Shrivastava",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Oct_13.mp4",
          },
          {
            day: 16,
            description:
              "The education summit in New York City in October 2012 and\nGreat news from four countries in Latin America—Part 3",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Oct_13.mp4",
          },
          {
            day: 15,
            description:
              "Flag raising ceremony around the world (Part 2) and replay of Raja Luis with special guest from Venezuela.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Oct_13.mp4",
          },
          {
            day: 14,
            description:
              "Flag raising ceremony around the world (Part 1) and Raja Luis with special guest from Venezuela.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Oct_13.mp4",
          },
          {
            day: 0o4,
            description:
              "Raja Wynne and Mother of the Domain Maureen with achievements in his Domain",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Oct_13.mp4",
          },
          {
            day: 0o2,
            description:
              "The education summit in New York City in October 2012 and\nGreat news from four countries in Latin America—Part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Oct_13.mp4",
          },
          {
            day: 0o1,
            description:
              "The education summit in New York City in October 2012 and\nGreat news from four countries in Latin America—Part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Oct_13.mp4",
          },
        ],
      },
      {
        monthName: "Nov",
        days: [
          {
            day: 29,
            description:
              "Raja Bjarne presents achievements from countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Nov_13.mp4",
          },
          {
            day: 27,
            description:
              "Dr. Girish Momaya presents the up-coming course at MERU including the winter assembly 2014. See www.merucourses.com for more details.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Nov_13.mp4",
          },
          {
            day: 26,
            description:
              "News from USA with Adam Pressman, presenting a new video about Authism, Transcendental Meditation and Stress, filmed at Washington, DC. USA.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Nov_13.mp4",
          },
          {
            day: 25,
            description: "News from Dr. Rainer Picha, Minister of Health",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Nov_13.mp4",
          },
          {
            day: 24,
            description:
              "Reading “Maharishi's Absolute Theory of Government” by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Nov_13.mp4",
          },
          {
            day: 23,
            description:
              "News from Raja Michael on the publication of the new Collected Papers, Vol. 7",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Nov_13.mp4",
          },
          {
            day: 20,
            description:
              "Dr. Eike Hartmann presenting the latest plans for new buildings at the West Virginia Purusha campus, in USA.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Nov_13.mp4",
          },
          {
            day: 19,
            description:
              "Dr Craig Pearson, executive Vice-President of Maharishi University of Management in Fairfield, Iowa on how the University is progressing",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Nov_13.mp4",
          },
          {
            day: 18,
            description:
              "News from Dr. Robert Schneider about health issues around the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Nov_13.mp4",
          },
          {
            day: 17,
            description:
              "Reading “Maharishi's Absolute Theory of Government” by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Nov_13.mp4",
          },
          {
            day: 16,
            description:
              "Reading form “Experiences of Higher States of Consciousness” published by Maharishi University of Management, Fairfield IA, USA",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Nov_13.mp4",
          },
          {
            day: 15,
            description:
              "News on Invincibility with a video lecture by Maharishi—“Flow of Awareness, Silence in Flow”",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Nov_13.mp4",
          },
          {
            day: 13,
            description:
              "Closure ceremony of the Maharishi Veda Aroma course at MERU.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Nov_13.mp4",
          },
          {
            day: 11,
            description:
              "News from Raja Antonio on the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Nov_13.mp4",
          },
          {
            day: 10,
            description:
              "Reading “Maharishi's Absolute Theory of Government” by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Nov_13.mp4",
          },
          {
            day: 08,
            description:
              "Update on GMO crops and other chemicals that goes with the GMO seeds.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Nov_13.mp4",
          },
          {
            day: 0o5,
            description:
              "Replay of Maharishi's Address on Dhanvantari Jayanti.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Nov_13.mp4",
          },
          {
            day: 0o4,
            description: "Maharishi on Deepavali on 7 November 1980",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Nov_13.mp4",
          },
        ],
      },
      {
        monthName: "Dec",
        days: [
          {
            day: 31,
            description:
              "Review of 2013 achievements by Dr. Bevan Morris and preparation for the period of silence at the beginning of 2014.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_Dec_13.mp4",
          },
          {
            day: 30,
            description:
              "Raja Paul and guests explains the Global World Peace Assembly on the coast of Turkey to bring peace in the middle-east. Visit the web site for more details and how to apply.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Dec_13.mp4",
          },
          {
            day: 29,
            description:
              "Report by Dr. Graham Lodge on the advanced Maharishi Vedic Science course conducted recently in New Zealand by Maharaja Adhiraj Rajaram.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Dec_13.mp4",
          },
          {
            day: 28,
            description:
              "Raja Graham with achievements in the countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Dec_13.mp4",
          },
          {
            day: 27,
            description:
              "Dr Peter Swan gives an overview of Maharishi Vedic Organic Agriculture and its benefits for global health and prosperity.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Dec_13.mp4",
          },
          {
            day: 26,
            description:
              "Raja Potter announces the Global World Peace Assembly and Residence Course, Now including Global Enlightened Leadership Summit in Antalya, Turkey– 15-25 January 2014 (10-day option) 18-25 January 2014 (7-day option). See the website.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Dec_13.mp4",
          },
          {
            day: 24,
            description:
              "Raja Luis with inspiring news from achievements in the countries of his domain in Latin America.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Dec_13.mp4",
          },
          {
            day: 22,
            description:
              "Reading “Maharishi's Absolute Theory of Government” by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Dec_13.mp4",
          },
          {
            day: 21,
            description:
              "Raja Peter with news from the countries of his domain including good news form around the world.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Dec_13.mp4",
          },
          {
            day: 20,
            description:
              "Dr Craig Pearson, Executive Vice-President of Maharishi University of Management, Fairfield, IA, USA on his last trip in South Africa.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Dec_13.mp4",
          },
          {
            day: 18,
            description:
              "Raja Paul Introduces Global Ambassadors of the\nThe Enlightened Leadership Programme.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Dec_13.mp4",
          },
          {
            day: 16,
            description:
              "Raja Peter reviews the web site: Global Good News with its wealth of good news from around the world and other relates sites like MaharishiDarshan site for Hindi-speaking listeners.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Dec_13.mp4",
          },
          {
            day: 15,
            description:
              "Reading “Maharishi's Absolute Theory of Government” by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Dec_13.mp4",
          },
          {
            day: 13,
            description:
              "“Data Mining” explained with Prof. Anil Maheshwari, Associate Professor of Management Information Systems form Maharishi University of Management, Fairfield USA.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Dec_13.mp4",
          },
          {
            day: 12,
            description:
              "Graduation ceremony of the latest Maharishi Light Therapy with Gem course with the course leaders, Prof. Joachim and Iris Roller.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Dec_13.mp4",
          },
          {
            day: 11,
            description:
              "Raja Peter and Richard Johnson report on Maharaja's visit to UK, and play clips of his talks.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Dec_13.mp4",
          },
          {
            day: 10,
            description:
              "Beautiful reflections from Dr Bevan Morris and Raja Luis: Dr Bevan Morris reflects on his experience in Latin America",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Dec_13.mp4",
          },
          {
            day: 0o3,
            description:
              "Raja Luis gives a report for the possibility of Invincible Colombia and Latin America. Peter Swan also recounts his meeting with a group in Lelystad, The Netherlands to which he introduced the principles of Maharishi Vedic Organic Agriculture.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Dec_13.mp4",
          },
          {
            day: 0o2,
            description:
              "Raja Paul presents the Global World Peace Assembly and Residence Course to Create Peace in the Middle East. Visit the site HERE.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Dec_13.mp4",
          },
          {
            day: 0o1,
            description:
              "Reading “Maharishi's Absolute Theory of Government” by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Dec_13.mp4",
          },
        ],
      },
    ],
  },
  // Year 2012
  {
    id: 1,
    year: 2012,
    months: [
      {
        monthName: "Jan",
        days: [
          {
            day: 30,
            description:
              "'The Individual is Cosmic' latest course update by Dr Alarik Arenander and Dr Rainer Picha",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Jan_12.mp4",
          },
          {
            day: 29,
            description:
              "Reading of Science of Being and Art of Living by Maharishi",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Jan_12.mp4",
          },
          {
            day: 26,
            description:
              "Dr Chris Crowell speaks on the Prime Minister’s scheduled trip across Africa",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Jan_12.mp4",
          },
          {
            day: 25,
            description:
              "Dr Bevan Morris arrives in Ghana, West Africa;\nDr Larry Chroman's goals for the Ministry of Trade and Commerce for 2012",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Jan_12.mp4",
          },
          {
            day: 23,
            description:
              "Raja Wynne summarizing the achievements of his Domain in 2011. Also Raja Vikram Aditya, Raja of Vedic Siam Domain, gave a nice presentation on the achievements of last year and presented his wishes for new year.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Jan_12.mp4",
          },
          {
            day: 22,
            description:
              "Reading of Science of Being and Art of Living by Maharishi",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Jan_12.mp4",
          },
          {
            day: 21,
            description:
              "Raja Tom Stanley wishes for 2012. Ashley Deans inspiring the leaders of education everywhere with Consciousness-Based Education",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Jan_12.mp4",
          },
          {
            day: 20,
            description:
              "The auspicious birthdays of Raja Bjarne, Col. Gunter Chasse and Dr David Lynch were celebrated",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Jan_12.mp4",
          },
          {
            day: 17,
            description:
              "The flag of the Global Country of World was raised in more countries during January 12 Celebrations. Raja Graham offered his wishes for 2012. Col Gunter Chasse is working with 34 countries to create invincible defence.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Jan_12.mp4",
          },
          {
            day: 16,
            description:
              "A visit to the Brahmasthan of India – A beautiful video documentary",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Jan_12.mp4",
          },
          {
            day: 15,
            description:
              "Reading of Science of Being and Art of Living by Maharishi",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Jan_12.mp4",
          },
          {
            day: 13,
            description:
              "Flag Raising ceremony for 12 January and Raja Peter report on the success in the UK",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Jan_12.mp4",
          },
          {
            day: 10,
            description: "Maharaja Adhiraj Rajaraam Birthday Celebration",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Jan_12.mp4",
          },
        ],
      },
      {
        monthName: "Feb",
        days: [
          {
            day: 28,
            description:
              "Dr Bevan Morris reports from Cape Town at the southern tip of Africa.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Feb_12.mp4",
          },
          {
            day: 26,
            description:
              "Reading of Science of Being and Art of Living by Maharishi.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Feb_12.mp4",
          },
          {
            day: 24,
            description:
              "Maharishi Aroma Therapy reports on the Nuremberg's fair.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Feb_12.mp4",
          },
          {
            day: 23,
            description:
              "Raja Michael and Dr. Susie Dillbeck on scientific research on Transcendental Meditation and TM-Sidhi Programme",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Feb_12.mp4",
          },
          {
            day: 22,
            description:
              "Raja Peter reports on the achievements of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Feb_12.mp4",
          },
          {
            day: 20,
            description:
              "Replay of the Shivaratri celebration from Brahmasthan of India",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Feb_12.mp4",
          },
          {
            day: 19,
            description:
              "Reading of Science of Being and Art of Living by Maharishi",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Feb_12.mp4",
          },
          {
            day: 18,
            description:
              "Dr Hagelin’s course ‘Foundations of Physics and Consciousness’ begins online on 25 February 2012",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Feb_12.mp4",
          },
          {
            day: 17,
            description:
              "Dr Bevan Morris reporting on his historic tour in Africa: Senegal and South Africa.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Feb_12.mp4",
          },
          {
            day: 16,
            description:
              "Lectures by Dr Michael and Susie Dillbeck on technologies of consciousness.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Feb_12.mp4",
          },
          {
            day: 14,
            description:
              "Dr Bobby Roth reports on success in USA. Celebration of Dr. Chris Crowell's birthday, Minister of Religion and Culture of the Global Country of World Peace. Raja Luis reports on the success of a course on the Ramayan in Human Physiology in the Brahmasthan of India for a group of Latin America.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Feb_12.mp4",
          },
          {
            day: 13,
            description: "Colonel Chassé reports on Invincible Defense.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Feb_12.mp4",
          },
          {
            day: 12,
            description:
              "Reading of Science of Being and Art of Living by Maharishi",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Feb_12.mp4",
          },
          {
            day: 10,
            description:
              "Dr Girishi Momaya announces the next MERU Global Spring Assembly and Raja Ior reports his success in his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Feb_12.mp4",
          },
          {
            day: 08,
            description: "Raja Peter reporting on success in his domain",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Feb_12.mp4",
          },
          {
            day: 0o7,
            description:
              "Raja Hagelin on the influence of large groups of Yogic Flyers",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Feb_12.mp4",
          },
          {
            day: 0o6,
            description:
              "Dr Bevan Morris describes his visit to Ghana, Ivory Coast and Togo",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Feb_12.mp4",
          },
          {
            day: 0o5,
            description:
              "Reading of Science of Being and Art of Living by Maharishi",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Feb_12.mp4",
          },
          {
            day: 0o4,
            description: "Dr Tetey Orgle, National Director of Ghana",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Feb_12.mp4",
          },
        ],
      },
      {
        monthName: "Mar",
        days: [
          {
            day: 31,
            description:
              "Dr Bevan Morris continues his thrilling 14-country Africa tour through Uganda, Tanzania and The Seychelles",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_Mar_12.mp4",
          },
          {
            day: 29,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Mar_12.mp4",
          },
          {
            day: 25,
            description:
              "Reading of Science of Being and Art of Living by Maharishi.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Mar_12.mp4",
          },
          {
            day: 27,
            description:
              "Oprah’s visit to Fairfield is broadcast. Raja Luis details growing invincibility in Latin America.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Mar_12.mp4",
          },
          {
            day: 24,
            description:
              "Dr Bevan Morris continues his thrilling 14-country Africa tour through Botswana, Kenya and Uganda",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Mar_12.mp4",
          },
          {
            day: 23,
            description:
              "MERU Spring Assembly will feature Historic Lectures of Maharishi in Africa",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Mar_12.mp4",
          },
          {
            day: 21,
            description:
              "Birthday Celebration of Raja Rafael with Raja Chancellor and Dr Eike Hartman. News from Maharishi Smarak in India.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Mar_12.mp4",
          },
          {
            day: 20,
            description: "News from Maharishi University of Management, USA.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Mar_12.mp4",
          },
          {
            day: 19,
            description: "Raja Michael reports on the successes in his Domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Mar_12.mp4",
          },
          {
            day: 16,
            description:
              "Birthdays of Dr. Benjamin Feldman, Kuber, and Raja Paul, Raja of Canada and Turkey.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Mar_12.mp4",
          },
          {
            day: 14,
            description:
              "Raja Peter reports on the Invincible Europe Assembly in the Maharishi Golden Dome in Skelmersdale, England.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Mar_12.mp4",
          },
          {
            day: 13,
            description:
              "Adam Pressman reports on success in the USA. Raja Luis on the success of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Mar_12.mp4",
          },
          {
            day: 11,
            description:
              "Reading of Science of Being and Art of Living by Maharishi.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Mar_12.mp4",
          },
          {
            day: 9,
            description:
              "Dr. Morris recounts the latest leg of his Africa tour.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Mar_12.mp4",
          },
          {
            day: 7,
            description:
              "Maharaja Adhiraj Rajaraam gives brilliant insights into his new book Ramayana in Human Physiology",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Mar_12.mp4",
          },
          {
            day: 6,
            description:
              "NBA students of MUM USA reporting. Raja Luis reporting on the success in his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Mar_12.mp4",
          },
          {
            day: 4,
            description:
              "Reading of Science of Being and Art of Living by Maharishi.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Mar_12.mp4",
          },
          {
            day: 3,
            description: "Dr. Bevan Morris birthday celebration",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Mar_12.mp4",
          },
          {
            day: 1,
            description:
              "Raja Michael and Dr. Susie Dillbeck on scientific research on Transcendental Meditation and TM-Sidhi Programme",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Mar_12.mp4",
          },
        ],
      },
      {
        monthName: "Apr",
        days: [
          {
            day: 30,
            description:
              "Dr. Bevan Morris recounts his trip in Tunisia, Greece, and Israel",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Apr_12.mp4",
          },
          {
            day: 29,
            description:
              "Reading of Science of Being and Art of Living by Maharishi",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Apr_12.mp4",
          },
          {
            day: 26,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Apr_12.mp4",
          },
          {
            day: 25,
            description:
              "Flag raising ceremonies on Akshaya Tritiya around world",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Apr_12.mp4",
          },
          {
            day: 23,
            description:
              "Raja Graham reports on the heartwarming progress of schools in Uganda",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Apr_12.mp4",
          },
          {
            day: 22,
            description:
              "Reading of Science of Being and Art of Living by Maharishi",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Apr_12.mp4",
          },
          {
            day: 21,
            description:
              'Raja Chancellor on the "New Paradigm" tour with Maharaja Ji.',
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Apr_12.mp4",
          },
          {
            day: 20,
            description: "Raja Peter on the achievements in his domain",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Apr_12.mp4",
          },
          {
            day: 18,
            description:
              "KHOE radio station at Maharishi University of Management, Fairfield, Iowa",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Apr_12.mp4",
          },
          {
            day: 15,
            description:
              "Reading of Science of Being and Art of Living by Maharishi",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Apr_12.mp4",
          },
          {
            day: 14,
            description:
              "Dr. Bevan Morris recounts his trip in Ethipia, Malta, and Italy",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Apr_12.mp4",
          },
          {
            day: 13,
            description:
              "Raja Chancellor presents “A New Paradigm: Veda, Consciousness, the Ramayana, and the Self”.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Apr_12.mp4",
          },
          {
            day: 12,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Apr_12.mp4",
          },
          {
            day: 11,
            description:
              "Kenya Expansion Projects Presented by Dr. Chris Crowell and Solomon Mwangi, National Director of Kenya",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Apr_12.mp4",
          },
          {
            day: 10,
            description:
              "Dr. Reinar Picha and Dr. Lothar Pirk presents programmes by the Ministry of Health of the Global Country of World Peace",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Apr_12.mp4",
          },
          {
            day: 9,
            description:
              "Dr. Girish Momaya announced two course on Maharishi Vedic Approach to health (see here for details) and Dr. Alarik and Cynthia Arenander complete their course: ‘Individual is Cosmic’.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Apr_12.mp4",
          },
          {
            day: 8,
            description:
              "Reading of Science of Being and Art of Living by Maharishi",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Apr_12.mp4",
          },
          {
            day: 7,
            description:
              "Raja John Hagelin and Raja Kingsley on his tour in South-Korea.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Apr_12.mp4",
          },
          {
            day: 5,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Apr_12.mp4",
          },
          {
            day: 2,
            description:
              'Raja Peter presenting the concept of "eco-house" with the Vastu coordinators of UK.',
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Apr_12.mp4",
          },
        ],
      },
      {
        monthName: "May",
        days: [
          {
            day: 31,
            description:
              "Guru Purnima announcement video. Dr. Ashley Dean birthday presentation and Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_May_12.mp4",
          },
          {
            day: 30,
            description:
              "Yoga Asana course available online, Poland is planning for invincibility with Maharishi Vastu. 16-Lesson Maharishi Yoga Asana course will be available worldwide over the internet, starting on the 4th of June 2012",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_May_12.mp4",
          },
          {
            day: 29,
            description:
              "Dr. Bill Sands and Acharya Gopal Krishna Dave from MUM, Fairfield, IA, USA on Vedic literature and Sanskrit",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_May_12.mp4",
          },
          {
            day: 27,
            description:
              "Reading of Maharishi’s Science of Being and Art of Living",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_May_12.mp4",
          },
          {
            day: 26,
            description:
              "Special broadcast from the Ministry of Architecture of the Global Country of World Peace feature Dr. Eike Hartmann on the occasion of his birthday.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_May_12.mp4",
          },
          {
            day: 25,
            description:
              "Dr. Swan recounts his recent trip in Maharishi University of Management, Fairfield, IA, and Maharishi Vedic City, USA.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_May_12.mp4",
          },
          {
            day: 24,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_May_12.mp4",
          },
          {
            day: 22,
            description:
              "Maharishi University of Management reports on Vedic Literature archives.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_May_12.mp4",
          },
          {
            day: 21,
            description: "Raja Peter reports on achievements in his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_May_12.mp4",
          },
          {
            day: 20,
            description:
              "Reading of Maharishi’s Science of Being and Art of Living",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_May_12.mp4",
          },
          {
            day: 15,
            description: "Raja Harris and Dr. Larry Chroman.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_May_12.mp4",
          },
          {
            day: 13,
            description:
              "Reading of Maharishi's Science of Being and Art of Living",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_May_12.mp4",
          },
          {
            day: 12,
            description: "Dr Morris on his trip in Israel and Turkey.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_May_12.mp4",
          },
          {
            day: 10,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_May_12.mp4",
          },
          {
            day: 08,
            description:
              "Dr. Robert Roth on USA and Raja Luis for Latin America's latest achievements",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_May_12.mp4",
          },
          {
            day: 0o7,
            description: "Raja Paul on the achievement in Canada",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_May_12.mp4",
          },
          {
            day: 0o6,
            description:
              "Reading of Maharishi's Science of Being and Art of Living",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_May_12.mp4",
          },
          {
            day: 0o3,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_May_12.mp4",
          },
          {
            day: 0o2,
            description: "Interview",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_May_12.mp4",
          },
          {
            day: 0o1,
            description:
              "Dr. Girish Momaya announces MERU Courses. Raja Luis reports on his domain",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_May_12.mp4",
          },
        ],
      },
      {
        monthName: "June",
        days: [
          {
            day: 28,
            description:
              "Celebrating 18 new graduates from the most recent course on Maharishi Aroma Therapy in MERU, Holland",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Jun_12.mp4",
          },
          {
            day: 26,
            description:
              "MUM Dr. Craig Pearson talks about MUM, Fairfield, IA, USA and Raja Luis on achievements in Latin America",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Jun_12.mp4",
          },
          {
            day: 25,
            description: "MUM Commencement 2012, part 3",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Jun_12.mp4",
          },
          {
            day: 24,
            description:
              "Reading of Maharishi’s Science of Being and Art of Living",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Jun_12.mp4",
          },
          {
            day: 23,
            description: "MUM Commencement 2012, part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Jun_12.mp4",
          },
          {
            day: 22,
            description: "MUM Commencement 2012, part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Jun_12.mp4",
          },
          {
            day: 21,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Jun_12.mp4",
          },
          {
            day: 20,
            description:
              "Video of the Sustainable Living Center, at the Maharishi University of Management, Faifield, IA, USA",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Jun_12.mp4",
          },
          {
            day: 19,
            description:
              "Last part of Dr Jon Lipman interviewed by Dr. Swan on the Sustainable Living Center, at the Maharishi University of Management, Faifield, IA, USA",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Jun_12.mp4",
          },
          {
            day: 18,
            description:
              "Raja Peter Warburton reviews the latest achievements of his domain",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Jun_12.mp4",
          },
          {
            day: 16,
            description:
              "Walter Moelk concludes the International Training Course for Maharishi Aroma Therapy Consultants with course participants.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Jun_12.mp4",
          },
          {
            day: 15,
            description:
              "Dr Alarik and Dr Cynthia Arenander explains the latest finding in the Vedic sounds and health issues.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Jun_12.mp4",
          },
          {
            day: 14,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Jun_12.mp4",
          },
          {
            day: 12,
            description:
              "Dr Jon Lipman interviewed by Dr. Swan on the Sustainable Living Center, at the Maharishi University of Management, Faifield, IA, USA",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Jun_12.mp4",
          },
          {
            day: 11,
            description:
              "Dr. Girish Momaya interviewed on Lilou's Juicy Living Tour EUROPE",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Jun_12.mp4",
          },
          {
            day: 10,
            description:
              "Reading of Maharishi’s Science of Being and Art of Living",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Jun_12.mp4",
          },
          {
            day: 9,
            description:
              "The Monthly Donor programme of the Brahmananda Saraswati Foundation is quickly increasing the number of Vedic Pandits at the Brahmasthan of India and also student Pandits in training.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Jun_12.mp4",
          },
          {
            day: 8,
            description:
              "Mini-World Peace Assembly in Brussels to support the European Summit presented by Dr Stijn Vandenbosch, National Director of Belgium. New Belgian website achieving excellent results presented by Joachim Claes.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Jun_12.mp4",
          },
          {
            day: 7,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Jun_12.mp4",
          },
          {
            day: 6,
            description:
              "Broadcast of the concluding meeting of the vastu builder seminar in MERU",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Jun_12.mp4",
          },
          {
            day: 5,
            description: "Dr Craig Pearson from MUM and Raja Luis",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Jun_12.mp4",
          },
          {
            day: 4,
            description:
              "Celebrating the 30th Anniversary of Maharishi's inauguration ofthe Maharishi Vedic Pandit Programme",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Jun_12.mp4",
          },
          {
            day: 3,
            description:
              "Reading of Maharishi’s Science of Being and Art of Living",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Jun_12.mp4",
          },
          {
            day: 1,
            description:
              "Replay of part of the presentation on Ramayan in the Physiology by Maharaja Adhiraj Rajaraam in Chicago presented by Raja Chancellor.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Jun_12.mp4",
          },
        ],
      },
      {
        monthName: "July",
        days: [
          {
            day: 31,
            description: "Raja Hagelin on the Higgs Boson.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_Jul_12.mp4",
          },
          {
            day: 29,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Bevan Morris and Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Jul_12.mp4",
          },
          {
            day: 27,
            description: "Maharishi University of Management Achievements 2012",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Jul_12.mp4",
          },
          {
            day: 26,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Jul_12.mp4",
          },
          {
            day: 25,
            description:
              "Dr. Eckart and Lila-Maria Stein on the new Maharishi Peace Palace in the Brahmasthan of Germany",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Jul_12.mp4",
          },
          {
            day: 23,
            description: "Raja Michael Dillbeck Research Presentation",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Jul_12.mp4",
          },
          {
            day: 22,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Bevan Morris and Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Jul_12.mp4",
          },
          {
            day: 21,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Jul_12.mp4",
          },
          {
            day: 20,
            description:
              "Raja Graham de Freitas and Raja Bright’s birthday celebration",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Jul_12.mp4",
          },
          {
            day: 19,
            description:
              "Many new exciting courses are presented. See www.maharishiglobalcalendar.org for more details on International courses and Holland courses at MERU.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Jul_12.mp4",
          },
          {
            day: 17,
            description:
              'Conclusion of the course "Individual is Cosmic" with Dr. Walter Moelk and course leaders Dr. Alarik and Cynthia Arenander and all course participants.',
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Jul_12.mp4",
          },
          {
            day: 16,
            description:
              "Replay of Dr. Girish C. Varma's offerings during the Guru Purnima Celebrations 2012 at MERU.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Jul_12.mp4",
          },
          {
            day: 15,
            description:
              "Starting the reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Bevan Morris and Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Jul_12.mp4",
          },
          {
            day: 14,
            description:
              "Raja Hagelin on the Higg Boson discovery and Raja Konhaus on achievement in his domain during the Guru Purnima Celebrations 2012",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Jul_12.mp4",
          },
          {
            day: 13,
            description:
              "Raja Paul and Dr. John Cowhig present the Ganga Island project on the west coast of Canada and show Maharishi Vastu houses built on the island.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Jul_12.mp4",
          },
          {
            day: 12,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Jul_12.mp4",
          },
          {
            day: 11,
            description:
              "Raja Paul summarizes the achievements in Canada with long time governor Dr. John Cowhig.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Jul_12.mp4",
          },
          {
            day: 10,
            description:
              "Replay of Raja Hagelin address on Guru Purnima 2012 and Raja Luis latest achieivements in his domain",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Jul_12.mp4",
          },
          {
            day: 9,
            description:
              "Raja Peter on good news around the world and with Dr. Donka, Nationa Director of Bulgaria",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Jul_12.mp4",
          },
          {
            day: 8,
            description:
              "Reading of Maharishi’s Science of Being and Art of Living",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Jul_12.mp4",
          },
        ],
      },
      {
        monthName: "Aug",
        days: [
          {
            day: 30,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Aug_12.mp4",
          },
          {
            day: 26,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Aug_12.mp4",
          },
          {
            day: 25,
            description:
              "Raja Willem presenting guests from his domain. Birthday of Dr. Girish C. Varma. Announcing the Summer and Autumn Courses on the island of Hvar, Croatia",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Aug_12.mp4",
          },
          {
            day: 24,
            description:
              "Training To Be a National Expert in Bringing Consciousness-Based Education Programmes to Your Nation Graduation Ceremony",
            videoSrc: "http://streaming.mou.org/MOU2/chat/24_Aug_12.mp4",
          },
          {
            day: 23,
            description:
              "Introduction to Maharishi Vedic Science in Agriculture and Environment by Dr Peter Swan and Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Aug_12.mp4",
          },
          {
            day: 22,
            description: "News report from Raja Peter",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Aug_12.mp4",
          },
          {
            day: 21,
            description:
              "Presentation of new house design by Jon Lipman, US National Minister of Architecture of USA",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Aug_12.mp4",
          },
          {
            day: 20,
            description:
              "Dr Bevan Morris recount his recent trip in the Balkan countries and east european countries",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Aug_12.mp4",
          },
          {
            day: 19,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Aug_12.mp4",
          },
          {
            day: 17,
            description: "Raja Felix reports inspiration news from his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Aug_12.mp4",
          },
          {
            day: 16,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Aug_12.mp4",
          },
          {
            day: 14,
            description:
              "Adam Pressman presents a short video on the Gala to Benefit Veterans and First Responders—Operation Warrior Wellness",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Aug_12.mp4",
          },
          {
            day: 12,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Aug_12.mp4",
          },
          {
            day: 9,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Aug_12.mp4",
          },
          {
            day: 7,
            description:
              "Raja Luis inspires everyone with inspiring achievements in his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Aug_12.mp4",
          },
          {
            day: 6,
            description:
              "Dr Alarik Arenander and Dr Otto Odermatt on the latest news from Seelisberg, Switzerland",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Aug_12.mp4",
          },
          {
            day: 5,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/05_Aug_12.mp4",
          },
          {
            day: 3,
            description:
              "Dr Girish Momaya presents the future courses at MERU: www.merucourses.com",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Aug_12.mp4",
          },
        ],
      },
      {
        monthName: "Sept",
        days: [
          {
            day: 30,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Sep_12.mp4",
          },
          {
            day: 29,
            description: "Dr. Peter Swan reviews information about GM food",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Sep_12.mp4",
          },
          {
            day: 27,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Sep_12.mp4",
          },
          {
            day: 26,
            description:
              "Raja Kingsley reports from South Korea and other countries of his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Sep_12.mp4",
          },
          {
            day: 25,
            description:
              "Col. Gunter Chasse and Dr Stijn Vandenbosch on a project to create a group Yogic Flying experts in Brussels. Raja Luis on establishing schools in his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Sep_12.mp4",
          },
          {
            day: 23,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Sep_12.mp4",
          },
          {
            day: 21,
            description:
              "Introduction to Maharishi Vedic Agriculture by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Sep_12.mp4",
          },
          {
            day: 20,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Sep_12.mp4",
          },
          {
            day: 19,
            description: "Maharishi's address on Ganesh Chaturthi Celebration",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Sep_12.mp4",
          },
          {
            day: 18,
            description:
              "Maharishi School of the Age of Enlightenment, Fairfield, IA video. Raja Peter on the latest course for Young Meditators in Hvar, Croatia.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Sep_12.mp4",
          },
          {
            day: 17,
            description:
              "Dr Bevan Morris recounts his latest experience on his tour of China",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Sep_12.mp4",
          },
          {
            day: 16,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Sep_12.mp4",
          },
          {
            day: 15,
            description: "MUM’s Founder Day celebration",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Sep_12.mp4",
          },
          {
            day: 13,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Sep_12.mp4",
          },
          {
            day: 12,
            description:
              "Raja Harris presents the latest news from Maharishi Vedic Pandits through the Brahmananda Saraswati Fund organization.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Sep_12.mp4",
          },
          {
            day: 11,
            description:
              "Raja Hagelin letter from Invincible America, Flow of Consciousness book quotes, and Raja Luis report",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Sep_12.mp4",
          },
          {
            day: 09,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Sep_12.mp4",
          },
          {
            day: 0o7,
            description:
              "Dr. Girish Momaya presented the exciting upcoming courses and assemblies at MERU, Holland and warmly invited all Governors, Sidhas and Meditators to take advantage of them. The courses will enhance their evolution through the knowledge of Maharishi’s Vedic Science, and deepen their experience of Trascendental Meditation and TM Sidhi programme with larger group programmes.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/07_Sep_12.mp4",
          },
          {
            day: 0o6,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Sep_12.mp4",
          },
          {
            day: 0o4,
            description:
              "Four MBA teams from Maharishi University of Management have finished in the top 10 out of 97 MBA teams from top universities throughout North America. The teams participated in the international CAPSIM Foundation simulation during the six-month period ending July 1, 2012",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Sep_12.mp4",
          },
          {
            day: 0o3,
            description:
              "Governor Recertification Course block 1 end of course expressions.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Sep_12.mp4",
          },
          {
            day: 0o2,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Sep_12.mp4",
          },
          {
            day: 0o1,
            description:
              "Maharishi Vedic Science in Agriculture and Environment—Key to Personal Enlightenment, Global Affluence and Sustainability\nEveryone is invited to attend the 16-lesson course from 9 to 15 September at MERU, Holland",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Sep_12.mp4",
          },
        ],
      },
      {
        monthName: "Oct",
        days: [
          {
            day: 31,
            description:
              "Vaidya Manohar outlines the nature of Mind in Maharishi Ayurveda",
            videoSrc: "http://streaming.mou.org/MOU2/chat/31_Oct_12.mp4",
          },
          {
            day: 30,
            description:
              "More Vijaya Dashami Flag raising ceremonies around the world and Raja Luis with news from his domain",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Oct_12.mp4",
          },
          {
            day: 29,
            description:
              "Maharishi Light Therapy with Gems course graduation ceremony",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Oct_12.mp4",
          },
          {
            day: 28,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Oct_12.mp4",
          },
          {
            day: 27,
            description:
              "Exposition on Maharishi Ayur-Veda from Vaidya Ravi Moos",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Oct_12.mp4",
          },
          {
            day: 26,
            description:
              "Dr Bevan Morris delights in visiting Korea and Mongolia",
            videoSrc: "http://streaming.mou.org/MOU2/chat/26_Oct_12.mp4",
          },
          {
            day: 25,
            description:
              "Vijaya Dashami Flag raising ceremonies around the world",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Oct_12.mp4",
          },
          {
            day: 20,
            description: "Purusha Anniversary special presentation",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Oct_12.mp4",
          },
          {
            day: 15,
            description: "Raja Peter on achievements from his domain.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Oct_12.mp4",
          },
          {
            day: 14,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Oct_12.mp4",
          },
          {
            day: 13,
            description:
              "Dr. Girish Momamay presents the up-coming new courses at MERU, including new treatments at the newly opened Maharishi Ayur-Veda clinic in Sint-Odilienberg",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Oct_12.mp4",
          },
          {
            day: 12,
            description:
              "Graduation ceremony of the Maharishi Yoga Asana TTC course conducted by Dr. Walter Moelk and Dr. Girish Momaya",
            videoSrc: "http://streaming.mou.org/MOU2/chat/12_Oct_12.mp4",
          },
          {
            day: 11,
            description:
              "Raja Tom Stanley reports achievements in his Domain specially in Mongolia",
            videoSrc: "http://streaming.mou.org/MOU2/chat/11_Oct_12.mp4",
          },
          {
            day: 10,
            description:
              "Continuation of the health research presentation with Dr. Norman Rosenthal",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Oct_12.mp4",
          },
          {
            day: 9,
            description:
              "Adam Pressman presents his latest video on health research from USA with Dr. Norman Rosenthal.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Oct_12.mp4",
          },
          {
            day: 8,
            description: "New MUM online course to learn Sanskrit",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Oct_12.mp4",
          },
          {
            day: 3,
            description: "All-Africa Parternship for Peace",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Oct_12.mp4",
          },
          {
            day: 2,
            description: "Purusha Capital of the Western World, WV, USA",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Oct_12.mp4",
          },
          {
            day: 1,
            description: "Raja Dr. Ior birthday celebration",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Oct_12.mp4",
          },
        ],
      },
      {
        monthName: "Nov",
        days: [
          {
            day: 29,
            description:
              "Raja Paul is joined by leaders to report on Invincible Canada",
            videoSrc: "http://streaming.mou.org/MOU2/chat/29_Nov_12.mp4",
          },
          {
            day: 28,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck",
            videoSrc: "http://streaming.mou.org/MOU2/chat/28_Nov_12.mp4",
          },
          {
            day: 27,
            description:
              "Strongest Study Yet Shows Meditation Can Lower Risk of Heart Attack and Stroke.”—TIME Magazine with Dr. Robert Schneider.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Nov_12.mp4",
          },
          {
            day: 25,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Nov_12.mp4",
          },
          {
            day: 23,
            description:
              "Maharishi University of Vedic Medicine is pleased to announce the start of its new online course Human Physiology—Expression of Veda and the Vedic Literature. Online Course – Starting date: 3 December 2012",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Nov_12.mp4",
          },
          {
            day: 22,
            description:
              "Col. Gunter Chasse invited Governors and Sidhas to join the “Representation of the Global Country of World Peace” in Brussels.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/22_Nov_12.mp4",
          },
          {
            day: 21,
            description:
              "Raja Paul talks about the next WPA in Turkey and other achievements in Turkey with Dr. Albert Baruh",
            videoSrc: "http://streaming.mou.org/MOU2/chat/21_Nov_12.mp4",
          },
          {
            day: 20,
            description:
              "Stress, addiction, meditation and self-healing conference—part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Nov_12.mp4",
          },
          {
            day: 19,
            description:
              "Dr Alarik Arenander announces his new course: Aging Brain Strategy",
            videoSrc: "http://streaming.mou.org/MOU2/chat/19_Nov_12.mp4",
          },
          {
            day: 18,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Nov_12.mp4",
          },
          {
            day: 17,
            description:
              "Dr Girish Momaya and Dr Lothar Pirc on the 20th Vedanta International Congress, at the Jawaharlal Nehru University, Delhi, India",
            videoSrc: "http://streaming.mou.org/MOU2/chat/17_Nov_12.mp4",
          },
          {
            day: 15,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck",
            videoSrc: "http://streaming.mou.org/MOU2/chat/15_Nov_12.mp4",
          },
          {
            day: 14,
            description:
              "Eckard and Lila-Maria Stein recount the construction of the newly inaugurated and first Maharishi Peace Palace in Erfurt, Germany",
            videoSrc: "http://streaming.mou.org/MOU2/chat/14_Nov_12.mp4",
          },
          {
            day: 10,
            description:
              "Science, Consciousness, Veda and the Self. Lecture by John Hagelin, PhD part of The Global Peace Initiative",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Nov_12.mp4",
          },
          {
            day: 9,
            description:
              "New Paradign Tour by Maharaja Adhiraj Raam at the Meadows Club, Chicago —part 1",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Nov_12.mp4",
          },
          {
            day: 8,
            description:
              "Raja John Konhaus on his trip in Egypt, one country of his domain",
            videoSrc: "http://streaming.mou.org/MOU2/chat/08_Nov_12.mp4",
          },
          {
            day: 4,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Nov_12.mp4",
          },
          {
            day: 3,
            description:
              "Raja Felix on a conference call on achievements in his domain",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Nov_12.mp4",
          },
          {
            day: 1,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Nov_12.mp4",
          },
        ],
      },
      {
        monthName: "Dec",
        days: [
          {
            day: 30,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/30_Dec_12.mp4",
          },
          {
            day: 27,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck",
            videoSrc: "http://streaming.mou.org/MOU2/chat/27_Dec_12.mp4",
          },
          {
            day: 25,
            description:
              "Two reports on Maharishi Vastu highlight the great progress in popularizing this essential aspect of Maharishi’s technologies for peace",
            videoSrc: "http://streaming.mou.org/MOU2/chat/25_Dec_12.mp4",
          },
          {
            day: 23,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/23_Dec_12.mp4",
          },
          {
            day: 20,
            description:
              "Latest devleopment on the Maharishi Smarak being finalized in Allahabad, India and news of the upcoming Kumbha mela with Dr. Eike Hartmann.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/20_Dec_12.mp4",
          },
          {
            day: 18,
            description:
              "Prof. Gurdy Leete and Stuart Tanner from MUM M.A. in Filmmaking at MUM, Fairfield, IA and Raja Luis on the updated flying time and bank details for the 21st December global celebration in Mexico.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/18_Dec_12.mp4",
          },
          {
            day: 16,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/16_Dec_12.mp4",
          },
          {
            day: 13,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck",
            videoSrc: "http://streaming.mou.org/MOU2/chat/13_Dec_12.mp4",
          },
          {
            day: 10,
            description:
              "Breaking news from the United Kingdom with Raja Peter.",
            videoSrc: "http://streaming.mou.org/MOU2/chat/10_Dec_12.mp4",
          },
          {
            day: 09,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/09_Dec_12.mp4",
          },
          {
            day: 0o6,
            description:
              "Scientific research review on Transcendental Meditation and TM-Sidhi programmes by Raja Michael Dillbeck and Dr. Susie Dillbeck",
            videoSrc: "http://streaming.mou.org/MOU2/chat/06_Dec_12.mp4",
          },
          {
            day: 0o4,
            description:
              "Stress, addiction, meditation and self-healing conference—part 2",
            videoSrc: "http://streaming.mou.org/MOU2/chat/04_Dec_12.mp4",
          },
          {
            day: 0o3,
            description:
              "Richard Beall on the Maharishi School of the Age of Enlightenment in Fairfield, IA, USA",
            videoSrc: "http://streaming.mou.org/MOU2/chat/03_Dec_12.mp4",
          },
          {
            day: 0o2,
            description:
              "Reading of the book ‘Ramayan in Human Physiology’ by Maharaja Adhiraj Rajaraam. Reading by Dr. Peter Swan",
            videoSrc: "http://streaming.mou.org/MOU2/chat/02_Dec_12.mp4",
          },
          {
            day: 0o1,
            description:
              "Dr. Girish Momaya announced the Global Winter Assemblies for next January and all the other courses offered at MERU for 2013",
            videoSrc: "http://streaming.mou.org/MOU2/chat/01_Dec_12.mp4",
          },
        ],
      },
    ],
  },
];
